import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import { getEntriesFromResponseData } from 'helpers/sagaAndReducerHelper';


function* createCompanySaga({ company, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.COMPANY_CREATE_OR_UPDATE,
			company
		);
		yield put(actions.companyCreateSuccess(response.data));
		yield put(actions.companyGetAll());
		history.push(endpointsFrontend.COMPANY_BROWSE);
	} catch (e) {
		yield put(actions.companyCreateError(e));
	}
}

function* deleteCompanySaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.COMPANY_GET_BY_ID.replace("{companyId}", id)
		);
		yield put(actions.companyDeleteSuccess());
		yield put(actions.companyGetAll());
		history.push(endpointsFrontend.COMPANY_BROWSE);
	} catch (e) {
		yield put(actions.companyDeleteError(e));
	}
}

function* getAllCompaniesSaga() {

	try {
		const responseAll = yield call(
			rest.get,
			endpointsBackend.COMPANY_GET_ALL
		);
		const companies = getEntriesFromResponseData(responseAll.data);

		if (companies.length) {

			let selectedCompany;

			const existingCompanyId = parseInt(yield localStorage.getItem('companyId'));

			if (existingCompanyId) {

				const filteredCompanies = companies.filter(company => company.id === existingCompanyId);
				if (filteredCompanies.length) {
					selectedCompany = filteredCompanies[0];
				}
			}

			if (!selectedCompany) {

				const responseLast = yield call(
					rest.get,
					endpointsBackend.COMPANY_GET_LAST
				);
				selectedCompany = responseLast.data;

				yield localStorage.setItem('companyId', selectedCompany.id);

			}

			yield put(actions.companyGetAllSuccess(companies, selectedCompany));

		} else {

			yield put(actions.companyNoneAvailable());

		}

	} catch (e) {
		yield put(actions.companyGetError(e));
	}

}


function* getAllCompaniesForTenantSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COMPANY_GET_ALL_FOR_TENANT
		);
		const companies = getEntriesFromResponseData(response.data);

		yield put(actions.companyGetAllForTenantSuccess(companies));

	} catch (e) {
		yield put(actions.companyGetError(e));
	}
}


function* getCompanyByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COMPANY_GET_BY_ID.replace("{companyId}", id)
		);
		yield put(actions.companyGetSuccess(response.data));
	} catch (e) {
		yield put(actions.companyGetError(e));
	}
}


function* setSelectedCompanySaga({ payload: { selectedCompany, history } }) {

	yield localStorage.setItem('companyId', selectedCompany.id);

	//yield history.push(endpointsFrontend.PLACEHOLDER);

}


function* companySaga() {
	yield (takeLatest(actionTypes.COMPANY_CREATE, createCompanySaga));
	yield (takeLatest(actionTypes.COMPANY_DELETE, deleteCompanySaga));
	yield (takeLatest(actionTypes.COMPANY_GET_ALL, getAllCompaniesSaga));
	yield (takeLatest(actionTypes.COMPANY_GET_ALL_FOR_TENANT, getAllCompaniesForTenantSaga));
	yield (takeLatest(actionTypes.COMPANY_GET_BY_ID, getCompanyByIdSaga));
	yield (takeLatest(actionTypes.COMPANY_SET_SELECTED, setSelectedCompanySaga));
}

export default companySaga;