import * as dateUtils from "./dateAndTimeUtils";

export const splitTasksPerDueDate = (taskList) => {
    const tasksDueToday = [];
    const tasksDueThisWeek = [];
    const tasksDueThisMonth = [];
    const overdueTasks = [];

    for (let i in taskList) {
        const task = taskList[i];
        const taskDueDate = new Date(Date.parse(task.dueDate));

        if (dateUtils.compareAsDates(taskDueDate, new Date()) === -1) {

            tasksDueToday.push(task);
            overdueTasks.push(task);

        } else if (taskDueDate.getFullYear() === (new Date()).getFullYear()) {

            if (taskDueDate.getMonth() === (new Date()).getMonth()) {

                if (dateUtils.getWeekNumber(taskDueDate) === dateUtils.getWeekNumber(new Date())) {


                    if (taskDueDate.getDate() === (new Date()).getDate()) {
                        tasksDueToday.push(task);
                    } else {
                        tasksDueThisWeek.push(task);
                    }

                } else {
                    tasksDueThisMonth.push(task);
                }
            }
        }

    }

    return {
        tasksDueToday: tasksDueToday,
        tasksDueThisWeek: tasksDueThisWeek,
        tasksDueThisMonth: tasksDueThisMonth,
        overdueTasks: overdueTasks
    }
}

export const updateTasks = (tasks, updatedTask) => {
	if (tasks && tasks.length) {
		const updatedTasks = [];
		let taskFoundAndUpdated = false;
		tasks.map(task => {
			if (task.id === updatedTask.id) {
				updatedTasks.push(updatedTask);
				taskFoundAndUpdated = true;
			} else {
				updatedTasks.push(task);
			}
		});
		if (!taskFoundAndUpdated) {
			updatedTasks.push(updatedTask);
		} else {
            return updatedTasks;
        }
	} else {
		return [ updatedTask ];
	}
}

export const deleteTask = (tasks, deletedTaskId) => {
	if (tasks && tasks.length) {
		return tasks.filter(task => task.id !== deletedTaskId);
	} else {
		return [ ];
	}
}

