import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';

import Backdrop from "components/Common/Backdrop";
import classes from './Legal.module.css';

const Legal = (props) => {
    return (
        props.show
            ?
            (<div className={classes.LegalContainer}>
                <Backdrop
                    show
                    onClick={props.onClick}
                />
                <Card
                    className={classes.LegalCard}
                >
                    <CardBody>
                        <div className={classes.LegalContents}>
                            {props.children}
                        </div>
                    </CardBody>
                </Card>
            </div>)
            : null
    )
}

export default Legal;