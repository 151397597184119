export const MergeStatementPeriod = [
	{ code: 0, id: "NONE", description: "None" }, 
	{ code: 10, id: "BY_DAY", description: "By day" }, 
	{ code: 20, id: "BY_WEEK", description: "By week" }, 
	{ code: 25, id: "BY_WEEK_AND_MONTH", description: "By week and month" }, 
	{ code: 30, id: "BY_MONTH", description: "By month" }, 
];

export const MergeStatementPeriodAsSelectFilterOptions = {
	"NONE": "None",
	"BY_DAY": "By day",
	"BY_WEEK": "By week",
	"BY_WEEK_AND_MONTH": "By week and month",
	"BY_MONTH": "By month",
};