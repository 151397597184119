import * as actionTypes from "./actionTypes";

export const tagCreate = (tag, history) => {
	return {
		type: actionTypes.TAG_CREATE,
		tag: tag, 
		history: history
	}
}

export const tagCreateError = (thisError) => {
	return {
		type: actionTypes.TAG_CREATE_ERROR,
		error: thisError
	}
}

export const tagCreateSuccess = () => {
	return {
		type: actionTypes.TAG_CREATE_SUCCESS
	}
}

export const tagDelete = (id, history) => {
	return {
		type: actionTypes.TAG_DELETE,
		id: id,
		history: history
	}
}

export const tagDeleteError = (thisError) => {
	return {
		type: actionTypes.TAG_DELETE_ERROR,
		error: thisError
	}
}

export const tagDeleteSuccess = () => {
	return {
		type: actionTypes.TAG_DELETE_SUCCESS
	}
}

export const tagGetAll = () => {
	return {
		type: actionTypes.TAG_GET_ALL
	}
}

export const tagGetById = (id) => {
	return {
		type: actionTypes.TAG_GET_BY_ID,
		id: id
	}
}

export const tagGetError = (thisError) => {
	return {
		type: actionTypes.TAG_GET_ERROR,
		error: thisError
	}
}

export const tagGetSuccess = (data) => {
	return {
		type: actionTypes.TAG_GET_SUCCESS,
		tags: data
	}
}

