export const PaymentDateToUse = [
	{ code: 0, id: "TODAY", description: "Today" }, 
	{ code: 1, id: "TOMORROW", description: "Tomorrow" }, 
	{ code: 10, id: "FROM_DUE_DATE_EARLIEST", description: "From due date (earliest of grouped invoices)" }, 
	{ code: 11, id: "FROM_DUE_DATE_LATEST", description: "From due date (latest of grouped invoices)" }, 
	{ code: 20, id: "FROM_DISCOUNT_DUE_DATE_EARLIEST", description: "From discount due date (earliest applicable of grouped invoices)" }, 
];

export const PaymentDateToUseAsSelectFilterOptions = {
	"TODAY": "Today",
	"TOMORROW": "Tomorrow",
	"FROM_DUE_DATE_EARLIEST": "From due date earliest",
	"FROM_DUE_DATE_LATEST": "From due date latest",
	"FROM_DISCOUNT_DUE_DATE_EARLIEST": "From discount due date earliest",
};