import * as actionTypes from "./actionTypes";

const initialState = {
    externalDataSyncRequests: null,
    loading: false,
    error: null,
    updating: false
}

const updateExternalDataSyncRequests = (requests, updatedRequest) => {
    const result = [];
    for (let i in requests) {
        if (requests[i].id === updatedRequest.id) {
            result.push(updatedRequest);
        } else {
            result.push(requests[i]);
        }
    }
    return result;
}

export default function externalDataSyncRequestReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE:
            return {
                ...state,
                error: null,
                updating: true
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE_ERROR:
            return {
                ...state,
                error: action.error,
                updating: false
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE_SUCCESS:
            return {
                ...state,
                externalDataSyncRequests: [action.createdRequest, ...state.externalDataSyncRequests], // We always push createdRequest on top, in case we later decide to remove the createdDateTime column from the frontend and rely on backend sorting only
                updating: false
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE:
            return {
                ...state,
                error: null,
                updating: true
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE_ERROR:
            return {
                ...state,
                error: action.error,
                updating: false
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                externalDataSyncRequests: updateExternalDataSyncRequests(state.externalDataSyncRequests, action.updatedRequest),
                updating: false
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL:
            return {
                ...state,
                error: null,
                loading: true
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL_WITHOUT_SPINNER:
            return {
                ...state,
                error: null
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                externalDataSyncRequests: action.externalDataSyncRequests
            }
        case actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state;
    }
}