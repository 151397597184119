export const saveLastUser = (username, tenantExternalId) => {
    const lastUsername = localStorage.getItem('username');
    const lastTenantExternalId = localStorage.getItem('tenantExternalId');

    if (username !== lastUsername || tenantExternalId !== lastTenantExternalId) {
        localStorage.setItem('username', username);
        localStorage.setItem('tenantExternalId', tenantExternalId);
        localStorage.removeItem('companyId');
    }
}

const userSmartIdDataVarName = (username) => {
    return 'smartIdLoginData-' + username.trim().toLowerCase();
}

const smartIdSessionDataVarName = () => {
    return 'smartIdSessionData';
}

export const saveUserSmartIdData = (username, countryCode, identityNumber) => {
    const data = {
        countryCode: countryCode,
        identityNumber: identityNumber
    };
    localStorage.setItem(userSmartIdDataVarName(username), JSON.stringify(data));
}

export const getUserSmartIdData = (username) => {
    const dataString = localStorage.getItem(userSmartIdDataVarName(username));
    if (dataString && dataString.length) {
        return JSON.parse(dataString);
    } else {
        return null;
    }
}

export const clearUserSmartIdData = (username) => {
    localStorage.removeItem(userSmartIdDataVarName(username));
}

export const saveLoginType = (loginType) => {
    localStorage.setItem("loginType", loginType);
}

export const getLoginType = () => {
    return localStorage.getItem("loginType");
}

export const clearLoginType = () => {
    localStorage.removeItem("loginType");
}

export const saveSmartIdSessionData = (data) => {
    localStorage.setItem(smartIdSessionDataVarName(), JSON.stringify(data));
}

export const getSmartIdSessionData = () => {
    const dataString = localStorage.getItem(smartIdSessionDataVarName());
    if (dataString && dataString.length) {
        return JSON.parse(dataString);
    } else {
        return null;
    }
}

export const clearSmartIdSessionData = () => {
    localStorage.removeItem(smartIdSessionDataVarName());
}

export const LOGIN_TYPE_PASSWORD = "password";
export const LOGIN_TYPE_SMART_ID = "smartId";