import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const name = {
	dataField: 'name',
	text: 'Name',
	filter: textFilter(),
	sort: true
};
export const tenantLicenses = {
	dataField: 'tenantLicenses',
	text: 'Licensed modules',
	filter: textFilter(),
	sort: true
};
export const tenantLicenseInfo = {
	dataField: 'tenantLicenseInfo',
	text: 'Licensed modules',
	filter: textFilter(),
	sort: true
}
export const expirationDateTime = {
	dataField: 'expirationDateTime',
	text: 'Expiration date time',
	filter: textFilter(),
	//formatter: (cell) => formatUtils.formatLocalDateTime(cell),
	//sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const admins = {
	dataField: 'admins',
	text: 'Administrators',
	filter: textFilter(),
	formatter: (cell) => cell && cell.length ? cell.join(", ") : "-",
	sort: true
};
