import * as actionTypes from "./actionTypes";

const initialState = {
	bankAccounts: null,
	deleting: false,
	error: null,
	loaded: false,
	loading: false,
	saving: false,
	testing: false,
	testSuccess: false
}

export default function bankAccountReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.BANK_ACCOUNT_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.BANK_ACCOUNT_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false,
			}
		case actionTypes.BANK_ACCOUNT_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saving: false
			}
		case actionTypes.BANK_ACCOUNT_DELETE:
			return {
				...state,
				deleting: true,
				error: null,
			}
		case actionTypes.BANK_ACCOUNT_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error,
			}
		case actionTypes.BANK_ACCOUNT_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
			}
		case actionTypes.BANK_ACCOUNT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				testSuccess: false
			}
		case actionTypes.BANK_ACCOUNT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true,
				testSuccess: false
			}
		case actionTypes.BANK_ACCOUNT_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			}
		case actionTypes.BANK_ACCOUNT_GET_SUCCESS:
			return {
				...state,
				bankAccounts: action.bankAccounts,
				loaded: true,
				loading: false,
			}
		case actionTypes.BANK_ACCOUNT_TEST_CONNECTION:
			return {
				...state,
				error: null,
				testing: true,
				testSuccess: false
			}
		case actionTypes.BANK_ACCOUNT_TEST_CONNECTION_ERROR:
			return {
				...state,
				error: action.error,
				testing: false
			}
		case actionTypes.BANK_ACCOUNT_TEST_CONNECTION_SUCCESS:
			return {
				...state,
				testing: false,
				testSuccess: true
			}
		default:
			return state;
	}
}