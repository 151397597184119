import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsUserAccount from '../../store/UserAccount/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class UserAccountInvite extends Component {

    constructor(props) {
        super(props);
        this.state = {
            invitationResponse: null,
            inviteeEmail: ""
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing

        const invitationData = {
            inviteeEmail: this.state.inviteeEmail
        }

        this.props.onInviteUser(invitationData);
    }

    componentDidMount() {
        this.setState({
            invitationResponse: null
        });
        /*
        if (this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            });
            this.props.onGetUserAccountById(this.props.match.params.id);
        }
        this.props.onGetTenantLicenses();
        */
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.invitationResponse !== this.props.invitationResponse) {
            this.setState({
                invitationResponse: this.props.invitationResponse
            });
        }
    }

    render() {
        const pageTitle = (this.state.id || this.props.loadingUserAccounts ? "Edit" : "Create") + " user account | " + config.AppName;
        const breadcrumbsTitle = "User Account";
        const breadcrumbsItem = (this.state.id || this.props.loadingUserAccounts ? "Edit" : "New") + " user account";

        const inviteSuccessAlert =
            this.state.invitationResponse ? (
                <Alert
                    color="success"
                >
                    An invitation has been sent to {this.state.invitationResponse.inviteeEmail}. The invitation is valid until {this.state.invitationResponse.validUntil}.
                </Alert>
            ) : null;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.textControl("inviteeEmail", "Email", this.onChange, this.state.inviteeEmail)}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={this.props.inviting}
                                        >
                                            {"Send invitation"}
                                            {" "}
                                            {this.props.inviting ? editFormControls.buttonSpinner() : null}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.props.error)}

                        {inviteSuccessAlert}

                        {editForm}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ userAccount }) => ({
    error: userAccount.error,
    invitationResponse: userAccount.invitationResponse,
    inviting: userAccount.inviting
})

const mapDispatchToProps = dispatch => ({
    onInviteUser: (invitationData) => dispatch(actionsUserAccount.userAccountInvite(invitationData))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountInvite);

