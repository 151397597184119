import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";

import * as actionsTenant from '../../store/Tenant/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import { modules } from "definitions/modules";

class TenantEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			externalId: "",
			name: "",
			tenantLicenses: [],
			admins: "",
			doesNotExpire: false,
			expirationDateTime: "",
			tenants: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing

		const tenantLicenses = modules
			.filter(module => {
				const stateFieldName = "has" + module.name;
				return this.state[stateFieldName];
			})
			.map(tenantLicense => "LICENSED_" + tenantLicense.name);

		let newOrUpdatedTenant = {
			externalId: this.state.externalId,
			name: this.state.name,
			tenantLicenses: this.state.tenantLicenses,
			doesNotExpire: this.state.doesNotExpire,
			expirationDateTime: this.state.doesNotExpire ? null : (this.state.expirationDateTime.length < 14 ? this.state.expirationDateTime.substring(0, 10) + " 00:00:00" : this.state.expirationDateTime),
			tenantLicenses: tenantLicenses
		}
		if (this.state.id) {
			newOrUpdatedTenant = {
				id: this.state.id,
				...newOrUpdatedTenant
			}
		}
		this.props.onCreateTenant(newOrUpdatedTenant, this.props.history);
	}

	onDeleteTenant = () => {
		if (window.confirm("Are you sure you want to delete this Tenant?")) {
			this.props.onDeleteTenant(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetTenantById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.tenants !== this.props.tenants) {
			if (this.props.tenants && this.props.tenants[0]) {

				this.setState({
					id: this.props.tenants[0].id,
					externalId: this.props.tenants[0].externalId,
					name: this.props.tenants[0].name,
					tenantLicenses: this.props.tenants[0].tenantLicenses,
					doesNotExpire: !this.props.tenants[0].expirationDateTime,
					expirationDateTime: this.props.tenants[0].expirationDateTime,
					admins: this.props.tenants[0].admins.join(", "),
				});

				const allLicenseNames = this.props.tenants[0].tenantLicenses;
				for (let key in allLicenseNames) {
					const stateFieldName = "has" + allLicenseNames[key].replace("LICENSED_", "");
					this.setState({
						[stateFieldName]: true
					})
				}

			} else {
				this.setState({
					id: "",
					externalId: "",
					name: "",
					tenantLicenses: [],
					expirationDateTime: ""
				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Tenant | " + config.AppName;
		const breadcrumbsTitle = "Tenant";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Tenant";

		const moduleSelectionControls = this.state.tenantLicenses
			? (modules
				// .filter(module => this.state.tenantLicenses.includes("LICENSED_" + module.name))
				.map((module, key) => {
					const stateFieldName = "has" + module.name;
					return (
						<div key={key}>
							{editFormControls.checkboxControl(stateFieldName, module.description, this.onChange, this.state[stateFieldName])}
						</div>
					);
				}))
			: null;

		const tenantAdminControl = this.state.admins && this.state.admins.length
			? editFormControls.staticTextControlWithoutLabel("admins", this.state.admins)
			: (
				<Button
					color="primary"
					onClick={() => this.props.history.push(endpointsFrontend.TENANT_CREATE_ADMIN.replace(":id", this.state.externalId))}
				>
					Create
				</Button>
			);

		const tenantAdminControlWithLabel =
			<FormGroup className="mb-4" row>
				<Label
					// htmlFor={props.fieldName}
					className={editFormControls.labelClass}
				>
					Administrators
				</Label>
				<Col lg={editFormControls.valueColSize}>
					{tenantAdminControl}
				</Col>
			</FormGroup>

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
										{editFormControls.textControl("name", "Name", this.onChange, this.state.name)}
										{editFormControls.checkboxControl("doesNotExpire", "Does not expire", this.onChange, this.state.doesNotExpire)}
										{editFormControls.dateControl("expirationDateTime", "Expiration date/time", this.onChange, this.state.expirationDateTime, this.state.doesNotExpire)}

										{this.state.id && tenantAdminControlWithLabel}

										<br />

										<br />
										<h4>Licensed modules</h4>
										<br />

										{moduleSelectionControls}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteTenant, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ tenant }) => ({
	tenants: tenant.tenants,
	loading: tenant.loading,
	error: tenant.error
})

const mapDispatchToProps = dispatch => ({
	onGetTenantById: (id) => dispatch(actionsTenant.tenantGetById(id)),
	onCreateTenant: (tenant, history) => dispatch(actionsTenant.tenantCreate(tenant, history)),
	onDeleteTenant: (id, history) => dispatch(actionsTenant.tenantDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TenantEdit);
