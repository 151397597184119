import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

export const removeFiltersFromColumns = (columns) => {
    for (let i in columns) {
        columns[i] = {
            ...columns[i],
            filter: null
        }
    }
    return columns;
}

export const applySelectFilterToColumn = (column, fieldValueOptions, fieldName) => {
    const filter = fieldValueOptions && fieldValueOptions[fieldName]
        ? selectFilter({ options: fieldValueOptions[fieldName] })
        : textFilter();

    return {
        ...column,
        filter: filter
    };
}