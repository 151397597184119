import * as actionTypes from './actionTypes';

export const timelogTimerChangeStatus = (data) => {
	return {
		type: actionTypes.TIMELOG_TIMER_CHANGE_STATUS,
		data: data
	}
}

export const timelogTimerChangeStatusError = (error) => {
	return {
		type: actionTypes.TIMELOG_TIMER_CHANGE_STATUS_ERROR,
        error: error
	}
}

export const timelogTimerChangeStatusSuccess = (data) => {
	return {
		type: actionTypes.TIMELOG_TIMER_CHANGE_STATUS_SUCCESS,
		data: data
	}
}

export const timelogTimerFindAllActive = () => {
	return {
		type: actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE
	}
}

export const timelogTimerFindAllActiveError = (error) => {
	return {
		type: actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE_ERROR,
        error: error
	}
}

export const timelogTimerFindAllActiveSuccess = (data) => {
	return {
		type: actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE_SUCCESS,
        data: data
	}
}

export const timelogTimerSetActiveTimerId = (id) => {
	return {
		type: actionTypes.TIMELOG_TIMER_SET_ACTIVE_TIMER_ID,
        id: id
	}
}


