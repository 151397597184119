import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

export const exportToXLSX = (sheets, filename) => {
    filename = filename ? filename : "report.xlsx";
    const wb = XLSX.utils.book_new();

    for (let sheetIdx in sheets) {
        const { records, columns, sheetName } = sheets[sheetIdx];

        // if (!records || !records.length) {
        //     window.alert("No data to report");
        //     return;
        // }

        const recordsToExport = [];
        for (let i in records) {
            const thisRecord = records[i];
            let thisRecordToExport = {};
            for (let j in columns) {
                thisRecordToExport = {
                    ...thisRecordToExport,
                    [columns[j].text]: thisRecord[columns[j].dataField]
                }
            }
            recordsToExport.push(thisRecordToExport);
        };

        if (!records.length) {
            // Generate one empty records so that a sheet with headers only gets created.
            // Important when an XLSX with multiple sheets is generated, with one or several of them might be empty
            // (e.g. Cash flow forecast, All data)
            let thisRecordToExport = {};
            for (let j in columns) {
                thisRecordToExport = {
                    ...thisRecordToExport,
                    [columns[j].text]: ""
                }
            }
            recordsToExport.push(thisRecordToExport);
        }

        const ws = XLSX.utils.json_to_sheet(recordsToExport);

        const headerCellStyle = {
            font: { bold: true },
        };

        const fieldKeys = Object.keys(recordsToExport[0]);
        for (let colIdx = 0; colIdx < fieldKeys.length; colIdx++) {
            const cellRef = XLSX.utils.encode_cell({ r: 0, c: colIdx });	// Calculate cell reference from row and column index (e.g. [0, 0] => "A1")
            ws[cellRef].s = headerCellStyle;
        }

        // Calculate column widths based on maximum value lengths
        const columnWidths = recordsToExport.reduce((acc, record) => {
            Object.keys(record).forEach((key) => {
                acc[key] = Math.max((acc[key] || 0), String(record[key]).length);
                acc[key] = Math.max(acc[key], String(fieldKeys[key]).length);   // Also take header text width into account
            });
            return acc;
        }, {});

        ws['!cols'] = [];	// Need to initialize ws['!cols'] before we can use it
        const columnIndexes = Object.keys(columnWidths);
        columnIndexes.forEach((colIdx, key) => {
            ws['!cols'][key] = { width: columnWidths[colIdx] + 2 };
        });

        XLSX.utils.book_append_sheet(wb, ws, (sheetName ? sheetName : "Report"));
    }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
    const blob = new Blob([wbout], { type: 'application/vnd.ms-excel' });
    saveAs(blob, filename);
}