export const ScheduledTaskType = [
	{ code: 10, id: "BANK_FILE_RECEIVE", description: "Bank file receive" }, 
	{ code: 50, id: "DATA_CLEANUP", description: "Data cleanup" }, 
	{ code: 30, id: "EXTERNAL_DATA_IMPORT_REQUEST", description: "External data import request" }, 
	{ code: 40, id: "PAYMENT", description: "Payment" }, 
	{ code: 15, id: "STATEMENT_REQUEST", description: "Statement request" }, 
	{ code: 60, id: "SUPPLIER_INVOICE_RECEIVE", description: "Supplier invoice processing" }, 
];

export const ScheduledTaskTypeAsSelectFilterOptions = {
	"BANK_FILE_RECEIVE": "Bank file receive",
	"DATA_CLEANUP": "Data cleanup",
	"EXTERNAL_DATA_IMPORT_REQUEST": "External data import request",
	"PAYMENT": "Payment",
	"STATEMENT_REQUEST": "Statement request",
	 "SUPPLIER_INVOICE_RECEIVE": "Supplier invoice processing",
};