import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsUserLogin from '../../store/UserAccountLogin/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import * as SmartIdCountryCode from '../../definitions/enums/SmartIdCountryCode';


class UserAccountProfileEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            firstName: "",
            lastName: "",
            username: "",
            // companies: "",
            // userRoleNames: "",
            // userAuthorizationNames: "",

            firstName: "",
            lastName: "",
            smartIdIsEnabled: false,
            smartIdIdentityType: "PNO",
            smartIdCountryCode: "LV",
            smartIdIdentityNumber: "",

            changeUsername: false,
            changePassword: false,
            password: "",

            changed: false,
            // userAccount: [],
            formErrors: null,
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue,
            changed: true
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing

        if (!this.state.firstName || !this.state.firstName.length) {
            document.getElementById("firstName").focus();
            this.setState({
                formErrors: { message: "First name is mandatory", controls: ["firstName"] }
            });
        } else if (this.state.smartIdIsEnabled && (!this.state.smartIdIdentityNumber || !this.state.smartIdIdentityNumber.length)) {
            document.getElementById("smartIdIdentityNumber").focus();
            this.setState({
                formErrors: { message: "Smart-Id identity number is mandatory", controls: ["smartIdIdentityNumber"] }
            });
        } else {
            let updatedUserData = {
                id: this.state.userId,
                username: this.state.username,
                tenantExternalId: this.state.tenantExternalId,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                changeUsername: this.state.changeUsername,
                changePassword: this.state.changePassword,
                smartIdIsEnabled: this.state.smartIdIsEnabled ? true : false,
                smartIdIdentityType: this.state.smartIdIdentityType,
                smartIdCountryCode: this.state.smartIdCountryCode,
                smartIdIdentityNumber: this.state.smartIdIdentityNumber,
            };

            this.setState({
                formErrors: null,
                changed: false
            }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
            this.props.onUpdateUserData(updatedUserData);
        }
    }

    updateUserDataFromProps = () => {
        if (this.props.userData) {
            this.setState({
                userId: this.props.userData.userId,
                username: this.props.userData.username,
                tenantExternalId: this.props.userData.tenantExternalId,
                firstName: this.props.userData.firstName,
                lastName: this.props.userData.lastName,
                smartIdIsEnabled: this.props.userData.smartIdIsEnabled ? true : false,
                // smartIdIdentityType: this.props.userData.smartIdIdentityType,
                smartIdCountryCode: this.props.userData.smartIdCountryCode,
                smartIdIdentityNumber: this.props.userData.smartIdIdentityNumber,
            });
        } else {
            this.setState({
                userId: "",
                username: "",
                tenantExternalId: "",
                firstName: "",
                lastName: "",
                smartIdIsEnabled: false,
                smartIdIdentityType: "PNO",
                smartIdCountryCode: "",
                smartIdIdentityNumber: "",
            });
        }
    }

    componentDidMount() {
        this.updateUserDataFromProps();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.errorUserAccounts !== this.props.errorUserAccounts) {
            if (this.props.errorUserAccounts) {
                this.setState({
                    errors: this.props.errorUserAccounts
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.userData !== this.props.userData) {
            this.updateUserDataFromProps();
        }

        if ((prevProps.saveSuccess !== this.props.saveSuccess) || (prevProps.error !== this.props.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    render() {
        const pageTitle = "Edit user profile | " + config.AppName;
        const breadcrumbsTitle = "User Profile";
        const breadcrumbsItem = "Edit user profile";

        const smartIdCountryCodeOptions = inputSelectUtils.generateOptionsFromData(SmartIdCountryCode.SmartIdCountryCode, row => row.description);

        const smartIdSubform =
            <React.Fragment>
                {/* {editFormControls.textControl("smartIdIdentityType", "Smart-ID identity type", this.onChange, this.state.smartIdIdentityType)} */}
                {editFormControls.hiddenValueControl("smartIdIdentityType", this.onChange, this.state.smartIdIdentityType)}
                {editFormControls.selectControl("smartIdCountryCode", "Smart-ID country", this.onChange, this.state.smartIdCountryCode, smartIdCountryCodeOptions)}
                {editFormControls.textControl("smartIdIdentityNumber", "Smart-ID identity No.", this.onChange, this.state.smartIdIdentityNumber, "Enter Smart-ID identity No.", this.state.formErrors)}
            </React.Fragment>

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("userId", this.onChange, this.state.userId)}
                                        {editFormControls.changeableStaticTextControl("username", "Username", this.onChange, this.state.username, "Username", this.state.changeUsername, (changeUsername) => this.setState({ changeUsername: changeUsername }))}
                                        {editFormControls.passwordControl("password", "Password", this.onChange, this.state.password, "", true, this.state.changePassword, (changePassword) => this.setState({ changePassword: changePassword }))}
                                        {editFormControls.textControl("firstName", "First name", this.onChange, this.state.firstName, "Enter first name", this.state.formErrors)}
                                        {editFormControls.textControl("lastName", "Last name", this.onChange, this.state.lastName)}
                                        {editFormControls.checkboxControl("smartIdIsEnabled", "Enable Smart-ID", this.onChange, this.state.smartIdIsEnabled)}
                                        {this.state.smartIdIsEnabled ? smartIdSubform : null}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, true)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loadingTenantLicenses || this.props.loadingUserAccounts;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.formErrors)}
                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "User profile")}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ userLogin }) => ({
    error: userLogin.error,
    saveSuccess: userLogin.saveSuccess,
    saving: userLogin.saving,
    userData: userLogin.userData
})

const mapDispatchToProps = dispatch => ({
    onUpdateUserData: (data) => dispatch(actionsUserLogin.userAccountUpdate(data))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountProfileEdit);

