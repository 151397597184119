import React from 'react';

import Legal from './Legal';
import classes from './Legal.module.css';

const Terms3GDPR = (props) => (
    <Legal {...props}><h1>Personal Data Processing Terms</h1>
        <h2>1. General Provisions</h2>
        <p>1.1. GDPR means Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</p>
        <p>1.2. Processor means a Supplier who, within the framework of personal data processing tasks, acts as a processor within the meaning of the GDPR.</p>
        <p>1.3. Controller means a Customer who, within the framework of personal data processing tasks, acts as a controller within the meaning of the GDPR.</p>
        <p>1.4. Service Contract means an agreement concluded between the Controller and the Processor for the provision of the Online Service and for the Maintenance Service, which sets out the Processor's obligations to provide the Controller with a service that includes the processing of personal data provided by the Controller using the functionality of the computer system (hereinafter referred to as the System) specified in the Service Contract.</p>
        <p>1.5. This Personal Data Processing Contract regulates the relationship between the Controller and the Processor regarding the processing of personal data that occurs in cases where the Processor provides a service to the Controller in accordance with the Service Contract. If the Processor's obligations under the Service Contract include both the provision of a service and the licensing of computer programs, then these Personal Data Processing Terms do not apply to the processing of personal data that the Controller performs using computer programs developed by the Processor on a license basis. In such cases, the Processor is not considered to be involved in the processing of personal data performed by the Controller.</p>
        <p>1.6. The Processor processes personal data only on the documented instructions of the Controller. By this Contract, the Controller instructs the Processor to process personal data in the manner necessary to fulfill the instructions given by the Controller.</p>
        <p>1.7. The Controller provides a legal basis for the processing of personal data that the Controller instructs the Processor to carry out. The Controller shall be liable for the expenses and losses incurred by the Processor in response to the claims of data subjects based on the lack of legal grounds for the processing operations that the Controller has instructed the Processor to perform.</p>
        <p>1.8. If the Processor is obliged to process personal data provided by the Controller in accordance with a regulatory enactment applicable to it, the Processor shall inform the Controller of this legal requirement before processing, unless the relevant legal enactments prohibit such informing.</p>
        <p>1.9. The Processor shall not check whether the data transferred by the Controller for processing are personal data, except in cases where this is necessary for the authentication of a specific person in the course of providing the service (for example, in the process of electronic signature). In some cases, the data may be checked based on formal characteristics (for example, the data entry format), if this is necessary for the technically correct use of the data, but this does not establish whether the entered data are personal data.</p>

        <h2>2. Description of the processing</h2>
        <p>2.1. Nature and purpose of processing: The Processor processes personal data as necessary to provide the service specified in the Service Contract, in accordance with the documented instructions of the Controller. In the case of an online service, the Controller gives instructions in accordance with the functionality of the System and only using the functionality of the System. The provision of the online service includes providing the options offered by the System, providing support, investigating and eliminating problems with the operation of the System and updating the System. In the case of a maintenance service, the Controller's instructions shall be included in the Maintenance Service request and may be detailed in the Maintenance Service provision offer.</p>
        <p>2.2. Categories of data subjects: all identified or identifiable persons whose personal data the Controller instructs the Processor to process. The categories of data subjects result from the data that the Controller actually instructs the Processor to process.</p>
        <p>2.3. Categories of personal data to be processed:</p>
        <p>2.3.1. personal data, the processing of which is previously provided for in the System, for example: name, surname, personal identification number, bank account number, postal address, e-mail address, telephone number, electronic signature certificate data, etc.</p>
        <p>2.3.2. any other category of personal data that the Controller transfers for processing.</p>
        <p>2.4. Duration of processing: until the deletion of personal data in accordance with the processing tasks given by the Controller or in connection with the termination of the Service Contract, and until the deletion of backup copies. Backup copies are usually deleted within 90 days after their creation. Backup copies of data are not created and cannot be used to provide the Controller with the opportunity to recover data that the Controller has deleted or to which the Controller has otherwise lost access. The Controller independently ensures the creation of backup copies of data. The obligation to delete does not apply to personal data that the Processor is obliged to retain in accordance with applicable laws and regulations or has the right to retain in accordance with this Contract.</p>

        <h2>3. Data security measures</h2>
        <p>3.1. The Processor shall implement the following technical and organisational measures to ensure the security of the personal data it processes:</p>
        <p>3.1.1. The application database shall be accessible only from a limited set of IP addresses. It shall be secured with a strong password, which shall be stored in a secure location, periodically changed and disclosed only to employees who need to know it. At the API level, data shall only be technically accessible to the Controller and the User.</p>
        <p>3.1.2. The Processor's employees shall be provided with training on data protection and security measures and shall be required to sign a form setting out their obligations with regard to data protection and the legal consequences in the event of a data breach.</p>
        <p>3.1.3. The Processor shall monitor server and application logs to detect unauthorised access or suspicious activities and shall respond promptly to any potential threats.</p>
        <p>3.1.4. The application database shall be periodically backed up to a secure Dropbox account to ensure that additional copies of the data are stored. The backup process is managed by authorized employees to ensure the security and integrity of personal data.</p>
        <p>3.2. The server hardware is provided in accordance with the terms of this service provider. The Supplier, at the request of the Customer or on its own initiative, indicates which person provides this service and the terms under which this person provides this service. The Supplier may select and change the service provider at its own discretion.</p>
        <p>3.3. The Processor may change the aforementioned measures as it deems necessary and in accordance with regulatory enactments to effectively ensure the protection of personal data against security breaches resulting in accidental or unlawful destruction, loss, alteration, unauthorized disclosure of or access to data (personal data breach). When assessing the appropriate level of security and deciding on the technical and organizational measures to be applied, the Processor shall take into account the state of the art, the implementation costs, the nature, context and purposes of the processing, as well as the risks posed to data subjects. The Processor shall inform the Controller of any significant changes to the aforementioned measures.</p>
        <p>3.4. The Processor grants its employees access to the personal data being processed only to the extent strictly necessary for the provision of the contracted service to the Controller, management and supervision. The Processor ensures that the persons authorised to process the personal data have undertaken to observe confidentiality or are subject to a relevant legal obligation to observe confidentiality.</p>
        <p>3.5. The Controller shall independently ascertain whether the measures provided by the Processor are sufficient for the security of the personal data being processed at the level required by the Controller and shall give the Processor tasks for processing personal data (use of the relevant Processor service or System functionality) only if the Controller considers the measures provided by the Processor to be appropriate and valid for the Controller in all respects.</p>
        <p>3.6. The Processor does not intend to use the System for processing data specified in Article 9(1) of the GDPR. The Controller shall independently assess the suitability of the System for the processing of such personal data and shall use the System for this purpose only if it considers that the System and the measures provided by the Processor are suitable for the processing of such data in all respects. The Controller shall bear all risks and liability for the use of the System for the processing of such personal data.</p>

        <h2>4. Controller's requests, audits</h2>
        <p>4.1. The Processor shall promptly and appropriately handle Controller's requests for the processing of personal data under this Contract.</p>
        <p>4.2. The Processor shall make available to the Controller all information necessary to demonstrate that the obligations set out in this Contract, as well as those obligations directly arising from the GDPR, have been fulfilled. The Processor shall allow audits of the processing operations carried out by the Processor on behalf of and on behalf of the Controller in the course of providing services and shall contribute to such audits, upon requests from the Controller, which the Controller shall carry out at reasonable intervals or in the event of suspected non-compliance.</p>
        <p>4.3. The Processor shall, upon request from the Controller, make available to the Controller all information necessary to demonstrate that the Processor's obligations set out in this Contract are being fulfilled and to allow the Controller or its authorised auditor to carry out and contribute to audits, including inspections. Audit activities, which include inspection of documents or premises, shall be agreed upon by the Controller and the Processor, taking into account the specific circumstances. The Controller may conduct the audit itself or mandate an independent auditor. The Controller shall pay all expenses related to the audit. If the Processor incurs costs that are reasonably and necessarily related to the audit conducted by the Controller, the Processor shall notify the Controller of such costs and the Controller shall reimburse them in their actual amount.</p>
        <p>4.4. The Parties shall make the information referred to in this section available to the competent supervisory authorities upon request, including the results of the audits.</p>

        <h2>5. Sub-processors</h2>
        <p>5.1. The Controller grants the Processor a general permission to engage sub-processors at the Processor's discretion. The Processor shall inform the Controller in writing of any intended change of sub-processor at least 30 days in advance, thereby giving the Controller sufficient time to withdraw from the Service Contract before engaging the relevant sub-processor, except where the change of sub-processor is necessary due to extraordinary circumstances and this notice period cannot be met. The Processor shall provide the Controller with information available to the Processor to enable the Controller to make an informed decision.</p>
        <p>5.2. Where the Processor engages a sub-processor to carry out specific processing activities (on behalf of the Controller), the Processor shall ensure that the sub-processor fulfils the obligations applicable to the Processor under these Personal Data Processing Regulations and the GDPR.</p>
        <p>5.3. Upon request by the Controller, the Processor shall provide the Controller with a copy of such sub-processor contract and any subsequent amendments or shall inform the Controller where they are easily accessible. To the extent necessary to protect trade secrets or other confidential information, including personal data, the Processor may edit the text of the contract before providing the copy. The Processor shall remain fully liable to the Controller for the performance of the subprocessor's obligations under the Controller's contract with the Processor. The Processor shall notify the Controller if the subprocessor fails to comply with its contractual obligations.</p>
        <p>5.4. The Controller agrees that where the Processor engages a subprocessor to carry out specific processing operations (on behalf of the Controller) and where those processing operations involve transfers of personal data within the meaning of Chapter V of the GDPR, the Processor and the subprocessor may ensure compliance with Chapter V of the GDPR by using the standard contractual clauses adopted by the Commission pursuant to Article 46(2) of the GDPR, provided that the conditions for the use of those standard contractual clauses are met, or by ensuring the subprocessor's compliance with the EU-US Privacy Shield Framework (https://www.privacyshield.gov/).</p>

        <h2>6. Assistance to the Controller</h2>
        <p>6.1. The Processor shall immediately notify the Controller of any submission received from a data subject in connection with the processing of personal data carried out by the Processor within the framework of these Personal Data Processing Regulations. The Processor shall not respond to such submission.</p>
        <p>6.2. The Processor shall assist the Controller in fulfilling its obligations to respond to submissions from data subjects to exercise their rights, taking into account the nature of the processing and the content of the submission.</p>
        <p>6.3. The Processor shall, taking into account the nature of the processing and the information available to the Processor, assist the Controller in fulfilling the following obligations of the Controller:</p>
        <p>6.3.1. the obligation to carry out an assessment of how the planned processing operations will affect the protection of personal data ('data protection impact assessment'), where the type of processing is likely to result in a high risk to the rights and freedoms of natural persons;</p>
        <p>6.3.2. the obligation to consult the competent supervisory authority prior to processing where the data protection impact assessment indicates that the processing would result in a high risk if the Controller did not take measures to mitigate the risk;</p>
        <p>6.3.3. the obligation to ensure that personal data are accurate and up-to-date, giving the Controller the opportunity to clarify and update such data using the functionality of the System;</p>
        <p>6.3.4. the obligations set out in Article 32 of the GDPR, using the means specified in Section 3 of these Personal Data Processing Regulations. If the Controller considers such means to be insufficient, the Controller has the right to withdraw from the Service Contract in the part relating to the provision of related services.</p>

        <h2>7. Reporting a personal data breach</h2>
        <p>7.1. In the event of a personal data breach, the Processor shall cooperate with the Controller and assist it, where appropriate, to fulfil its obligations under Articles 33 and 34 of the GDPR, taking into account the nature of the processing and the information available to the Processor.</p>
        <p>7.2. If the breach relates to data processed by the Controller, the Processor shall assist the Controller in fulfilling the following obligations of the Controller:</p>
        <p>7.2.1. to notify the competent supervisory authority of the personal data breach without undue delay,</p>
        <p>7.2.2. to notify the data subject of the personal data breach without undue delay where the personal data breach is likely to result in a high risk to the rights and freedoms of natural persons.</p>
        <p>7.2.3. upon request of the Controller, providing all information available to the Processor about the nature of the personal data breach, including, where possible, the categories and approximate number of data subjects affected and the categories and approximate number of personal data records affected, the possible consequences of the personal data breach, the measures taken or proposed to be taken by the Controller to prevent the personal data breach, including, where appropriate, measures to mitigate its possible adverse consequences.</p>
        <p>7.3. If the Processor has committed a personal data breach in relation to data processed by the Processor, the Processor shall notify the Controller without undue delay as soon as the Processor has become aware of the breach. Such notification shall include at least:</p>
        <p>7.3.1. a description of the breach (including, where possible, the categories and approximate number of data subjects and data records concerned);</p>
        <p>7.3.2. information about the contact person from whom additional information about the personal data breach can be obtained;</p>
        <p>7.3.3. the possible consequences of the breach and the measures taken or proposed to be taken to address the breach, including to mitigate its possible adverse effects.</p>
        <p>7.4. If it is not possible to provide all of this information at the same time, the Processor shall include the information available at that time in the initial notification and shall then provide additional information without undue delay as soon as it becomes available.</p>

        <h2>8. Liability for breaches of the personal data processing rules</h2>
        <p>8.1. Without prejudice to the provisions of the GDPR, if the Processor fails to fulfil its obligations in the field of personal data processing, the Controller may withdraw from the Service Contract in whole or in part, which relates to the provision of related services. The Controller's withdrawal from the Service Contract or part thereof shall be deemed to be an instruction of the Controller to the Processor to delete all personal data processed on behalf of the Controller, which relate to the terminated service, after the termination of the Service Contract (the relevant part). Upon the Controller's request, the Processor shall certify to the Controller that this has been done. The deletion obligation does not apply to personal data that the Processor is obliged to retain in accordance with applicable laws and regulations.</p>
        <p>8.2. The Processor shall keep audit records of the operations performed in the System for 24 months after the relevant operation. The Controller's right to raise objections in connection with the personal data processing operations performed in the System and their correct execution shall expire as soon as the period specified in this clause has elapsed after the relevant operation has been performed.</p>

        <h2>9. Final Provisions</h2>
        <p>9.1. The Processor has the right to amend these Personal Data Processing Terms by notifying the Controller of the amendments and making the new version of this Contract available to the Controller at least 30 days before the amendments come into force. The new version of these Personal Data Processing Terms shall enter into force within the period specified by the Processor. If the Controller does not agree with the new version of the Contract, the Controller has the right to withdraw from the Service Contract.</p>
        <p>9.2. In all cases where, in accordance with these Personal Data Processing Terms, the Controller has the right to withdraw from the Service Contract, the Controller may exercise this right, regardless of the withdrawal period specified in the Service Contract and without compensating the Processor for losses arising from the early termination of the Service Contract. The Controller agrees that the right to withdraw from the Service Contract is a complete and sufficient means of protecting the Controller's rights and interests, and will not require any other compensation from the Processor in connection with such withdrawal.</p>

    </Legal>
);

export default Terms3GDPR;