export const CM20PAYMENT_GET_ALL = "CM20PAYMENT_GET_ALL";
export const CM20PAYMENT_GET_ERRONEOUS = "CM20PAYMENT_GET_ERRONEOUS";
export const CM20PAYMENT_GET_SIGNED = "CM20PAYMENT_GET_SIGNED";
export const CM20PAYMENT_GET_UNSIGNED = "CM20PAYMENT_GET_UNSIGNED";
export const CM20PAYMENT_GET_BY_ID = "CM20PAYMENT_GET_BY_ID";
export const CM20PAYMENT_GET_IN_PROCESS = "CM20PAYMENT_GET_IN_PROCESS";
export const CM20PAYMENT_GET_ERROR = "CM20PAYMENT_GET_ERROR";
export const CM20PAYMENT_GET_SUCCESS = "CM20PAYMENT_GET_SUCCESS";

export const CM20PAYMENT_CREATE = "CM20PAYMENT_CREATE";
export const CM20PAYMENT_CREATE_ERROR = "CM20PAYMENT_CREATE_ERROR";
export const CM20PAYMENT_CREATE_SUCCESS = "CM20PAYMENT_CREATE_SUCCESS";

export const CM20PAYMENT_DELETE = "CM20PAYMENT_DELETE";

export const CM20PAYMENT_SIGN_GET_CERTIFICATE = "CM20PAYMENT_SIGN_GET_CERTIFICATE";
export const CM20PAYMENT_SIGN_INIT = "CM20PAYMENT_SIGN_INIT";
export const CM20PAYMENT_SIGN_ERROR = "CM20PAYMENT_SIGN_ERROR";
export const CM20PAYMENT_SIGN_RESET = "CM20PAYMENT_SIGN_RESET";
export const CM20PAYMENT_SIGN_RESET_SUCCESS = "CM20PAYMENT_SIGN_RESET_SUCCESS";
export const CM20PAYMENT_SIGN_SUCCESS = "CM20PAYMENT_SIGN_SUCCESS";
