export const SCHEDULED_TASK_CREATE = "SCHEDULED_TASK_CREATE";
export const SCHEDULED_TASK_CREATE_ERROR = "SCHEDULED_TASK_CREATE_ERROR";
export const SCHEDULED_TASK_CREATE_SUCCESS = "SCHEDULED_TASK_CREATE_SUCCESS";

export const SCHEDULED_TASK_DELETE = "SCHEDULED_TASK_DELETE";
export const SCHEDULED_TASK_DELETE_ERROR = "SCHEDULED_TASK_DELETE_ERROR";
export const SCHEDULED_TASK_DELETE_SUCCESS = "SCHEDULED_TASK_DELETE_SUCCESS";

export const SCHEDULED_TASK_EXECUTE = "SCHEDULED_TASK_EXECUTE";
export const SCHEDULED_TASK_EXECUTE_ERROR = "SCHEDULED_TASK_EXECUTE_ERROR";
export const SCHEDULED_TASK_EXECUTE_SUCCESS = "SCHEDULED_TASK_EXECUTE_SUCCESS";

export const SCHEDULED_TASK_GET_ALL = "SCHEDULED_TASK_GET_ALL";
export const SCHEDULED_TASK_GET_BY_ID = "SCHEDULED_TASK_GET_BY_ID";
export const SCHEDULED_TASK_GET_ERROR = "SCHEDULED_TASK_GET_ERROR";
export const SCHEDULED_TASK_GET_SUCCESS = "SCHEDULED_TASK_GET_SUCCESS";

