export const getSelectedRowOnSelect = (sourceArray, row, isSelect) => {
    let selectedRows = [...sourceArray];
    if (isSelect) {
        if (selectedRows.indexOf(row.id) < 0) {
            selectedRows.push(row.id);
        }
    } else {
        if (selectedRows.indexOf(row.id) >= 0) {
            selectedRows = selectedRows.filter((selectedRow) => (selectedRow !== row.id));
        }
    }
    return selectedRows;
}

export const getSelectedRowOnSelectAll = (sourceArray, rows, isSelect) => {
    let selectedRows;
    if (isSelect) {
        selectedRows = [...sourceArray];
        rows.map((row, rowIndex) => {
            if (selectedRows.indexOf(row.id) < 0) {
                selectedRows.push(row.id);
            }
        });
    } else {
        const rowIds = rows.map(row => row.id);
        selectedRows = sourceArray.filter(selectedRow => !rowIds.includes(selectedRow));
    }
    return selectedRows;
}