import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Supplier/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsSupplier from 'definitions/columns/supplier';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import EditSupplierAndBankAccounts from "components/Pages/EditSupplierAndBankAccounts";

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class SuppliersBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showEditSupplierForm: false
		}
	}

	componentDidMount() {
		this.props.onGetSuppliers();
	}

	newSupplier = () => {
		this.setState({
			selectedSupplier: null,
			showEditSupplierForm: true
		})
	}

	render() {

		const pageTitle = "Suppliers | " + config.AppName;
		const breadcrumbsTitle = "Suppliers";
		const breadcrumbsItem = "Browse suppliers";

		const EditSupplierFormAsVar =
			<EditSupplierAndBankAccounts
				onClose={() => this.setState({ showEditSupplierForm: false })}
				supplier={this.state.selectedSupplier}
			/>

		const columns = [
			columnsSupplier.name,
			columnsSupplier.regNo,
			columnsSupplier.vatRegNo,
			columnsSupplier.addressCountryName,
			columnsSupplier.reference,
			columnsSupplier.externalId
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				// this.props.history.push(endpointsFrontend.SUPPLIER_EDIT.replace(":id", row.id));
				if (row.id) {
					this.setState({
						selectedSupplier: row,
						showEditSupplierForm: true
					})
				}
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.suppliers}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
				/>
				<br />
				<Button
					color="success"
					onClick={this.newSupplier}
				>
					<div style={{ display: "flex" }}>
						<span
							className={"avatar-title bg-success rounded-circle font-size-18"}
							style={{ marginLeft: "-3px", marginRight: "6px" }}
						>
							<i className={"bx bxs-plus-circle"} />
						</span>
						<span>
							New
						</span>
					</div>
				</Button>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showEditSupplierForm ? EditSupplierFormAsVar : null}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.suppliers ?
											table
											: null}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ supplier }) => ({
	suppliers: supplier.suppliers,
	loading: supplier.loading,
	error: supplier.error
})

const mapDispatchToProps = dispatch => ({
	onGetSuppliers: () => dispatch(actions.supplierGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SuppliersBrowse));
