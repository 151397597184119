import * as actionTypes from "./actionTypes";
import * as allocationDataTools from "../../helpers/allocationDataTools";

const initialState = {
	allocatedPayments: null,
	allocatedSupplierInvoices: null,
	allocatedCustomerInvoices: null,
	allocatedBankCharges: null,
	allocating: false,
	allocations: null,
	confirmingAllocations: null,
	bankStatementRecord: null,
	bankStatementRecords: null,
	error: null,
	loading: false,
	revertingAllocations: null,
	updatingAllocations: false
}

const updateAllocations = (state, updatedAllocation) => {
	let allocatedPayments = state.allocatedPayments;
	let allocatedSupplierInvoices = state.allocatedSupplierInvoices;
	let allocatedSuppliers = state.allocatedSuppliers;
	let allocatedCustomerInvoices = state.allocatedCustomerInvoices;
	let allocatedCustomers = state.allocatedCustomers;
	let allocatedBankCharges = state.allocatedBankCharges;
	const bankStatementRecords = [...state.bankStatementRecords];
	const transformedAllocationDTO = allocationDataTools.transformAllocationDTO(updatedAllocation);
	const updatedBankStatementRecord = transformedAllocationDTO.bankStatementRecord;
	for (let i = 0; i < bankStatementRecords.length; ++i) {
		if (bankStatementRecords[i].id == updatedBankStatementRecord.id) {
			bankStatementRecords[i] = updatedBankStatementRecord;
			allocatedPayments = allocatedPayments.filter(payment => (
				payment.bankStatementRecordId != updatedBankStatementRecord.id
			)).concat(transformedAllocationDTO.allocatedPayments);
			allocatedSupplierInvoices = allocatedSupplierInvoices.filter(supplierInvoice => (
				supplierInvoice.bankStatementRecordId != updatedBankStatementRecord.id
			)).concat(transformedAllocationDTO.allocatedSupplierInvoices);
			allocatedSuppliers = allocatedSuppliers.filter(supplier => (
				supplier.bankStatementRecordId != updatedBankStatementRecord.id
			)).concat(transformedAllocationDTO.allocatedSuppliers);
			allocatedCustomerInvoices = allocatedCustomerInvoices.filter(customerInvoice => (
				customerInvoice.bankStatementRecordId != updatedBankStatementRecord.id
			)).concat(transformedAllocationDTO.allocatedCustomerInvoices);
			allocatedCustomers = allocatedCustomers.filter(customer => (
				customer.bankStatementRecordId != updatedBankStatementRecord.id
			)).concat(transformedAllocationDTO.allocatedCustomers);
			allocatedBankCharges = allocatedBankCharges.filter(bankCharge => (
				bankCharge.bankStatementRecordId != updatedBankStatementRecord.id
			));
			if (transformedAllocationDTO.allocatedBankCharge.amount) {
				allocatedBankCharges = allocatedBankCharges.concat(transformedAllocationDTO.allocatedBankCharge);
			}
			break;
		}
	}
	return {
		bankStatementRecords: bankStatementRecords,
		allocatedPayments: allocatedPayments,
		allocatedSupplierInvoices: allocatedSupplierInvoices,
		allocatedSuppliers: allocatedSuppliers,
		allocatedCustomerInvoices: allocatedCustomerInvoices,
		allocatedCustomers: allocatedCustomers,
		allocatedBankCharges: allocatedBankCharges
	};
}

export default function allocationReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.ALLOCATION_AUTO_ALLOCATE:
			return state;
		case actionTypes.ALLOCATION_CONFIRM:
			return {
				...state,
				confirmingAllocations: true,
				error: null,
				updatingAllocations: true
			}
		case actionTypes.ALLOCATION_CREATE:
		case actionTypes.ALLOCATION_DELETE:
		case actionTypes.ALLOCATION_REVERT:
			return {
				...state,
				error: null,
				updatingAllocations: true
			}
		case actionTypes.ALLOCATION_CONFIRM_ERROR:
			return {
				...state,
				error: action.error
			}
		case actionTypes.ALLOCATION_CONFIRM_SUCCESS:
			return {
				...state,
				confirmingAllocations: false,
				updatingAllocations: false,
				bankStatementRecords: action.bankStatementRecords,
				allocatedPayments: action.allocatedPayments,
				allocatedSupplierInvoices: action.allocatedSupplierInvoices,
				allocatedSuppliers: action.allocatedSuppliers,
				allocatedCustomerInvoices: action.allocatedCustomerInvoices,
				allocatedCustomers: action.allocatedCustomers,
				allocatedBankCharges: action.allocatedBankCharges
			}
		case actionTypes.ALLOCATION_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				updatingAllocations: false
			}
		case actionTypes.ALLOCATION_CREATE_SUCCESS:
		case actionTypes.ALLOCATION_DELETE_SUCCESS:
		case actionTypes.ALLOCATION_REVERT_SUCCESS:
			const updatedAllocations = updateAllocations(state, action.updatedAllocation);
			return {
				...state,
				updatingAllocations: false,
				bankStatementRecords: updatedAllocations.bankStatementRecords,
				allocatedPayments: updatedAllocations.allocatedPayments,
				allocatedSupplierInvoices: updatedAllocations.allocatedSupplierInvoices,
				allocatedSuppliers: updatedAllocations.allocatedSuppliers,
				allocatedCustomerInvoices: updatedAllocations.allocatedCustomerInvoices,
				allocatedCustomers: updatedAllocations.allocatedCustomers,
				allocatedBankCharges: updatedAllocations.allocatedBankCharges
			}
		case actionTypes.ALLOCATION_GET_ALL:
		case actionTypes.ALLOCATION_GET_BY_CUSTOMER_INVOICE:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.ALLOCATION_GET_SUCCESS:
			return {
				...state,
				loading: false,
				bankStatementRecords: action.bankStatementRecords,
				allocatedPayments: action.allocatedPayments,
				allocatedSupplierInvoices: action.allocatedSupplierInvoices,
				allocatedSuppliers: action.allocatedSuppliers,
				allocatedCustomerInvoices: action.allocatedCustomerInvoices,
				allocatedCustomers: action.allocatedCustomers,
				allocatedBankCharges: action.allocatedBankCharges
			}
		case actionTypes.ALLOCATION_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.ALLOCATION_RECORD_SET:
			return {
				...state,
				allocationRecord: action.allocationRecord
			}
		case actionTypes.ALLOCATION_REVERT_ALL:
			return {
				...state,
				revertingAllocations: true,
				updatingAllocations: true
			}
		case actionTypes.ALLOCATION_REVERT_ALL_SUCCESS:
			return {
				...state,
				revertingAllocations: false,
				updatingAllocations: false,
				bankStatementRecords: action.bankStatementRecords,
				allocatedPayments: action.allocatedPayments,
				allocatedSupplierInvoices: action.allocatedSupplierInvoices,
				allocatedSuppliers: action.allocatedSuppliers,
				allocatedCustomerInvoices: action.allocatedCustomerInvoices,
				allocatedCustomers: action.allocatedCustomers,
				allocatedBankCharges: action.allocatedBankCharges
			}
		default:
			return state;
	}
}