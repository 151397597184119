import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import { modules } from 'definitions/modules';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* createTenantSaga({ tenant, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.TENANT_CREATE,
			tenant
		);
		history.push(endpointsFrontend.TENANT_BROWSE);
	} catch (e) {
		yield put(actions.tenantCreateError(e));
	}
}

function* createTenantAdminSaga({ tenantAdmin, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_CREATE_FOR_TENANT_AND_SET_ADMIN,
			tenantAdmin
		);
		history.goBack();	// Go back to the tenant for which the admin is created
	} catch (e) {
		yield put(actions.tenantCreateError(e));
	}
}

function* deleteTenantSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.TENANT_GET_BY_ID.replace("{tenantId}", id)
		);
		history.push(endpointsFrontend.TENANT_BROWSE);
	} catch (e) {
		yield put(actions.tenantCreateError(e));
	}
}

function* getAllTenantsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TENANT_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {

			const entry = response.data[key];
			let tenantLicenseInfo = "";
			if (entry.tenantLicenses.length === 0) {
				tenantLicenseInfo = "-";
			} else {
				const licenseDescriptions = modules
					.filter(module => entry.tenantLicenses.includes("LICENSED_" + module.name))
					.map(module => module.description);
				for (let j in licenseDescriptions) {
					tenantLicenseInfo += (tenantLicenseInfo.length ? ", " : "") + licenseDescriptions[j];
				}
			}

			entries.push({
				...entry,
				tenantLicenseInfo: tenantLicenseInfo
			});
		}

		yield put(actions.tenantGetSuccess(sagaHelper.getEntriesFromResponseData(entries)));
	} catch (e) {
		yield put(actions.tenantGetError(e));
	}
}

function* getTenantByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TENANT_GET_BY_ID.replace("{tenantId}", id)
		);
		yield put(actions.tenantGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.tenantGetError(e));
	}
}

function* tenantSaga() {
	yield (takeLatest(actionTypes.TENANT_ADMIN_CREATE, createTenantAdminSaga));
	yield (takeLatest(actionTypes.TENANT_CREATE, createTenantSaga));
	yield (takeLatest(actionTypes.TENANT_DELETE, deleteTenantSaga));
	yield (takeLatest(actionTypes.TENANT_GET_ALL, getAllTenantsSaga));
	yield (takeLatest(actionTypes.TENANT_GET_BY_ID, getTenantByIdSaga));
}

export default tenantSaga;