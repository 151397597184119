import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllCRMMaintenanceNoticesSaga() {
	yield put(actions.crmMaintenanceNoticeGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CRM_MAINTENANCE_NOTICE_GET_PENDING
		);

		const CRMMaintenanceNotices = [];
		for (let key in response.data) {
			CRMMaintenanceNotices.push({
				...response.data[key]
			});
		}

		yield put(actions.crmMaintenanceNoticeGetSuccess(CRMMaintenanceNotices));
	} catch (e) {
		yield put(actions.crmMaintenanceNoticeGetError(e));
	}
}


function* crmMaintenanceNoticeSaga() {
	yield (takeEvery(actionTypes.CRM_MAINTENANCE_NOTICE_GET_PENDING, getAllCRMMaintenanceNoticesSaga));
}

export default crmMaintenanceNoticeSaga;