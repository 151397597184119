import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./i18n";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const app = (
	<Provider
		store={store}
	>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
);

const element = document.getElementById("root");

ReactDOM.render(app, element);
serviceWorker.unregister();