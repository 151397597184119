import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from "helpers/formatUtils";
import * as ScheduledTaskType from '../enums/ScheduledTaskType';
import * as tableUtils from "helpers/tableUtils";

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const cronExpression = {
	dataField: 'cronExpression',
	text: 'Cron expression',
	filter: textFilter(),
	sort: true
};
export const name = {
	dataField: 'name',
	text: 'Name',
	filter: textFilter(),
	sort: true
};
export const bankAccountId = {
	dataField: 'bankAccountId',
	text: 'Bank account ID',
	filter: textFilter(),
	sort: true
};
export const allowCurrentDay = {
	dataField: 'allowCurrentDay',
	text: 'Allow current day',
	filter: textFilter(),
	sort: true
};
export const mergeWithExistingStatements = {
	dataField: 'mergeWithExistingStatements',
	text: 'Merge with existing statements',
	filter: textFilter(),
	sort: true
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: selectFilter({
		options: ScheduledTaskType.ScheduledTaskTypeAsSelectFilterOptions
	}),
	sort: true,
	formatter: (cell, row) => formatUtils.formatScheduledTaskType(cell, row)
};
export const scheduleType = {
	dataField: 'scheduleType',
	text: 'Schedule type',
	filter: textFilter(),
	sort: true
}
export const nextExecutionTime = {
	dataField: 'nextExecutionTime',
	text: 'Next execution',
	filter: textFilter(),
	sort: true
}
export const lastExecutionStartDateTime = {
	dataField: 'lastExecutionStartDateTime',
	text: 'Last execution',
	filter: textFilter(),
	sort: true
};
export const lastExecutionFinishDateTime = {
	dataField: 'lastExecutionFinishDateTime',
	text: 'Last execution finish',
	filter: textFilter(),
	sort: true
};
export const lastExecutionStatus = {
	dataField: 'lastExecutionStatus',
	text: 'Last execution status',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatScheduledTaskStatus(cell),
	sort: true
};
export const lastErrorMessage = {
	dataField: 'lastErrorMessage',
	text: 'Last error message',
	filter: textFilter(),
	sort: true
};
