import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	loading: false,
	crmBankImplementations: null,
}

export default function crmBankImplementationReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CRM_BANK_IMPLEMENTATION_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.CRM_BANK_IMPLEMENTATION_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				crmBankImplementations: action.crmBankImplementations
			}
		case actionTypes.CRM_BANK_IMPLEMENTATION_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}