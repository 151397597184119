import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const cronExpression = {
	dataField: 'cronExpression',
	text: 'Cron expression',
	filter: textFilter(),
	sort: true
};
export const name = {
	dataField: 'name',
	text: 'Name',
	filter: textFilter(),
	sort: true
};
export const urgency = {
	dataField: 'urgency',
	text: 'Urgency',
	filter: textFilter(),
	sort: true
};
export const chargeBearer = {
	dataField: 'chargeBearer',
	text: 'Charge bearer',
	filter: textFilter(),
	sort: true
};
export const paymentDetails = {
	dataField: 'paymentDetails',
	text: 'Payment details',
	filter: textFilter(),
	sort: true
};
export const bankAccountId = {
	dataField: 'bankAccountId',
	text: 'Bank account ID',
	filter: textFilter(),
	sort: true
};
export const supplierId = {
	dataField: 'supplierId',
	text: 'Supplier ID',
	filter: textFilter(),
	sort: true
};
export const supplierBankAccountId = {
	dataField: 'supplierBankAccountId',
	text: 'Supplier bank account ID',
	filter: textFilter(),
	sort: true
};
export const amountToPay = {
	dataField: 'amountToPay',
	text: 'Amount to pay',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountNo = {
	dataField: 'bankAccountNo',
	text: 'Bank account No.',
	filter: textFilter(),
	sort: true
};
export const bankAccountCurrencyCode = {
	dataField: 'bankAccountCurrencyCode',
	text: 'Bank account currency code',
	filter: textFilter(),
	sort: true
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier',
	filter: textFilter(),
	sort: true
};
export const supplierBankAccountNo = {
	dataField: 'supplierBankAccountNo',
	text: 'Supplier bank account No.',
	filter: textFilter(),
	sort: true
};
export const amountToPayLocalCurrency = {
	dataField: 'amountToPayLocalCurrency',
	text: 'Amount to pay (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const calculatedExecutionDate = {
	dataField: 'calculatedExecutionDate',
	text: 'Date',
	filter: textFilter(),
	sort: true
}