import * as actionTypes from "./actionTypes";

const initialState = {
	companies: [],
	companiesForTenant: [],
	company: null,
	deleting: false,
	error: false,
	loading: false,
	loadingSingle: false,
	noAvailableCompanies: false,
	saving: false,
	selectedCompany: null
}

export default function companyReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.COMPANY_CREATE:
			return {
				...state,
				error: false,
				saving: true
			}
		case actionTypes.COMPANY_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.COMPANY_CREATE_SUCCESS:
			return {
				...state,
				error: false,
				saving: false
			}
		case actionTypes.COMPANY_DELETE:
			return {
				...state,
				deleting: true,
				error: false
			}
		case actionTypes.COMPANY_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.COMPANY_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: false
			}
		case actionTypes.COMPANY_GET_ALL:
			return {
				...state,
				error: false,
				loading: true
			}
		case actionTypes.COMPANY_GET_ALL_FOR_TENANT:
			return {
				...state,
				error: false,
				loading: true
			}
		case actionTypes.COMPANY_GET_ALL_FOR_TENANT_SUCCESS:
			return {
				...state,
				companiesForTenant: action.companies,
				error: false,
				loading: false
			}
		case actionTypes.COMPANY_GET_BY_ID:
			return {
				...state,
				error: false,
				loadingSingle: true
			}
		case actionTypes.COMPANY_GET_ERROR:
			return {
				...state,
				error: true
			}
		case actionTypes.COMPANY_GET_ALL_SUCCESS:
			return {
				...state,
				companies: action.companies,
				error: false,
				loading: false,
				noAvailableCompanies: (!action.companies || !action.companies.length),
				selectedCompany: action.selectedCompany
			}
		case actionTypes.COMPANY_GET_LAST:
			return state;
		case actionTypes.COMPANY_GET_SUCCESS:
			return {
				...state,
				company: action.company,
				error: false,
				loadingSingle: false
			}
		case actionTypes.COMPANY_NONE_AVAILABLE:
			return {
				...state,
				companies: [],
				error: false,
				loading: false,
				noAvailableCompanies: true
			}
		case actionTypes.COMPANY_SET_SELECTED:
			return {
				...state,
				selectedCompany: action.selectedCompany
			}
		default:
			return state;
	}
}