import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { } from "../../store/actions";
import { withTranslation } from "react-i18next";

//import logo from "../../assets/images/cm-logo-transparent.png";
import logoLarge from "../../assets/images/cm-logo-transparent.png";
import logoSmall from "../../assets/images/cm-logoonly-transparent.png";
//import logoDark from "../../assets/images/cm-logo-transparent.png";
import SidebarContent from "./SidebarContent";

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<React.Fragment>

				<div className="vertical-menu">
					<div className="navbar-brand-box">
						{/*
						<Link to="/" className="logo logo-dark">
							<span className="logo-sm">
								<img src={logo} alt="" height="22" />
							</span>
							<span className="logo-lg">
								<img src={logoDark} alt="" height="17" />
							</span>
						</Link>
						*/}

						<Link to="/" className="logo logo-light">
							<span className="logo-sm">
								<img src={logoSmall} alt="" height="22" />
							</span>
							<span className="logo-lg">
								<img src={logoLarge} alt="" height="64" />
							</span>
						</Link>
					</div>
					<div data-simplebar className="h-100">
						{this.props.type !== "condensed" ? (
							<SidebarContent />
						) : (
							<SidebarContent />
						)}
					</div>
					<div className="sidebar-background"></div>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	}
}

export default connect(
	mapStateToProps,
	{}
)(withRouter(withTranslation()(Sidebar)));