import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import MetaTags from 'react-meta-tags';
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as columnsBankStatementRecord from "../../definitions/columns/bankStatementRecord";
import * as columnsCustomerInvoiceLine from "../../definitions/columns/customerInvoiceLine";
import * as columnsCustomerQuoteLine from "../../definitions/columns/customerQuoteLine";
import * as browseFormTools from "../../helpers/browseFormTools";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";
import * as selectRowUtils from '../../helpers/selectRowUtils';

class CustomerQuoteInvoiceEdit extends Component {

	constructor(props) {
		super(props);

		const defaultQuoteDate = new Date();					// today's date
		const defaultDueDate = new Date();
		defaultDueDate.setDate(defaultQuoteDate.getDate() + 7);	// today's date + 7

		this.state = {
			id: "",
			customerId: "",
			customerName: "",
			quoteNo: "",
			quoteDate: defaultQuoteDate.toJSON().substring(0, 10),
			dueDate: defaultDueDate.toJSON().substring(0, 10),
			canceled: "",
			notes: "",
			currencyId: "",
			currencyCode: "",
			amountWithoutVAT: "",
			vatAmount: "",
			amountWithVAT: "",
			vatRatesAndAmounts: [],
			lastSendingDate: null,
			allocationCount: 0,

			editable: true,
			showAllocations: false,

			customerQuotes: [],
			changed: false,
			errors: {},
			changed: false,
			selectedRows: [],
			movingLines: false,		// Can be deleted - it was only added for commandButtons and even still never worked as intended
			counterValue: null
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue,
			changed: true
		});
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetCustomerQuoteById(id);
		} else {
			this.props.onGetAndUpdateCounter();
		}
		this.props.onGetCurrencies();
		this.props.onGetCustomers();
		this.setState({
			selectedRows: []
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id
				})
			}
		}

		if (prevProps.customers !== this.props.customers) {
			if (!this.state.customerId && this.props.customers[0]) {
				this.setState({
					customerId: this.props.customers[0].id
				})
			}
		}

		if (prevProps.customerQuotes !== this.props.customerQuotes) {
			if (this.props.customerQuotes && this.props.customerQuotes[0]) {
				if (this.props.customerQuotes[0].id !== this.state.id
					|| this.props.customerQuotes[0].lastSendingDate !== this.state.lastSendingDate
					|| this.props.customerQuotes[0].allocationCount !== this.state.allocationCount
				) {
					this.setState({
						editable: !this.props.customerQuotes[0].lastSendingDate && !this.props.customerQuotes[0].allocationCount
					});
				}
				this.setState({
					id: this.props.customerQuotes[0].id,
					customerId: this.props.customerQuotes[0].customerId,
					customerName: this.props.customerQuotes[0].customerName,
					quoteNo: (this.props.targetNameShort === "quote" ? this.props.customerQuotes[0].quoteNo : this.props.customerQuotes[0].invoiceNo),
					quoteDate: (this.props.targetNameShort === "quote" ? this.props.customerQuotes[0].quoteDate : this.props.customerQuotes[0].invoiceDate),
					dueDate: this.props.customerQuotes[0].dueDate,
					canceled: this.props.customerQuotes[0].canceled,
					notes: this.props.customerQuotes[0].notes,
					currencyId: this.props.customerQuotes[0].currencyId,
					currencyCode: this.props.customerQuotes[0].currencyCode,
					amountWithoutVAT: this.props.customerQuotes[0].amountWithoutVAT,
					vatAmount: this.props.customerQuotes[0].vatAmount,
					amountWithVAT: this.props.customerQuotes[0].amountWithVAT,
					vatRatesAndAmounts: this.props.customerQuotes[0].vatRatesAndAmounts,
					lastSendingDate: this.props.customerQuotes[0].lastSendingDate,
					allocationCount: this.props.customerQuotes[0].allocationCount,
					selectedRows: [],
				});
				this.props.onGetCustomerQuoteLines(this.props.customerQuotes[0].id);
			} else {
				this.setState({
					id: "",
					customerId: "",
					quoteNo: (this.state.counterValue ? this.state.counterValue : ""),
					quoteDate: "",
					dueDate: "",
					canceled: "",
					notes: "",
					currencyId: "",
					currencyCode: "",
					amountWithoutVAT: "",
					vatAmount: "",
					amountWithVAT: "",
					vatRatesAndAmounts: [],
					selectedRows: [],
					allocationCount: 0,
					editable: true
				});
			}
		}

		if (prevProps.movingLines != this.props.movingLines) {
			this.setState({
				movingLines: this.props.movingLines
			});
		}

		if (prevProps.counterValue != this.props.counterValue) {
			this.setState({
				quoteNo: this.props.counterValue,
				counterValue: this.props.counterValue
			})
		}

		if ((prevProps.copySuccess !== this.props.copySuccess) || (prevProps.error !== this.props.error)) {
			window.scrollBy(0, -document.body.scrollHeight);
		}
	}

	prepareQuoteForInsertOrUpdate = () => {
		let newOrUpdatedCustomerQuote = {
			customerId: this.state.customerId,
			dueDate: this.state.dueDate,
			canceled: this.state.canceled,
			notes: this.state.notes,
			currencyId: this.state.currencyId,
			currencyCode: this.state.currencyCode,
			amountWithoutVAT: this.state.amountWithoutVAT,
			vatAmount: this.state.vatAmount,
			amountWithVAT: this.state.amountWithVAT,
			vatRatesAndAmounts: [],
			lastSendingDate: this.state.lastSendingDate,
		}
		if (this.props.targetNameShort === "quote") {
			newOrUpdatedCustomerQuote = {
				...newOrUpdatedCustomerQuote,
				quoteNo: this.state.quoteNo,
				quoteDate: this.state.quoteDate
			}
		} else {
			newOrUpdatedCustomerQuote = {
				...newOrUpdatedCustomerQuote,
				invoiceNo: this.state.quoteNo,
				invoiceDate: this.state.quoteDate
			}
		}
		if (this.state.id) {
			newOrUpdatedCustomerQuote = {
				id: this.state.id,
				...newOrUpdatedCustomerQuote
			}
		}
		return newOrUpdatedCustomerQuote;
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		const newOrUpdatedCustomerQuote = this.prepareQuoteForInsertOrUpdate();
		if (this.state.id) {
			this.props.onCreateCustomerQuote(newOrUpdatedCustomerQuote, this.props.history);	// Update the quote and return to Browse Quotes
		} else {
			this.props.onCreateCustomerQuote(newOrUpdatedCustomerQuote, null);					// Create the quote without returning to Browse Quotes, so that it's possible to add lines
		}
		this.setState({
			changed: false
		});
	}

	onAddNewLine = () => {
		const newOrUpdatedCustomerQuote = this.prepareQuoteForInsertOrUpdate();
		if (this.state.changed) {
			this.props.onCreateCustomerQuote(newOrUpdatedCustomerQuote, null);	// Update the quote without returing to Browse Quotes
		}
		if (this.props.targetNameShort === "quote") {
			this.props.history.push(endpointsFrontend.CUSTOMER_QUOTE_LINE_NEW.replace(":quoteid", this.state.id));
		} else {
			this.props.history.push(endpointsFrontend.CUSTOMER_INVOICE_LINE_NEW.replace(":invoiceid", this.state.id));
		}
	}

	onCopyCustomerQuote = () => {
		this.props.onCopyCustomerQuote(this.state.id, this.props.history);
	}

	onCopyCustomerQuoteLines = () => {
		this.props.onCopyCustomerQuoteLines(this.state.selectedRows);
	}

	onPrintCustomerQuote = () => {
		this.props.onPrintCustomerQuote(this.state.id);
	}

	onToggleShowAllocations = () => {
		if (this.state.showAllocations) {
			this.setState({
				showAllocations: false
			});
		} else {
			this.setState({
				showAllocations: true
			});
			this.props.onGetAllocations(this.state.id);
		}
	}

	onDeleteCustomerQuote = () => {
		if (window.confirm("Are you sure you want to delete this " + this.props.targetNameLowercase + "?")) {
			this.props.onDeleteCustomerQuote(this.state.id, this.props.history);
		}
	}

	onDeleteCustomerQuoteLines = () => {
		if (window.confirm("Are you sure you want to delete the selected lines?")) {
			this.props.onDeleteCustomerQuoteLines(this.state.selectedRows);
		}
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		// Could not disable or render the buttons differently on the basis of formatExtraData.movingLines, as apparently it does not get updated when needed
		return (
			<React.Fragment>
				<i
					className="bx bxs-up-arrow"
					onClick={() => {
						this.props.onMoveCustomerQuoteLineUp(row.id);
					}}
				/>
				{" "}
				<i
					className="bx bxs-down-arrow"
					onClick={() => {
						this.props.onMoveCustomerQuoteLineDown(row.id);
					}}
				/>
				{/* 
				<Button
					size="sm"
					onClick={() => {
						this.props.onMoveCustomerQuoteLineUp(row.id);
					}}
				>
					<i className="bx bx-up-arrow" />
				</Button>
				{" "}
				<Button
					size="sm"
					onClick={() => {
						this.props.onMoveCustomerQuoteLineDown(row.id);
					}}
				>
					<i className="bx bx-down-arrow" />
				</Button>				
				*/}
			</React.Fragment>
		);
	};

	setEditable = () => {
		const customerName = (this.state.customerName ? this.state.customerName : "the customer");
		const confirmationText_AlreadySent = "This invoice has been sent to " + customerName + " on " + this.state.lastSendingDate
			+ ".\nIf you proceed, its sending status will be reset and you will need to send it to " + customerName + " again.";
		const confirmationText_Allocated = "This invoice has already been allocated.\nIf you edit it, existing allocations might be affected.";

		if (this.state.lastSendingDate && this.state.allocationCount) {
			if (window.confirm(confirmationText_AlreadySent) && window.confirm(confirmationText_Allocated)) {
				this.setState({ editable: true });
			}
		} else if (this.state.lastSendingDate) {
			if (window.confirm(confirmationText_AlreadySent)) {
				this.setState({ editable: true });
			}
		} else if (this.state.allocationCount) {
			if (window.confirm(confirmationText_Allocated)) {
				this.setState({ editable: true });
			}
		}
	}


	render() {
		const pageTitle = (this.state.id || this.props.loading ? (this.state.editable ? "Edit" : "View") : "Create") + " " + this.props.targetNameLowercase + " | " + config.AppName;
		const breadcrumbsTitle = this.props.targetNameCapitalized;
		const breadcrumbsItem = (this.state.id || this.props.loading ? (this.state.editable ? "Edit" : "View") : "New") + " " + this.props.targetNameLowercase;
		let returnLink;
		if (this.props.targetNameShort === "quote") {
			returnLink = endpointsFrontend.CUSTOMER_QUOTE_BROWSE;
		} else {
			returnLink = endpointsFrontend.CUSTOMER_INVOICE_BROWSE_ALL;
		}

		const nonEditableAlertText = this.state.lastSendingDate
			? "This invoice has already been sent to " + (this.state.customerName ? this.state.customerName : "the customer") + " on " + this.state.lastSendingDate + "."
			: "This invoice has already been allocated.";

		const nonEditableAlert =
			<Alert
				color="warning"
			>
				{nonEditableAlertText}
				<span> </span>
				<Button
					color="warning"
					onClick={this.setEditable}
				>
					Edit anyway
				</Button>
			</Alert>

		const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
		const customerOptions = inputSelectUtils.generateOptionsFromData(this.props.customers,
			customer => customer.name,
			(customer1, customer2) => (customer1.name > customer2.name ? 1 : customer1.name === customer2.name ? 0 : -1)
		);

		const printButton =
			<Button
				color="secondary"
				onClick={this.onPrintCustomerQuote}
				disabled={this.props.printing}
			>
				Print to PDF
				{" "}
				{this.props.printing ? editFormControls.buttonSpinner() : null}
			</Button>

		const showAllocationsButton =
			<Button
				color="secondary"
				disabled={!this.state.allocationCount}
				onClick={this.onToggleShowAllocations}
			>
				{this.state.showAllocations ? "Hide allocations" : "Show allocations (" + this.state.allocationCount + ")"}
				{" "}
				{this.props.loadingAllocations ? editFormControls.buttonSpinner() : null}
			</Button>


		const columnEvents = {
			onClick: (e, column, columnIndex, row, rowIndex) => {
				if (row.id) {
					if (this.props.targetNameShort === "quote") {
						this.props.history.push(endpointsFrontend.CUSTOMER_QUOTE_LINE_EDIT.replace(":id", row.id));
					} else {
						this.props.history.push(endpointsFrontend.CUSTOMER_INVOICE_LINE_EDIT.replace(":id", row.id));
					}
				}
			}
		}

		let columns;
		if (this.props.targetNameShort === "invoice") {
			columns = [
				columnsCustomerInvoiceLine.position,
				columnsCustomerInvoiceLine.endCustomerName,
				columnsCustomerInvoiceLine.period,
				columnsCustomerInvoiceLine.productNameLang0,
				columnsCustomerInvoiceLine.unitNameLang0,
				columnsCustomerInvoiceLine.quantity,
				columnsCustomerInvoiceLine.price,
				columnsCustomerInvoiceLine.discount,
				columnsCustomerInvoiceLine.totalAmountWithoutVAT,
				columnsCustomerInvoiceLine.vatRate
			];
		} else if (this.props.targetNameShort === "quote") {
			columns = [
				columnsCustomerQuoteLine.position,
				columnsCustomerQuoteLine.productNameLang0,
				columnsCustomerQuoteLine.unitNameLang0,
				columnsCustomerQuoteLine.quantity,
				columnsCustomerQuoteLine.price,
				columnsCustomerQuoteLine.discount,
				columnsCustomerQuoteLine.totalAmountWithoutVAT,
				columnsCustomerQuoteLine.vatRate
			];
		}
		if (this.state.editable) {
			for (let i in columns) {
				columns[i] = {
					...columns[i],
					events: columnEvents
				}
			}
			columns = columns.concat([
				{
					dataField: "commandButtons",
					text: "",
					formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {
						/*movingLines: this.state.movingLines  // does not work correctly */
					})
				}
			]);
		}

		const addNewLineButton =
			<Button
				color="primary"
				size="sm"
				onClick={this.onAddNewLine}
			>
				Add new line
			</Button>

		const copyLinesButton =
			<Button
				color="secondary"
				size="sm"
				disabled={this.state.selectedRows.length === 0 || this.props.copyingLines}
				onClick={this.onCopyCustomerQuoteLines}
			>
				Duplicate lines
				{" "}
				{this.props.copyingLines ? editFormControls.buttonSpinner() : null}
			</Button>

		const deleteLinesButton =
			<Button
				color="danger"
				size="sm"
				disabled={this.state.selectedRows.length === 0 || this.props.deletingLines}
				onClick={this.onDeleteCustomerQuoteLines}
			>
				Delete lines
				{" "}
				{this.props.deletingLines ? editFormControls.buttonSpinner() : null}
			</Button>

		const customerQuoteLines = this.props.match.params.id ? this.props.customerQuoteLines : [];		// necessary, because this.props.customerQuoteLines might be filled with previously viewed quote lines (observed 2022-05-26)

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const table = (this.state.editable ? (
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={customerQuoteLines}
					columns={columns}
					selectRow={selectRow}
					sort={{
						dataField: 'sequenceNo',
						order: 'asc'
					}}
				/>
			</div>
		) : (
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={customerQuoteLines}
					columns={columns}
					sort={{
						dataField: 'sequenceNo',
						order: 'asc'
					}}
				/>
			</div>
		));

		const totals =
			<React.Fragment>
				{editFormControls.staticTextControl("totalAmountWOVAT", "Total excl. VAT, " + this.state.currencyCode, formatUtils.formatAmount(this.state.amountWithoutVAT))}

				{this.state.vatRatesAndAmounts ?
					this.state.vatRatesAndAmounts.map(vatAmountAndRate =>
						editFormControls.staticTextControlWithKey("vatAmount" + vatAmountAndRate.rate, "VAT " + vatAmountAndRate.rate + "%, " + this.state.currencyCode, formatUtils.formatAmount(vatAmountAndRate.amount), vatAmountAndRate.rate)
					)
					: null
				}

				{editFormControls.staticTextControl("totalAmountWVAT", "Total incl. VAT, " + this.state.currencyCode, formatUtils.formatAmount(this.state.amountWithVAT))}
			</React.Fragment>


		const editForm = (
			<form
				className="outer-repeater"
				onSubmit={this.onSubmit}
			>
				<div data-repeater-list="outer-group" className="outer">
					<div data-repeater-item className="outer">

						{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
						{editFormControls.textControl("quoteNo", this.props.targetNameShortCapitalized + " No.", this.onChange, this.state.quoteNo)}
						{editFormControls.dateControl("quoteDate", this.props.targetNameShortCapitalized + " date", this.onChange, this.state.quoteDate)}
						{editFormControls.dateControl("dueDate", "Due date", this.onChange, this.state.dueDate)}
						{editFormControls.selectControl("customerId", "Customer", this.onChange, this.state.customerId, customerOptions)}
						{editFormControls.checkboxControl("canceled", "Canceled", this.onChange, this.state.canceled)}
						{editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}

						<div
							style={{
								position: "relative"
							}}
						>
							<OverlayWithSpinner
								show={this.props.movingLines}
							/>
							{this.state.id && !this.props.loadingLines && this.props.customerQuoteLines ? table : null}

							{this.state.id ? addNewLineButton : null}
							{" "}
							{this.state.id ? copyLinesButton : null}
							{" "}
							{this.state.id ? deleteLinesButton : null}

						</div>

						<br />
						<br />
						<br />

						{this.props.targetNameShort === "quote" ? editFormControls.textAreaControl("notes", "Notes", this.onChange, this.state.notes) : null}

						<br />

						{this.state.id ? totals : null}

					</div>
				</div>

				<Row className="justify-content-end">
					<Col lg="10">
						{editFormControls.saveButton(this.props.saving, this.state.id)}
						<span> </span>
						{editFormControls.copyButton(this.props.copying, this.onCopyCustomerQuote, this.state.id)}
						<span> </span>
						{this.state.id ? printButton : null}
						<span> </span>
						{this.props.targetNameShort === "invoice" ? showAllocationsButton : null}
						<span> </span>
						{editFormControls.deleteButton(this.props.deleting, this.onDeleteCustomerQuote, this.state.id)}
					</Col>
				</Row>
			</form>
		);

		const viewForm = (
			<form
				className="outer-repeater"
			>
				<div data-repeater-list="outer-group" className="outer">
					<div data-repeater-item className="outer">

						{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
						{editFormControls.staticTextControl("quoteNo", this.props.targetNameShortCapitalized + " No.", this.state.quoteNo)}
						{editFormControls.staticTextControl("quoteDate", this.props.targetNameShortCapitalized + " date", this.state.quoteDate)}
						{editFormControls.staticTextControl("dueDate", "Due date", this.state.dueDate)}
						{editFormControls.staticTextControl("customerName", "Customer", this.state.customerName)}
						{editFormControls.checkboxControl("canceled", "Canceled", () => { }, this.state.canceled)}
						{editFormControls.staticTextControl("currencyCode", "Currency", this.state.currencyCode)}

						<div
							style={{
								position: "relative"
							}}
						>
							{this.state.id && !this.props.loadingLines && this.props.customerQuoteLines ? table : null}
						</div>

						<br />
						<br />
						<br />

						{this.props.targetNameShort === "quote" ? editFormControls.textAreaControl("notes", "Notes", this.onChange, this.state.notes) : null}

						<br />

						{this.state.id ? totals : null}

					</div>
				</div>

				<Row className="justify-content-end">
					<Col lg="10">
						<Button
							onClick={() => { this.props.history.push(returnLink) }}
						>
							Return
						</Button>
						<span> </span>
						{editFormControls.copyButton(this.props.copying, this.onCopyCustomerQuote, this.state.id)}
						<span> </span>
						{this.state.id ? printButton : null}
						<span> </span>
						{this.props.targetNameShort === "invoice" ? showAllocationsButton : null}
					</Col>
				</Row>
			</form>
		);

		const allocationRowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.BANK_STATEMENT_RECONCILE.replace(":id", row.bankStatementId));
			}
		};

		const allocationTable =
			<BootstrapTable
				keyField='id'
				data={this.props.allocations}
				columns={browseFormTools.removeFiltersFromColumns([
					columnsBankStatementRecord.debitCredit,
					columnsBankStatementRecord.beneficiaryName,
					columnsBankStatementRecord.amount,
					columnsBankStatementRecord.paymentDate,
					columnsBankStatementRecord.paymentDetails
				])}
				rowEvents={allocationRowEvents}
				rowStyle={{ cursor: "pointer" }}
			/>

		const editOrViewForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							{this.state.editable ? editForm : viewForm}
							{this.state.showAllocations && this.props.allocations ? allocationTable : null}
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		const loading = this.props.loading || this.props.loadingLines || this.props.loadingCounters || this.props.loadingCurrencies || this.props.loadingCustomers;

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs
							title={breadcrumbsTitle}
							breadcrumbItem={breadcrumbsItem}
							link={returnLink}
						/>

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.copySuccessAlert(!this.state.changed && this.props.copySuccess, this.props.targetNameCapitalized)}

						{this.state.editable ? null : nonEditableAlert}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading ? editOrViewForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default CustomerQuoteInvoiceEdit;