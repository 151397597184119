import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

//import * as formatUtils from 'helpers/formatUtils';

export const name = {
	dataField: 'name',
	text: 'Name',
	filter: textFilter(),
	sort: true
};
export const registrationNo = {
	dataField: 'registrationNo',
	text: 'Registration no',
	filter: textFilter(),
	sort: true
};
