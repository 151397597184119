import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsAvailableModule from '../../store/AvailableModule/actions';
import * as actionsCompany from '../../store/Company/actions';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import { httpErrorMessageAndAdditionalText } from "helpers/errorMessageHelper";

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			availableModules: [],
			error: false,
			loadingAvailableModules: true,
			loadingCompanies: true,
			noAvailableCompanies: false
		}
	}

	componentDidMount() {
		if (this.props.userIsAdmin) {
			this.props.onGetAllCompaniesForTenant();
		}
		this.props.onGetAllCompanies();
		this.props.onGetAvailableModules();
	}

	componentDidUpdate(prevProps) {

		if (this.props.availableModules != prevProps.availableModules) {
			const availableModules = [];
			if (this.props.availableModules) {
				this.props.availableModules.map(availableModule => {
					availableModules.push(availableModule.name);
				})
			}
			this.setState({
				availableModules: availableModules,
				loadingAvailableModules: false
			});
		}

		if (this.props.errorAvailableModules != prevProps.errorAvailableModules) {
			this.setState({
				error: this.props.errorAvailableModules
			});
		}

		if (this.props.loadingAvailableModules != prevProps.loadingAvailableModules) {
			this.setState({
				loadingAvailableModules: this.props.loadingAvailableModules
			});
		}

		if (this.props.loadingCompanies != prevProps.loadingCompanies) {
			this.setState({
				loadingCompanies: this.props.loadingCompanies
			});
		}

		if (this.props.noAvailableCompanies != prevProps.noAvailableCompanies) {
			this.setState({
				loadingCompanies: false,
				loadingAvailableModules: false,
				noAvailableCompanies: this.props.noAvailableCompanies
			});
		}

	}

	render() {
		const LoadingSpinner = (
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<div align="center">
										{editFormControls.bigSpinner()}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		)

		const warningMessageComponent = (warningMessage) => {
			return (
				<div className="page-content">
					<Container fluid>
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<Alert
											color="warning"
										>
											{warningMessage}
										</Alert>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			)
		}

		const errorMessageComponent = (errorMessage) => {
			return (
				<div className="page-content">
					<Container fluid>
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<Alert
											color="danger"
										>
											{errorMessage}
										</Alert>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			)
		}

		let redirectEndpoint;
		if (this.props.userData && (!this.props.userData.firstName || !this.props.userData.firstName.length)) {
			redirectEndpoint = endpointsFrontend.USER_ACCOUNT_PROFILE_EDIT;
		} else if (this.state.availableModules.includes("DASHBOARD")) {
			redirectEndpoint = endpointsFrontend.DASHBOARD;
		} else if (this.state.availableModules.includes("BANK_STATEMENT")) {
			redirectEndpoint = endpointsFrontend.BANK_STATEMENT_BROWSE;
		} else if (this.state.availableModules.includes("SUPPLIER_INVOICE")) {
			redirectEndpoint = endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID;
		} else if (this.state.availableModules.includes("PAYMENT_SIGNABLE_IN_CRM")) {
			redirectEndpoint = endpointsFrontend.PAYMENT_BROWSE_UNSIGNED;
		} else if (this.state.availableModules.includes("CM20PAYMENTS")) {
			redirectEndpoint = endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED;
		}

		let renderableComponent;

		if (this.state.loadingCompanies || this.state.loadingAvailableModules) {
			renderableComponent = (
				LoadingSpinner
			);
		} else if (!this.state.loadingCompanies && this.state.noAvailableCompanies) {
			if (this.props.userData.userRoles && this.props.userData.userRoles.length && this.props.userData.userRoles.includes("ROLE_ADMIN")) {
				renderableComponent = (
					<Redirect to={endpointsFrontend.COMPANY_NEW} />
				);
			} else {
				renderableComponent = (
					warningMessageComponent("You have no companies available. Please request the person who invited you to " + config.AppName + " to give you access.")
				);
			}
		} else if (!this.state.loadingCompanies && this.state.error) {
			renderableComponent = (
				errorMessageComponent(httpErrorMessageAndAdditionalText("Error", this.state.error))
			);
		} else if (!this.state.loadingCompanies && !this.state.loadingAvailableModules && !this.state.availableModules.length) {
			renderableComponent = (
				warningMessageComponent("You have no modules available. Please request the person who invited you to " + config.AppName + " to give you access.")
			);
		} else if (!this.state.loadingCompanies && !this.state.loadingAvailableModules && redirectEndpoint) {
			renderableComponent = (
				<Redirect to={redirectEndpoint} />
			);
		} else {
			renderableComponent = (
				<React.Fragment></React.Fragment>
			);
		}

		return (
			renderableComponent
		);
	}
}

const mapStateToProps = ({ availableModule, company, userLogin }) => ({
	availableModules: availableModule.availableModules,
	companies: company.companies,
	companiesForTenant: company.companiesForTenant,
	errorAvailableModules: availableModule.error,
	loadingAvailableModules: availableModule.loading,
	loadingCompanies: company.loading,
	noAvailableCompanies: company.noAvailableCompanies,
	userData: userLogin.userData,
	userIsAdmin: userLogin.userData && userLogin.userData.userRoles && userLogin.userData.userRoles.includes("ROLE_ADMIN")
});

const mapDispatchToProps = dispatch => ({
	onGetAllCompanies: () => dispatch(actionsCompany.companyGetAll()),
	onGetAllCompaniesForTenant: () => dispatch(actionsCompany.companyGetAllForTenant()),
	onGetAvailableModules: () => dispatch(actionsAvailableModule.availableModuleGetAll()),
	onSetNoAvailableModules: () => dispatch(actionsAvailableModule.availableModulesSetNone())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
