export const ALLOCATION_CRITERION_CREATE = "ALLOCATION_CRITERION_CREATE";
export const ALLOCATION_CRITERION_CREATE_ERROR = "ALLOCATION_CRITERION_CREATE_ERROR";
export const ALLOCATION_CRITERION_CREATE_SUCCESS = "ALLOCATION_CRITERION_CREATE_SUCCESS";

export const ALLOCATION_CRITERION_DELETE = "ALLOCATION_CRITERION_DELETE";
export const ALLOCATION_CRITERION_DELETE_ERROR = "ALLOCATION_CRITERION_DELETE_ERROR";
export const ALLOCATION_CRITERION_DELETE_SUCCESS = "ALLOCATION_CRITERION_DELETE_SUCCESS";

export const ALLOCATION_CRITERION_GET_ALL = "ALLOCATION_CRITERION_GET_ALL";
export const ALLOCATION_CRITERION_GET_BY_ID = "ALLOCATION_CRITERION_GET_BY_ID";
export const ALLOCATION_CRITERION_GET_ERROR = "ALLOCATION_CRITERION_GET_ERROR";
export const ALLOCATION_CRITERION_GET_SUCCESS = "ALLOCATION_CRITERION_GET_SUCCESS";

export const ALLOCATION_CRITERION_MOVE_UP = "ALLOCATION_CRITERION_MOVE_UP";
export const ALLOCATION_CRITERION_MOVE_DOWN = "ALLOCATION_CRITERION_MOVE_DOWN";
export const ALLOCATION_CRITERION_MOVE_SUCCESS = "ALLOCATION_CRITERION_MOVE_SUCCESS";
export const ALLOCATION_CRITERION_MOVE_ERROR = "ALLOCATION_CRITERION_MOVE_ERROR";
