export const PRODUCT_GET_ALL = "PRODUCT_GET_ALL";
export const PRODUCT_GET_BY_ID = "PRODUCT_GET_BY_ID";
export const PRODUCT_GET_ERROR = "PRODUCT_GET_ERROR";
export const PRODUCT_GET_SUCCESS = "PRODUCT_GET_SUCCESS";

export const PRODUCT_CREATE = "PRODUCT_CREATE";
export const PRODUCT_CREATE_ERROR = "PRODUCT_CREATE_ERROR";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";

export const PRODUCT_DELETE = "PRODUCT_DELETE";

