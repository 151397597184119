import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const supplierId = {
	dataField: 'supplierId',
	text: 'Supplier ID',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const accountNo = {
	dataField: 'accountNo',
	text: 'Account No.',
	filter: textFilter(),
	sort: true
};
export const bankSwiftCode = {
	dataField: 'bankSwiftCode',
	text: 'Bank SWIFT code',
	filter: textFilter(),
	sort: true
};
export const bankAlternativeCode = {
	dataField: 'bankAlternativeCode',
	text: 'Bank alternative code',
	filter: textFilter(),
	sort: true
};
export const bankName = {
	dataField: 'bankName',
	text: 'Bank name',
	filter: textFilter(),
	sort: true
};
export const corrAccountNo = {
	dataField: 'corrAccountNo',
	text: 'Corr account No.',
	filter: textFilter(),
	sort: true
};
export const corrSwiftCode = {
	dataField: 'corrSwiftCode',
	text: 'Corr swift code',
	filter: textFilter(),
	sort: true
};
export const corrBankName = {
	dataField: 'corrBankName',
	text: 'Corr bank name',
	filter: textFilter(),
	sort: true
};
export const additionalInfo = {
	dataField: 'additionalInfo',
	text: 'Additional info',
	filter: textFilter(),
	sort: true
};
export const accountCustomType = {
	dataField: 'accountCustomType',
	text: 'Account custom type',
	filter: textFilter(),
	sort: true
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier name',
	filter: textFilter(),
	sort: true
};
export const status = {
	dataField: 'status',
	text: 'Status',
	formatter: (cell) => formatUtils.formatSupplierBankAccountStatus(cell),
	sort: true
};
