import * as actionTypes from "./actionTypes";

export const tenantLicenseGetAll = () => {
	return {
		type: actionTypes.TENANT_LICENSE_GET_ALL
	}
}

export const tenantLicenseGetError = (thisError) => {
	return {
		type: actionTypes.TENANT_LICENSE_GET_ERROR,
		error: thisError
	}
}

export const tenantLicenseGetSuccess = (data) => {
	return {
		type: actionTypes.TENANT_LICENSE_GET_SUCCESS,
		tenantLicenses: data
	}
}