import * as actionTypes from "./actionTypes";

export const scheduledTaskCreate = (scheduledTask) => {
	return {
		type: actionTypes.SCHEDULED_TASK_CREATE,
		scheduledTask: scheduledTask, 
	}
}

export const scheduledTaskCreateError = (thisError) => {
	return {
		type: actionTypes.SCHEDULED_TASK_CREATE_ERROR,
		error: thisError
	}
}

export const scheduledTaskCreateSuccess = (scheduledTask) => {
	return {
		type: actionTypes.SCHEDULED_TASK_CREATE_SUCCESS,
		scheduledTask: scheduledTask
	}
}

export const scheduledTaskDelete = (idList) => {
	return {
		type: actionTypes.SCHEDULED_TASK_DELETE,
		idList: idList
	}
}

export const scheduledTaskDeleteError = (thisError) => {
	return {
		type: actionTypes.SCHEDULED_TASK_DELETE_ERROR,
		error: thisError
	}
}

export const scheduledTaskDeleteSuccess = (data) => {
	return {
		type: actionTypes.SCHEDULED_TASK_DELETE_SUCCESS,
		scheduledTasks: data
	}
}

export const scheduledTaskExecute = (id, executedTaskName) => {
	return {
		type: actionTypes.SCHEDULED_TASK_EXECUTE,
		executedTaskName: executedTaskName,
		id: id
	}
}

export const scheduledTaskExecuteError = (thisError) => {
	return {
		type: actionTypes.SCHEDULED_TASK_EXECUTE_ERROR,
		error: thisError
	}
}

export const scheduledTaskExecuteSuccess = () => {
	return {
		type: actionTypes.SCHEDULED_TASK_EXECUTE_SUCCESS
	}
}

export const scheduledTaskGetAll = () => {
	return {
		type: actionTypes.SCHEDULED_TASK_GET_ALL
	}
}

export const scheduledTaskGetById = (id) => {
	return {
		type: actionTypes.SCHEDULED_TASK_GET_BY_ID,
		id: id
	}
}

export const scheduledTaskGetError = (thisError) => {
	return {
		type: actionTypes.SCHEDULED_TASK_GET_ERROR,
		error: thisError
	}
}

export const scheduledTaskGetSuccess = (data) => {
	return {
		type: actionTypes.SCHEDULED_TASK_GET_SUCCESS,
		scheduledTasks: data
	}
}

