import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row, UncontrolledTooltip } from "reactstrap";

import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as actions from "../../store/UserAccountLogin/actions";
import profile from "../../assets/images/cm-logo-transparent.png";
import { httpErrorMessageAndAdditionalText } from "helpers/errorMessageHelper";


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",
            tenantExternalId: "",
            ignoreInvalid_email: true,
            ignoreInvalid_password: true,
            ignoreInvalid_passwordConfirm: true,
            ignoreInvalid_tenantExternalId: true
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    isValidEmail(value) {
        return value.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        const ignoreInvalidFieldName = "ignoreInvalid_" + thisObjectName;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (e.target.id == "tenantExternalId") {
            thisValue = thisValue.replace(/[^a-z0-9\-_]/g, "");
        }
        this.setState({
            [thisObjectName]: thisValue,
            [ignoreInvalidFieldName]: false
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        this.setState({
            ignoreInvalid_email: false,
            ignoreInvalid_password: false,
            ignoreInvalid_passwordConfirm: false,
            ignoreInvalid_tenantExternalId: false
        });
        if (this.isValidEmail(this.state.email) && this.state.password && this.state.password === this.state.passwordConfirm && this.state.tenantExternalId) {
            const createUserAccountAndTenantDTO = {
                username: this.state.email,
                password: this.state.password,
                tenantExternalId: this.state.tenantExternalId
            }
            this.props.onRegisterUser(createUserAccountAndTenantDTO, this.props.history);
        }
    }

    componentDidMount() {
        //this.props.loginCheck(this.props.history);
    }

    render() {

		const errorText = this.props.error ? httpErrorMessageAndAdditionalText("Error", this.props.error) : "";

        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
                                        <img src={profile} alt="" className="img-fluid" />
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <Form
                                                onSubmit={this.onSubmit}
                                            >
                                                {errorText ? (
                                                    <Alert color="danger">{errorText}</Alert>
                                                ) : null}

                                                <div className="mb-3">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="email"
                                                        >
                                                            Email <span style={{ color: "gray" }} id="EmailTooltip"><i className="bx bxs-help-circle" /></span>
                                                        </Label>
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            invalid={!this.state.ignoreInvalid_email && !this.isValidEmail(this.state.email)}
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            onChange={this.onChange}
                                                            value={this.state.email}
                                                        />
                                                        <FormFeedback>
                                                            Must be a valid email address
                                                        </FormFeedback>
                                                    </FormGroup>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="EmailTooltip"
                                                    >
                                                        Email address you will use to log in. We will send you an activation link there. Also, in case you forget your password, we will send there a password reset link.
                                                    </UncontrolledTooltip>
                                                </div>

                                                <div className="mb-3">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="password"
                                                        >
                                                            Password
                                                        </Label>
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            invalid={!this.state.ignoreInvalid_password && !this.state.password}
                                                            className="form-control"
                                                            placeholder="Enter password"
                                                            onChange={this.onChange}
                                                            value={this.state.password}
                                                        />
                                                        <FormFeedback>
                                                            Password must not be empty
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </div>

                                                <div className="mb-3">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="passwordConfirm"
                                                        >
                                                            Confirm password
                                                        </Label>
                                                        <Input
                                                            id="passwordConfirm"
                                                            name="passwordConfirm"
                                                            type="password"
                                                            invalid={!this.state.ignoreInvalid_passwordConfirm && !(this.state.password === this.state.passwordConfirm)}
                                                            className="form-control"
                                                            placeholder="Confirm password"
                                                            onChange={this.onChange}
                                                            value={this.state.passwordConfirm}
                                                        />
                                                        <FormFeedback>
                                                            Passwords don't match
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </div>

                                                <div>
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="tenantExternalId"
                                                        >
                                                            <span>Subscription ID <span style={{ color: "gray" }} id="TenantIDTooltip"><i className="bx bxs-help-circle" /></span></span>
                                                        </Label>
                                                        <Input
                                                            id="tenantExternalId"
                                                            name="tenantExternalId"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter desired subscription ID"
                                                            invalid={!this.state.ignoreInvalid_tenantExternalId && !this.state.tenantExternalId}
                                                            onChange={this.onChange}
                                                            value={this.state.tenantExternalId}
                                                        />
                                                        <FormFeedback>
                                                            Subscription ID must not be empty
                                                        </FormFeedback>
                                                    </FormGroup>
                                                    <UncontrolledTooltip
                                                        placement="right"
                                                        target="TenantIDTooltip"
                                                    >
                                                        An internal subscription ID. Only lowercase letters, numbers and symbols "-" and "_" are allowed. If you have one company in the subscription, you can name it after that company (e.g. my_company_ltd). If you have multiple companies, we recommend you name the subscription after the mother company (e.g. mother_company_ltd).
                                                    </UncontrolledTooltip>
                                                </div>

                                                <br />

                                                <div className="mt-3 d-grid">
                                                    <button
                                                        className="btn btn-primary btn-block"
                                                        type="submit"
                                                        disabled={this.props.loading}
                                                    >
                                                        Sign Up
                                                        {" "}
                                                        {this.props.loading ? editFormControls.buttonSpinner() : null}
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    {
                                        <p>
                                            Already registered?{" "}
                                            <Link
                                                to={endpointsFrontend.USER_ACCOUNT_LOGIN}
                                                className="fw-medium text-primary"
                                            >
                                                {" "}
                                                Log in{" "}
                                            </Link>{" "}
                                        </p>
                                    }
                                    <p>
                                        Copyright © {new Date().getFullYear()} D.Vision SIA
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        error: state.userLogin.errorRegistering
    };
};

const mapDispatchToProps = dispatch => ({
    onRegisterUser: (data, history) => dispatch(actions.userRegister(data, history))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Login)
);