import * as actionTypes from "./actionTypes";

const initialState = {
	crmMaintenanceNotices: null,
	loading: false,
	error: null
}

export default function crmMaintenanceNoticeReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CRM_MAINTENANCE_NOTICE_GET_PENDING:
			return {
				...state,
				error: null
			}
		case actionTypes.CRM_MAINTENANCE_NOTICE_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.CRM_MAINTENANCE_NOTICE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				crmMaintenanceNotices: action.crmMaintenanceNotices
			}
		case actionTypes.CRM_MAINTENANCE_NOTICE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}