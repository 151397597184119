import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";

function* getCashFlowSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.DATA_ANALYSIS_CASHFLOW_GET_DATA
		);

		yield put(actions.cashFlowGetSuccess(response.data));
	} catch (e) {
		yield put(actions.cashFlowGetError(e));
	}
}

function* dataAnalysisSaga() {
	yield (takeLatest(actionTypes.CASH_FLOW_GET_ALL, getCashFlowSaga));
}

export default dataAnalysisSaga;