import * as actionTypes from "./actionTypes";

const initialState = {
	allocationCriteria: null,
	deleting: false,
	error: null,
	loaded: false,
	loading: false,
	moving: false,
	saving: false
}

const updateAllocationCriteria = (state, updatedAllocationCriterion) => {
	const allocationCriteria = [...state.allocationCriteria];
	let updated = false;
	for (let i = 0; i < allocationCriteria.length; ++i) {
		if (allocationCriteria[i].id === updatedAllocationCriterion.id) {
			allocationCriteria[i] = updatedAllocationCriterion;
			updated = true;
			break;
		}
	}
	if (!updated) {
		allocationCriteria.push(updatedAllocationCriterion);
	}
	return allocationCriteria;
}

export default function allocationCriterionReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.ALLOCATION_CRITERION_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.ALLOCATION_CRITERION_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false,
			}
		case actionTypes.ALLOCATION_CRITERION_CREATE_SUCCESS:
			const updatedAllocationCriteria = updateAllocationCriteria(state, action.allocationCriterion);
			return {
				...state,
				allocationCriteria: updatedAllocationCriteria,
				error: null,
				saving: false
			}
		case actionTypes.ALLOCATION_CRITERION_DELETE:
			return {
				...state,
				deleting: true,
				error: null,
			}
		case actionTypes.ALLOCATION_CRITERION_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error,
			}
		case actionTypes.ALLOCATION_CRITERION_DELETE_SUCCESS:
			return {
				...state,
				allocationCriteria: action.allocationCriteria,
				deleting: false,
				error: null,
			}
		case actionTypes.ALLOCATION_CRITERION_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.ALLOCATION_CRITERION_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.ALLOCATION_CRITERION_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			}
		case actionTypes.ALLOCATION_CRITERION_GET_SUCCESS:
			return {
				...state,
				allocationCriteria: action.allocationCriteria,
				loaded: true,
				loading: false,
			}
		case actionTypes.ALLOCATION_CRITERION_MOVE_UP:
		case actionTypes.ALLOCATION_CRITERION_MOVE_DOWN:
			return {
				...state,
				moving: true,
				error: null
			}
		case actionTypes.ALLOCATION_CRITERION_MOVE_SUCCESS:
			return {
				...state,
				moving: false,
				error: null,
				allocationCriteria: action.allocationCriteria
			}
		case actionTypes.ALLOCATION_CRITERION_MOVE_ERROR:
			return {
				...state,
				moving: false,
				error: action.error
			}
		default:
			return state;
	}
}