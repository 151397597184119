import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Timelog/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsTimelog from '../../definitions/columns/timelog';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class TimelogBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			filteredTimelogs: [],
			//totalHours: 0,		// Currently implemented automatic calculation of totals upon state (filteredTimelogs) change
			calculatedFilters: null
		}
	}

	componentDidMount() {
		this.props.onGetTimelogs();
	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	// calculateTotals = (dataset) => {
	// 	let totalHours = 0;
	// 	if (dataset) {
	// 		for (let i in dataset) {
	// 			totalHours += dataset[i].hoursLogged;
	// 		}
	// 	}
	// 	this.setState({
	// 		totalHours: totalHours
	// 	});
	// }

	afterFilter = (newDataset, newFilters) => {
		if (!this.state.calculatedFilters || (this.state.calculatedFilters !== newFilters)) {	// Without this check, this code sometimes goes to an infinite loop, e.g. when entering "frontend" for task title in Timelogs
			this.setState({
				filteredTimelogs: newDataset,
				calculatedFilters: newFilters
			});
			//this.calculateTotals(newDataset);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((prevProps.timelogs != this.props.timelogs) && this.props.timelogs) {
			this.setState({
				filteredTimelogs: this.props.timelogs
			});
			//this.calculateTotals(this.props.timelogs);
		}
	}

	render() {

		const pageTitle = "Timelogs | " + config.AppName;
		const breadcrumbsTitle = "Timelog";
		const breadcrumbsItem = "Browse timelogs";

		let totalHours = 0;
		if (this.state.filteredTimelogs) {
			for (let i in this.state.filteredTimelogs) {
				totalHours += this.state.filteredTimelogs[i].hoursLogged;
			}
		}

		const columns = [
			columnsTimelog.taskTitle,
			columnsTimelog.date,
			columnsTimelog.hoursLogged,
			columnsTimelog.userLoggedInfo,
			columnsTimelog.comment
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.TIMELOG_EDIT.replace(":id", row.id));
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.timelogs}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory({ afterFilter: this.afterFilter })}
					sort={{
						dataField: 'date',
						order: 'desc'
					}}
				/>
				{editFormControls.staticTextControl("fieldName", "Total hours logged", formatUtils.formatHours(totalHours))}
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.timelogs ?
											table
											: null}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ timelog }) => ({
	timelogs: timelog.timelogs,
	loading: timelog.loading,
	error: timelog.error
})

const mapDispatchToProps = dispatch => ({
	onGetTimelogs: () => dispatch(actions.timelogGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TimelogBrowse));
