import * as actionTypes from "./actionTypes";

export const counterCreate = (counter) => {
	return {
		type: actionTypes.COUNTER_CREATE,
		counter: counter
	}
}

export const counterCreateError = (thisError) => {
	return {
		type: actionTypes.COUNTER_CREATE_ERROR,
		error: thisError
	}
}

export const counterCreateSuccess = (counter) => {
	return {
		type: actionTypes.COUNTER_CREATE_SUCCESS,
		Counter: counter
	}
}

export const counterGetAll = () => {
	return {
		type: actionTypes.COUNTER_GET_ALL
	}
}

export const counterGetAndUpdate = (counterType) => {
	return {
		type: actionTypes.COUNTER_GET_AND_UPDATE,
		counterType: counterType
	}
}

export const counterGetById = (id) => {
	return {
		type: actionTypes.COUNTER_GET_BY_ID,
		id: id
	}
}

export const counterGetError = (thisError) => {
	return {
		type: actionTypes.COUNTER_GET_ERROR,
		error: thisError
	}
}

export const counterGetValueError = (thisError) => {
	return {
		type: actionTypes.COUNTER_GET_VALUE_ERROR,
		error: thisError
	}
}

export const counterGetSuccess = (data) => {
	return {
		type: actionTypes.COUNTER_GET_SUCCESS,
		Counters: data
	}
}

export const counterGetValueSuccess = (value) => {
	return {
		type: actionTypes.COUNTER_GET_VALUE_SUCCESS,
		value: value
	}
}

export const counterDelete = (id, history) => {
	return {
		type: actionTypes.COUNTER_DELETE,
		id: id,
		history: history
	}
}

export const counterDeleteError = (thisError) => {
	return {
		type: actionTypes.COUNTER_DELETE_ERROR,
		error: thisError
	}
}

export const counterDeleteSuccess = (counters) => {
	return {
		type: actionTypes.COUNTER_DELETE_SUCCESS,
		counters: counters
	}
}