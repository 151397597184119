import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsVATRate from '../../store/VATRate/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class VATRateEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            rate: "",

            vatRates: [],
            errors: {}
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedVATRate = {
            rate: this.state.rate,
        }
        if (this.state.id) {
            newOrUpdatedVATRate = {
                id: this.state.id,
                ...newOrUpdatedVATRate
            }
        }
        this.props.onCreateVATRate(newOrUpdatedVATRate, this.props.history);
    }

    onDeleteVATRate = () => {
        if (window.confirm("Are you sure you want to delete this VAT rate?")) {
            this.props.onDeleteVATRate(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.onGetVATRateById(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.vatRates !== this.props.vatRates) {
            if (this.props.vatRates && this.props.vatRates[0]) {
                this.setState({
                    id: this.props.vatRates[0].id,
                    rate: this.props.vatRates[0].rate,
                });
            } else {
                this.setState({
                    id: "",
                    rate: "",
                });
            }
        }
    }

    render() {
        const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " VAT rate | " + config.AppName;
        const breadcrumbsTitle = "VAT rate";
        const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " VAT rate";

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.numberControl("rate", "Rate, %", this.onChange, this.state.rate, 0.1)}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteVATRate, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.formLoadingSpinner(this.props.loading)}

                        {!this.props.loading ? editForm : null}
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ vatRate }) => ({
    deleting: vatRate.deleting,
    error: vatRate.error,
    loading: vatRate.loading,
    saving: vatRate.saving,
    vatRates: vatRate.vatRates,
})

const mapDispatchToProps = dispatch => ({
    onGetVATRateById: (id) => dispatch(actionsVATRate.vatRateGetById(id)),
    onCreateVATRate: (vatRate, history) => dispatch(actionsVATRate.vatRateCreate(vatRate, history)),
    onDeleteVATRate: (id, history) => dispatch(actionsVATRate.vatRateDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VATRateEdit);
