import * as formatUtils from './formatUtils';

const localDateValueForSortFunc = (localDate) => {
    return localDate[0] * 12 * 366 + localDate[1] * 366 + localDate[2];
}

const localDateTimeValueForSortFunc = (localDate) => {
    return localDate[0] * 12 * 366 * 24 * 60 + localDate[1] * 366 * 24 * 60 + localDate[2] * 24 * 60 + localDate[3] * 60 + localDate[4];
}

export const sortFuncForLocalDate = (a, b, order) => {
    if (order === 'asc') {
        return localDateValueForSortFunc(a) - localDateValueForSortFunc(b);
    }
    else {
        return localDateValueForSortFunc(b) - localDateValueForSortFunc(a);
    }
}

export const sortFuncForLocalDateTime = (a, b, order) => {
    if (order === 'asc') {
        return localDateTimeValueForSortFunc(a) - localDateTimeValueForSortFunc(b);
    }
    else {
        return localDateTimeValueForSortFunc(b) - localDateTimeValueForSortFunc(a);
    }
}

export const sortFuncForRatio = (a, b, order) => {
    if (order === 'asc') {
        return formatUtils.ratioValue(a) - formatUtils.ratioValue(b);
    }
    else {
        return formatUtils.ratioValue(b) - formatUtils.ratioValue(a);
    }
}

export const getSavedColumnSetup = (columnDefinitions, pageEndpoint, defaultColumnSetup, alwaysIncldeDefaultColumns) => {
    let savedColumnSetup = localStorage.getItem('columns' + pageEndpoint);
    if (!savedColumnSetup || !savedColumnSetup.length) {
        savedColumnSetup = defaultColumnSetup;
    } else {
        if (alwaysIncldeDefaultColumns && defaultColumnSetup && defaultColumnSetup.length) {
            savedColumnSetup = defaultColumnSetup + "," + savedColumnSetup;
        }
    }

    return applySavedColumnSetup(columnDefinitions, savedColumnSetup);
}

export const applySavedColumnSetup = (columnDefinitions, savedColumnSetup) => {

    if (!savedColumnSetup || !savedColumnSetup.length) {
        savedColumnSetup = "";
    }

    const columns = [];
    const savedColumnSetupAsStringArray = savedColumnSetup.split(",");

    for (let i in savedColumnSetupAsStringArray) {
        const thisColumnId = savedColumnSetupAsStringArray[i];
        columns.push(columnDefinitions[thisColumnId]);
    }

    return columns;
}

export const setSavedColumnSetup = (savedColumnSetup, pageEndpoint) => {
    localStorage.setItem('columns' + pageEndpoint, savedColumnSetup);
}