import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaAndReducerHelper';

function* createAllocationCriterionSaga({ allocationCriterion }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.ALLOCATION_CRITERION_CREATE_OR_UPDATE,
			allocationCriterion
		);
		yield put(actions.allocationCriterionCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.allocationCriterionCreateError(e));
	}
}

function* deleteAllocationCriterionSaga({ idList, allocationCriterionType }) {
    try {
        const response = yield call(
            rest.del,
            endpointsBackend.ALLOCATION_CRITERION_GET_ALL_BY_TYPE
                .replace("{allocationCriterionType}", allocationCriterionType),
            { idList: idList }
        );
        yield put(actions.allocationCriterionDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.allocationCriterionDeleteError(e));
    }
}

function* getAllAllocationCriteriaSaga({ allocationCriterionType }) {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.ALLOCATION_CRITERION_GET_ALL_BY_TYPE.replace("{allocationCriterionType}", allocationCriterionType)
        );

        yield put(actions.allocationCriterionGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.allocationCriterionGetError(e));
    }
}

function* getAllocationCriterionByIdSaga({ id }) {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.ALLOCATION_CRITERION_GET_BY_ID.replace("{allocationCriterionId}", id)
        );

        yield put(actions.allocationCriterionGetSuccess([{ ...response.data }]));
    } catch (e) {
        yield put(actions.allocationCriterionGetError(e));
    }
}

function* moveAllocationCriterionSaga({ id, url }) {
    try {
        const response = yield call(
            rest.post,
            url.replace("{allocationCriterionId}", id)
        );

        yield put(actions.allocationCriterionMoveSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.allocationCriterionGetError(e));
    }
}


function* moveUpAllocationCriterionSaga({ id }) {
    yield call(moveAllocationCriterionSaga, { id: id, url: endpointsBackend.ALLOCATION_CRITERION_MOVE_UP });
}

function* moveDownAllocationCriterionSaga({ id }) {
    yield call(moveAllocationCriterionSaga, { id: id, url: endpointsBackend.ALLOCATION_CRITERION_MOVE_DOWN });
}

function* allocationCriterionSaga() {
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_CREATE, createAllocationCriterionSaga));
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_DELETE, deleteAllocationCriterionSaga));
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_GET_ALL, getAllAllocationCriteriaSaga));
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_GET_BY_ID, getAllocationCriterionByIdSaga));
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_MOVE_UP, moveUpAllocationCriterionSaga));
    yield (takeLatest(actionTypes.ALLOCATION_CRITERION_MOVE_DOWN, moveDownAllocationCriterionSaga));
}

export default allocationCriterionSaga;