export const TASK_GET_ALL = "TASK_GET_ALL";
export const TASK_GET_BY_ID = "TASK_GET_BY_ID";
export const TASK_GET_ERROR = "TASK_GET_ERROR";
export const TASK_GET_SUCCESS = "TASK_GET_SUCCESS";

export const TASK_CREATE = "TASK_CREATE";
export const TASK_CREATE_ERROR = "TASK_CREATE_ERROR";
export const TASK_CREATE_SUCCESS = "TASK_CREATE_SUCCESS";

export const TASK_DELETE = "TASK_DELETE";
export const TASK_DELETE_ERROR = "TASK_DELETE_ERROR";
export const TASK_DELETE_SUCCESS = "TASK_DELETE_SUCCESS";

