export const PAYMENT_GET_ALL = "PAYMENT_GET_ALL";
export const PAYMENT_GET_BY_ID = "PAYMENT_GET_BY_ID";
export const PAYMENT_GET_ERRONEOUS = "PAYMENT_GET_ERRONEOUS";
export const PAYMENT_GET_EXPORTED_TO_BANK_AS_FILE = "PAYMENT_GET_EXPORTED_TO_BANK";
export const PAYMENT_GET_SENT_TO_BANK_SIGNED = "PAYMENT_GET_SENT_TO_BANK_SIGNED";
export const PAYMENT_GET_SENT_TO_BANK_UNSIGNED = "PAYMENT_GET_SENT_TO_BANK_UNSIGNED";
export const PAYMENT_GET_SIGNED = "PAYMENT_GET_SIGNED";
export const PAYMENT_GET_UNSIGNED = "PAYMENT_GET_UNSIGNED";
export const PAYMENT_GET_UNALLOCATED = "PAYMENT_GET_UNALLOCATED";

export const PAYMENT_GET_IN_PROCESS = "PAYMENT_GET_IN_PROCESS";
export const PAYMENT_GET_ERROR = "PAYMENT_GET_ERROR";
export const PAYMENT_GET_SUCCESS = "PAYMENT_GET_SUCCESS";

export const PAYMENT_GET_TOTAL = "PAYMENT_GET_TOTAL";
export const PAYMENT_GET_TOTAL_FROM_SIMULATED = "PAYMENT_GET_TOTAL_FROM_SIMULATED";
export const PAYMENT_GET_TOTAL_ERROR = "PAYMENT_GET_TOTAL_ERROR";
export const PAYMENT_GET_TOTAL_SUCCESS = "PAYMENT_GET_TOTAL_SUCCESS";

export const PAYMENT_CREATE = "PAYMENT_CREATE";
export const PAYMENT_CREATE_ERROR = "PAYMENT_CREATE_ERROR";
export const PAYMENT_CREATE_SUCCESS = "PAYMENT_CREATE_SUCCESS";

export const PAYMENT_SEND_TO_BANK_LIST = "PAYMENT_SEND_TO_BANK_LIST";
export const PAYMENT_SEND_TO_BANK_ERROR = "PAYMENT_SEND_TO_BANK_ERROR";
export const PAYMENT_SEND_TO_BANK_SUCCESS = "PAYMENT_SEND_TO_BANK_SUCCESS";

export const PAYMENT_DELETE = "PAYMENT_DELETE";
export const PAYMENT_DELETE_LIST = "PAYMENT_DELETE_LIST";
export const PAYMENT_DELETE_ERROR = "PAYMENT_DELETE_ERROR";
export const PAYMENT_DELETE_SUCCESS = "PAYMENT_DELETE_SUCCESS";

export const PAYMENT_SIGN_GET_CERTIFICATE = "PAYMENT_SIGN_GET_CERTIFICATE";
export const PAYMENT_SIGN_INIT = "PAYMENT_SIGN_INIT";
export const PAYMENT_SIGN_ERROR = "PAYMENT_SIGN_ERROR";
export const PAYMENT_SIGN_RESET = "PAYMENT_SIGN_RESET";
export const PAYMENT_SIGN_RESET_SUCCESS = "PAYMENT_SIGN_RESET_SUCCESS";
export const PAYMENT_SIGN_SUCCESS = "PAYMENT_SIGN_SUCCESS";

export const PAYMENT_LINE_GET_ALL = "PAYMENT_LINE_GET_ALL";
export const PAYMENT_LINE_GET_ERROR = "PAYMENT_LINE_GET_ERROR";
export const PAYMENT_LINE_GET_PREPARED = "PAYMENT_LINE_GET_PREPARED";
export const PAYMENT_LINE_GET_SUCCESS = "PAYMENT_LINE_GET_SUCCESS";
