import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SimpleLineChart from '../ChartRechart/SimpleLineChart';
import SimpleAreaChart from '../ChartRechart/SimpleAreaChart';
import MixBarChart from '../ChartRechart/MixBarChart';
import VerticalComposedChart from '../ChartRechart/VerticalComposedChart';
import ThreeDimScatterChart from '../ChartRechart/ThreeDimScatterChart';
import SpecifiedDomainRadarChart from '../ChartRechart/SpecifiedDomainRadarChart';
import SimpleRadialBarChart from '../ChartRechart/SimpleRadialBarChart';
import CustomActiveShapePieChart from '../ChartRechart/CustomActiveShapePieChart';

class ReCharts extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    <div className="page-content">
                        <MetaTags>
                            <title>Re Chart | Skote - React Admin & Dashboard Template</title>
                        </MetaTags>
                        <div className="container-fluid">
                            <Breadcrumbs title="Charts" breadcrumbItem="Re Chart" />

                            <Row>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SimpleLine Chart</CardTitle>
                                            <SimpleLineChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SimpleArea Chart</CardTitle>
                                            <SimpleAreaChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SimpleLine Chart</CardTitle>
                                            <MixBarChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SimpleArea Chart</CardTitle>
                                            <VerticalComposedChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">ThreeDimScatter Chart</CardTitle>
                                            <ThreeDimScatterChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SpecifiedDomain Radar Chart</CardTitle>
                                            <SpecifiedDomainRadarChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">SimpleRadialBar Chart</CardTitle>
                                            <SimpleRadialBarChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl={6}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-4">Pie Responsive Chart</CardTitle>
                                            <CustomActiveShapePieChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </React.Fragment>
            </React.Fragment>
        );
    }
  }

    export default ReCharts;