export const EXCHRATE_GET_ALL = "EXCHRATE_GET_ALL";
export const EXCHRATE_GET_BY_ID = "EXCHRATE_GET_BY_ID";
export const EXCHRATE_GET_IN_PROCESS = "EXCHRATE_GET_IN_PROCESS";
export const EXCHRATE_GET_ERROR = "EXCHRATE_GET_ERROR";
export const EXCHRATE_GET_SUCCESS = "EXCHRATE_GET_SUCCESS";

export const EXCHRATE_CREATE = "EXCHRATE_CREATE";
export const EXCHRATE_CREATE_ERROR = "EXCHRATE_CREATE_ERROR";
export const EXCHRATE_CREATE_SUCCESS = "EXCHRATE_CREATE_SUCCESS";

export const EXCHRATE_DELETE = "EXCHRATE_DELETE";

