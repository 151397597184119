import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllCurrenciesSaga() {
	yield put(actions.countryGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTRY_GET_ALL
		);

		const countries = [];
		for (let key in response.data) {
			countries.push({
				...response.data[key]
			});
		}

		yield put(actions.countryGetSuccess(countries));
	} catch (e) {
		yield put(actions.countryGetError(e));
	}
}

function* getCountryByIdSaga({ id }) {
	yield put(actions.countryGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.COUNTRY_GET_BY_ID.replace("{countryId}", id)
		);

		yield put(actions.countryGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.countryGetError(e));
	}
}

function* createCountrySaga({ country, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.COUNTRY_CREATE_OR_UPDATE,
			country
		);
		history.push(endpointsFrontend.COUNTRY_BROWSE);
	} catch (e) {
		yield put(actions.countryCreateError(e));
	}
}

function* deleteCountrySaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.COUNTRY_GET_BY_ID.replace("{countryId}", id)
		);
		history.push(endpointsFrontend.COUNTRY_BROWSE);
	} catch (e) {
		yield put(actions.countryCreateError(e));
	}
}

function* countrySaga() {
	yield (takeEvery(actionTypes.COUNTRY_GET_ALL, getAllCurrenciesSaga));
	yield (takeEvery(actionTypes.COUNTRY_GET_BY_ID, getCountryByIdSaga));
	yield (takeEvery(actionTypes.COUNTRY_CREATE, createCountrySaga));
	yield (takeEvery(actionTypes.COUNTRY_DELETE, deleteCountrySaga));
}

export default countrySaga;