import * as formatUtils from 'helpers/formatUtils';

export const criterionId = {
	dataField: 'criterion.id',
	text: 'Criterion',
	formatter: (cell, row, rowIndex) => formatUtils.formatAllocationCriterion(row.criterion, row.bankStatementRecordId + "_" + row.id)
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier',
};
export const paymentDetails = {
	dataField: 'paymentDetails',
	text: 'Payment details',
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
};
export const totalAmountToPay = {
	dataField: 'totalAmountToPay',
	text: 'Payment amount',
	align: "right",
	headerStyle: { textAlign: "right" },
	formatter: (cell) => formatUtils.formatAmountWithDCSign(-cell)

};
export const allocatedAmountInfo = {
	dataField: 'allocatedAmountInfo',
	text: 'Allocated amount',
	align: "right",
	headerStyle: { textAlign: "right" },
};
