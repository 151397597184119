import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsReport from '../../store/Report/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsBankStatement from 'definitions/columns/bankStatement';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class BankStatementReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedReports: []
        }
    }

    initData = () => {
        this.props.onGetReports();
    }

    componentDidMount() {
        if (this.props.selectedCompany) {
            this.initData();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // 2023.11.27: fix: 231123-CompanyId
        if (prevProps.selectedCompany != this.props.selectedCompany) {
            this.initData();
        }

        if (prevProps.reports != this.props.reports && this.props.reports) {
            this.setState({
                selectedReports: []
            })
        }

    }

    onDeleteReports = () => {
        if (window.confirm("Are you sure you want to delete the selected reports?")) {
            this.props.onDeleteReports(this.state.selectedReports);
        }
    }

    render() {

        const pageTitle = "Bank Statements | " + config.AppName;
        const breadcrumbsTitle = "Bank statements";
        const breadcrumbsItem = "Browse bank statements reports";

        const columns = [
            columnsBankStatement.accountNo,
            columnsBankStatement.currencyCode,
            columnsBankStatement.openingDate,
            columnsBankStatement.closingDate,
            //columnsBankStatement.allocatedAmountRatio,
            columnsBankStatement.allocatedRecordRatio,
            columnsBankStatement.confirmedRatio
        ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.onDownloadReport(row.id)
            }
        };

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedReports, row, isSelect);
                this.setState({
                    selectedReports: selectedRows
                });
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedReports, rows, isSelect)
                this.setState({
                    selectedReports: selectedRows
                });
            }
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.reports}
                    columns={columns}
                    pagination={paginationFactory()}
                    rowEvents={rowEvents}
                    rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                    defaultSorted={[{
                        dataField: 'openingDate',
                        order: 'desc'
                    }]}
                    selectRow={selectRow}
                />
            </div>


        return (

            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>

                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {editFormControls.errorAlert(this.props.error)}

                                        {editFormControls.formLoadingSpinner(this.props.loading)}

                                        {!this.props.loading && this.props.reports && table}

                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteReports, true, !this.state.selectedReports || !this.state.selectedReports.length)}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ bankAccount, bankStatement, company, report }) => ({
    bankAccounts: bankAccount.bankAccounts,
    bankStatements: bankStatement.bankStatements,
    deleting: bankStatement.deleting,
    error: report.error,
    loadedBankAccounts: bankAccount.loaded,
    loading: report.loading,
    loadingBankAccounts: bankAccount.loading,
    receiving: bankStatement.receiving,
    reports: report.reportsOfBankStatement,
    selectedCompany: company.selectedCompany,
    uploading: bankStatement.uploading,
    uploadResult: bankStatement.uploadResult,
    error: bankStatement.error
})

const mapDispatchToProps = dispatch => ({
    onDeleteReports: (idList) => dispatch(actionsReport.reportOfBankStatementDelete(idList)),
    onDownloadReport: (id) => dispatch(actionsReport.reportOfBankStatementDownload(id)),
    onGetReports: () => dispatch(actionsReport.reportOfBankStatementGetAll()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BankStatementReports));
