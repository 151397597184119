import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Col, Row } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";

import * as actions from '../../store/DashboardData/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CashFlowChart from "./cash-flow-chart";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as errorMessageHelper from '../../helpers/errorMessageHelper';
import * as formatUtils from '../../helpers/formatUtils';
import MiniWidget from "./mini-widget"
import * as taskHelper from "../../helpers/taskHelper";
import TaskList from "./task-list";

class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: null,
			errorLink: "",
			errorLinkText: "",
			widgetCustomerPayments: null,
			widgetSupplierInvoices: null,
			widgetTasks: null,
			widgetTransactions: null,
			reports: [],
		}
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.props.onGetDashboardData();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		// 2023.11.27: fix: 231123-CompanyId
		if (prevProps.selectedCompany != this.props.selectedCompany) {
			this.props.onGetDashboardData();
		}

		if (prevProps.error !== this.props.error) {
			let error = this.props.error;
			let errorLink = "";
			let errorLinkText = "";
			const errorMessage = errorMessageHelper.httpErrorMessageAndAdditionalText("", error);
			if (errorMessage && errorMessage.length) {
				if (errorMessage.includes('localCurrency')) {
					error = { message: "Local currency is not set." }
					errorLink = endpointsFrontend.COMPANY_SETTINGS_EDIT;
					errorLinkText = "Click here to update company settings."
				}
			}
			this.setState({
				error: error,
				errorLink: errorLink,
				errorLinkText: errorLinkText
			});
		}

		if (prevProps.dashboardData !== this.props.dashboardData) {
			if (this.props.dashboardData) {

				const tasksSplitByDueDate = taskHelper.splitTasksPerDueDate(this.props.dashboardData.tasksDueThisMonth);
				const smartIdTransactionCount = this.props.dashboardData.smartIdAuthorizationCount + this.props.dashboardData.smartIdSignatureCount;
				const allowedSmartIdTransactionCount = 550;
				const timestampTransactionCount = this.props.dashboardData.timestampCount;
				const allowedTimestampTransactionCount = 1000;

				this.setState({
					widgetCustomerPayments: {
						icon: "bx bxs-file",
						link: endpointsFrontend.CUSTOMER_INVOICE_BROWSE_UNPAID,
						title: "Customer payments expected",
						value: this.props.dashboardData.customerInvoicesExpected.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.customerInvoicesExpected.amount),
						badges: [
							{
								link: endpointsFrontend.CUSTOMER_INVOICE_BROWSE_OVERDUE,
								value: this.props.dashboardData.customerInvoicesOverdue.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.customerInvoicesOverdue.amount) + " overdue",
								color: this.props.dashboardData.customerInvoicesOverdue.amount ? "warning" : "success",
								description: this.props.dashboardData.customerInvoicesOverdue.count ? "Total " + this.props.dashboardData.customerInvoicesOverdue.count + " invoice(s)" : "",
							}
						]
					},
					widgetSupplierInvoices: {
						icon: "bx bx-file",
						title: "Supplier invoices due this week",
						link: endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID,
						value: this.props.dashboardData.supplierInvoicesDueThisWeek.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.supplierInvoicesDueThisWeek.amount),
						badges: [
							{
								link: endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID,
								value: this.props.dashboardData.supplierInvoicesOverdue.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.supplierInvoicesOverdue.amount) + " overdue",
								color: this.props.dashboardData.supplierInvoicesOverdue.amount ? "danger" : "success",
								description: this.props.dashboardData.supplierInvoicesOverdue.count ? "Total " + this.props.dashboardData.supplierInvoicesOverdue.count + " invoice(s)" : "",
							},
							{
								link: endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID,
								value: this.props.dashboardData.supplierInvoicesDueToday.amount ? this.props.dashboardData.supplierInvoicesDueToday.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.supplierInvoicesDueToday.amount) + " due today" : "",
								color: this.props.dashboardData.supplierInvoicesDueToday.amount ? "warning" : "success",
								description: this.props.dashboardData.supplierInvoicesOverdue.count ? "Total " + this.props.dashboardData.supplierInvoicesDueToday.count + " invoice(s)" : "",
							},
							{
								link: endpointsFrontend.PAYMENT_BROWSE_ERRONEOUS,
								value: this.props.dashboardData.failedPayments ? this.props.dashboardData.failedPayments + " failed payment file(s)" : "",
								color: this.props.dashboardData.failedPayments ? "danger" : "success",
							},
							{
								link: endpointsFrontend.PAYMENT_BROWSE_UNSIGNED,
								value: this.props.dashboardData.paymentsToSign ? this.props.dashboardData.paymentsToSign + " payment file(s) to sign" : "",
								color: this.props.dashboardData.paymentsToSign ? "warning" : "success",
							},
							{
								link: endpointsFrontend.PAYMENT_BROWSE_PREPARED,
								value: this.props.dashboardData.preparedPayments ? this.props.dashboardData.preparedPayments + " prepared payment(s)" : "",
								color: this.props.dashboardData.preparedPayments ? "warning" : "success",
							},
							{
								link: endpointsFrontend.SUPPLIER_INVOICE_CANDIDATE_BROWSE,
								value: this.props.dashboardData.supplierInvoiceCandidates.count ? this.props.dashboardData.supplierInvoiceCandidates.currencyCode + " " + formatUtils.formatAmount(this.props.dashboardData.supplierInvoiceCandidates.amount) + " received" : "",
								color: this.props.dashboardData.supplierInvoiceCandidates.count  ? "warning" : "success",
								description: this.props.dashboardData.supplierInvoiceCandidates.count ? "Total " + this.props.dashboardData.supplierInvoiceCandidates.count + " invoice(s)" : "",
							},
						]
					},
					widgetTransactions: {
						icon: "bx bx-code-alt",
						title: "Transactions this month",
						value: "",
						badges: [
							{
								value: smartIdTransactionCount + " / " + allowedSmartIdTransactionCount,
								color: (smartIdTransactionCount < allowedSmartIdTransactionCount * 0.75 ? "success" : smartIdTransactionCount <= allowedSmartIdTransactionCount ? "warning" : "danger"),
								description: "Smart-ID transactions"
							},
							{
								value: timestampTransactionCount + " / " + allowedTimestampTransactionCount,
								color: (timestampTransactionCount < allowedTimestampTransactionCount * 0.75 ? "success" : timestampTransactionCount <= allowedTimestampTransactionCount ? "warning" : "danger"),
								description: "Timestamps"
							}
						]
					},
					widgetTasks: {
						icon: "bx bx-task",
						title: "Tasks for today",
						link: endpointsFrontend.TASK_BROWSE,
						value: tasksSplitByDueDate.tasksDueToday.length + " tasks",
						badges: [
							{
								link: endpointsFrontend.TASK_BROWSE,
								value: tasksSplitByDueDate.overdueTasks.length + " overdue",
								color: tasksSplitByDueDate.overdueTasks.length ? "warning" : "success",
								description: "",
							}
						]
					}
				})
			}
		}
	}

	render() {
		const pageTitle = "Dashboard | " + config.AppName;
		const breadcrumbsTitle = "Dashboard";
		const breadcrumbsItem = "Dashboard";

		const dashboardForm =
			<React.Fragment>
				<Row>
					<Col xl="12">
						<Row>
							<Col xl="4">
								<MiniWidget report={this.state.widgetCustomerPayments} />
							</Col>
							<Col xl="4">
								<MiniWidget report={this.state.widgetSupplierInvoices} />
							</Col>
							<Col xl="4">
								<MiniWidget report={this.state.widgetTransactions} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xl="8">
						<CashFlowChart
							customerInvoices={this.props.dashboardData ? this.props.dashboardData.customerInvoicesAsCashflow : []}
							supplierInvoices={this.props.dashboardData ? this.props.dashboardData.supplierInvoicesAsCashflow : []}
							categories={this.props.dashboardData ? this.props.dashboardData.cashflowXAxis : []}
						/>
					</Col>
					<Col xl="4">
						<Container>
							<Row>
								<MiniWidget report={this.state.widgetTasks} />
							</Row>
							<Row>
								<TaskList
									taskList={this.props.dashboardData ? this.props.dashboardData.tasksDueThisMonth : []}
									starredTaskList={this.props.dashboardData ? this.props.dashboardData.tasksStarred : []}
									recentTaskList={this.props.dashboardData ? this.props.dashboardData.tasksRecent : []}
								/>
							</Row>
						</Container>
					</Col>
				</Row>
			</React.Fragment>

		const loading = this.props.loading;

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />
						{editFormControls.errorAlertWithLink(this.state.error, this.state.errorLink, this.state.errorLinkText)}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading && dashboardForm}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ company, dashboardData }) => ({
	dashboardData: dashboardData.dashboardData,
	error: dashboardData.error,
	loading: dashboardData.loading,
	selectedCompany: company.selectedCompany
})

const mapDispatchToProps = dispatch => ({
	onGetDashboardData: () => dispatch(actions.dashboardDataGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Dashboard));