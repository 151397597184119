import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import * as endpointsFrontend from '../definitions/endpoints/endpoints-frontend';

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	loggedIn,
	...rest
}) => (
	<Route
		{...rest}
		render={props => {
			if (isAuthProtected /*&& props.loginData */ && !loggedIn) {
				return (
					<Redirect
						to={{
							pathname: endpointsFrontend.USER_ACCOUNT_LOGIN,
							state: { from: props.location }
						}}
					/>
				)
			}

			return (
				<Layout>
					<Component
						{...props}
					/>
				</Layout>
			)
		}}
	/>
)

const mapStateToProps = ({ userLogin }) => {
	return {
		loggedIn: userLogin.loggedIn,
		loginData: userLogin
	}
}

export default connect(mapStateToProps)(AppRoute);
