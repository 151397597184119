import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

import * as PaymentStatus from '../enums/PaymentStatus';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountId = {
	dataField: 'bankAccountId',
	text: 'Bank account ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountNo = {
	dataField: 'bankAccountNo',
	text: 'Bank account No.',
	filter: textFilter(),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency code',
	filter: textFilter(),
	sort: true
};
export const urgency = {
	dataField: 'urgency',
	text: 'Urgency',
	filter: textFilter(),
	sort: true
};
export const chargeBearer = {
	dataField: 'chargeBearer',
	text: 'Charge bearer',
	filter: textFilter(),
	sort: true
};
export const totalAmount = {
	dataField: 'totalAmount',
	text: 'Total amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier name',
	filter: textFilter(),
	sort: true
};
export const supplierRegNo = {
	dataField: 'supplierRegNo',
	text: 'Supplier reg No.',
	filter: textFilter(),
	sort: true
};
export const supplierBankAccountNo = {
	dataField: 'supplierBankAccountNo',
	text: 'Supplier bank account',
	filter: textFilter(),
	sort: true
};
export const supplierExternalId = {
	dataField: 'supplierExternalId',
	text: 'Supplier external ID',
	filter: textFilter(),
	sort: true
};
export const paymentDetails = {
	dataField: 'paymentDetails',
	text: 'Payment details',
	filter: textFilter(),
	sort: true
};
export const paymentDate = {
	dataField: 'paymentDate',
	text: 'Payment date',
	filter: textFilter(),
	// formatter: (cell) => formatUtils.formatLocalDate(cell),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDate(a, b, order),
	sort: true
};
export const paymentStatus = {
	dataField: 'paymentStatus',
	text: 'Payment status',
	formatter: (cell) => formatUtils.formatPaymentStatus(cell),
	filter: selectFilter({
		options: PaymentStatus.PaymentStatusAsSelectFilterOptions
	}),
	sort: true
};
export const bankStatusMessage = {
	dataField: 'bankStatusMessage',
	text: 'Bank status message',
	filter: textFilter(),
	sort: true
};
