import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier name',
	filter: textFilter(),
	sort: true,
//	editInputType: "text", // To enable editing, also need to update SupplierInvoiceCandidateService.update() and UpdateSupplierInvoiceCandidateDTO on the backend
};
export const detectedSupplierName = {
	dataField: 'detectedSupplierName',
	text: 'Supplier name',
	filter: textFilter(),
	sort: true
};
export const supplierRegNo = {
	dataField: 'supplierRegNo',
	text: 'Supplier reg. No.',
	filter: textFilter(),
	sort: true,
//	editInputType: "text", // To enable editing, also need to update SupplierInvoiceCandidateService.update() and UpdateSupplierInvoiceCandidateDTO on the backend
};
export const supplierVATRegNo = {
	dataField: 'supplierVATRegNo',
	text: 'Supplier VAT Reg. No.',
	filter: textFilter(),
	sort: true,
//	editInputType: "text", // To enable editing, also need to update SupplierInvoiceCandidateService.update() and UpdateSupplierInvoiceCandidateDTO on the backend
};
export const customerName = {
	dataField: 'customerName',
	text: 'Customer name',
	filter: textFilter(),
	sort: true
};
export const customerRegNo = {
	dataField: 'customerRegNo',
	text: 'Customer reg. No.',
	filter: textFilter(),
	sort: true
};
export const customerVATRegNo = {
	dataField: 'customerVATRegNo',
	text: 'Customer VAT reg. No.',
	filter: textFilter(),
	sort: true
};
export const invoiceDate = {
	dataField: 'invoiceDate',
	text: 'Invoice date',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatLocalDate(cell),
	sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDate(a, b, order),
	sort: true,
	editInputType: "date"
};
export const dueDate = {
	dataField: 'dueDate',
	text: 'Due date',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatLocalDate(cell),
	sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDate(a, b, order),
	sort: true,
	editInputType: "date"
};
export const totalInvoiceAmountWithoutVAT = {
	dataField: 'totalInvoiceAmountWithoutVAT',
	text: 'Total amount excl. VAT',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};
export const totalVATAmount = {
	dataField: 'totalVATAmount',
	text: 'Total VAT amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};
export const totalInvoiceAmountWithVAT = {
	dataField: 'totalInvoiceAmountWithVAT',
	text: 'Total amount incl. VAT',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};
export const invoiceCurrencyCode = {
	dataField: 'invoiceCurrencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true,
//	editInputType: "text", // To enable editing, also need to update SupplierInvoiceCandidateService.update() and UpdateSupplierInvoiceCandidateDTO on the backend
};
export const detectedCurrencyCode = {
	dataField: 'detectedCurrencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const attachment = {
	dataField: 'attachment',
	text: 'Attachment',
	filter: textFilter(),
	sort: true
};
export const supplierBankAccounts = {
	dataField: 'supplierBankAccounts',
	text: 'Supplier bank accounts',
	filter: textFilter(),
	sort: true
};
export const invoiceNo = {
	dataField: 'invoiceNo',
	text: 'Invoice No.',
	filter: textFilter(),
	editInputType: "text",
	sort: true
};
export const status ={
	dataField: 'status',
	text: 'Status',
	formatter: (cell) => formatUtils.formatSupplierInvoiceCandidateStatus(cell),
	sort: true
};
export const alreadyPaidAmount = {
	dataField: 'totalPaidAmount',
	text: 'Already paid amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};
export const totalDebtAmount = {
	dataField: 'totalDebtAmount',
	text: 'Total debt amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};

export const totalPayableAmount = {
	dataField: 'totalPayableAmount',
	text: 'Total payable amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true,
	editInputType: "number",
};
