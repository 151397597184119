import * as actionTypes from "./actionTypes";

export const companyCreate = (company, history) => {
	return {
		type: actionTypes.COMPANY_CREATE,
		company: company, 
		history: history
	}
}

export const companyCreateSuccess = (company) => {
	return {
		type: actionTypes.COMPANY_CREATE_SUCCESS,
		company: company
	}
}

export const companyCreateError = (thisError) => {
	return {
		type: actionTypes.COMPANY_CREATE_ERROR,
		error: thisError
	}
}

export const companyDelete = (id, history) => {
	return {
		type: actionTypes.COMPANY_DELETE,
		id: id,
		history: history
	}
}

export const companyDeleteError = (error) => {
	return {
		type: actionTypes.COMPANY_DELETE_ERROR,
		error: error
	}
}

export const companyDeleteSuccess = () => {
	return {
		type: actionTypes.COMPANY_DELETE_SUCCESS
	}
}

export const companyGetAll = () => {
	return {
		type: actionTypes.COMPANY_GET_ALL
	}
}

export const companyGetAllForTenant = () => {
	return {
		type: actionTypes.COMPANY_GET_ALL_FOR_TENANT
	}
}

export const companyGetAllForTenantSuccess = (companies) => {
	return {
		type: actionTypes.COMPANY_GET_ALL_FOR_TENANT_SUCCESS,
		companies: companies
	}
}

export const companyGetById = (id) => {
	return {
		type: actionTypes.COMPANY_GET_BY_ID,
		id: id
	}
}

export const companyGetAllSuccess = (companies, selectedCompany) => {
	return {
		type: actionTypes.COMPANY_GET_ALL_SUCCESS,
		companies: companies,
		selectedCompany: selectedCompany
	}
}

export const companyGetLast = () => {
	return {
		type: actionTypes.COMPANY_GET_LAST
	}
}

export const companyGetError = (thisError) => {
	return {
		type: actionTypes.COMPANY_GET_ERROR,
		error: thisError
	}
}

export const companyGetSuccess = (data) => {
	return {
		type: actionTypes.COMPANY_GET_SUCCESS,
		company: data
	}
}

export const companyNoneAvailable = () => {
	return {
		type: actionTypes.COMPANY_NONE_AVAILABLE
	}
}

export const companySetSelected = (selectedCompany, history) => {
	return {
		type: actionTypes.COMPANY_SET_SELECTED,
		payload: { selectedCompany, history }
	}
}
