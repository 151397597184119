import { takeEvery, put, call } from "redux-saga/effects";

import { USER_REGISTER_FROM_INVITATION } from "./actionTypes";
import { userRegisterFromInvitationSuccess, userRegisterFromInvitationError } from "./actions"
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from "../../definitions/endpoints/endpoints-backend";
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";


function* userRegisterFromInvitationSaga({ payload }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_CREATE_FROM_INVITATION,
			{
				invitationToken: payload.token,
				inviteeEmail: payload.inviteeEmail,
				username: payload.email,
				password: payload.password
			},
			{
				withCredentials: true
			}
		);

		if (response.data.isActivated) {
			yield payload.history.push(endpointsFrontend.USER_ACCOUNT_REGISTER_SUCCESS);
		} else {
			yield payload.history.push(endpointsFrontend.USER_ACCOUNT_REGISTER_SUCCESS_ACTIVATION_PENDING.replace(":email", payload.email));
		}

		yield put(userRegisterFromInvitationSuccess("Registered successfully"));
		
	} catch (error) {

		yield put(userRegisterFromInvitationError(error));

	}
}

function* userRegisterSaga() {
	yield takeEvery(USER_REGISTER_FROM_INVITATION, userRegisterFromInvitationSaga);
}

export default userRegisterSaga;
