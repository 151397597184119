import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCountry from '../../store/Country/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class CountryEdit extends Component {



	constructor(props) {
		super(props);
		this.state = {
			id: "",
			code: "",
			nameLang0: "",
			nameLang1: "",
			externalId: "",

			countries: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue = e.target.value;
		if (thisObjectName === "exchangeRate") {
			//illegalCharacters = thisValue.replaceAll('[0-1]')
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		if (this.state.id) {
			const updatedCountry = {
				id: this.state.id,
				externalId: this.state.externalId,
				code: this.state.code,
				nameLang0: this.state.nameLang0,
				nameLang1: this.state.nameLang1,
			}
			this.props.onCreateCountry(updatedCountry, this.props.history);
		} else {
			const newCountry = {
				externalId: this.state.externalId,
				code: this.state.code,
				nameLang0: this.state.nameLang0,
				nameLang1: this.state.nameLang1,
			}
			this.props.onCreateCountry(newCountry, this.props.history);
		}
	}

	onDeleteCountry = () => {
		if (window.confirm("Are you sure you want to delete this country?")) {
			this.props.onDeleteCountry(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetCountryById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.countries !== this.props.countries) {
			if (this.props.countries && this.props.countries[0]) {
				const {
					id,
					externalId,
					code,
					nameLang0,
					nameLang1
				} = this.props.countries[0];

				this.setState({
					id: id,
					externalId: externalId,
					code: code,
					nameLang0: nameLang0,
					nameLang1: nameLang1
				});
			} else {
				this.setState({
					id: "",
					externalId: "",
					code: "",
					nameLang0: "",
					nameLang1: ""
				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " country | " + config.AppName;
		const breadcrumbsTitle = "Country";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " country";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("code", "Code", this.onChange, this.state.code, "Enter country code (e.g. LV)")}
										{editFormControls.textControl("nameLang0", "Name", this.onChange, this.state.nameLang0, "Enter country name (e.g. Republic of Latvia)")}
										{editFormControls.textControl("nameLang1", "Alternative name", this.onChange, this.state.nameLang1, "Enter alternative country name (e.g. Latvijas Republika)")}
										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteCountry, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ country }) => ({
	countries: country.countries,
	loading: country.loading,
	error: country.error
})

const mapDispatchToProps = dispatch => ({
	onGetCountryById: (id) => dispatch(actionsCountry.countryGetById(id)),
	onCreateCountry: (country, history) => dispatch(actionsCountry.countryCreate(country, history)),
	onDeleteCountry: (id, history) => dispatch(actionsCountry.countryDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CountryEdit);

