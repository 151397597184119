import * as actionTypes from './actionTypes';

const initialState = {
    activeTimerTaskId: null,
    error: null,
    loading: false,
    timelogTimers: [],
    updating: false
}

export default function timelogTimerReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TIMELOG_TIMER_CHANGE_STATUS:
            return {
                ...state,
                activeTimerTaskId: action.data.taskId,
                error: null,
                updating: true
            }
        case actionTypes.TIMELOG_TIMER_CHANGE_STATUS_ERROR:
            return {
                ...state,
                error: action.error,
                updating: false
            }
        case actionTypes.TIMELOG_TIMER_CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                timelogTimers: action.data,
                updating: false
            }
        case actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE:
            return {
                ...state,
                error: null,
                loading: true,
                timelogTimers: []
            }
        case actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE_SUCCESS:
            return {
                ...state,
                activeTimerId: (action.data && action.data.length && action.data.length === 1 ? action.data[0].id : state.activeTimerId),
                loading: false,
                timelogTimers: action.data
            }
        case actionTypes.TIMELOG_TIMER_SET_ACTIVE_TIMER_ID:
            return {
                ...state,
                activeTimerId: action.id                
            }
        default:
            return state;
    }
}
