import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router-dom";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';

class CM20PaymentSignSuccess extends Component {
	render() {
		return (
			<React.Fragment>
				<Redirect to={endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED} />
			</React.Fragment>
		);
	}
}

export default withRouter(CM20PaymentSignSuccess);