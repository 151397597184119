import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	messageToUser: "",
	messageToUserShowCount: 0,
	notCreatedPaymentBulks: null,
	paymentBulks: null,
	saving: false,
	total: null
}

export default function PaymentBulkReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.PAYMENT_BULK_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				payments: [action.payment],
				notCreatedPaymentBulks: null
			}
		case actionTypes.PAYMENT_BULK_CREATE_PARTIAL_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				notCreatedPaymentBulks: action.notCreatedPaymentBulks
			}
		case actionTypes.PAYMENT_BULK_CREATE_ERROR:
			return {
				...state,
				loading: false,
				saving: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES:
			return {
				...state,
				error: null,
				messageToUser: "",
				saving: true
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_CREATE_FILES_SUCCESS:
			return {
				...state,
				error: null,
				messageToUser: action.exportFile.data.messageToUser,
				messageToUserShowCount: 0,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_CREATE_FROM_INVOICES:
			return {
				...state,
				saving: true,
				error: null,
				notCreatedPaymentBulks: null
			}
		case actionTypes.PAYMENT_BULK_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_BULK_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_BULK_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.PAYMENT_BULK_EDIT:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.PAYMENT_BULK_EDIT_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_EDIT_SUCCESS:
			return {
				...state,
				saving: false
			}
		case actionTypes.PAYMENT_BULK_GET_BY_ID:
		case actionTypes.PAYMENT_BULK_GET_PREPARED:
		case actionTypes.PAYMENT_BULK_GET_UNALLOCATED:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.PAYMENT_BULK_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_GET_SUCCESS:
			return {
				...state,
				loading: false,
				paymentBulks: action.paymentBulks
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL:
			return {
				...state,
				loadingTotal: true,
				total: null,
				error: null
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL_SUCCESS:
			return {
				...state,
				loadingTotal: false,
				total: action.total
			}
		case actionTypes.PAYMENT_BULK_GET_TOTAL_ERROR:
			return {
				...state,
				loadingTotal: false,
				total: null,
				error: action.error
			}
		case actionTypes.PAYMENT_BULK_MESSAGE_TO_USER_RESET:
			// A hack to show messageToUser only once.
			// Relies on the fact that as of 2024-05-30, both in Firefox and Chrome the payment-file-browse component is updated twice with the same messageToUser.
			// So the first two times it is updated, we increase messageToUserShowCount, but the third time (supposedly when the user refreshes the page 
			// or returns to payment-file-browse from another component) we reset messageToUser.
			if (state.messageToUserShowCount < 3) {
				return {
					...state,
					messageToUserShowCount: state.messageToUserShowCount + 1
				}
			} else {
				return {
					...state,
					messageToUser: ""
				}
			}
		default:
			return state;
	}
}