import { numberFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as allocationDataTools from 'helpers/allocationDataTools';

export const criterionTrustLevel = {
	dataField: 'criterionTrustLevel',
	text: 'Criterion',
	sort: true,
	formatter: (cell, row, rowIndex) => formatUtils.formatAllocationCriterionTrustLevel(row.criterionTrustLevel)
};
export const dcOptions = {
	"C": "C (Credit)",
	"D": "D (Debit)"
};
export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const bankReference = {
	dataField: 'bankReference',
	text: 'Bank reference',
	filter: textFilter(),
	sort: true
};
export const beneficiaryName = {
	dataField: 'beneficiaryName',
	text: 'Beneficiary name',
	filter: textFilter(),
	sort: true
};
export const beneficiaryRegNo = {
	dataField: 'beneficiaryRegNo',
	text: 'Reg. No.',
	filter: textFilter(),
	sort: true
};
export const beneficiaryBankAccountNo = {
	dataField: 'beneficiaryBankAccountNo',
	text: 'Beneficiary bank account No.',
	filter: textFilter(),
	sort: true
};
export const isDebit = {
	dataField: 'isDebit',
	text: 'Is debit',
	filter: textFilter(),
	sort: true
};
export const amount = {
	dataField: 'amount',
	text: 'Amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell, row) => formatUtils.formatAmountWithDCSign(cell, row.isDebit),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const paymentDate = {
	dataField: 'paymentDate',
	text: 'Date',
	filter: textFilter(),
	sort: true,
	formatter: (cell) => formatUtils.formatLocalDate(cell)
};
export const paymentDetails = {
	dataField: 'paymentDetails',
	text: 'Payment details',
	filter: textFilter(),
	sort: true
};
export const bankAccountNo = {
	dataField: 'bankAccountNo',
	text: 'Bank account No.',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: textFilter(),
	sort: true
};
export const debitCredit = {
	dataField: 'debitCredit',
	text: 'D/C',
	filter: selectFilter({
		options: dcOptions
	}),
	sort: true
};
export const status = {
	dataField: 'status',
	text: 'Status',
	filter: selectFilter({
		options: allocationDataTools.bankStatementRecordStatusOptions()
	}),
	sort: true,
	formatter: (cell) => allocationDataTools.transformBankStatementRecordStatus(cell)
};
