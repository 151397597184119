import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Nav, NavItem, NavLink, Row } from "reactstrap";
import Chart from "react-apexcharts";
import classnames from "classnames";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';

import * as columnsBankAccount from "definitions/columns/bankAccount";
import * as columnsCustomerInvoice from "definitions/columns/customerInvoice";
import * as columnsSupplierInvoice from "definitions/columns/supplierInvoice";
import * as columnsScheduledPayment from "definitions/columns/scheduledPayment";
import * as editFormControls from "helpers/editFormControls";
import * as formatUtils from "helpers/formatUtils";
import * as selectRowUtils from "helpers/selectRowUtils";
import * as xlsxHelper from 'helpers/xlsxHelper';

class CashFlowChart extends Component {
	constructor(props) {
		super(props)
		this.filterByDate = this.filterByDate.bind(this);
		this.state = {
			options: {
				chart: {
					id: "cashFlowChart",
					// toolbar: "false",	// Uncommenting this leads to the "'c' is undefined" error
					stacked: true
				},
				dataLabels: {
					enabled: !1,
				},
				colors: [],
				stroke: {
					curve: "smooth",
					width: 3,
				},
				xaxis: {
					categories: []
				}
			},
			series: [],

			multiplierCustomerInvoicesLastYear: 0.6,
			multiplierCustomerInvoicesTwoYearsAgo: 0.4,
			multiplierSupplierInvoicesLastYear: 0.6,
			multiplierSupplierInvoicesTwoYearsAgo: 0.4,

			useBankAccountBalances: true,
			useCustomerInvoicesUnallocated: true,
			useCustomerInvoicesLastYear: true,
			useCustomerInvoicesTwoYearsAgo: true,
			useSupplierInvoicesUnallocated: true,
			useSupplierInvoicesLastYear: true,
			useSupplierInvoicesTwoYearsAgo: true,
			useScheduledPayments: true,

			cashFlowStartValue: null,
			cashFlowEndValue: null,
			cashFlowMinValue: null,
			cashFlowMaxValue: null,

			cashFlowStartDate: null,
			cashFlowEndDate: null,
			cashFlowMinDate: null,
			cashFlowMaxDate: null,

			activeTab: 1,

			selectedBankAccounts: [],
			selectedCustomerInvoicesUnallocated: [],
			selectedCustomerInvoicesLastYear: [],
			selectedCustomerInvoicesTwoYearsAgo: [],
			selectedSupplierInvoicesUnallocated: [],
			selectedSupplierInvoicesLastYear: [],
			selectedSupplierInvoicesTwoYearsAgo: [],
			selectedScheduledPayments: [],

			reportDropdownOpen: false
		}
		this.onChange = this.onChange.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		const arrayPattern = /^([a-zA-Z0-9]+)\[(\d+)\]$/;
		if (arrayPattern.test(thisObjectName)) {
			// If thisObjectName is as follows:
			// someArray[someIndex]
			// then we assign the corresponding element of the corresponding array in the state.
			const [, arrayName, fieldIndex] = arrayPattern.exec(thisObjectName);
			const modifiedArray = [...this.state[arrayName]];
			modifiedArray[parseInt(fieldIndex, 10)] = thisValue;
			this.setState({
				[arrayName]: modifiedArray
			});
		} else {
			// Otherwise, just assign the field named <thisObjectName> in the state.
			this.setState({
				[thisObjectName]: thisValue
			});
		}
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			})
		}
	}

	fallOnSameMonth = (date1, date2) => {
		return date1.substring(0, 8) === date2.substring(0, 8);
	}

	getDateISOStringWithOffsetFromNow = (monthOffset, yearOffset) => {
		const date = new Date();
		date.setMonth(date.getMonth() + monthOffset);
		date.setFullYear(date.getFullYear() + yearOffset);
		return date.toISOString();
	}

	recalculateChart = () => {
		const totalBankAccountBalanceLocalCurrency = [];

		const customerInvoicesUnallocatedAmounts = [];
		const customerInvoicesLastYearAmounts = [];
		const customerInvoicesTwoYearsAgoAmounts = [];

		const supplierInvoicesUnallocatedAmounts = [];
		const supplierInvoicesLastYearAmounts = [];
		const supplierInvoicesTwoYearsAgoAmounts = [];

		const scheduledPaymentAmounts = [];

		for (let i = 0; i < this.props.categories.length; ++i) {
			totalBankAccountBalanceLocalCurrency.push(0);	// We need totalBankAccountBalanceLocalCurrency to be an array and all elements except the 0th to be filled in with zeros, otherwise other bars will not be vertically offset correctly on the chart

			customerInvoicesUnallocatedAmounts.push(0);
			customerInvoicesLastYearAmounts.push(0);
			customerInvoicesTwoYearsAgoAmounts.push(0);

			supplierInvoicesUnallocatedAmounts.push(0);
			supplierInvoicesLastYearAmounts.push(0);
			supplierInvoicesTwoYearsAgoAmounts.push(0);

			scheduledPaymentAmounts.push(0);
		}

		const cashFlowData = [];
		let cashFlowValue = 0;
		let cashFlowStartValue = null;
		let cashFlowEndValue = null;
		let cashFlowMinValue = null;
		let cashFlowMaxValue = null;
		let cashFlowStartDate = null;
		let cashFlowEndDate = null;
		let cashFlowMinDate = null;
		let cashFlowMaxDate = null

		if (this.state.useBankAccountBalances) {
			for (let i in this.props.bankAccounts) {
				const thisBankAccount = this.props.bankAccounts[i];
				const thisBalance = thisBankAccount.lastBalanceLocalCurrency;
				if (thisBalance && this.state.selectedBankAccounts.includes(thisBankAccount.id)) {
					totalBankAccountBalanceLocalCurrency[0] += thisBalance;
				}
			}
		}
		cashFlowValue += totalBankAccountBalanceLocalCurrency[0];

		for (let i = 0; i < this.props.categories.length; ++i) {
			const currentMonth = this.getDateISOStringWithOffsetFromNow(i, 0);
			const currentMonthLastYear = this.getDateISOStringWithOffsetFromNow(i, -1);
			const currentMonthTwoYearsAgo = this.getDateISOStringWithOffsetFromNow(i, -2);

			if (this.state.useCustomerInvoicesUnallocated) {
				for (let j in this.props.customerInvoicesUnallocated) {
					const thisInvoice = this.props.customerInvoicesUnallocated[j];
					if (this.fallOnSameMonth(thisInvoice.dueDate, currentMonth) && this.state.selectedCustomerInvoicesUnallocated.includes(thisInvoice.id)) {
						customerInvoicesUnallocatedAmounts[i] += thisInvoice.unallocatedAmountLocalCurrency;
					}
				}
			}
			if (this.state.useCustomerInvoicesLastYear) {
				for (let j in this.props.customerInvoicesLastYear) {
					const thisInvoice = this.props.customerInvoicesLastYear[j];
					if (this.fallOnSameMonth(thisInvoice.dueDate, currentMonthLastYear) && this.state.selectedCustomerInvoicesLastYear.includes(thisInvoice.id)) {
						customerInvoicesLastYearAmounts[i] += thisInvoice.amountLocalCurrency * this.state.multiplierCustomerInvoicesLastYear;
					}
				}
			}
			if (this.state.useCustomerInvoicesTwoYearsAgo) {
				for (let j in this.props.customerInvoicesTwoYearsAgo) {
					const thisInvoice = this.props.customerInvoicesTwoYearsAgo[j];
					if (this.fallOnSameMonth(thisInvoice.dueDate, currentMonthTwoYearsAgo) && this.state.selectedCustomerInvoicesTwoYearsAgo.includes(thisInvoice.id)) {
						customerInvoicesTwoYearsAgoAmounts[i] += thisInvoice.amountLocalCurrency * this.state.multiplierCustomerInvoicesTwoYearsAgo;
					}
				}
			}
			if (this.state.useSupplierInvoicesUnallocated) {
				for (let j in this.props.supplierInvoicesUnallocated) {
					const thisInvoice = this.props.supplierInvoicesUnallocated[j];
					if (this.fallOnSameMonth(thisInvoice.suggestedPaymentDate, currentMonth) && this.state.selectedSupplierInvoicesUnallocated.includes(thisInvoice.id)) {
						supplierInvoicesUnallocatedAmounts[i] -= thisInvoice.unpaidAmountLocalCurrency
							+ thisInvoice.paidAmountLocalCurrency
							+ thisInvoice.validPaymentDiscountLocalCurrency;
					}
				}
			}
			if (this.state.useSupplierInvoicesLastYear) {
				for (let j in this.props.supplierInvoicesLastYear) {
					const thisInvoice = this.props.supplierInvoicesLastYear[j];
					if (this.fallOnSameMonth(thisInvoice.dueDate, currentMonthLastYear) && this.state.selectedSupplierInvoicesLastYear.includes(thisInvoice.id)) {
						supplierInvoicesLastYearAmounts[i] -= thisInvoice.totalPaidAmountLocalCurrency * this.state.multiplierSupplierInvoicesLastYear;
					}
				}
			}
			if (this.state.useSupplierInvoicesTwoYearsAgo) {
				for (let j in this.props.supplierInvoicesTwoYearsAgo) {
					const thisInvoice = this.props.supplierInvoicesTwoYearsAgo[j];
					if (this.fallOnSameMonth(thisInvoice.dueDate, currentMonthTwoYearsAgo) && this.state.selectedSupplierInvoicesTwoYearsAgo.includes(thisInvoice.id)) {
						supplierInvoicesTwoYearsAgoAmounts[i] -= thisInvoice.totalPaidAmountLocalCurrency * this.state.multiplierSupplierInvoicesTwoYearsAgo;
					}
				}
			}
			if (this.state.useScheduledPayments) {
				for (let j in this.props.scheduledPayments) {
					const thisPayment = this.props.scheduledPayments[j];
					if (this.fallOnSameMonth(thisPayment.calculatedExecutionDate, currentMonth) && this.state.selectedScheduledPayments.includes(thisPayment.id)) {
						scheduledPaymentAmounts[i] -= thisPayment.amountToPayLocalCurrency;
					}
				}
			}
			cashFlowValue += customerInvoicesUnallocatedAmounts[i] + customerInvoicesLastYearAmounts[i] + customerInvoicesTwoYearsAgoAmounts[i]
				+ supplierInvoicesUnallocatedAmounts[i] + supplierInvoicesLastYearAmounts[i] + supplierInvoicesTwoYearsAgoAmounts[i] + scheduledPaymentAmounts[i];

			if (cashFlowMinValue == null || cashFlowValue < cashFlowMinValue) {
				cashFlowMinValue = cashFlowValue;
				cashFlowMinDate = this.props.categories[i];
			}
			if (cashFlowMaxValue == null || cashFlowValue > cashFlowMaxValue) {
				cashFlowMaxValue = cashFlowValue;
				cashFlowMaxDate = this.props.categories[i];
			}

			cashFlowData.push(Math.round(cashFlowValue * 100) / 100);
		}

		cashFlowStartValue = cashFlowData[0];
		cashFlowEndValue = cashFlowData[11];
		cashFlowStartDate = this.props.categories[0];
		cashFlowEndDate = this.props.categories[11];

		for (let i = 0; i < this.props.categories.length; ++i) {
			customerInvoicesUnallocatedAmounts[i] = Math.round(customerInvoicesUnallocatedAmounts[i] * 100) / 100;
			customerInvoicesLastYearAmounts[i] = Math.round(customerInvoicesLastYearAmounts[i] * 100) / 100;
			customerInvoicesTwoYearsAgoAmounts[i] = Math.round(customerInvoicesTwoYearsAgoAmounts[i] * 100) / 100;

			supplierInvoicesUnallocatedAmounts[i] = Math.round(supplierInvoicesUnallocatedAmounts[i] * 100) / 100;
			supplierInvoicesLastYearAmounts[i] = Math.round(supplierInvoicesLastYearAmounts[i] * 100) / 100;
			supplierInvoicesTwoYearsAgoAmounts[i] = Math.round(supplierInvoicesTwoYearsAgoAmounts[i] * 100) / 100;

			scheduledPaymentAmounts[i] = Math.round(scheduledPaymentAmounts[i] * 100) / 100;
		}

		const series = [
			{
				name: "Cash flow",
				type: "line",
				data: cashFlowData,
			},
			{
				name: "Bank account total balance",
				type: "bar",
				data: totalBankAccountBalanceLocalCurrency
			}
		];
		const colors = ["#616187", "#71b9e9"];

		if (this.state.useCustomerInvoicesUnallocated) {
			series.push({
				name: "Customer invoices",
				type: "bar",
				data: customerInvoicesUnallocatedAmounts,
			});
			colors.push("#67b988");
		}
		if (this.state.useCustomerInvoicesLastYear) {
			series.push({
				name: "Customer invoices (last year) x " + this.state.multiplierCustomerInvoicesLastYear,
				type: "bar",
				data: customerInvoicesLastYearAmounts,
			});
			colors.push("#a7e9c8");
		}
		if (this.state.useCustomerInvoicesTwoYearsAgo) {
			series.push({
				name: "Customer invoices (two years ago) x " + this.state.multiplierCustomerInvoicesTwoYearsAgo,
				type: "bar",
				data: customerInvoicesTwoYearsAgoAmounts,
			});
			colors.push("#c7f9e8");
		}
		if (this.state.useSupplierInvoicesUnallocated) {
			series.push({
				name: "Supplier invoices",
				type: "bar",
				data: supplierInvoicesUnallocatedAmounts,
			});
			colors.push("#b16e6e");
		}
		if (this.state.useSupplierInvoicesLastYear) {
			series.push({
				name: "Supplier invoices (last year) x " + this.state.multiplierSupplierInvoicesLastYear,
				type: "bar",
				data: supplierInvoicesLastYearAmounts,
			});
			colors.push("#d18e8e");
		}
		if (this.state.useSupplierInvoicesTwoYearsAgo) {
			series.push({
				name: "Supplier invoices (two years ago) x " + this.state.multiplierSupplierInvoicesTwoYearsAgo,
				type: "bar",
				data: supplierInvoicesTwoYearsAgoAmounts,
			});
			colors.push("#f19e9e");
		}
		if (this.state.useScheduledPayments) {
			series.push({
				name: "Scheduled payments",
				type: "bar",
				data: scheduledPaymentAmounts,
			});
			colors.push("#e1c17e");
		}

		const filterByDate = this.filterByDate;
		this.setState({
			options: {
				chart: {
					events: {
						xAxisLabelClick: function (event, chartContext, config) {
							if (event && event.target && event.target.innerHTML) {
								const clickedXValue = event.target.innerHTML;
								filterByDate(clickedXValue);
							}
						},
						dataPointSelection: function (event, chartContext, config) {
							const dataPointIndex = config.dataPointIndex;
							const xAxisData = chartContext.w.config.xaxis.categories;
							const clickedXValue = xAxisData[dataPointIndex];
							filterByDate(clickedXValue);
						}
					}
				},
				colors: colors,
				xaxis: {
					id: "cashFlowChart",
					toolbar: "false",
					stacked: true,
					categories: this.props.categories
				},
			},
			series: series,
			cashFlowMinValue: cashFlowMinValue,
			cashFlowMaxValue: cashFlowMaxValue,
			cashFlowStartValue,
			cashFlowEndValue,
			cashFlowMinDate,
			cashFlowMaxDate,
			cashFlowStartDate,
			cashFlowEndDate
		})
	}

	componentDidMount() {
		const selectedBankAccounts = [];
		const selectedCustomerInvoicesUnallocated = [];
		const selectedCustomerInvoicesLastYear = [];
		const selectedCustomerInvoicesTwoYearsAgo = [];
		const selectedSupplierInvoicesUnallocated = [];
		const selectedSupplierInvoicesLastYear = [];
		const selectedSupplierInvoicesTwoYearsAgo = [];
		const selectedScheduledPayments = [];
		this.props.bankAccounts.map(bankAccount => selectedBankAccounts.push(bankAccount.id));
		this.props.customerInvoicesUnallocated.map(invoice => selectedCustomerInvoicesUnallocated.push(invoice.id));
		this.props.customerInvoicesLastYear.map(invoice => selectedCustomerInvoicesLastYear.push(invoice.id));
		this.props.customerInvoicesTwoYearsAgo.map(invoice => selectedCustomerInvoicesTwoYearsAgo.push(invoice.id));
		this.props.supplierInvoicesUnallocated.map(invoice => selectedSupplierInvoicesUnallocated.push(invoice.id));
		this.props.supplierInvoicesLastYear.map(invoice => selectedSupplierInvoicesLastYear.push(invoice.id));
		this.props.supplierInvoicesTwoYearsAgo.map(invoice => selectedSupplierInvoicesTwoYearsAgo.push(invoice.id));
		this.props.scheduledPayments.map(payment => selectedScheduledPayments.push(payment.id));
		this.setState({
			selectedBankAccounts,
			selectedCustomerInvoicesUnallocated,
			selectedCustomerInvoicesLastYear,
			selectedCustomerInvoicesTwoYearsAgo,
			selectedSupplierInvoicesUnallocated,
			selectedSupplierInvoicesLastYear,
			selectedSupplierInvoicesTwoYearsAgo,
			selectedScheduledPayments
		});

		this.recalculateChart();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.useBankAccountBalances != this.state.useBankAccountBalances
			|| prevState.useCustomerInvoicesUnallocated != this.state.useCustomerInvoicesUnallocated
			|| prevState.useCustomerInvoicesLastYear != this.state.useCustomerInvoicesLastYear
			|| prevState.useCustomerInvoicesTwoYearsAgo != this.state.useCustomerInvoicesTwoYearsAgo
			|| prevState.useSupplierInvoicesUnallocated != this.state.useSupplierInvoicesUnallocated
			|| prevState.useSupplierInvoicesLastYear != this.state.useSupplierInvoicesLastYear
			|| prevState.useSupplierInvoicesTwoYearsAgo != this.state.useSupplierInvoicesTwoYearsAgo
			|| prevState.useScheduledPayments != this.state.useScheduledPayments
			|| prevState.multiplierCustomerInvoicesLastYear != this.state.multiplierCustomerInvoicesLastYear
			|| prevState.multiplierCustomerInvoicesTwoYearsAgo != this.state.multiplierCustomerInvoicesTwoYearsAgo
			|| prevState.multiplierSupplierInvoicesLastYear != this.state.multiplierSupplierInvoicesLastYear
			|| prevState.multiplierSupplierInvoicesTwoYearsAgo != this.state.multiplierSupplierInvoicesTwoYearsAgo
			|| prevState.selectedBankAccounts != this.state.selectedBankAccounts
			|| prevState.selectedCustomerInvoicesUnallocated != this.state.selectedCustomerInvoicesUnallocated
			|| prevState.selectedCustomerInvoicesLastYear != this.state.selectedCustomerInvoicesLastYear
			|| prevState.selectedCustomerInvoicesTwoYearsAgo != this.state.selectedCustomerInvoicesTwoYearsAgo
			|| prevState.selectedSupplierInvoicesUnallocated != this.state.selectedSupplierInvoicesUnallocated
			|| prevState.selectedSupplierInvoicesLastYear != this.state.selectedSupplierInvoicesLastYear
			|| prevState.selectedSupplierInvoicesTwoYearsAgo != this.state.selectedSupplierInvoicesTwoYearsAgo
			|| prevState.selectedScheduledPayments != this.state.selectedScheduledPayments
		) {
			this.recalculateChart();
		}
	}

	filterByDate(value) {
		const currentYear = value.split('-')[0];
		const currentMonth = value.split('-')[1];
		const valueLastYear = ((parseInt(currentYear)) - 1) + "-" + currentMonth;
		const valueTwoYearsAgo = ((parseInt(currentYear)) - 2) + "-" + currentMonth;

		// Before the respective tab is clicked, the table and its corresponding filter function (e.g. dateFilterCurrentYear()) are not initialized.
		// Therefore, it is essential to check if the function exists before calling it.
		if (this.dateFilterCurrentYear) {
			this.dateFilterCurrentYear(value);
		}
		if (this.dateFilterLastYear) {
			this.dateFilterLastYear(valueLastYear);
		}
		if (this.dateFilterTwoYearsAgo) {
			this.dateFilterTwoYearsAgo(valueTwoYearsAgo);
		}
	}

	render() {
		const badgePlain = (color, value) =>
			<span
				className={"badge badge-soft-" + color + " font-size-12"}
				style={{ marginBottom: "0.3rem" }}
			>
				{value}
			</span>

		const amountKPI = (value, caption, date) =>
			<span>
				{badgePlain((value < 0 ? "danger" : "success"), caption)}
				{" "}
				{formatUtils.formatAmount(value) + " " + this.props.localCurrencyCode}
				{" "}
				{badgePlain("secondary", date)}
				<br />
			</span>

		const customCheckboxControl = (fieldName, onChange, stateField, caption, multiplierFieldName, multiplierStateField, key) => {
			return (
				<Row className="align-items-bottom" key={key}>
					<Col xs="auto" style={{ height: "1.6rem" }}>
						{editFormControls.checkboxControlWithoutLabel(fieldName, onChange, stateField)}
					</Col>
					<Col className="text-left" style={{ paddingLeft: "0px" }}>
						<span>
							{caption}
						</span>
					</Col>
					{multiplierFieldName && multiplierStateField &&
						<Col xs="auto">
							<Input
								id={multiplierFieldName}
								name={multiplierFieldName}
								type="range"
								min="0"
								max="1"
								step="0.2"
								value={multiplierStateField}
								onChange={onChange}
							/>
						</Col>
					}
				</Row>
			);
		}

		const selectRow = (selectedRowsArray, selectedRowsArrayName) => {
			return {
				mode: 'checkbox',
				selected: selectedRowsArray,

				onSelect: (row, isSelect, rowIndex, e) => {
					const selectedRows = selectRowUtils.getSelectedRowOnSelect(selectedRowsArray, row, isSelect);
					this.setState({
						[selectedRowsArrayName]: selectedRows
					});
				},

				onSelectAll: (isSelect, rows, e) => {
					const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(selectedRowsArray, rows, isSelect)
					this.setState({
						[selectedRowsArrayName]: selectedRows
					});
				}
			}
		}

		const navItem = (tabIndex, tabTitle) =>
			<NavItem>
				<NavLink
					className={classnames({
						active: this.state.activeTab === tabIndex
					})}
					onClick={() => {
						this.toggleTab(tabIndex)
					}}
				>
					{tabTitle}
				</NavLink>
			</NavItem>

		const selectedColumnsBankAccount = [
			columnsBankAccount.accountNo,
			columnsBankAccount.currencyCode,
			columnsBankAccount.lastBalance,
			{
				...columnsBankAccount.lastBalanceLocalCurrency,
				text: "Balance, " + this.props.localCurrencyCode
			},
			columnsBankAccount.lastBalanceDate
		]

		const selectedColumnsCustomerInvoice = [
			columnsCustomerInvoice.customerName,
			columnsCustomerInvoice.invoiceNo,
			{
				...columnsCustomerInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterCurrentYear = filter; } }),
			},
			{
				...columnsCustomerInvoice.unallocatedAmountLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsCustomerInvoiceLastYear = [
			columnsCustomerInvoice.customerName,
			columnsCustomerInvoice.invoiceNo,
			{
				...columnsCustomerInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterLastYear = filter; } }),
			},
			{
				...columnsCustomerInvoice.amountLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsCustomerInvoiceTwoYearsAgo = [
			columnsCustomerInvoice.customerName,
			columnsCustomerInvoice.invoiceNo,
			{
				...columnsCustomerInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterTwoYearsAgo = filter; } }),
			},
			{
				...columnsCustomerInvoice.amountLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsSupplierInvoice = [
			columnsSupplierInvoice.supplierName,
			columnsSupplierInvoice.invoiceNo,
			{
				...columnsSupplierInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterCurrentYear = filter; } }),
			},
			{
				...columnsSupplierInvoice.unpaidAmountLocalCurrency,
				text: "Unpaid, " + this.props.localCurrencyCode
			},
			{
				...columnsSupplierInvoice.paidAmountLocalCurrency,
				text: "Paid, " + this.props.localCurrencyCode
			},
			{
				...columnsSupplierInvoice.validPaymentDiscountLocalCurrency,
				text: "Valid discount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsSupplierInvoiceLastYear = [
			columnsSupplierInvoice.supplierName,
			columnsSupplierInvoice.invoiceNo,
			{
				...columnsSupplierInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterLastYear = filter; } }),
			},
			{
				...columnsSupplierInvoice.totalPaidAmountLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsSupplierInvoiceTwoYearsAgo = [
			columnsSupplierInvoice.supplierName,
			columnsSupplierInvoice.invoiceNo,
			{
				...columnsSupplierInvoice.dueDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterTwoYearsAgo = filter; } }),
			},
			{
				...columnsSupplierInvoice.totalPaidAmountLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectedColumnsScheduledPayment = [
			{
				...columnsScheduledPayment.calculatedExecutionDate,
				filter: textFilter({ getFilter: (filter) => { this.dateFilterCurrentYear = filter; } }),
			},
			columnsScheduledPayment.name,
			columnsScheduledPayment.supplierName,
			columnsScheduledPayment.amountToPay,
			columnsScheduledPayment.currencyCode,
			{
				...columnsScheduledPayment.amountToPayLocalCurrency,
				text: "Amount, " + this.props.localCurrencyCode
			}
		]

		const selectRowBankAccounts = selectRow(this.state.selectedBankAccounts, "selectedBankAccounts")
		const selectRowCustomerInvoicesUnallocated = selectRow(this.state.selectedCustomerInvoicesUnallocated, "selectedCustomerInvoicesUnallocated")
		const selectRowCustomerInvoicesLastYear = selectRow(this.state.selectedCustomerInvoicesLastYear, "selectedCustomerInvoicesLastYear")
		const selectRowCustomerInvoicesTwoYearsAgo = selectRow(this.state.selectedCustomerInvoicesTwoYearsAgo, "selectedCustomerInvoicesTwoYearsAgo")
		const selectRowSupplierInvoicesUnallocated = selectRow(this.state.selectedSupplierInvoicesUnallocated, "selectedSupplierInvoicesUnallocated")
		const selectRowSupplierInvoicesLastYear = selectRow(this.state.selectedSupplierInvoicesLastYear, "selectedSupplierInvoicesLastYear")
		const selectRowSupplierInvoicesTwoYearsAgo = selectRow(this.state.selectedSupplierInvoicesTwoYearsAgo, "selectedSupplierInvoicesTwoYearsAgo")
		const selectRowScheduledPayments = selectRow(this.state.selectedScheduledPayments, "selectedScheduledPayments")

		const table = (data, columns, selectRow) =>
			<BootstrapTable
				keyField='id'
				data={data}
				columns={columns}
				pagination={paginationFactory({ sizePerPageList: [4, 10, 25] })}
				filter={filterFactory()}
				selectRow={selectRow}
			/>

		const tableBankAccounts = table(this.props.bankAccounts, selectedColumnsBankAccount, selectRowBankAccounts);
		const tableCustomerInvoicesUnallocated = table(this.props.customerInvoicesUnallocated, selectedColumnsCustomerInvoice, selectRowCustomerInvoicesUnallocated);
		const tableCustomerInvoicesLastYear = table(this.props.customerInvoicesLastYear, selectedColumnsCustomerInvoiceLastYear, selectRowCustomerInvoicesLastYear);
		const tableCustomerInvoicesTwoYearsAgo = table(this.props.customerInvoicesTwoYearsAgo, selectedColumnsCustomerInvoiceTwoYearsAgo, selectRowCustomerInvoicesTwoYearsAgo);
		const tableSupplierInvoicesUnallocated = table(this.props.supplierInvoicesUnallocated, selectedColumnsSupplierInvoice, selectRowSupplierInvoicesUnallocated);
		const tableSupplierInvoicesLastYear = table(this.props.supplierInvoicesLastYear, selectedColumnsSupplierInvoiceLastYear, selectRowSupplierInvoicesLastYear);
		const tableSupplierInvoicesTwoYearsAgo = table(this.props.supplierInvoicesTwoYearsAgo, selectedColumnsSupplierInvoiceTwoYearsAgo, selectRowSupplierInvoicesTwoYearsAgo);
		const tableScheduledPayments = table(this.props.scheduledPayments, selectedColumnsScheduledPayment, selectRowScheduledPayments);

		const onAllDataReport = () => {
			xlsxHelper.exportToXLSX([
				{ records: this.props.bankAccounts, columns: selectedColumnsBankAccount, sheetName: "Bank accounts" },
				{ records: this.props.customerInvoicesUnallocated, columns: selectedColumnsCustomerInvoice, sheetName: "Customer invoices" },
				{ records: this.props.customerInvoicesLastYear, columns: selectedColumnsCustomerInvoiceLastYear, sheetName: "Customer invoices, last year" },
				{ records: this.props.customerInvoicesTwoYearsAgo, columns: selectedColumnsCustomerInvoiceTwoYearsAgo, sheetName: "Customer invoices, 2 years ago" },
				{ records: this.props.supplierInvoicesUnallocated, columns: selectedColumnsSupplierInvoice, sheetName: "Supplier invoices" },
				{ records: this.props.supplierInvoicesLastYear, columns: selectedColumnsSupplierInvoiceLastYear, sheetName: "Supplier invoices, last year" },
				{ records: this.props.supplierInvoicesTwoYearsAgo, columns: selectedColumnsSupplierInvoiceTwoYearsAgo, sheetName: "Supplier invoices, 2 years ago" },
				{ records: this.props.scheduledPayments, columns: selectedColumnsScheduledPayment, sheetName: "Scheduled payments" },
			], 
			formatUtils.companyNameAsFilenamePrefix(this.props.selectedCompany) + "cash-flow-all-data.xlsx");
		}

		return (
			<React.Fragment>
				<Row>
					<Col xl="8">
						<Card>
							<CardBody>
								{/* <h4 className="card-title mb-4">Cash flow</h4> */}

								<div id="line-chart" className="apex-charts" dir="ltr">
									<Chart
										series={this.state.series}
										options={this.state.options}
										type="bar"
										height={320}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col>
						<Row>
							<Col>
								<Card>
									<CardBody>
										<div className="text-muted">
											{amountKPI(this.state.cashFlowStartValue, "Start", this.state.cashFlowStartDate)}
											{amountKPI(this.state.cashFlowEndValue, "End", this.state.cashFlowEndDate)}
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col>
								<Card>
									<CardBody>
										<div className="text-muted">
											{amountKPI(this.state.cashFlowMinValue, "Min", this.state.cashFlowMinDate)}
											{amountKPI(this.state.cashFlowMaxValue, "Max", this.state.cashFlowMaxDate)}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
						<Row>
							<Col>
								<Card>
									<CardBody>
										{customCheckboxControl("useBankAccountBalances", this.onChange, this.state.useBankAccountBalances, "Bank account balances")}
										{customCheckboxControl("useCustomerInvoicesUnallocated", this.onChange, this.state.useCustomerInvoicesUnallocated, "Customer invoices")}
										{customCheckboxControl("useCustomerInvoicesLastYear", this.onChange, this.state.useCustomerInvoicesLastYear, "Customer invoices (last year)", "multiplierCustomerInvoicesLastYear", this.state.multiplierCustomerInvoicesLastYear)}
										{customCheckboxControl("useCustomerInvoicesTwoYearsAgo", this.onChange, this.state.useCustomerInvoicesTwoYearsAgo, "Customer invoices (two years ago)", "multiplierCustomerInvoicesTwoYearsAgo", this.state.multiplierCustomerInvoicesTwoYearsAgo)}
										{customCheckboxControl("useSupplierInvoicesUnallocated", this.onChange, this.state.useSupplierInvoicesUnallocated, "Supplier invoices")}
										{customCheckboxControl("useSupplierInvoicesLastYear", this.onChange, this.state.useSupplierInvoicesLastYear, "Supplier invoices (last year)", "multiplierSupplierInvoicesLastYear", this.state.multiplierSupplierInvoicesLastYear)}
										{customCheckboxControl("useSupplierInvoicesTwoYearsAgo", this.onChange, this.state.useSupplierInvoicesTwoYearsAgo, "Supplier invoices (two years ago)", "multiplierSupplierInvoicesTwoYearsAgo", this.state.multiplierSupplierInvoicesTwoYearsAgo)}
										{customCheckboxControl("useScheduledPayments", this.onChange, this.state.useScheduledPayments, "Scheduled payments")}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						<Card>
							<CardBody>
								<Nav pills className="bg-light rounded">
									{this.state.useBankAccountBalances && navItem(1, "Bank accounts")}
									{this.state.useCustomerInvoicesUnallocated && navItem(2, "Customer invoices")}
									{this.state.useCustomerInvoicesLastYear && navItem(3, "Customer invoices (last year)")}
									{this.state.useCustomerInvoicesTwoYearsAgo && navItem(4, "Customer invoices (two years ago)")}
									{this.state.useSupplierInvoicesUnallocated && navItem(5, "Supplier invoices")}
									{this.state.useSupplierInvoicesLastYear && navItem(6, "Supplier invoices (last year)")}
									{this.state.useSupplierInvoicesTwoYearsAgo && navItem(7, "Supplier invoices (two years ago)")}
									{this.state.useScheduledPayments && navItem(8, "Scheduled payments")}
								</Nav>

								<br />

								{this.state.activeTab == 1 && this.state.useBankAccountBalances && tableBankAccounts}
								{this.state.activeTab == 2 && this.state.useCustomerInvoicesUnallocated && tableCustomerInvoicesUnallocated}
								{this.state.activeTab == 3 && this.state.useCustomerInvoicesLastYear && tableCustomerInvoicesLastYear}
								{this.state.activeTab == 4 && this.state.useCustomerInvoicesTwoYearsAgo && tableCustomerInvoicesTwoYearsAgo}
								{this.state.activeTab == 5 && this.state.useSupplierInvoicesUnallocated && tableSupplierInvoicesUnallocated}
								{this.state.activeTab == 6 && this.state.useSupplierInvoicesLastYear && tableSupplierInvoicesLastYear}
								{this.state.activeTab == 7 && this.state.useSupplierInvoicesTwoYearsAgo && tableSupplierInvoicesTwoYearsAgo}
								{this.state.activeTab == 8 && this.state.useScheduledPayments && tableScheduledPayments}

							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col>
						<Dropdown
							isOpen={this.state.reportDropdownOpen}
							toggle={() => this.setState({ reportDropdownOpen: !this.state.reportDropdownOpen })}
						>
							<DropdownToggle
								color="secondary"
								caret
							>
								Report...
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem onClick={onAllDataReport}>All data (XLSX)</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

export default CashFlowChart;