import * as allocationCriterionTrustLevel from "../definitions/enums/AllocationCriterionTrustLevel";
import * as formatUtils from "./formatUtils";

export const bankStatementRecordStatusOptions = () => {
    return {
        "NONE": transformBankStatementRecordStatus("NONE"),
        "CONFIRMED_IN_CRM": transformBankStatementRecordStatus("CONFIRMED_IN_CRM"),
        "EXPORTED": transformBankStatementRecordStatus("EXPORTED")
    }
}

export const transformBankStatementRecordStatus = (status) => {
    switch (status) {
        case "NONE":
            return "none";
        case "CONFIRMED_IN_CRM":
            return "Confirmed";
        case "EXPORTED":
            return "Exported";
        default:
            return "Unknown";
    }
}

export const transformAllocationDTO = (allocationDTO) => {
    const bankStatementRecord = {
        ...allocationDTO.bankStatementRecordDTO,
        unallocatedAmount: allocationDTO.unallocatedAmount
    };

    const allocatedPayments = [];
    const allocatedSupplierInvoices = [];
    const allocatedSuppliers = [];
    const allocatedCustomerInvoices = [];
    const allocatedCustomers = [];

    const paymentDTOSet = allocationDTO.paymentDTOSet;
    for (let invoiceKey in paymentDTOSet) {

        const bankStatementCurrency = allocationDTO.bankStatementRecordDTO.currencyCode;
        const invoiceCurrency = paymentDTOSet[invoiceKey].currencyCode;
        const allocatedAmountInvoiceCurrency = formatUtils.formatAmountWithDCSign(paymentDTOSet[invoiceKey].allocatedAmountInvoiceCurrency);
        const allocatedAmountInfo = allocatedAmountInvoiceCurrency + (bankStatementCurrency === invoiceCurrency ? "" : " (" + bankStatementCurrency + " " + formatUtils.formatAmountWithDCSign(paymentDTOSet[invoiceKey].allocatedAmount) + ")");

        allocatedPayments.push({
            ...paymentDTOSet[invoiceKey],
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            allocatedAmountInfo: allocatedAmountInfo
        });
    }

    const supplierInvoiceDTOSet = allocationDTO.supplierInvoiceDTOSet;
    for (let invoiceKey in supplierInvoiceDTOSet) {

        const bankStatementCurrency = allocationDTO.bankStatementRecordDTO.currencyCode;
        const invoiceCurrency = supplierInvoiceDTOSet[invoiceKey].currencyCode;
        const allocatedAmountInvoiceCurrency = formatUtils.formatAmountWithDCSign(supplierInvoiceDTOSet[invoiceKey].allocatedAmountInvoiceCurrency);
        const allocatedAmountInfo = allocatedAmountInvoiceCurrency + (bankStatementCurrency === invoiceCurrency ? "" : " (" + bankStatementCurrency + " " + formatUtils.formatAmountWithDCSign(supplierInvoiceDTOSet[invoiceKey].allocatedAmount) + ")");

        allocatedSupplierInvoices.push({
            ...supplierInvoiceDTOSet[invoiceKey],
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            allocatedAmountInfo: allocatedAmountInfo
        });
    }

    const supplierDTOSet = allocationDTO.supplierDTOSet;
    for (let supplierKey in supplierDTOSet) {

        const allocatedAmount = formatUtils.formatAmountWithDCSign(supplierDTOSet[supplierKey].allocatedAmount);
        const allocatedAmountInfo = allocatedAmount;

        allocatedSuppliers.push({
            ...supplierDTOSet[supplierKey],
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            allocatedAmountInfo: allocatedAmountInfo
        });
    }

    const customerInvoiceDTOSet = allocationDTO.customerInvoiceDTOSet;
    for (let invoiceKey in customerInvoiceDTOSet) {

        const bankStatementCurrency = allocationDTO.bankStatementRecordDTO.currencyCode;
        const invoiceCurrency = customerInvoiceDTOSet[invoiceKey].currencyCode;
        const allocatedAmountInvoiceCurrency = formatUtils.formatAmountWithDCSign(customerInvoiceDTOSet[invoiceKey].allocatedAmountInvoiceCurrency);
        const allocatedAmountInfo = allocatedAmountInvoiceCurrency + (bankStatementCurrency === invoiceCurrency ? "" : " (" + bankStatementCurrency + " " + formatUtils.formatAmountWithDCSign(customerInvoiceDTOSet[invoiceKey].allocatedAmount) + ")");

        allocatedCustomerInvoices.push({
            ...customerInvoiceDTOSet[invoiceKey],
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            allocatedAmountInfo: allocatedAmountInfo
        });
    }

    const customerDTOSet = allocationDTO.customerDTOSet;
    for (let customerKey in customerDTOSet) {

        const allocatedAmount = formatUtils.formatAmountWithDCSign(customerDTOSet[customerKey].allocatedAmount);
        const allocatedAmountInfo = allocatedAmount;

        allocatedCustomers.push({
            ...customerDTOSet[customerKey],
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            allocatedAmountInfo: allocatedAmountInfo
        });
    }

    return {
        bankStatementRecord: bankStatementRecord,
        allocatedPayments: allocatedPayments,
        allocatedSupplierInvoices: allocatedSupplierInvoices,
        allocatedSuppliers: allocatedSuppliers,
        allocatedCustomerInvoices: allocatedCustomerInvoices,
        allocatedCustomers: allocatedCustomers,
        allocatedBankCharge: {
            bankStatementRecordId: allocationDTO.bankStatementRecordDTO.id,
            bankStatementRecordStatus: allocationDTO.bankStatementRecordDTO.status,
            criterion: allocationDTO.bankChargeCriterion,
            type: "Bank charge",
            amount: allocationDTO.bankChargeAmount,
            currencyCode: allocationDTO.bankStatementRecordDTO.currencyCode
        }
    }
}

const getCriterionTrustLevelCode = (trustLevelID) => {
    if (trustLevelID === "MANUAL") {
        return -1;
    }
    for (let i in allocationCriterionTrustLevel.AllocationCriterionTrustLevel) {
        if (allocationCriterionTrustLevel.AllocationCriterionTrustLevel[i].id === trustLevelID) {
            return allocationCriterionTrustLevel.AllocationCriterionTrustLevel[i].code;
        }
    }
    return null;
}


const getMinimumCriterionTrustLevel = (currentTrustLevel, criterion) => {
    if (criterion && criterion.trustLevel) {
        const currentTrustLevelCode = getCriterionTrustLevelCode(currentTrustLevel);
        const thisCriterionTrustLevelCode = getCriterionTrustLevelCode(criterion.trustLevel);
        if (currentTrustLevelCode && (currentTrustLevelCode < thisCriterionTrustLevelCode)) {
            return currentTrustLevel;
        } else {
            return criterion.trustLevel;
        }
    } else {
        return "MANUAL";
    }
}

export const transformAllocationDTOList = (allocationDTOList) => {
    const bankStatementRecords = [];
    const allocatedPayments = [];
    const allocatedSupplierInvoices = [];
    const allocatedSuppliers = [];
    const allocatedCustomerInvoices = [];
    const allocatedCustomers = [];
    const allocatedBankCharges = [];

    for (let key in allocationDTOList) {
        const transformedAllocationDTO = transformAllocationDTO(allocationDTOList[key]);
        let minimumCriterionTrustLevel;

        for (let i in transformedAllocationDTO.allocatedPayments) {
            allocatedPayments.push(transformedAllocationDTO.allocatedPayments[i]);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedPayments[i].criterion);
        }
        for (let i in transformedAllocationDTO.allocatedSupplierInvoices) {
            allocatedSupplierInvoices.push(transformedAllocationDTO.allocatedSupplierInvoices[i]);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedSupplierInvoices[i].criterion);
        }
        for (let i in transformedAllocationDTO.allocatedSuppliers) {
            allocatedSuppliers.push(transformedAllocationDTO.allocatedSuppliers[i]);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedSuppliers[i].criterion);
        }
        for (let i in transformedAllocationDTO.allocatedCustomerInvoices) {
            allocatedCustomerInvoices.push(transformedAllocationDTO.allocatedCustomerInvoices[i]);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedCustomerInvoices[i].criterion);
        }
        for (let i in transformedAllocationDTO.allocatedCustomers) {
            allocatedCustomers.push(transformedAllocationDTO.allocatedCustomers[i]);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedCustomers[i].criterion);
        }
        if (transformedAllocationDTO.allocatedBankCharge.amount) {
            allocatedBankCharges.push(transformedAllocationDTO.allocatedBankCharge);
            minimumCriterionTrustLevel = getMinimumCriterionTrustLevel(minimumCriterionTrustLevel, transformedAllocationDTO.allocatedBankCharge.criterion);
        }
        bankStatementRecords.push({
            ...transformedAllocationDTO.bankStatementRecord,
            criterionTrustLevel: minimumCriterionTrustLevel
        });
    }

    return {
        bankStatementRecords,
        allocatedPayments,
        allocatedSupplierInvoices,
        allocatedSuppliers,
        allocatedCustomerInvoices,
        allocatedCustomers,
        allocatedBankCharges
    }
}