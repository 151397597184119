export const BANK_ACCOUNT_CREATE = "BANK_ACCOUNT_CREATE";
export const BANK_ACCOUNT_CREATE_ERROR = "BANK_ACCOUNT_CREATE_ERROR";
export const BANK_ACCOUNT_CREATE_SUCCESS = "BANK_ACCOUNT_CREATE_SUCCESS";

export const BANK_ACCOUNT_DELETE = "BANK_ACCOUNT_DELETE";
export const BANK_ACCOUNT_DELETE_ERROR = "BANK_ACCOUNT_DELETE_ERROR";
export const BANK_ACCOUNT_DELETE_SUCCESS = "BANK_ACCOUNT_DELETE_SUCCESS";

export const BANK_ACCOUNT_GET_ALL = "BANK_ACCOUNT_GET_ALL";
export const BANK_ACCOUNT_GET_BY_ID = "BANK_ACCOUNT_GET_BY_ID";
export const BANK_ACCOUNT_GET_ERROR = "BANK_ACCOUNT_GET_ERROR";
export const BANK_ACCOUNT_GET_SUCCESS = "BANK_ACCOUNT_GET_SUCCESS";

export const BANK_ACCOUNT_TEST_CONNECTION = "BANK_ACCOUNT_TEST_CONNECTION";
export const BANK_ACCOUNT_TEST_CONNECTION_ERROR = "BANK_ACCOUNT_TEST_CONNECTION_ERROR";
export const BANK_ACCOUNT_TEST_CONNECTION_SUCCESS = "BANK_ACCOUNT_TEST_CONNECTION_SUCCESS";

