import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllExchRatesSaga() {
	yield put(actions.exchRateGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CURRENCY_HISTORY_GET_ALL
		);

		const exchRates = [];
		for (let key in response.data) {
			exchRates.push({
				...response.data[key]
			});
		}
		yield put(actions.exchRateGetSuccess(exchRates));
	} catch (e) {
		yield put(actions.exchRateGetError(e));
	}
}

function* getExchRateByIdSaga({ id }) {
	yield put(actions.exchRateGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CURRENCY_HISTORY_GET_BY_ID.replace("{currencyHistoryId}", id)
		);

		yield put(actions.exchRateGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.exchRateGetError(e));
	}
}

function* createExchRateSaga({ exchRate, history }) {
	try {
		//console.log(exchRate);
		// As of 2022.01.11, encountered a situation when in an empty company, after creating a new currency, upon trying to create a new exchange rate
		// the frontend was somehow sending a request with currencyId = null. Could not investigate as the problem disappeared after page refresh.

		yield call(
			rest.post,
			endpointsBackend.CURRENCY_HISTORY_CREATE_OR_UPDATE,
			exchRate
		);
		history.push(endpointsFrontend.CURRENCY_EXCHRATE_BROWSE);
	} catch (e) {
		yield put(actions.exchRateCreateError(e));
	}
}

function* deleteExchRateSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CURRENCY_HISTORY_GET_BY_ID.replace("{currencyHistoryId}", id)
		);
		history.push(endpointsFrontend.CURRENCY_EXCHRATE_BROWSE);
	} catch (e) {
		yield put(actions.exchRateCreateError(e));
	}
}

function* exchRateSaga() {
	yield (takeEvery(actionTypes.EXCHRATE_GET_ALL, getAllExchRatesSaga));
	yield (takeEvery(actionTypes.EXCHRATE_GET_BY_ID, getExchRateByIdSaga));
	yield (takeEvery(actionTypes.EXCHRATE_CREATE, createExchRateSaga));
	yield (takeEvery(actionTypes.EXCHRATE_DELETE, deleteExchRateSaga));
}

export default exchRateSaga;