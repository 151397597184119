import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from '../../helpers/sagaAndReducerHelper';

function* createBankAccountSaga({ bankAccount, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.BANK_ACCOUNT_CREATE_OR_UPDATE,
			bankAccount
		);
		yield put(actions.bankAccountCreateSuccess());
		history.push(endpointsFrontend.BANK_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.bankAccountCreateError(e));
	}
}

function* deleteBankAccountSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.BANK_ACCOUNT_GET_BY_ID.replace("{bankAccountId}", id)
		);
		yield put(actions.bankAccountDeleteSuccess());
		history.push(endpointsFrontend.BANK_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.bankAccountDeleteError(e));
	}
}

function* getAllBankAccountsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_ACCOUNT_GET_ALL
		);

		yield put(actions.bankAccountGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.bankAccountGetError(e));
	}
}

function* getBankAccountByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.BANK_ACCOUNT_GET_BY_ID.replace("{bankAccountId}", id)
		);

		yield put(actions.bankAccountGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.bankAccountGetError(e));
	}
}

function* testBankAccountSaga({ id }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.BANK_ACCOUNT_DC_CHECK_CONNECTION.replace("{bankAccountId}", id)
		);
		yield put(actions.bankAccountTestConnectionSuccess());
	} catch (e) {
		yield put(actions.bankAccountTestConnectionError(e));
	}
}

function* bankAccountSaga() {
	yield (takeLatest(actionTypes.BANK_ACCOUNT_CREATE, createBankAccountSaga));
	yield (takeLatest(actionTypes.BANK_ACCOUNT_DELETE, deleteBankAccountSaga));
	yield (takeLatest(actionTypes.BANK_ACCOUNT_GET_ALL, getAllBankAccountsSaga));
	yield (takeLatest(actionTypes.BANK_ACCOUNT_GET_BY_ID, getBankAccountByIdSaga));
	yield (takeLatest(actionTypes.BANK_ACCOUNT_TEST_CONNECTION, testBankAccountSaga));
}

export default bankAccountSaga;