export const ALLOCATION_AUTO_ALLOCATE = "ALLOCATION_AUTO_ALLOCATE";

export const ALLOCATION_CREATE = "ALLOCATION_CREATE";
export const ALLOCATION_CREATE_ERROR = "ALLOCATION_CREATE_ERROR";
export const ALLOCATION_CREATE_SUCCESS = "ALLOCATION_CREATE_SUCCESS";

export const ALLOCATION_DELETE = "ALLOCATION_DELETE";
export const ALLOCATION_DELETE_ERROR = "ALLOCATION_DELETE_ERROR";
export const ALLOCATION_DELETE_SUCCESS = "ALLOCATION_DELETE_SUCCESS";

export const ALLOCATION_GET_ALL = "ALLOCATION_GET_ALL";
export const ALLOCATION_GET_BY_CUSTOMER_INVOICE = "ALLOCATION_GET_BY_CUSTOMER_INVOICE";
export const ALLOCATION_GET_ERROR = "ALLOCATION_GET_ERROR";
export const ALLOCATION_GET_SUCCESS = "ALLOCATION_GET_SUCCESS";

export const ALLOCATION_RECORD_SET = "ALLOCATION_RECORD_SET";

export const ALLOCATION_REVERT = "ALLOCATION_REVERT";
export const ALLOCATION_REVERT_ERROR = "ALLOCATION_REVERT_ERROR";
export const ALLOCATION_REVERT_SUCCESS = "ALLOCATION_REVERT_SUCCESS";

export const ALLOCATION_CONFIRM = "ALLOCATION_CONFIRM";
export const ALLOCATION_CONFIRM_ERROR = "ALLOCATION_CONFIRM_ERROR";
export const ALLOCATION_CONFIRM_SUCCESS = "ALLOCATION_CONFIRM_SUCCESS";

export const ALLOCATION_REVERT_ALL = "ALLOCATION_REVERT_ALL";
export const ALLOCATION_REVERT_ALL_ERROR = "ALLOCATION_REVERT_ALL_ERROR";
export const ALLOCATION_REVERT_ALL_SUCCESS = "ALLOCATION_REVERT_ALL_SUCCESS";

