import * as actionTypes from "./actionTypes";

export const exchRateGetAll = () => {
	return {
		type: actionTypes.EXCHRATE_GET_ALL
	}
}

export const exchRateGetById = (id) => {
	return {
		type: actionTypes.EXCHRATE_GET_BY_ID,
		id: id
	}
}

export const exchRateGetInProcess = () => {
	return {
		type: actionTypes.EXCHRATE_GET_IN_PROCESS
	}
}

export const exchRateGetError = (thisError) => {
	return {
		type: actionTypes.EXCHRATE_GET_ERROR,
		error: thisError
	}
}

export const exchRateGetSuccess = (data) => {
	return {
		type: actionTypes.EXCHRATE_GET_SUCCESS,
		exchRates: data
	}
}

export const exchRateCreate = (exchRate, history) => {
	return {
		type: actionTypes.EXCHRATE_CREATE,
		exchRate: exchRate, 
		history: history
	}
}

export const exchRateCreateError = (thisError) => {
	return {
		type: actionTypes.EXCHRATE_CREATE_ERROR,
		error: thisError
	}
}

export const exchRateDelete = (id, history) => {
	return {
		type: actionTypes.EXCHRATE_DELETE,
		id: id,
		history: history
	}
}