const sessionTimeout = 20 * 60 * 1000;
// const sessionTimeout = 3000;

class SessionTimerService {

    constructor() {
        this.timer = null;
        this.logoutCallback = null;
    }

    setLogoutCallback(callback) {
        this.logoutCallback = callback;
    }

    startTimer() {
        this.clearTimer();
        if (this.logoutCallback) {
            this.timer = setTimeout(() => {
                this.logoutCallback();
            }, sessionTimeout);
        }
    }

    resetTimer() {
        if (this.timer) {
            this.startTimer(sessionTimeout);
        }
    }

    clearTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }
}

const timerService = new SessionTimerService();
export default timerService;
