import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	paymentFiles: null,
	receiving: false,
	resettingBulks: false,
	saving: false,
	signing: false,
	total: null
}

export default function PaymentFileReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.PAYMENT_FILE_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_FILE_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.PAYMENT_FILE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.PAYMENT_FILE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.PAYMENT_FILE_GET:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.PAYMENT_FILE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.PAYMENT_FILE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				paymentFiles: action.paymentFiles
			}
		case actionTypes.PAYMENT_FILE_GET_TOTAL:
			return {
				...state,
				loadingTotal: true,
				total: null,
				error: null
			}
		case actionTypes.PAYMENT_FILE_GET_TOTAL_SUCCESS:
			return {
				...state,
				loadingTotal: false,
				total: action.total
			}
		case actionTypes.PAYMENT_FILE_GET_TOTAL_ERROR:
			return {
				...state,
				loadingTotal: false,
				total: null,
				error: action.error
			}
		case actionTypes.PAYMENT_FILE_REFRESH_STATUS:
			return {
				...state,
				error: null,
				receiving: true
			}
		case actionTypes.PAYMENT_FILE_REFRESH_STATUS_ERROR:
			return {
				...state,
				error: action.error,
				receiving: false
			}
		case actionTypes.PAYMENT_FILE_REFRESH_STATUS_SUCCESS:
			return {
				...state,
				error: null,
				receiving: false
			}
		case actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_LIST:
			return {
				...state,
				resettingBulks: true,
				error: null
			}
		case actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_ERROR:
			return {
				...state,
				resettingBulks: false,
				error: action.error
			}
		case actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_SUCCESS:
			return {
				...state,
				resettingBulks: false,
				error: null
			}
		case actionTypes.PAYMENT_FILE_SIGN_GET_CERTIFICATE:
			return {
				...state,
				error: null,
				signing: true
			}
		case actionTypes.PAYMENT_FILE_SIGN_INIT:
			return state;
		case actionTypes.PAYMENT_FILE_SIGN_ERROR:
			return {
				...state,
				error: action.error,
				resetting: false,
				signing: false
			}
		case actionTypes.PAYMENT_FILE_SIGN_RESET:
			return {
				...state,
				error: null,
				resetting: true
			}
		case actionTypes.PAYMENT_FILE_SIGN_RESET_SUCCESS:
			return {
				...state,
				paymentFiles: action.paymentFiles,
				resetting: false
			}
		case actionTypes.PAYMENT_FILE_SIGN_SUCCESS:
			return {
				...state,
				error: null,
				paymentFiles: action.paymentFiles,
				signing: false
			}
		default:
			return state;
	}
}