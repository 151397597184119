import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper"

function* createUnitSaga({ unit, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.UNIT_CREATE_OR_UPDATE,
			unit
		);
		yield put(actions.unitCreateSuccess());
		//history.push(endpointsFrontend.UNIT_BROWSE);
	} catch (e) {
		yield put(actions.unitCreateError(e));
	}
}

function* deleteUnitSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.UNIT_GET_BY_ID.replace("{unitId}", id)
		);
		yield put(actions.unitDeleteSuccess());
		history.push(endpointsFrontend.UNIT_BROWSE);
	} catch (e) {
		yield put(actions.unitDeleteError(e));
	}
}

function* getAllUnitsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.UNIT_GET_ALL
		);

		yield put(actions.unitGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.unitGetError(e));
	}
}

function* getUnitByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.UNIT_GET_BY_ID.replace("{unitId}", id)
		);

		yield put(actions.unitGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.unitGetError(e));
	}
}

function* unitSaga() {
	yield (takeLatest(actionTypes.UNIT_CREATE, createUnitSaga));
	yield (takeLatest(actionTypes.UNIT_DELETE, deleteUnitSaga));
	yield (takeLatest(actionTypes.UNIT_GET_ALL, getAllUnitsSaga));
	yield (takeLatest(actionTypes.UNIT_GET_BY_ID, getUnitByIdSaga));
}

export default unitSaga;