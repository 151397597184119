import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsUnit from '../../store/Unit/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';

class UnitEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			nameLang0: "",
			nameLang1: "",
			units: [],

			changed: false,
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
            changed: true,
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedUnit = {
			nameLang0: this.state.nameLang0,
			nameLang1: this.state.nameLang1,

		}
		if (this.state.id) {
			newOrUpdatedUnit = {
				id: this.state.id,
				...newOrUpdatedUnit
			}
		}
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
		this.props.onCreateUnit(newOrUpdatedUnit, this.props.history);
	}

	onDeleteUnit = () => {
		if (window.confirm("Are you sure you want to delete this Unit?")) {
			this.props.onDeleteUnit(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetUnitById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.units !== this.props.units) {
			if (this.props.units && this.props.units[0]) {

				this.setState({
					id: this.props.units[0].id,
					nameLang0: this.props.units[0].nameLang0,
					nameLang1: this.props.units[0].nameLang1,

				});
			} else {
				this.setState({
					id: "",
					nameLang0: "",
					nameLang1: "",

				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Unit | " + config.AppName;
		const breadcrumbsTitle = "Unit";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Unit";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}

										{editFormControls.textControl("nameLang0", "Name", this.onChange, this.state.nameLang0)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteUnit, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

                        {editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Unit", "Browse units", endpointsFrontend.UNIT_BROWSE)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ unit }) => ({
	error: unit.error,
	deleting: unit.deleting,
	loading: unit.loading,
	saveSuccess: unit.saveSuccess,
	saving: unit.saving,
	units: unit.units
})

const mapDispatchToProps = dispatch => ({
	onGetUnitById: (id) => dispatch(actionsUnit.unitGetById(id)),
	onCreateUnit: (unit, history) => dispatch(actionsUnit.unitCreate(unit, history)),
	onDeleteUnit: (id, history) => dispatch(actionsUnit.unitDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UnitEdit);
