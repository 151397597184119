import { connect } from "react-redux";

import * as actionsAllocation from '../../store/Allocation/actions';
import * as actionsCounter from '../../store/Counter/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsCustomerInvoice from '../../store/CustomerInvoice/actions';
import * as actionsCustomer from '../../store/Customer/actions';

import CustomerQuoteInvoiceEdit from "pages/CustomerQuoteInvoice/customer-quote-invoice-edit";

const mapStateToProps = ({ allocation, counter, currency, customer, customerInvoice }) => ({
	targetNameLowercase: "customer invoice",
	targetNameCapitalized: "Customer invoice",
	targetNameShort: "invoice",
	targetNameShortCapitalized: "Invoice",
	allocations: allocation.bankStatementRecords,
	copying: customerInvoice.copying,
	copyingLines: customerInvoice.copyingLines,
	copySuccess: customerInvoice.copySuccess,
	counterValue: counter.value,
	currencies: currency.currencies,
	customerQuotes: customerInvoice.customerInvoices,
	customerQuoteLines: customerInvoice.customerInvoiceLines,
	customers: customer.customers,
	deleting: customerInvoice.deleting,
	deletingLines: customerInvoice.deletingLines,
	error: customerInvoice.error,
	errorCounters: counter.error,
	errorLines: customerInvoice.errorLines,
	loading: customerInvoice.loading,
	loadingAllocations: allocation.loading,
	loadingLines: customerInvoice.loadingLines,
	loadingCounters: counter.loading,
	loadingCurrencies: currency.loading,
	loadingCustomers: customer.loading,
	movingLines: customerInvoice.movingLines,
	printing: customerInvoice.printing,
	saving: customerInvoice.saving
});

const mapDispatchToProps = dispatch => ({
	onCopyCustomerQuote: (id, history) => dispatch(actionsCustomerInvoice.customerInvoiceCopy(id, history)),
	onCreateCustomerQuote: (customerInvoice, history) => dispatch(actionsCustomerInvoice.customerInvoiceCreate(customerInvoice, history)),
	onCopyCustomerQuoteLines: (idList) => dispatch(actionsCustomerInvoice.customerInvoiceLineCopy(idList)),
	onDeleteCustomerQuote: (id, history) => dispatch(actionsCustomerInvoice.customerInvoiceDelete(id, history)),
	onDeleteCustomerQuoteLines: (idList) => dispatch(actionsCustomerInvoice.customerInvoiceLineDeleteList(idList)),
	onGetAllocations: (id) => dispatch(actionsAllocation.allocationGetByCustomerInvoice(id)),
	onGetAndUpdateCounter: () => dispatch(actionsCounter.counterGetAndUpdate("CUSTOMER_INVOICE_NO")),
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetCustomers: () => dispatch(actionsCustomer.customerGetAll()),
	onGetCustomerQuoteLines: (customerInvoiceId) => dispatch(actionsCustomerInvoice.customerInvoiceLineGetAll(customerInvoiceId)),
	onGetCustomerQuoteById: (id) => dispatch(actionsCustomerInvoice.customerInvoiceGetById(id)),
	onMoveCustomerQuoteLineUp: (id) => dispatch(actionsCustomerInvoice.customerInvoiceLineMoveUp(id)),
	onMoveCustomerQuoteLineDown: (id) => dispatch(actionsCustomerInvoice.customerInvoiceLineMoveDown(id)),
	onPrintCustomerQuote: (id) => dispatch(actionsCustomerInvoice.customerInvoicePrintToPDF(id))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerQuoteInvoiceEdit);

/*

const mapStateToProps = ({ country, customerInvoice }) => ({
	countries: country.countries,
	customerInvoices: customerInvoice.customerInvoices,
	loading: customerInvoice.loading,
	error: customerInvoice.error
})

const mapDispatchToProps = dispatch => ({
	onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
	onGetCustomerInvoiceById: (id) => dispatch(actionsCustomerInvoice.customerInvoiceGetById(id)),
	onCreateCustomerInvoice: (customerInvoice, history) => dispatch(actionsCustomerInvoice.customerInvoiceCreate(customerInvoice, history)),
	onDeleteCustomerInvoice: (id, history) => dispatch(actionsCustomerInvoice.customerInvoiceDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerInvoiceEdit);

*/