import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	invitationResponse: null,
	inviting: false,
	loading: false,
	saving: false,
	userAccounts: null,
}

export default function userAccountReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.USER_ACCOUNT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.USER_ACCOUNT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.USER_ACCOUNT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				userAccounts: action.userAccounts
			}
		case actionTypes.USER_ACCOUNT_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case actionTypes.USER_ACCOUNT_INVITE:
			return {
				...state,
				error: null,
				invitationResponse: null,
				inviting: true
			}
		case actionTypes.USER_ACCOUNT_INVITE_ERROR:
			return {
				...state,
				error: action.error,
				inviting: false
			}
		case actionTypes.USER_ACCOUNT_INVITE_SUCCESS:
			return {
				...state,
				invitationResponse: action.invitationResponse,
				inviting: false
			}
		case actionTypes.USER_ACCOUNT_UPDATE_RIGHTS:
			return {
				...state,
				error: false,
				saving: true
			}
		case actionTypes.USER_ACCOUNT_UPDATE_RIGHTS_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.USER_ACCOUNT_UPDATE_RIGHTS_SUCCESS:
			return {
				...state,
				error: false,
				saving: false
			}
		default:
			return state;
	}
}