import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	saveSuccess: false,
	saving: false,
	units: null,
}

export default function unitReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.UNIT_CREATE:
			return {
				...state,
				error: null,
				saveSuccess: false,
				saving: true
			}
		case actionTypes.UNIT_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.UNIT_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saveSuccess: true,
				saving: false
			}
		case actionTypes.UNIT_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.UNIT_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.UNIT_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.UNIT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.UNIT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.UNIT_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				units: action.units
			}
		case actionTypes.UNIT_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}