import * as actionTypes from "./actionTypes";

const initialState = {
	availableModules: null,
	loading: false,
	error: null	
}

export default function availableModuleReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.AVAILABLE_MODULE_GET_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.AVAILABLE_MODULE_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.AVAILABLE_MODULE_GET_SUCCESS:
			return {
				...state,
				availableModules: action.availableModules,
				loading: false
			}
		case actionTypes.AVAILABLE_MODULE_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case actionTypes.AVAILABLE_MODULE_SET_NONE:
			return {
				...state,
				availableModules: [],
				loading: false
			}
		default:
			return state;
	}
}