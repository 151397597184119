import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const taskId = {
	dataField: 'taskId',
	text: 'Task ID',
	filter: textFilter(),
	sort: true
};
export const date = {
	dataField: 'date',
	text: 'Date',
	filter: textFilter(),
	sort: true
};
export const hoursLogged = {
	dataField: 'hoursLogged',
	text: 'Hours logged',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatHours(cell),
	sort: true
};
export const userLoggedId = {
	dataField: 'userLoggedId',
	text: 'User logged ID',
	filter: textFilter(),
	sort: true
};
export const comment = {
	dataField: 'comment',
	text: 'Comment',
	filter: textFilter(),
	sort: true
};
export const userLoggedInfo = {
	dataField: 'userLoggedInfo',
	text: 'User logged',
	filter: textFilter(),
	sort: true
};
export const taskTitle = {
	dataField: 'taskTitle',
	text: 'Task title',
	filter: textFilter(),
	sort: true
};
