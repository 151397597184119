import * as actionTypes from "./actionTypes";
import * as reducerTools from "../../helpers/reducerTools";

const initialState = {
	exchRates: null,
	fieldValueOptions: {},
	loading: false,
	error: null
}

export default function exchRateReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.EXCHRATE_GET_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.EXCHRATE_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.EXCHRATE_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.EXCHRATE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				exchRates: action.exchRates,
				fieldValueOptions: reducerTools.getFieldValueOptions(action.exchRates, "currencyCode")
			}
		case actionTypes.EXCHRATE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.EXCHRATE_CREATE:
			return {
				...state,
				error: null
			}
		case actionTypes.EXCHRATE_CREATE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}