import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect } from "react-redux";

import * as actions from '../../store/Company/actions';
import "./CompanyDropdown.css";
import * as editFormControls from '../../helpers/editFormControls';

class CompanyDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			selectedCompanyId: "",
			selectedCompanyName: ""
		}
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.setState({
				selectedCompanyId: this.props.selectedCompany.id,
				selectedCompanyName: this.props.selectedCompany.name
			})
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedCompany !== this.props.selectedCompany && this.props.selectedCompany) {
			this.setState({
				selectedCompanyId: this.props.selectedCompany.id,
				selectedCompanyName: this.props.selectedCompany.name
			})
		}
	}

	changeCompanyAction = company => {
		this.props.onSetSelectedCompany(company, this.props.history);
		window.location.reload(false);
	}

	toggle = () => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	}

	render() {
		const LoadingSpinner = (
			<div className="spinnerbox">
				<div className="spinner">
					{editFormControls.bigSpinner()}
				</div>
			</div>
		)

		const CompanySelectionControl = (
			<Dropdown
				isOpen={this.state.isOpen}
				toggle={this.toggle}
				className="d-inline-block"
			>
				<DropdownToggle
					className="btn header-item"
					tag="button"
				>
					{this.state.selectedCompanyName}
					<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
				</DropdownToggle>
				<DropdownMenu
					className="language-switch dropdown-menu-end"
				>
					{this.props.companies.map((company, key) => (
						<DropdownItem
							key={key}
							onClick={() => this.changeCompanyAction(company)}
							className={`notify-item ${this.state.selectedCompanyId === company.id ? "active" : "none"}`}
						>
							{company.name}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		)

		const NoAvailableCompaniesControl = (
			<div>
			</div>
		)

		return (
			this.props.loading
				? LoadingSpinner
				: (this.props.noAvailableCompanies
					? NoAvailableCompaniesControl
					: CompanySelectionControl)
		);
	}
}

const mapStateToProps = ({ company }) => ({
	companies: company.companies,
	loading: company.loading,
	noAvailableCompanies: company.noAvailableCompanies,
	selectedCompany: company.selectedCompany
});

const mapDispatchToProps = dispatch => ({
	onSetSelectedCompany: (selectedCompany, history) => dispatch(actions.companySetSelected(selectedCompany, history))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CompanyDropdown);
