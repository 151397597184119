export const SMART_ID_LOGIN_ERROR = "SMART_ID_LOGIN_ERROR";

export const SMART_ID_LOGIN_INIT = "SMART_ID_LOGIN_INIT";
export const SMART_ID_LOGIN_INIT_SUCCESS = "SMART_ID_LOGIN_INIT_SUCCESS";

export const SMART_ID_LOGIN_FINALIZE = "SMART_ID_LOGIN_FINALIZE";
export const SMART_ID_LOGIN_FINALIZE_SUCCESS = "SMART_ID_LOGIN_FINALIZE_SUCCESS";

export const SMART_ID_LOGIN_REFRESH = "SMART_ID_LOGIN_REFRESH";
export const SMART_ID_LOGIN_REFRESH_SUCCESS = "SMART_ID_LOGIN_REFRESH_SUCCESS";

export const SMART_ID_SIGN_CM20_PAYMENT_FILE_ERROR = "SMART_ID_SIGN_CM20_PAYMENT_FILE_ERROR";

export const SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT = "SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT";
export const SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT_SUCCESS = "SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT_SUCCESS";

export const SMART_ID_SIGN_CM20_PAYMENT_FILE_FINALIZE_SUCCESS = "SMART_ID_SIGN_CM20_PAYMENT_FILE_FINALIZE_SUCCESS";

export const SMART_ID_SIGN_PAYMENT_FILE_ERROR = "SMART_ID_SIGN_PAYMENT_FILE_ERROR";

export const SMART_ID_SIGN_PAYMENT_FILE_INIT = "SMART_ID_SIGN_PAYMENT_FILE_INIT";
export const SMART_ID_SIGN_PAYMENT_FILE_INIT_SUCCESS = "SMART_ID_SIGN_PAYMENT_FILE_INIT_SUCCESS";

export const SMART_ID_SIGN_PAYMENT_FILE_FINALIZE_SUCCESS = "SMART_ID_SIGN_PAYMENT_FILE_FINALIZE_SUCCESS";