import * as actionTypes from "./actionTypes";

export const productGetAll = () => {
	return {
		type: actionTypes.PRODUCT_GET_ALL
	}
}

export const productGetById = (id) => {
	return {
		type: actionTypes.PRODUCT_GET_BY_ID,
		id: id
	}
}

export const productGetError = (thisError) => {
	return {
		type: actionTypes.PRODUCT_GET_ERROR,
		error: thisError
	}
}

export const productGetSuccess = (data) => {
	return {
		type: actionTypes.PRODUCT_GET_SUCCESS,
		products: data
	}
}

export const productCreate = (product, history) => {
	return {
		type: actionTypes.PRODUCT_CREATE,
		product: product, 
		history: history
	}
}

export const productCreateError = (thisError) => {
	return {
		type: actionTypes.PRODUCT_CREATE_ERROR,
		error: thisError
	}
}

export const productDelete = (id, history) => {
	return {
		type: actionTypes.PRODUCT_DELETE,
		id: id,
		history: history
	}
}