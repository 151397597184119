import * as formatUtils from 'helpers/formatUtils';

export const criterionId = {
	dataField: 'criterion.id',
	text: 'Criterion',
	formatter: (cell, row, rowIndex) => formatUtils.formatAllocationCriterion(row.criterion, row.bankStatementRecordId + "_" + row.id)
};
export const name = {
	dataField: 'name',
	text: 'Supplier',
};
export const allocatedAmountInfo = {
	dataField: 'allocatedAmountInfo',
	text: 'Allocated amount',
	align: "right",
	headerStyle: { textAlign: "right" },
};
export const advanceNo = {
	dataField: 'advanceNo',
	text: 'Advance No.'
};