import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as ExternalDataSyncType from 'definitions/enums/ExternalDataSyncType';
import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const createdDateTime = {
	dataField: 'createdDateTime',
	text: 'Scheduled on',
	filter: textFilter(),
	sort: true
};
export const requestedSyncDateTime = {
	dataField: 'requestedSyncDateTime',
	text: 'Start date/time',
	filter: textFilter(),
	formatter: (cell) => cell || "Anytime",
	sort: true
};
export const closedDateTime = {
	dataField: 'closedDateTime',
	text: 'Closed date time',
	filter: textFilter(),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const canceledDateTime = {
	dataField: 'canceledDateTime',
	text: 'Canceled date time',
	filter: textFilter(),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const expiredDateTime = {
	dataField: 'expiredDateTime',
	text: 'Expired date time',
	filter: textFilter(),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const syncType = {
	dataField: 'syncType',
	text: 'Type',
	filter: textFilter(),
	formatter: (cell) => {
		const filteredRows = ExternalDataSyncType.ExternalDataSyncType.filter(row => row.id === cell);
		return filteredRows.length ? filteredRows[0].description : ""
	},
	sort: true
};
export const closedByImportId = {
	dataField: 'closedByImportId',
	text: 'Closed by import ID',
	filter: textFilter(),
	sort: true
};
export const startDateTime = {
	dataField: 'importStartDateTime',
	text: 'Start date/time',
	filter: textFilter(),
	formatter: (cell, row) => (row.status === "EXPIRED" || row.status === "CANCELED") ? "" : (cell || row.requestedSyncDateTime || "Now"),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const finishDateTime = {
	dataField: 'importFinishDateTime',
	text: 'Finish date/time',
	filter: textFilter(),
	// formatter: (cell) => formatUtils.formatLocalDateTime(cell),
	// sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const status = {
	dataField: 'status',
	text: 'Status',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatScheduledTaskStatus(cell),
	sort: true
};
export const createdByUserFullName = {
	dataField: 'createdByUserFullName',
	text: 'Scheduled by',
	filter: textFilter(),
	sort: true
};
