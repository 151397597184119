import * as actionTypes from "./actionTypes";

export const allocationAutoAllocate = (bankStatementId, history) => {
	return {
		type: actionTypes.ALLOCATION_AUTO_ALLOCATE,
		bankStatementId: bankStatementId,
		history: history
	}
}

export const allocationConfirm = (bankStatementId, filter) => {
	return {
		type: actionTypes.ALLOCATION_CONFIRM,
		bankStatementId: bankStatementId,
		filter: filter
	}
}

export const allocationConfirmError = (error) => {
	return {
		type: actionTypes.ALLOCATION_CONFIRM_ERROR,
		error: error
	}
}

export const allocationConfirmSuccess = (bankStatementRecords, allocatedPayments, allocatedSupplierInvoices, allocatedSuppliers, allocatedCustomerInvoices, allocatedCustomers, allocatedBankCharges) => {
	return {
		type: actionTypes.ALLOCATION_CONFIRM_SUCCESS,
		bankStatementRecords: bankStatementRecords, 
		allocatedPayments: allocatedPayments,
		allocatedSupplierInvoices: allocatedSupplierInvoices,
		allocatedSuppliers: allocatedSuppliers,
		allocatedCustomerInvoices: allocatedCustomerInvoices,
		allocatedCustomers: allocatedCustomers,
		allocatedBankCharges: allocatedBankCharges
	}
}

export const allocationCreate = (allocation) => {
	return {
		type: actionTypes.ALLOCATION_CREATE,
		allocation: allocation
	}
}

export const allocationCreateError = (error) => {
	return {
		type: actionTypes.ALLOCATION_CREATE_ERROR,
		error: error
	}
}

export const allocationCreateSuccess = (updatedAllocation) => {
	return {
		type: actionTypes.ALLOCATION_CREATE_SUCCESS,
		updatedAllocation: updatedAllocation
	}
}

export const allocationDelete = (allocation) => {
	return {
		type: actionTypes.ALLOCATION_DELETE,
		allocation: allocation
	}
}

export const allocationDeleteError = (thisError) => {
	return {
		type: actionTypes.ALLOCATION_DELETE_ERROR,
		error: thisError
	}
}

export const allocationDeleteSuccess = (updatedAllocation) => {
	return {
		type: actionTypes.ALLOCATION_DELETE_SUCCESS,
		updatedAllocation: updatedAllocation
	}
}

export const allocationGetAll = (id) => {
	return {
		type: actionTypes.ALLOCATION_GET_ALL,
		id: id
	}
}

export const allocationGetByCustomerInvoice = (customerInvoiceId) => {
	return {
		type: actionTypes.ALLOCATION_GET_BY_CUSTOMER_INVOICE,
		customerInvoiceId: customerInvoiceId
	}
}

export const allocationGetError = (thisError) => {
	return {
		type: actionTypes.ALLOCATION_GET_ERROR,
		error: thisError
	}
}

export const allocationGetSuccess = (bankStatementRecords, allocatedPayments, allocatedSupplierInvoices, allocatedSuppliers, allocatedCustomerInvoices, allocatedCustomers, allocatedBankCharges) => {
	return {
		type: actionTypes.ALLOCATION_GET_SUCCESS,
		bankStatementRecords: bankStatementRecords, 
		allocatedPayments: allocatedPayments,
		allocatedSupplierInvoices: allocatedSupplierInvoices,
		allocatedSuppliers: allocatedSuppliers,
		allocatedCustomerInvoices: allocatedCustomerInvoices,
		allocatedCustomers: allocatedCustomers,
		allocatedBankCharges: allocatedBankCharges
	}
}

export const allocationRecordSet = (allocationRecord) => {
	return {
		type: actionTypes.ALLOCATION_RECORD_SET,
		allocationRecord: allocationRecord
	}
}

export const allocationRevert = (bankStatementRecordId) => {
	return {
		type: actionTypes.ALLOCATION_REVERT,
		bankStatementRecordId: bankStatementRecordId
	}
}

export const allocationRevertError = (error) => {
	return {
		type: actionTypes.ALLOCATION_REVERT_ERROR,
		error: error
	}
}

export const allocationRevertSuccess = (updatedAllocation) => {
	return {
		type: actionTypes.ALLOCATION_REVERT_SUCCESS,
		updatedAllocation: updatedAllocation
	}
}

export const allocationRevertAll = (bankStatementId) => {
	return {
		type: actionTypes.ALLOCATION_REVERT_ALL,
		bankStatementId: bankStatementId
	}
}

export const allocationRevertAllError = (error) => {
	return {
		type: actionTypes.ALLOCATION_REVERT_ALL_ERROR,
		error: error
	}
}

export const allocationRevertAllSuccess = (bankStatementRecords, allocatedPayments, allocatedSupplierInvoices, allocatedSuppliers, allocatedCustomerInvoices, allocatedCustomers, allocatedBankCharges) => {
	return {
		type: actionTypes.ALLOCATION_REVERT_ALL_SUCCESS,
		bankStatementRecords: bankStatementRecords, 
		allocatedPayments: allocatedPayments,
		allocatedSupplierInvoices: allocatedSupplierInvoices,
		allocatedSuppliers: allocatedSuppliers,
		allocatedCustomerInvoices: allocatedCustomerInvoices,
		allocatedCustomers: allocatedCustomers,
		allocatedBankCharges: allocatedBankCharges
	}
}

