import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Tenant/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsTenant from 'definitions/columns/tenant';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class TenantBrowse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date()
        }
    }

    componentDidMount() {
        this.props.onGetTenants();
    }

    handleChange = date => {
        this.setState({
            startDate: date,
        });
    }

    render() {

        const pageTitle = "Tenants | " + config.AppName;
        const breadcrumbsTitle = "Tenant";
        const breadcrumbsItem = "Browse tenants";

        const columns = [
            columnsTenant.id,
            columnsTenant.externalId,
            columnsTenant.name,
            columnsTenant.tenantLicenseInfo,
            columnsTenant.expirationDateTime,
            columnsTenant.admins
        ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.history.push(endpointsFrontend.TENANT_EDIT.replace(":id", row.id));
            }
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.tenants}
                    columns={columns}
                    pagination={paginationFactory()}
                    rowEvents={rowEvents}
		    rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                />
            </div>

        return (

            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {editFormControls.errorAlert(this.props.error)}

                                        {editFormControls.formLoadingSpinner(this.props.loading)}

                                        {!this.props.loading && this.props.tenants ?
                                            table
                                            : null}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );

    }
}

const mapStateToProps = ({ tenant }) => ({
    tenants: tenant.tenants,
    loading: tenant.loading,
    error: tenant.error
})

const mapDispatchToProps = dispatch => ({
    onGetTenants: () => dispatch(actions.tenantGetAll())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TenantBrowse));
