export const TAG_GET_ALL = "TAG_GET_ALL";
export const TAG_GET_BY_ID = "TAG_GET_BY_ID";
export const TAG_GET_ERROR = "TAG_GET_ERROR";
export const TAG_GET_SUCCESS = "TAG_GET_SUCCESS";

export const TAG_CREATE = "TAG_CREATE";
export const TAG_CREATE_ERROR = "TAG_CREATE_ERROR";
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS";

export const TAG_DELETE = "TAG_DELETE";
export const TAG_DELETE_ERROR = "TAG_DELETE_ERROR";
export const TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS";

