import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";

import user1 from "../../assets/images/users/avatar-0.png";

class ProfileMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			menu: false,
			name: "Admin",
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle() {
		this.setState(prevState => ({
			menu: !prevState.menu,
		}))
	}

	componentDidMount() {
		if (localStorage.getItem("authUser")) {
			const obj = JSON.parse(localStorage.getItem("authUser"))
			this.setState({ name: obj.username })
		}
	}

	render() {
		return (
			<React.Fragment>
				<Dropdown
					isOpen={this.state.menu}
					toggle={this.toggle}
					className="d-inline-block"
				>
					<DropdownToggle
						className="btn header-item"
						id="page-header-user-dropdown"
						tag="button"
					>
						<img
							className="rounded-circle header-profile-user"
							src={user1}
							alt="Header Avatar"
						/>{" "}
					</DropdownToggle>
					<DropdownMenu className="dropdown-menu-end">
						<Link to={endpointsFrontend.USER_ACCOUNT_PROFILE_EDIT} className="dropdown-item">
							<i className="bx bx-user font-size-16 align-middle me-1" />
							<span>{this.props.t("Profile")}</span>
						</Link>
						{/*
						<DropdownItem tag="a" href="#">
						<span className="badge bg-success float-end">11</span>
							<i className="bx bx-wrench font-size-17 align-middle me-1"/>
							{this.props.t("Settings")}
						</DropdownItem>
						*/}
						<div className="dropdown-divider" />
						<Link to={endpointsFrontend.USER_ACCOUNT_LOGOUT} className="dropdown-item">
							<i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
							<span>{this.props.t("Logout")}</span>
						</Link>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		)
	}
}

export default withRouter(withTranslation()(ProfileMenu))
