import * as actionTypes from "./actionTypes";

export const availableModuleGetAll = () => {
	return {
		type: actionTypes.AVAILABLE_MODULE_GET_ALL
	}
}

export const availableModuleGetInProcess = () => {
	return {
		type: actionTypes.AVAILABLE_MODULE_GET_IN_PROCESS
	}
}

export const availableModuleGetError = (thisError) => {
	return {
		type: actionTypes.AVAILABLE_MODULE_GET_ERROR,
		error: thisError
	}
}

export const availableModuleGetSuccess = (data) => {
	return {
		type: actionTypes.AVAILABLE_MODULE_GET_SUCCESS,
		availableModules: data
	}
}

export const availableModulesSetNone = () => {
	return {
		type: actionTypes.AVAILABLE_MODULE_SET_NONE
	}
}