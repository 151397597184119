import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* getAllSupplierBankAccountsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_BANK_ACCOUNT_GET_ALL
		);

		yield put(actions.supplierBankAccountGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierBankAccountGetError(e));
	}
}

function* getSupplierBankAccountByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_BANK_ACCOUNT_GET_BY_ID.replace("{supplierBankAccountId}", id)
		);

		yield put(actions.supplierBankAccountGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierBankAccountGetError(e));
	}
}

function* getSupplierBankAccountBySupplierSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_BANK_ACCOUNT_GET_ALL_BY_SUPPLIER_ID.replace("{supplierId}", id)
		);

		yield put(actions.supplierBankAccountGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierBankAccountGetError(e));
	}
}

function* createSupplierBankAccountSaga({ supplierBankAccount, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_BANK_ACCOUNT_CREATE_OR_UPDATE,
			supplierBankAccount
		);
		history.push(endpointsFrontend.SUPPLIER_BANK_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.supplierBankAccountCreateError(e));
	}
}

function* deleteSupplierBankAccountSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.SUPPLIER_BANK_ACCOUNT_GET_BY_ID.replace("{supplierBankAccountId}", id)
		);
		history.push(endpointsFrontend.SUPPLIER_BANK_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.supplierBankAccountCreateError(e));
	}
}

function* supplierBankAccountSaga() {
	yield (takeLatest(actionTypes.SUPPLIER_BANK_ACCOUNT_GET_ALL, getAllSupplierBankAccountsSaga));
	yield (takeLatest(actionTypes.SUPPLIER_BANK_ACCOUNT_GET_BY_ID, getSupplierBankAccountByIdSaga));
	yield (takeLatest(actionTypes.SUPPLIER_BANK_ACCOUNT_GET_BY_SUPPLIER, getSupplierBankAccountBySupplierSaga));
	yield (takeLatest(actionTypes.SUPPLIER_BANK_ACCOUNT_CREATE, createSupplierBankAccountSaga));
	yield (takeLatest(actionTypes.SUPPLIER_BANK_ACCOUNT_DELETE, deleteSupplierBankAccountSaga));
}

export default supplierBankAccountSaga;