import * as actionTypes from "./actionTypes";

const initialState = {
	customerAgreements: null,
	deleting: false,
	error: null,
	loading: false,
	saving: false
}

export default function customerAgreementReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CUSTOMER_AGREEMENT_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.CUSTOMER_AGREEMENT_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false,
			}
		case actionTypes.CUSTOMER_AGREEMENT_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saving: false
			}
		case actionTypes.CUSTOMER_AGREEMENT_DELETE:
			return {
				...state,
				error: null,
				deleting: true
			}
		case actionTypes.CUSTOMER_AGREEMENT_DELETE_ERROR:
			return {
				...state,
				error: action.error,
				deleting: false,
			}
		case actionTypes.CUSTOMER_AGREEMENT_DELETE_SUCCESS:
			return {
				...state,
				error: null,
				deleting: false
			}
		case actionTypes.CUSTOMER_AGREEMENT_GET_ALL:
		case actionTypes.CUSTOMER_AGREEMENT_GET_BY_ID:
		case actionTypes.CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.CUSTOMER_AGREEMENT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				customerAgreements: action.customerAgreements
			}
		case actionTypes.CUSTOMER_AGREEMENT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}