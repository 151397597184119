import {
	USER_PASSWORD_RESET_REQUEST,
	USER_PASSWORD_RESET_REQUEST_SUCCESS,
	USER_PASSWORD_RESET_REQUEST_ERROR,
	USER_PASSWORD_RESET,
	USER_PASSWORD_RESET_SUCCESS,
	USER_PASSWORD_RESET_ERROR,
} from "./actionTypes";

const initialState = {
	username: null,
	resetRequestError: null,
	resetRequestSuccessMessage: null,
	token: null,
	newPassword: null,
	resetError: null,
	resetSuccessMessage: null
}

const userPasswordReset = (state = initialState, action) => {
	switch (action.type) {
		case USER_PASSWORD_RESET_REQUEST:
			state = {
				...state,
				username: action.payload.username,
				resetRequestError: null,
				resetRequestSuccessMessage: null
			};
			break;
		case USER_PASSWORD_RESET_REQUEST_ERROR:
			state = {
				...state,
				resetRequestError: action.payload,
				resetRequestSuccessMessage: null
			}
			break;
		case USER_PASSWORD_RESET_REQUEST_SUCCESS:
			state = {
				...state,
				resetRequestError: null,
				resetRequestSuccessMessage: action.payload
			}
			break;
		case USER_PASSWORD_RESET:
			state = {
				...state,
				token: action.payload.token,
				newPassword: action.payload.newPassword,
				resetError: null,
				resetSuccessMessage: null
			};
			break;
		case USER_PASSWORD_RESET_ERROR:
			state = {
				...state,
				resetError: action.payload,
				resetSuccessMessage: null
			}
			break;
		case USER_PASSWORD_RESET_SUCCESS:
			state = {
				...state,
				resetError: null,
				resetSuccessMessage: action.payload
			}
			break;
		default:
			break;
	}
	return state;
}

export default userPasswordReset;