import * as actionTypes from "./actionTypes";

export const vatRateCreate = (vatRate, history) => {
	return {
		type: actionTypes.VAT_RATE_CREATE,
		vatRate: vatRate, 
		history: history
	}
}

export const vatRateCreateError = (thisError) => {
	return {
		type: actionTypes.VAT_RATE_CREATE_ERROR,
		error: thisError
	}
}

export const vatRateCreateSuccess = () => {
	return {
		type: actionTypes.VAT_RATE_CREATE_SUCCESS
	}
}

export const vatRateDelete = (id, history) => {
	return {
		type: actionTypes.VAT_RATE_DELETE,
		id: id,
		history: history
	}
}

export const vatRateDeleteError = (thisError) => {
	return {
		type: actionTypes.VAT_RATE_DELETE_ERROR,
		error: thisError
	}
}

export const vatRateDeleteSuccess = () => {
	return {
		type: actionTypes.VAT_RATE_DELETE_SUCCESS
	}
}

export const vatRateGetAll = () => {
	return {
		type: actionTypes.VAT_RATE_GET_ALL
	}
}

export const vatRateGetById = (id) => {
	return {
		type: actionTypes.VAT_RATE_GET_BY_ID,
		id: id
	}
}

export const vatRateGetError = (thisError) => {
	return {
		type: actionTypes.VAT_RATE_GET_ERROR,
		error: thisError
	}
}

export const vatRateGetSuccess = (data) => {
	return {
		type: actionTypes.VAT_RATE_GET_SUCCESS,
		vatRates: data
	}
}

