export const USER_ACCOUNT_DELETE = "USER_ACCOUNT_DELETE";
export const USER_ACCOUNT_DELETE_ERROR = "USER_ACCOUNT_DELETE_ERROR";

export const USER_ACCOUNT_GET_ALL = "USER_ACCOUNT_GET_ALL";
export const USER_ACCOUNT_GET_BY_ID = "USER_ACCOUNT_GET_BY_ID";
export const USER_ACCOUNT_GET_IN_PROCESS = "USER_ACCOUNT_GET_IN_PROCESS";
export const USER_ACCOUNT_GET_ERROR = "USER_ACCOUNT_GET_ERROR";
export const USER_ACCOUNT_GET_SUCCESS = "USER_ACCOUNT_GET_SUCCESS";

export const USER_ACCOUNT_INVITE = "USER_ACCOUNT_INVITE";
export const USER_ACCOUNT_INVITE_ERROR = "USER_ACCOUNT_INVITE_ERROR";
export const USER_ACCOUNT_INVITE_SUCCESS = "USER_ACCOUNT_INVITE_SUCCESS";

export const USER_ACCOUNT_UPDATE_RIGHTS = "USER_ACCOUNT_UPDATE_RIGHTS";
export const USER_ACCOUNT_UPDATE_RIGHTS_ERROR = "USER_ACCOUNT_UPDATE_RIGHTS_ERROR";
export const USER_ACCOUNT_UPDATE_RIGHTS_SUCCESS = "USER_ACCOUNT_UPDATE_RIGHTS_SUCCESS";
