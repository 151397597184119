import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import { userPasswordReset } from "../../store/actions";
import profile from "../../assets/images/cm-logo-transparent.png";

class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			internalErrorMessage: ""
		};

		this.handleValidSubmit = this.handleValidSubmit.bind(this);
	}

	handleValidSubmit(event, values) {
		if (values.password !== values.password_confirm) {
			this.setState({
				internalErrorMessage: "Passwords don't match"
			});
		} else {
			this.setState({
				internalErrorMessage: ""
			});
			if (this.props.match.params.token) {
				this.props.userPasswordReset(this.props.match.params.token, values.password, this.props.history);
			}
		}
	}

	render() {
		let errorMessageToDisplay = "";
		if (this.props.resetError) {
			errorMessageToDisplay = this.props.resetError.message ? this.props.resetError.message : "Error resetting the password";
		} else if (this.state.internalErrorMessage) {
			errorMessageToDisplay = this.state.internalErrorMessage;
		}

		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
										<img src={profile} alt="" className="img-fluid" />
									</div>
									<CardBody className="pt-0">
										<div className="p-2">
											{errorMessageToDisplay ? (
												<Alert color="danger" style={{ marginTop: "13px" }}>
													{errorMessageToDisplay}
												</Alert>
											) : null}
											{this.props.resetSuccessMessage ? (
												<Alert color="success" style={{ marginTop: "13px" }}>
													{this.props.resetSuccessMessage}
												</Alert>
											) : null}

											<AvForm
												className="form-horizontal"
												onValidSubmit={this.handleValidSubmit}
											>
												<br />
												Enter your new password
												<br />
												<br />
												<br />
												<div className="mb-3">
													<AvField
														name="password"
														label="Password"
														className="form-control"
														placeholder="Enter password"
														type="password"
														required
													/>
													<br />
													<AvField
														name="password_confirm"
														label="Confirm password"
														className="form-control"
														placeholder="Confirm password"
														type="password"
														required
													/>
												</div>
												<div className="text-end">
													<button
														className="btn btn-primary w-md"
														type="submit"
													>
														Reset password
													</button>
												</div>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Go back to{" "}
										<Link
											to={endpointsFrontend.USER_ACCOUNT_LOGIN}
											className="fw-medium text-primary"
										>
											Login
										</Link>{" "}
									</p>
									<p>
										Copyright © {new Date().getFullYear()} D.Vision SIA
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	const { resetError, resetSuccessMessage } = state.userPasswordReset;
	return { resetError, resetSuccessMessage };
};

export default withRouter(
	connect(mapStateToProps, { userPasswordReset })(PasswordReset)
);