import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* getAllVATRatesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.VAT_RATE_GET_ALL
		);

		yield put(actions.vatRateGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.vatRateGetError(e));
	}
}

function* getVATRateByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.VAT_RATE_GET_BY_ID.replace("{vatRateId}", id)
		);

		yield put(actions.vatRateGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.vatRateGetError(e));
	}
}

function* createVATRateSaga({ vatRate, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.VAT_RATE_CREATE_OR_UPDATE,
			vatRate
		);
		yield put(actions.vatRateCreateSuccess());
		history.push(endpointsFrontend.VAT_RATE_BROWSE);
	} catch (e) {
		yield put(actions.vatRateCreateError(e));
	}
}

function* deleteVATRateSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.VAT_RATE_GET_BY_ID.replace("{vatRateId}", id)
		);
		yield put(actions.vatRateDeleteSuccess());
		history.push(endpointsFrontend.VAT_RATE_BROWSE);
	} catch (e) {
		yield put(actions.vatRateDeleteError(e));
	}
}

function* vatRateSaga() {
	yield (takeLatest(actionTypes.VAT_RATE_GET_ALL, getAllVATRatesSaga));
	yield (takeLatest(actionTypes.VAT_RATE_GET_BY_ID, getVATRateByIdSaga));
	yield (takeLatest(actionTypes.VAT_RATE_CREATE, createVATRateSaga));
	yield (takeLatest(actionTypes.VAT_RATE_DELETE, deleteVATRateSaga));
}

export default vatRateSaga;