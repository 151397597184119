import * as actionTypes from "./actionTypes";

export const cm20PaymentFileDelete = (id, history) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_DELETE,
		id: id,
		history: history
	}
}

export const cm20PaymentFileDeleteList = (cm20PaymentFilesToDelete, history) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_DELETE_LIST,
		cm20PaymentFilesToDelete: cm20PaymentFilesToDelete,
		history: history
	}
}

export const cm20PaymentFileDeleteError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_DELETE_ERROR,
		error: thisError
	}
}

export const cm20PaymentFileDeleteSuccess = () => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_DELETE_SUCCESS
	}
}

export const cm20PaymentFileGetError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET_ERROR,
		error: thisError
	}
}

export const cm20PaymentFileGet = (filter) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET,
		filter: filter
	}
}

export const cm20PaymentFileGetSuccess = (data) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET_SUCCESS,
		cm20PaymentFiles: data
	}
}

export const cm20PaymentFileGetTotal = (selectedCM20PaymentFiles, history) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET_TOTAL,
		selectedCM20PaymentFiles: selectedCM20PaymentFiles,
		history: history
	}
}

export const cm20PaymentFileGetTotalError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET_TOTAL_ERROR,
		error: thisError
	}
}

export const cm20PaymentFileGetTotalSuccess = (total) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_GET_TOTAL_SUCCESS,
		total: total
	}
}

export const cm20PaymentFileRefreshStatus = (filter) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_REFRESH_STATUS,
		filter: filter
	}
}

export const cm20PaymentFileRefreshStatusError = () => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_REFRESH_STATUS_ERROR
	}
}

export const cm20PaymentFileRefreshStatusSuccess = () => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_REFRESH_STATUS_SUCCESS
	}
}

export const cm20PaymentFileSignGetCertificate = () => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_GET_CERTIFICATE
	}
}

export const cm20PaymentFileSignInit = (cm20PaymentFilesToSign, signingCertificate, signingCertificateObject, history) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_INIT,
		cm20PaymentFilesToSign: cm20PaymentFilesToSign,
		signingCertificate: signingCertificate,
		signingCertificateObject: signingCertificateObject,
		history: history
	}
}

export const cm20PaymentFileSignError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_ERROR,
		error: thisError
	}
}

export const cm20PaymentFileSignReset = (cm20PaymentFilesToReset, history) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_RESET,
		cm20PaymentFilesToReset: cm20PaymentFilesToReset,
		history: history
	}
}

export const cm20PaymentFileSignResetSuccess = (cm20PaymentFiles) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_RESET_SUCCESS,
		cm20PaymentFiles: cm20PaymentFiles
	}
}

export const cm20PaymentFileSignSuccess = (cm20PaymentFiles) => {
	return {
		type: actionTypes.CM20PAYMENT_FILE_SIGN_SUCCESS,
		cm20PaymentFiles: cm20PaymentFiles
	}
}

