//import { connect } from "react-redux";

//const addTenantAndCompanyToUrl = props => {
const addTenantAndCompanyToUrl = url => {
	// console.log("addTenantAndCompanyToUrl");		// Does not get output when the function is exported with connect()
	// console.log(props);
	const tenantExternalId = localStorage.getItem('tenantExternalId');
	const companyId = localStorage.getItem('companyId');

	//return props.url.replace("{tenantId}", props.tenantExternalId).replace("{companyId}", companyId);
	return url.replace("{tenantId}", tenantExternalId).replace("{companyId}", companyId);
}

// const mapStateToProps = state => {
// 	return {
// 		tenantExternalId: state.authLogin.tenantExternalId
// 	}
// }

//export default connect(mapStateToProps)(addTenantAndCompanyToUrl);	// Does not work
export default addTenantAndCompanyToUrl;