export const UNIT_GET_ALL = "UNIT_GET_ALL";
export const UNIT_GET_BY_ID = "UNIT_GET_BY_ID";
export const UNIT_GET_ERROR = "UNIT_GET_ERROR";
export const UNIT_GET_SUCCESS = "UNIT_GET_SUCCESS";

export const UNIT_CREATE = "UNIT_CREATE";
export const UNIT_CREATE_ERROR = "UNIT_CREATE_ERROR";
export const UNIT_CREATE_SUCCESS = "UNIT_CREATE_SUCCESS";

export const UNIT_DELETE = "UNIT_DELETE";
export const UNIT_DELETE_ERROR = "UNIT_DELETE_ERROR";
export const UNIT_DELETE_SUCCESS = "UNIT_DELETE_SUCCESS";

