export const generateOptionsFromData = (data, displayDataRowFunction, dataSortFunction, idField) => {
    if (!idField) {
        idField = "id";
    }
    if (data) {
        if (dataSortFunction) {
            return data.sort(dataSortFunction).map(dataRow => (
                <option
                    value={dataRow[idField]}
                    key={dataRow[idField]}
                >
                    {displayDataRowFunction(dataRow)}
                </option>
            ));
        } else {
            return data.map(dataRow => (
                <option
                    value={dataRow[idField]}
                    key={dataRow[idField]}
                >
                    {displayDataRowFunction(dataRow)}
                </option>
            ));
        }
    } else {
        return null;
    }
}

export const addNullOption = (options) => {
    if (options) {
        return [
            (<option
                value=""
                key="-1"
            >
            </option>),
            ...options
        ];
    } else {
        return null;
    }
}