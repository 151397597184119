export const ScheduledTaskStatus = [
	{ code: 0, id: "PREPARED", description: "Prepared" }, 
	{ code: 4, id: "CANCELED", description: "Canceled" }, 
	{ code: 5, id: "EXPIRED", description: "Expired" }, 
	{ code: 10, id: "STARTED", description: "Started" }, 
	{ code: 12, id: "STALLED", description: "Stalled" }, 
	{ code: 13, id: "FAILED", description: "Failed" }, 
	{ code: 20, id: "COMPLETED", description: "Completed" }, 
];

export const ScheduledTaskStatusAsSelectFilterOptions = {
	"PREPARED": "Prepared",
	"CANCELED": "Canceled",
	"EXPIRED": "Expired",
	"STARTED": "Started",
	"STALLED": "Stalled",
	"FAILED": "Failed",
	"COMPLETED": "Completed",
};