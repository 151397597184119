export const REPORT_OF_BANK_STATEMENT_DELETE = "REPORT_OF_BANK_STATEMENT_DELETE";
export const REPORT_OF_BANK_STATEMENT_ERROR = "REPORT_OF_BANK_STATEMENT_DELETE_ERROR";
export const REPORT_OF_BANK_STATEMENT_SUCCESS = "REPORT_OF_BANK_STATEMENT_DELETE_SUCCESS";

export const REPORT_OF_BANK_STATEMENT_DOWNLOAD = "REPORT_OF_BANK_STATEMENT_DOWNLOAD";
export const REPORT_OF_BANK_STATEMENT_DOWNLOAD_ERROR = "REPORT_OF_BANK_STATEMENT_DOWNLOAD_ERROR";
export const REPORT_OF_BANK_STATEMENT_DOWNLOAD_SUCCESS = "REPORT_OF_BANK_STATEMENT_DOWNLOAD_SUCCESS";

export const REPORT_OF_BANK_STATEMENT_GET_ALL = "REPORT_OF_BANK_STATEMENT_GET_ALL";
export const REPORT_OF_BANK_STATEMENT_GET_ERROR = "REPORT_OF_BANK_STATEMENT_GET_ERROR";
export const REPORT_OF_BANK_STATEMENT_GET_SUCCESS = "REPORT_OF_BANK_STATEMENT_GET_SUCCESS";

export const REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD = "REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD";
export const REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR = "REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR";
export const REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS = "REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS";

export const REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD = "REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD";
export const REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR = "REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR";
export const REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS = "REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS";
