import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";

function* createTaskSaga({ task, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.TASK_CREATE_OR_UPDATE,
			task
		);
		yield put(actions.taskCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.taskCreateError(e));
	}
}

function* deleteTaskSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.TASK_GET_BY_ID.replace("{taskId}", id)
		);
		yield put(actions.taskDeleteSuccess(id));
	} catch (e) {
		yield put(actions.taskDeleteError(e));
	}
}

function* getAllTasksSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TASK_GET_ALL
		);

		const rows = [];
		for (let key in response.data) {
			let tagsAsString = "";
			if (response.data[key].tags) {
				response.data[key].tags.map(tag => { tagsAsString += tag.title + ";"})
			}
			rows.push({
				...response.data[key],
				tagsAsString
			});
		}

		yield put(actions.taskGetSuccess(rows));
	} catch (e) {
		yield put(actions.taskGetError(e));
	}
}

function* getTaskByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TASK_GET_BY_ID.replace("{taskId}", id)
		);

		yield put(actions.taskGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.taskGetError(e));
	}
}

function* taskSaga() {
	yield (takeLatest(actionTypes.TASK_CREATE, createTaskSaga));
	yield (takeLatest(actionTypes.TASK_DELETE, deleteTaskSaga));
	yield (takeLatest(actionTypes.TASK_GET_ALL, getAllTasksSaga));
	yield (takeLatest(actionTypes.TASK_GET_BY_ID, getTaskByIdSaga));
}

export default taskSaga;