import * as actionTypes from "./actionTypes";

export const currencyGetAll = () => {
	return {
		type: actionTypes.CURRENCY_GET_ALL
	}
}

export const currencyGetById = (id) => {
	return {
		type: actionTypes.CURRENCY_GET_BY_ID,
		id: id
	}
}

export const currencyGetInProcess = () => {
	return {
		type: actionTypes.CURRENCY_GET_IN_PROCESS
	}
}

export const currencyGetError = (thisError) => {
	return {
		type: actionTypes.CURRENCY_GET_ERROR,
		error: thisError
	}
}

export const currencyGetSuccess = (data) => {
	return {
		type: actionTypes.CURRENCY_GET_SUCCESS,
		currencies: data
	}
}

export const currencyCreate = (currency, history) => {
	return {
		type: actionTypes.CURRENCY_CREATE,
		currency: currency, 
		history: history
	}
}

export const currencyCreateError = (thisError) => {
	return {
		type: actionTypes.CURRENCY_CREATE_ERROR,
		error: thisError
	}
}

export const currencyDelete = (id, history) => {
	return {
		type: actionTypes.CURRENCY_DELETE,
		id: id,
		history: history
	}
}