import * as actionTypes from "./actionTypes";

export const bankStatementDelete = (idList) => {
	return {
		type: actionTypes.BANK_STATEMENT_DELETE,
		idList: idList
	}
}

export const bankStatementDeleteError = (thisError) => {
	return {
		type: actionTypes.BANK_STATEMENT_DELETE_ERROR,
		error: thisError
	}
}

export const bankStatementDeleteSuccess = (bankStatements) => {
	return {
		type: actionTypes.BANK_STATEMENT_DELETE_SUCCESS,
		bankStatements: bankStatements
	}
}

export const bankStatementGetAll = () => {
	return {
		type: actionTypes.BANK_STATEMENT_GET_ALL
	}
}

export const bankStatementGetById = (id) => {
	return {
		type: actionTypes.BANK_STATEMENT_GET_BY_ID,
		id: id
	}
}

export const bankStatementGetError = (thisError) => {
	return {
		type: actionTypes.BANK_STATEMENT_GET_ERROR,
		error: thisError
	}
}

export const bankStatementGetSuccess = (data) => {
	return {
		type: actionTypes.BANK_STATEMENT_GET_SUCCESS,
		bankStatements: data
	}
}

export const bankStatementReceive = () => {
	return {
		type: actionTypes.BANK_STATEMENT_RECEIVE
	}
}

export const bankStatementReceiveError = () => {
	return {
		type: actionTypes.BANK_STATEMENT_RECEIVE_ERROR
	}
}

export const bankStatementReceiveSuccess = () => {
	return {
		type: actionTypes.BANK_STATEMENT_RECEIVE_SUCCESS
	}
}

export const bankStatementRefreshAll = () => {
	return {
		type: actionTypes.BANK_STATEMENT_REFRESH_ALL
	}
}

export const bankStatementRequest = (request) => {
	return {
		type: actionTypes.BANK_STATEMENT_REQUEST,
		request: request
	}
}

export const bankStatementRequestError = () => {
	return {
		type: actionTypes.BANK_STATEMENT_REQUEST_ERROR
	}
}

export const bankStatementRequestSuccess = () => {
	return {
		type: actionTypes.BANK_STATEMENT_REQUEST_SUCCESS
	}
}

export const bankStatementUpdate = () => {
	return {
		type: actionTypes.BANK_STATEMENT_UPDATE
	}
}

export const bankStatementUpload = (bankStatement) => {
	return {
		type: actionTypes.BANK_STATEMENT_UPLOAD,
		bankStatement: bankStatement
	}
}

export const bankStatementUploadError = (thisError) => {
	return {
		type: actionTypes.BANK_STATEMENT_UPLOAD_ERROR,
		error: thisError
	}
}

export const bankStatementUploadResultReset = () => {
	return {
		type: actionTypes.BANK_STATEMENT_UPLOAD_RESULT_RESET
	}
}

export const bankStatementUploadSuccess = (uploadResult) => {
	return {
		type: actionTypes.BANK_STATEMENT_UPLOAD_SUCCESS,
		uploadResult: uploadResult
	}
}