import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsTag from '../../store/Tag/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class TagEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            externalId: "",
            title: "",

            errors: {}
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedTag = {
            externalId: this.state.externalId,
            title: this.state.title,
        }
        if (this.state.id) {
            newOrUpdatedTag = {
                id: this.state.id,
                ...newOrUpdatedTag
            }
        }
        console.log(newOrUpdatedTag)
        this.props.onCreateTag(newOrUpdatedTag, this.props.history);
    }

    onDeleteTag = () => {
        if (window.confirm("Are you sure you want to delete this Tag?")) {
            this.props.onDeleteTag(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.onGetTagById(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.tags !== this.props.tags) {
            if (this.props.tags && this.props.tags[0]) {

                this.setState({
                    id: this.props.tags[0].id,
                    externalId: this.props.tags[0].externalId,
                    title: this.props.tags[0].title,
                });
            } else {
                this.setState({
                    id: "",
                    externalId: "",
                    title: "",
                });
            }
        }
    }

    render() {
        const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Tag | " + config.AppName;
        const breadcrumbsTitle = "Tag";
        const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Tag";

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">
                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.textControl("title", "Title", this.onChange, this.state.title)}
                                        {editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteTag, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.formLoadingSpinner(this.props.loading)}

                        {!this.props.loading ? editForm : null}
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ tag }) => ({
    tags: tag.tags,
    loading: tag.loading,
    error: tag.error
})

const mapDispatchToProps = dispatch => ({
    onGetTagById: (id) => dispatch(actionsTag.tagGetById(id)),
    onCreateTag: (tag, history) => dispatch(actionsTag.tagCreate(tag, history)),
    onDeleteTag: (id, history) => dispatch(actionsTag.tagDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TagEdit);
