import * as actionTypes from "./actionTypes";

export const countryGetAll = () => {
	return {
		type: actionTypes.COUNTRY_GET_ALL
	}
}

export const countryGetById = (id) => {
	return {
		type: actionTypes.COUNTRY_GET_BY_ID,
		id: id
	}
}

export const countryGetInProcess = () => {
	return {
		type: actionTypes.COUNTRY_GET_IN_PROCESS
	}
}

export const countryGetError = (thisError) => {
	return {
		type: actionTypes.COUNTRY_GET_ERROR,
		error: thisError
	}
}

export const countryGetSuccess = (data) => {
	return {
		type: actionTypes.COUNTRY_GET_SUCCESS,
		countries: data
	}
}

export const countryCreate = (country, history) => {
	return {
		type: actionTypes.COUNTRY_CREATE,
		country: country, 
		history: history
	}
}

export const countryCreateError = (thisError) => {
	return {
		type: actionTypes.COUNTRY_CREATE_ERROR,
		error: thisError
	}
}

export const countryDelete = (id, history) => {
	return {
		type: actionTypes.COUNTRY_DELETE,
		id: id,
		history: history
	}
}