export const InvoiceDuplicationStatus = [
	{ code: 1, id: "VALUE_NOT_SPECIFIED", description: "Value not specified" },
	{ code: 2, id: "FULL_DUPLICATES_IN_LIST", description: "Duplicate in list" },
	{ code: 3, id: "PARTIAL_DUPLICATES_IN_LIST", description: "Partial duplicate in list" },
	{ code: 6, id: "FULL_DUPLICATES_IN_DB", description: "Duplicate" },
	{ code: 7, id: "PARTIAL_DUPLICATES_IN_DB", description: "Partial duplicate" },
	{ code: 10, id: "NO_DUPLICATIONS", description: "OK" },
];

export const InvoiceDuplicationStatusAsSelectFilterOptions = {
	"VALUE_NOT_SPECIFIED": "Value not specified",
	"FULL_DUPLICATES_IN_LIST": "Duplicate in list",
	"PARTIAL_DUPLICATES_IN_LIST": "Partial duplicate in list",
	"FULL_DUPLICATES_IN_DB": "Duplicate",
	"PARTIAL_DUPLICATES_IN_DB": "Partial duplicate",
	"NO_DUPLICATIONS": "OK",
};

export const formatInvoiceDuplicationStatusColor = (status) => {
	switch (status) {
		case "VALUE_NOT_SPECIFIED":
			return "danger";
		case "FULL_DUPLICATES_IN_LIST":
			return "danger";
		case "PARTIAL_DUPLICATES_IN_LIST":
			return "warning";
		case "FULL_DUPLICATES_IN_DB":
			return "danger";
		case "PARTIAL_DUPLICATES_IN_DB":
			return "warning";
		case "NO_DUPLICATIONS":
			return "success";
		default:
			return "secondary";
	}
}

