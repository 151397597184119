import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row, Spinner } from "reactstrap";

class Breadcrumbs extends Component {
	render() {

		const spinner =
			<Spinner animation="border" size="sm" />

		const saveIndicator =
			<i className="bx bx-check" />

		// const closeButton =
		// 	<Link to={this.props.link || "#"}>
		// 		<h3>
		// 			<i className="bx bx-x" />
		// 		</h3>
		// 	</Link>

		return (
			<React.Fragment>
				<Row>
					<Col xs="12">
						<div className="page-title-box d-sm-flex align-items-center justify-content-between">
							<h4 className="mb-0 font-size-18">
								{this.props.breadcrumbItem}
								{" "}
								{this.props.saving ? spinner : null}
								{" "}
								{this.props.saved ? saveIndicator : null}
							</h4>
							<div className="page-title-right">
								{/*this.props.link ? closeButton : null*/}
								<Breadcrumb listClassName="m-0">
									<BreadcrumbItem>
										<Link to={this.props.link || "#"}>
											{this.props.title}
										</Link>
									</BreadcrumbItem>
									<BreadcrumbItem active>
										<Link to="#">
											{this.props.breadcrumbItem}
										</Link>
									</BreadcrumbItem>
								</Breadcrumb>
							</div>
						</div>
					</Col>
				</Row>
			</React.Fragment >
		)
	}
}

export default Breadcrumbs;