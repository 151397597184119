import * as actionTypes from "./actionTypes";

export const timelogCreate = (timelog, history) => {
	return {
		type: actionTypes.TIMELOG_CREATE,
		timelog: timelog, 
		history: history
	}
}

export const timelogCreateError = (thisError) => {
	return {
		type: actionTypes.TIMELOG_CREATE_ERROR,
		error: thisError
	}
}

export const timelogCreateSuccess = (timelogs) => {
	return {
		type: actionTypes.TIMELOG_CREATE_SUCCESS,
		timelogs: timelogs
	}
}

export const timelogDelete = (id, history) => {
	return {
		type: actionTypes.TIMELOG_DELETE,
		id: id,
		history: history
	}
}

export const timelogDeleteError = (thisError) => {
	return {
		type: actionTypes.TIMELOG_DELETE_ERROR,
		error: thisError
	}
}

export const timelogDeleteSuccess = () => {
	return {
		type: actionTypes.TIMELOG_DELETE_SUCCESS
	}
}

export const timelogGetAll = () => {
	return {
		type: actionTypes.TIMELOG_GET_ALL
	}
}

export const timelogGetById = (id) => {
	return {
		type: actionTypes.TIMELOG_GET_BY_ID,
		id: id
	}
}

export const timelogGetByTask = (taskId) => {
	return {
		type: actionTypes.TIMELOG_GET_BY_TASK,
		taskId: taskId
	}
}

export const timelogGetError = (thisError) => {
	return {
		type: actionTypes.TIMELOG_GET_ERROR,
		error: thisError
	}
}

export const timelogGetSuccess = (data) => {
	return {
		type: actionTypes.TIMELOG_GET_SUCCESS,
		timelogs: data
	}
}

