import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsCountry from '../../store/Country/actions';
import * as actionsCRMBankImplementation from '../../store/CRMBankImplementation/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsSupplierBankAccount from '../../store/SupplierBankAccount/actions';
import Backdrop from '../Common/Backdrop';
import * as browseFormControls from "../../helpers/browseFormControls";
import * as browseFormTools from '../../helpers/browseFormTools';
import * as columnsSupplierBankAccount from 'definitions/columns/supplierBankAccount';
import * as editFormControls from '../../helpers/editFormControls';

import classes from './Pages.module.css';
import { SupplierBankAccountStatusConstants } from 'definitions/enums/SupplierBankAccountStatus';

const fieldDefaultValues = {
    id: "",
    externalId: "",
    customerExternalId: "",
    forwardToExternalId: "",
    name: "",
    alternativeName: "",
    customCode: "",
    regNo: "",
    vatRegNo: "",
    addressCountryId: "",
    addressRegion: "",
    addressCity: "",
    addressPostCode: "",
    addressStreet: "",
    blocked: "",
    reference: "",
    contactEmail: "",
    contactFax: "",
    contactPhone: "",
    isIndividual: "",
    category: "",
    type: "",
}

class EditSupplierAndBankAccounts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,
            currentBankImplementation: null,
            showAllFields: false,
            supplierBankAccounts: [],
            changed: false,
            expandedRowKeys: []
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    crmTemplateToRegex(crmTemplate) {
        return crmTemplate.replaceAll("?", ".").replaceAll("*", ".*?");
    }

    detectBankImplementation(accountNo, bankSwiftCode) {
        let matchingImplementation = null;
        if (!this.props.crmBankImplementations || !this.props.crmBankImplementations.length || !accountNo) {
            return;
        }
        const matchesByIBAN = this.props.crmBankImplementations.filter(item => accountNo.match(this.crmTemplateToRegex(item.ibanTemplate)));
        if (matchesByIBAN && matchesByIBAN.length) {
            matchingImplementation = matchesByIBAN[0];
        } else if (bankSwiftCode && bankSwiftCode.length) {
            const matchesBySWIFTCode = this.props.crmBankImplementations.filter(item => bankSwiftCode.match(this.crmTemplateToRegex(item.bankSwiftCode)));
            if (matchesBySWIFTCode && matchesBySWIFTCode.length) {
                matchingImplementation = matchesBySWIFTCode[0];
            }
        }
        return matchingImplementation;
    }

    onChange(e) {
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        let thisObjectName = e.target.name;
        let bankAccountIdx = null;
        if (e.target.name.includes("[")) {
            thisObjectName = e.target.name.split("[")[0];
            bankAccountIdx = e.target.name.split("[")[1].split("]")[0];
        }
        if (bankAccountIdx) {
            const updatedSupplierBankAccounts = [];
            this.state.supplierBankAccounts.map(bankAccount => {
                if ((bankAccount.tempId == bankAccountIdx) || (bankAccount.tempId === bankAccountIdx)) {
                    updatedSupplierBankAccounts.push({
                        ...bankAccount,
                        [thisObjectName]: thisValue
                    });
                } else {
                    updatedSupplierBankAccounts.push(bankAccount);
                }
            });
            this.setState({
                supplierBankAccounts: updatedSupplierBankAccounts,
                changed: true
            });
        } else {
            this.setState({
                [thisObjectName]: thisValue,
                changed: true
            });
        }
    }

    setStateFromSupplier() {
        if (this.props.supplier && (this.props.supplier.id || this.props.supplier.name)) {  // supplier.name is supplied by supplier-invoice-candidate-browse when creating a supplier form an invoice candidate
            this.setState({
                id: this.props.supplier.id,
                externalId: this.props.supplier.externalId,
                customerExternalId: this.props.supplier.customerExternalId,
                forwardToExternalId: this.props.supplier.forwardToExternalId,
                name: this.props.supplier.name,
                alternativeName: this.props.supplier.alternativeName,
                customCode: this.props.supplier.customCode,
                regNo: this.props.supplier.regNo,
                vatRegNo: this.props.supplier.vatRegNo,
                addressCountryId: this.props.supplier.addressCountryId,
                addressRegion: this.props.supplier.addressRegion,
                addressCity: this.props.supplier.addressCity,
                addressPostCode: this.props.supplier.addressPostCode,
                addressStreet: this.props.supplier.addressStreet,
                blocked: this.props.supplier.blocked,
                reference: this.props.supplier.reference,
                contactEmail: this.props.supplier.contactEmail,
                contactFax: this.props.supplier.contactFax,
                contactPhone: this.props.supplier.contactPhone,
                isIndividual: this.props.supplier.isIndividual,
                category: this.props.supplier.category,
                type: this.props.supplier.type,
            });

            if (this.props.supplier.id) {
                // When selecting an existing supplier, its bank accounts are fetched from the backend.
                this.props.onGetSupplierBankAccounts(this.props.supplier.id);

                // See also componentDidUpdate()

            } else if (this.props.supplier.bankAccounts) {
                // supplier-invoice-candidate-browse provides supplier.bankAccounts when creating a supplier form an invoice candidate.
                this.setState({
                    supplierBankAccounts: this.enrichSupplierBankAccounts(this.props.supplier.bankAccounts)
                });

            }
        } else {
            this.setState({
                ...fieldDefaultValues,
                addressCountryId: (this.props.countries && this.props.countries.length ? this.props.countries[0].id : "")
            });
        }
        if (!this.props.crmBankImplementations || !this.props.crmBankImplementations.length) {
            this.props.onGetCRMBankImplementations();
        }
    }

    componentDidMount() {
        this.setStateFromSupplier();
        if (!this.props.countries || !this.props.countries.length) {
            this.props.onGetCountries();
        }
    }

    useCorrBank = (bankAccount) => {
        if ((bankAccount.corrAccountNo && bankAccount.corrAccountNo.length)
            && ((bankAccount.corrSwiftCode && bankAccount.corrSwiftCode.length)
                || (bankAccount.corrBankName && bankAccount.corrBankName.length))) {
            return true;
        } else {
            return false;
        }
    }

    compareAndEnrichSupplierBankAccounts = (existingBankAccounts, bankAccountsFromCandidate) => {
        const updatedAccounts = bankAccountsFromCandidate.map(bankAccount => {
            const existingAccountsWithSameNoArray = existingBankAccounts.filter(entry => entry.accountNo === bankAccount.accountNo);
            if (existingAccountsWithSameNoArray && existingAccountsWithSameNoArray.length) {
                const existingAccountsWithSameNo = existingAccountsWithSameNoArray[0];
                let status;
                if (existingAccountsWithSameNo.bankSwiftCode === bankAccount.bankSwiftCode
                    && existingAccountsWithSameNo.bankName === bankAccount.bankName) {
                    status = SupplierBankAccountStatusConstants.OK;
                } else {
                    status = SupplierBankAccountStatusConstants.UPDATED;
                }
                return ({
                    ...bankAccount,
                    id: existingAccountsWithSameNo.id,
                    tempId: existingAccountsWithSameNo.id,
                    useCorrBank: this.useCorrBank(bankAccount),
                    status: status
                });
            } else {
                return ({
                    ...bankAccount,
                    tempId: crypto.randomUUID(),
                    useCorrBank: this.useCorrBank(bankAccount),
                    status: SupplierBankAccountStatusConstants.NEW
                });
            }
        });
        const removedAccounts = [];
        for (let i in existingBankAccounts) {
            const entry1 = existingBankAccounts[i];
            const updatedAccountsWithSameNoArray = bankAccountsFromCandidate.filter(entry2 => entry1.accountNo === entry2.accountNo);
            if (!updatedAccountsWithSameNoArray || !updatedAccountsWithSameNoArray.length) {
                removedAccounts.push({
                    ...entry1,
                    tempId: entry1.id,
                    useCorrBank: this.useCorrBank(entry1),
                    status: SupplierBankAccountStatusConstants.NOT_FOUND
                });
            }
        }
        return [
            ...updatedAccounts,
            ...removedAccounts
        ]
        // if (updatedAccounts && updatedAccounts.length) {
        //     if (removedAccounts && removedAccounts.length) {
        //         return [
        //             ...updatedAccounts,
        //             ...removedAccounts
        //         ];
        //     } else {
        //         return updatedAccounts;
        //     }
        // } else if (removedAccounts && removedAccounts.length) {
        //     return removedAccounts;
        // } else {
        //     return [];
        // }
    }

    enrichSupplierBankAccounts = (supplierBankAccounts) => {
        return supplierBankAccounts.map(bankAccount => ({
            ...bankAccount,
            tempId: bankAccount.id ? bankAccount.id : crypto.randomUUID(),
            useCorrBank: this.useCorrBank(bankAccount)
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.countries !== this.props.countries && this.props.countries && this.props.countries.length) {
            this.setState({
                addressCountryId: this.props.countries[0].id
            });
        }

        if (prevProps.supplier !== this.props.supplier) {
            this.setStateFromSupplier();
        }

        if (prevProps.supplierBankAccounts !== this.props.supplierBankAccounts) {
            if (this.props.supplier.bankAccounts) {
                // supplier-invoice-candidate-browse will provide both supplier.id (which will trigger reloading of existing supplierBankAccounts from backend 
                // to this.props.supplierBankAccounts) and supplier.bankAccounts (the accounts received from the invoice candidate that we need to update
                // the backend with).
                this.setState({
                    supplierBankAccounts: this.compareAndEnrichSupplierBankAccounts(this.props.supplierBankAccounts, this.props.supplier.bankAccounts)
                });
            } else {
                this.setState({
                    supplierBankAccounts: this.enrichSupplierBankAccounts(this.props.supplierBankAccounts)
                });
            }
        }

        if (prevState.supplierBankAccounts !== this.state.supplierBankAccounts && this.state.supplierBankAccounts && this.state.supplierBankAccounts.length) {
            if (prevState.supplierBankAccounts.length < this.state.supplierBankAccounts.length) {
                // Detecting implementation for the newly added account
            } else if (prevState.supplierBankAccounts.length == this.state.supplierBankAccounts.length) {
                // Comparing all bank accounts and detecting implementation only for the changed one
                for (let i in prevState.supplierBankAccounts) {
                    const account = this.state.supplierBankAccounts[i];
                    if (prevState.supplierBankAccounts[i].accountNo !== account.accountNo
                        || prevState.supplierBankAccounts[i].bankSwiftCode !== account.bankSwiftCode) {

                        const implementation = this.detectBankImplementation(account.accountNo, account.bankSwiftCode);
                        if (implementation) {
                            const updatedBankAccounts = this.state.supplierBankAccounts;
                            updatedBankAccounts[i].bankSwiftCode = implementation.bankSwiftCode.replaceAll("*", "");
                            updatedBankAccounts[i].bankName = implementation.bankName.replaceAll("*", "");
                            this.setState({
                                supplierBankAccounts: updatedBankAccounts
                            });
                        }
                        break;
                    }
                }
            }
        }

        if (prevState.currentBankImplementation !== this.state.currentBankImplementation && this.state.currentBankImplementation) {
            const currentBankImplementation = this.state.currentBankImplementation;
            this.setState({
                bankName: currentBankImplementation.bankName,
                bankSwiftCode: currentBankImplementation.bankSwiftCode.replaceAll("*", "")
            });
        }

        // The following code is for focusing on invalid elements both for adding a new account and for checking account validity
        // (in both cases expandedRowKeys is modified).
        if (prevState.expandedRowKeys !== this.state.expandedRowKeys) {
            setTimeout(() => {
                const invalidElements = document.querySelectorAll('.is-invalid');
                if (invalidElements && invalidElements.length) {
                    invalidElements[0].focus();
                }
                const scrollableControlsDiv = document.getElementById("scrollableControlsDiv");
                if (scrollableControlsDiv) {
                    scrollableControlsDiv.scrollTop = scrollableControlsDiv.scrollTopMax;
                }
            }, 100);
        }
    }

    onAttemptCloseForm = (changed) => {
        if (changed) {
            if (window.confirm("Are you sure you want to discard changes?")) {
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        for (let i in this.state.supplierBankAccounts) {
            const bankAccount = this.state.supplierBankAccounts[i];
            if (!bankAccount.accountNo || !bankAccount.accountNo.length) {
                this.setState({
                    expandedRowKeys: [bankAccount.tempId]
                });
                return;
            };
        }
        let newOrUpdatedSupplier = {
            externalId: this.state.externalId,
            customerExternalId: this.state.customerExternalId,
            forwardToExternalId: this.state.forwardToExternalId,
            name: this.state.name,
            alternativeName: this.state.alternativeName,
            customCode: this.state.customCode,
            regNo: this.state.regNo,
            vatRegNo: this.state.vatRegNo,
            addressCountryId: this.state.addressCountryId,
            addressRegion: this.state.addressRegion,
            addressCity: this.state.addressCity,
            addressPostCode: this.state.addressPostCode,
            addressStreet: this.state.addressStreet,
            blocked: this.state.blocked,
            reference: this.state.reference,
            contactEmail: this.state.contactEmail,
            contactFax: this.state.contactFax,
            contactPhone: this.state.contactPhone,
            isIndividual: this.state.isIndividual,
            category: this.state.category,
            type: this.state.type,
            bankAccounts: this.state.supplierBankAccounts
        }
        if (this.state.id) {
            newOrUpdatedSupplier = {
                id: this.state.id,
                ...newOrUpdatedSupplier
            }
        }
        if (this.props.returnInvoiceCandidates) {
            this.props.onCreateOrUpdateSupplierAndMatchInvoiceCandidates(newOrUpdatedSupplier)
        } else {
            this.props.onCreateOrUpdateSupplier(newOrUpdatedSupplier);
        }
        this.setState({
            changed: false
        });
        this.onAttemptCloseForm(false);
    }

    addBankAccount = () => {
        const updatedBankAccounts = this.state.supplierBankAccounts;
        const newTempId = crypto.randomUUID();
        updatedBankAccounts.push({
            tempId: newTempId
        });
        this.setState({
            supplierBankAccounts: updatedBankAccounts,
            expandedRowKeys: [newTempId],
            changed: true
        });
    }

    editBankAccount = (tempId) => {
        this.setState({
            expandedRowKeys: [tempId]
        });
    }

    deleteBankAccount = (tempId) => {
        if (window.confirm("Are you sure you want to delete this bank account?")) {
            const updatedBankAccounts = this.state.supplierBankAccounts.filter(supplierBankAccount => supplierBankAccount.tempId !== tempId);
            this.setState({
                supplierBankAccounts: updatedBankAccounts,
                changed: true
            });
        }
    }
    deleteSupplier = () => {
        if (window.confirm("Are you sure you want to delete this supplier?")) {
            this.props.onDeleteSupplier(this.state.id);
            this.onAttemptCloseForm(false);
        }
    }

    commandButtons = (cell, row, rowIndex, formatExtraData) => {
        return (
            <React.Fragment>
                <div style={{ display: "flex" }}>
                    {editFormControls.roundButton("bx-edit-alt", "bg-secondary", () => this.editBankAccount(row.tempId))}
                    &nbsp;
                    {editFormControls.roundButton("bx-x", "bg-danger", () => this.deleteBankAccount(row.tempId))}
                </div>
            </React.Fragment>
        );
    };

    render() {

        const countryOptions = this.props.countries ? this.props.countries.map(countryRow => (
            <option
                value={countryRow.id}
                key={countryRow.id}
            >
                {countryRow.code} {countryRow.nameLang0 ? "(" + countryRow.nameLang0 + ")" : ""}
            </option>
        )) : null;

        const columns = browseFormTools.removeFiltersFromColumns([
            columnsSupplierBankAccount.accountNo,
            columnsSupplierBankAccount.bankSwiftCode,
            columnsSupplierBankAccount.bankName
        ]);
        if (this.props.supplier && this.props.supplier.id && this.props.supplier.bankAccounts) {
            columns.push(columnsSupplierBankAccount.status);
        }
        columns.push({
            dataField: "commandButtons",
            text: "",
            formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
        });

        const bankAlternativeCodeContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.bankAlternativeCodeContents && this.state.currentBankImplementation.bankAlternativeCodeContents.length > 1)
            ? this.state.currentBankImplementation.bankAlternativeCodeContents : null;

        const additionalInfoContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.additionalInfoContents && this.state.currentBankImplementation.additionalInfoContents.length > 1)
            ? this.state.currentBankImplementation.additionalInfoContents : null;

        const expandRow = {
            renderer: (row) => {
                return (
                    <div key={row.tempId}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td style={{ minWidth: "3rem" }}></td>
                                    <td>
                                        {/* {editFormControls.hiddenValueControl("id", this.onChange, row.id)} */}
                                        {editFormControls.textControl(`accountNo[${row.tempId}]`, "Account No.", this.onChange, row.accountNo, "", null, !row.accountNo || !row.accountNo.length)}
                                        {editFormControls.textControl(`bankSwiftCode[${row.tempId}]`, "Bank SWIFT-BIC", this.onChange, row.bankSwiftCode)}
                                        {bankAlternativeCodeContents && editFormControls.textControl(`bankAlternativeCode[${row.tempId}]`, bankAlternativeCodeContents, this.onChange, row.bankAlternativeCode, "Enter " + bankAlternativeCodeContents)}
                                        {editFormControls.textControl(`bankName[${row.tempId}]`, "Bank name", this.onChange, row.bankName)}
                                        {editFormControls.checkboxControl(`useCorrBank[${row.tempId}]`, "Use correspondent account", this.onChange, row.useCorrBank)}
                                        {row.useCorrBank && editFormControls.textControl(`corrAccountNo[${row.tempId}]`, "Corr. account No.", this.onChange, row.corrAccountNo)}
                                        {row.useCorrBank && editFormControls.textControl(`corrSwiftCode[${row.tempId}]`, "Corr. SWIFT-BIC", this.onChange, row.corrSwiftCode)}
                                        {row.useCorrBank && editFormControls.textControl(`corrBankName[${row.tempId}]`, "Corr. bank name", this.onChange, row.corrBankName)}
                                        {additionalInfoContents && editFormControls.textControl(`additionalInfo[${row.tempId}]`, additionalInfoContents, this.onChange, row.additionalInfo, "Enter " + additionalInfoContents)}
                                        {editFormControls.textControl(`externalId[${row.tempId}]`, "External ID", this.onChange, row.externalId)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            },
            showExpandColumn: true,
            onExpand: (row, isExpand, rowIndex, e) => {
                if (isExpand && !this.state.expandedRowKeys.includes(row.tempId)) {
                    const updatedExpandedRowKeys = [
                        ...this.state.expandedRowKeys,
                        row.tempId
                    ]
                    this.setState({
                        expandedRowKeys: updatedExpandedRowKeys
                    })
                }
                if (!isExpand && this.state.expandedRowKeys.includes(row.tempId)) {
                    const updatedExpandedRowKeys = this.state.expandedRowKeys.filter(rowKey => rowKey != row.tempId);
                    this.setState({
                        expandedRowKeys: updatedExpandedRowKeys
                    })
                }
            },
            //expandByColumnOnly: true,
            expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
            expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands),
            expanded: this.state.expandedRowKeys
        }

        const bankAccountTableAndControls =
            <div className="mt-3">
                <BootstrapTable
                    keyField='tempId'
                    data={this.state.supplierBankAccounts}
                    columns={columns}
                    expandRow={expandRow}
                />
                <div style={{ display: "flex" }}>
                    <Button
                        color="success"
                        size="sm"
                        onClick={this.addBankAccount}
                    >
                        <div style={{ display: "flex" }}>
                            <span
                                style={{ marginLeft: "-3px", marginRight: "6px", marginTop: "-3px", marginBottom: "-6px", fontSize: "18px" }}
                            >
                                <i className={"bx bxs-plus-circle"} />
                            </span>
                            <span>
                                New account
                            </span>
                        </div>
                    </Button>

                    {/* {editFormControls.roundButton("bx-plus", "bg-success", () => this.addBankAccount())} */}
                </div>
            </div>

        return (

            <div>
                <Backdrop
                    show
                    onClick={() => this.onAttemptCloseForm(this.state.changed)}
                >
                </Backdrop>
                <div className={classes.EditSupplierContainer}>
                    <Card className={classes.PageCard} style={{ height: "100%" }}>  {/* Setting height of PageCard in css is ignored */}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.supplier ? "Edit" : "Create"} supplier
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={() => this.onAttemptCloseForm(this.state.changed)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody
                            className={classes.EditSupplierContainerCardBody}
                            id="scrollableControlsDiv"
                        >

                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                {editFormControls.textControl("name", "Name", this.onChange, this.state.name, "Enter supplier name")}
                                {this.state.showAllFields && editFormControls.textControl("alternativeName", "Alternative name", this.onChange, this.state.alternativeName, "Enter supplier alternative name")}
                                {this.state.showAllFields && editFormControls.textControl("customCode", "Custom code", this.onChange, this.state.customCode, "Enter supplier custom code")}
                                {editFormControls.textControl("regNo", "Registration No.", this.onChange, this.state.regNo, "Enter supplier registration No.")}
                                {editFormControls.textControl("vatRegNo", "VAT registration No.", this.onChange, this.state.vatRegNo, "Enter supplier VAT registration No.")}
                                {editFormControls.selectControl("addressCountryId", "Address - Country", this.onChange, this.state.addressCountryId, countryOptions)}
                                {this.state.showAllFields && editFormControls.textControl("addressRegion", "Address - Region", this.onChange, this.state.addressRegion, "Enter supplier region")}
                                {this.state.showAllFields && editFormControls.textControl("addressCity", "Address - City", this.onChange, this.state.addressCity, "Enter supplier city")}
                                {this.state.showAllFields && editFormControls.textControl("addressPostCode", "Address - Postal code", this.onChange, this.state.addressPostCode, "Enter supplier postal code")}
                                {this.state.showAllFields && editFormControls.textControl("addressStreet", "Address - Street", this.onChange, this.state.addressStreet, "Enter supplier street address")}
                                {this.state.showAllFields && editFormControls.checkboxControl("blocked", "Blocked", this.onChange, this.state.blocked)}
                                {this.state.showAllFields && editFormControls.textControl("reference", "Reference", this.onChange, this.state.reference, "Enter supplier reference")}
                                {this.state.showAllFields && editFormControls.textControl("contactEmail", "Contact email", this.onChange, this.state.contactEmail, "Enter supplier contact email")}
                                {this.state.showAllFields && editFormControls.textControl("contactFax", "Contact fax", this.onChange, this.state.contactFax, "Enter supplier contact fax")}
                                {this.state.showAllFields && editFormControls.textControl("contactPhone", "Contact phone", this.onChange, this.state.contactPhone, "Enter supplier contact phone")}
                                {this.state.showAllFields && editFormControls.checkboxControl("isIndividual", "Is individual", this.onChange, this.state.isIndividual)}
                                {this.state.showAllFields && editFormControls.textControl("category", "Category", this.onChange, this.state.category, "Enter supplier category")}
                                {this.state.showAllFields && editFormControls.textControl("type", "Type", this.onChange, this.state.type, "Enter supplier type")}
                                {this.state.showAllFields && editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId, "Enter supplier external ID")}
                                {this.state.showAllFields && editFormControls.textControl("customerExternalId", "External ID as customer", this.onChange, this.state.customerExternalId, "Enter supplier external ID as customer")}
                                {this.state.showAllFields && editFormControls.textControl("forwardToExternalId", "Forward-to external ID", this.onChange, this.state.forwardToExternalId, "Enter supplier forward-to external ID")}

                                {editFormControls.mediumCheckboxControl("showAllFields", "Show all fields", this.onChange, this.state.showAllFields)}
                                <br />

                                {editFormControls.formLoadingSpinner(this.props.loading)}

                                {!this.props.loading && this.state.supplierBankAccounts ?
                                    bankAccountTableAndControls
                                    : null}

                                <Row>
                                    <Col style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "center"
                                    }}>
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        &nbsp;
                                        {editFormControls.deleteButton(this.props.deleting, () => this.deleteSupplier(), this.state.id)}
                                    </Col>
                                </Row>
                            </form>

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ crmBankImplementation, country, customer, supplier, supplierBankAccount }) => ({
    countries: country.countries,
    crmBankImplementations: crmBankImplementation.crmBankImplementations,
    customers: customer.customers,
    deleting: supplier.deleting,
    loadingCustomers: customer.loading,
    loadingSupplierBankAccounts: supplierBankAccount.loading,
    loadingSuppliers: supplier.loading,
    saving: supplier.saving,
    supplierBankAccounts: supplierBankAccount.supplierBankAccounts,
    suppliers: supplier.suppliers
});

const mapDispatchToProps = dispatch => ({
    onCreateOrUpdateSupplier: (supplier) => dispatch(actionsSupplier.supplierCreateWithBankAccounts(supplier)),
    onCreateOrUpdateSupplierAndMatchInvoiceCandidates: (supplier) => dispatch(actionsSupplier.supplierCreateAndMatchInvoiceCandidates(supplier)),
    onDeleteSupplier: (id) => dispatch(actionsSupplier.supplierDelete(id)),
    onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
    onGetCRMBankImplementations: () => dispatch(actionsCRMBankImplementation.crmBankImplementationGetAll()),
    onGetSupplierBankAccounts: (supplierId) => dispatch(actionsSupplierBankAccount.supplierBankAccountGetBySupplier(supplierId)),
    onGetSupplierById: (id) => dispatch(actionsSupplier.supplierGetById(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSupplierAndBankAccounts);