import * as actionTypes from "./actionTypes";

const initialState = {
	bankStatements: null,
	error: null,
	deleting: false,
	loading: false,
	receiving: false,
	requesting: false,
	updating: false,
	uploading: false,
	uploadResult: null,
	uploadResultAlreadyDisplayed: false
}

export default function bankStatementReducer(state = initialState, action) {
	switch (action.type) {

		case actionTypes.BANK_STATEMENT_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.BANK_STATEMENT_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.BANK_STATEMENT_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
				bankStatements: action.bankStatements
			}
		case actionTypes.BANK_STATEMENT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.BANK_STATEMENT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.BANK_STATEMENT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error,
				updating: false
			}
		case actionTypes.BANK_STATEMENT_GET_SUCCESS:
			return {
				...state,
				bankStatements: action.bankStatements,
				loading: false,
				updating: false
			}
		case actionTypes.BANK_STATEMENT_RECEIVE:
			return {
				...state,
				error: null,
				receiving: true
			}
		case actionTypes.BANK_STATEMENT_RECEIVE_ERROR:
			return {
				...state,
				error: action.error,
				receiving: false
			}
		case actionTypes.BANK_STATEMENT_RECEIVE_SUCCESS:
			return {
				...state,
				error: null,
				receiving: false
			}
		case actionTypes.BANK_STATEMENT_REFRESH_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.BANK_STATEMENT_REQUEST:
			return {
				...state,
				error: null,
				requesting: true
			}
		case actionTypes.BANK_STATEMENT_REQUEST_ERROR:
			return {
				...state,
				error: action.error,
				requesting: false
			}
		case actionTypes.BANK_STATEMENT_REQUEST_SUCCESS:
			return {
				...state,
				error: null,
				requesting: false
			}
		case actionTypes.BANK_STATEMENT_UPDATE:
			return {
				...state,
				updating: true
			}
		case actionTypes.BANK_STATEMENT_UPLOAD:
			return {
				...state,
				uploading: true,
				uploadResult: null,
				error: null
			}
		case actionTypes.BANK_STATEMENT_UPLOAD_ERROR:
			return {
				...state,
				uploading: false,
				error: action.error
			}
		case actionTypes.BANK_STATEMENT_UPLOAD_SUCCESS:
			return {
				...state,
				uploading: false,
				uploadResult: action.uploadResult
			}
		case actionTypes.BANK_STATEMENT_UPLOAD_RESULT_RESET:
			return {
				...state,
				uploadResult: null
			}
		default:
			return state;
	}
}