import { Buffer } from 'buffer';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* copyCustomerQuoteSaga({ customerQuoteId, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_COPY.replace("{customerQuoteId}", customerQuoteId)
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_QUOTE_EDIT.replace(":id", response.data.id));
		}
		yield put(actions.customerQuoteCopySuccess(response.data));
	} catch (e) {
		yield put(actions.customerQuoteCopyError(e));
	}
}

function* createCustomerQuoteSaga({ customerQuote, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_CREATE_OR_UPDATE,
			customerQuote
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_QUOTE_BROWSE);
		}
		yield put(actions.customerQuoteCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.customerQuoteCreateError(e));
	}
}

function* deleteCustomerQuoteSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_QUOTE_GET_BY_ID.replace("{customerQuoteId}", id)
		);
		yield put(actions.customerQuoteDeleteSuccess());
		history.push(endpointsFrontend.CUSTOMER_QUOTE_BROWSE);
	} catch (e) {
		yield put(actions.customerQuoteDeleteError(e));
	}
}

function* deleteCustomerQuoteListSaga({ customerQuotesToDelete }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_QUOTE_CREATE_OR_UPDATE,
			{ idList: customerQuotesToDelete }
		);
		yield put(actions.customerQuoteDeleteSuccess());
		yield call(getAllCustomerQuotesSaga);
	} catch (e) {
		yield put(actions.customerQuoteDeleteError(e));
	}
}

function* getAllCustomerQuotesSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_QUOTE_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerQuoteGetSuccess(entries));
	} catch (e) {
		yield put(actions.customerQuoteGetError(e));
	}
}

function* getCustomerQuoteByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_QUOTE_GET_BY_ID.replace("{customerQuoteId}", id)
		);

		yield put(actions.customerQuoteGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerQuoteGetError(e));
	}
}


function* getAllCustomerQuoteLinesSaga({ customerQuoteId }) {
	yield put(actions.customerQuoteLineGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_QUOTE_LINE_GET_ALL.replace("{customerQuoteId}", customerQuoteId)
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerQuoteLineGetSuccess(entries));
	} catch (e) {
		yield put(actions.customerQuoteLineGetError(e));
	}
}

function* getCustomerQuoteLineByIdSaga({ id }) {
	yield put(actions.customerQuoteLineGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_QUOTE_LINE_GET_BY_ID.replace("{customerQuoteLineId}", id)
		);

		yield put(actions.customerQuoteLineGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.customerQuoteLineGetError(e));
	}
}

function* createCustomerQuoteLineSaga({ customerQuoteLine, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_LINE_CREATE_OR_UPDATE,
			customerQuoteLine
		);
		if (history) {
			history.push(endpointsFrontend.CUSTOMER_QUOTE_EDIT.replace(":id", customerQuoteLine.customerQuoteId));
		}

		yield put(actions.customerQuoteLineCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.customerQuoteLineCreateError(e));
	}
}

function* moveUpCustomerQuoteLineSaga({ id }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_LINE_MOVE_UP.replace("{customerQuoteLineId}", id)
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerQuoteLineMoveSuccess(entries));
	} catch (e) {
		yield put(actions.customerQuoteLineGetError(e));
	}
}

function* moveDownCustomerQuoteLineSaga({ id }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_LINE_MOVE_DOWN.replace("{customerQuoteLineId}", id)
		);

		const entries = [];
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
		}

		yield put(actions.customerQuoteLineMoveSuccess(entries));
	} catch (e) {
		yield put(actions.customerQuoteLineGetError(e));
	}
}

function* copyCustomerQuoteLineSaga({ idList }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CUSTOMER_QUOTE_LINE_COPY,
			{ idList: idList }
		);

		const entries = [];
		let customerQuoteId = null;
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
			if (!customerQuoteId) {
				customerQuoteId = response.data[key].customerQuoteId;
			}
		}

		yield put(actions.customerQuoteLineMoveSuccess(entries));
		if (customerQuoteId) {
			yield call(getCustomerQuoteByIdSaga, { id: customerQuoteId });
		}
	} catch (e) {
		yield put(actions.customerQuoteLineGetError(e));
	}
}

function* deleteCustomerQuoteLineSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CUSTOMER_QUOTE_LINE_GET_BY_ID.replace("{customerQuoteLineId}", id)
		);
		history.goBack();
		//history.push(endpointsFrontend.CUSTOMER_QUOTE_LINE_BROWSE);
	} catch (e) {
		yield put(actions.customerQuoteLineCreateError(e));
	}
}

function* deleteCustomerQuoteLineListSaga({ idList }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.CUSTOMER_QUOTE_LINE_CREATE_OR_UPDATE,
			{ idList: idList }
		);

		const entries = [];
		let customerQuoteId = null;
		for (let key in response.data) {
			entries.push({
				...response.data[key]
			});
			if (!customerQuoteId) {
				customerQuoteId = response.data[key].customerQuoteId;
			}
		}

		yield put(actions.customerQuoteLineDeleteSuccess(entries));
		if (customerQuoteId) {
			yield call(getCustomerQuoteByIdSaga, { id: customerQuoteId });
		}
	} catch (e) {
		yield put(actions.customerQuoteLineDeleteError(e));
	}
}

function* printCustomerQuoteToPDFSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CUSTOMER_QUOTE_PRINT.replace("{customerQuoteId}", id)
		);
		if (response.data && response.data.contents && response.data.contents.length) {
			// Download the file.
			// It is encoded as AttachmentAsArray object in response.data,
			// with the "contents" property being encoded in Base64
			const filename = response.data.filename;
			const contentType = response.data.contentType;
			const contents = Buffer.from(response.data.contents, 'base64');

			const link = document.createElement("a");
			link.target = "_blank";
			link.download = filename;
			link.href = URL.createObjectURL(
				new Blob([contents], { type: contentType })
			);
			link.click();
		}
		yield put(actions.customerQuotePrintToPDFSuccess());
	} catch (e) {
		yield put(actions.customerQuotePrintToPDFError(e));
	}
}

function* customerQuoteSaga() {
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_COPY, copyCustomerQuoteSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_CREATE, createCustomerQuoteSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_DELETE, deleteCustomerQuoteSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_DELETE_LIST, deleteCustomerQuoteListSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_GET_ALL, getAllCustomerQuotesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_GET_BY_ID, getCustomerQuoteByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF, printCustomerQuoteToPDFSaga));

	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_GET_ALL, getAllCustomerQuoteLinesSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_GET_BY_ID, getCustomerQuoteLineByIdSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_CREATE, createCustomerQuoteLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_DELETE, deleteCustomerQuoteLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_DELETE_LIST, deleteCustomerQuoteLineListSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_MOVE_UP, moveUpCustomerQuoteLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_MOVE_DOWN, moveDownCustomerQuoteLineSaga));
	yield (takeLatest(actionTypes.CUSTOMER_QUOTE_LINE_COPY, copyCustomerQuoteLineSaga));

}

export default customerQuoteSaga;