import * as actionTypes from "./actionTypes";

export const allocationCriterionCreate = (allocationCriterion) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_CREATE,
		allocationCriterion: allocationCriterion, 
	}
}

export const allocationCriterionCreateError = (thisError) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_CREATE_ERROR,
		error: thisError
	}
}

export const allocationCriterionCreateSuccess = (allocationCriterion) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_CREATE_SUCCESS,
		allocationCriterion: allocationCriterion
	}
}

export const allocationCriterionDelete = (idList, allocationCriterionType) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_DELETE,
		idList: idList,
		allocationCriterionType: allocationCriterionType
	}
}

export const allocationCriterionDeleteError = (thisError) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_DELETE_ERROR,
		error: thisError
	}
}

export const allocationCriterionDeleteSuccess = (data) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_DELETE_SUCCESS,
		allocationCriteria: data
	}
}

export const allocationCriterionGetAll = (allocationCriterionType) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_GET_ALL,
        allocationCriterionType: allocationCriterionType
	}
}

export const allocationCriterionGetById = (id) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_GET_BY_ID,
		id: id
	}
}

export const allocationCriterionGetError = (thisError) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_GET_ERROR,
		error: thisError
	}
}

export const allocationCriterionGetSuccess = (data) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_GET_SUCCESS,
		allocationCriteria: data
	}
}

export const allocationCriterionMoveUp = (id) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_MOVE_UP,
		id: id
	}
}

export const allocationCriterionMoveDown = (id) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_MOVE_DOWN,
		id: id
	}
}

export const allocationCriterionMoveSuccess = (data) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_MOVE_SUCCESS,
		allocationCriteria: data
	}
}

export const allocationCriterionMoveError = (error) => {
	return {
		type: actionTypes.ALLOCATION_CRITERION_MOVE_ERROR,
		error: error
	}
}

