export const MatchingStatus = [
	{ code: 0, id: "MATCH_NOT_FOUND", description: "Not found" },
	{ code: 1, id: "VALUE_NOT_SPECIFIED", description: "Value not specified" },
	{ code: 2, id: "DATA_MISMATCH", description: "Data mismatch" },
	{ code: 10, id: "OK", description: "OK" },
];

export const MatchingStatusAsSelectFilterOptions = {
	"MATCH_NOT_FOUND": "Not found",
	"VALUE_NOT_SPECIFIED": "Value not specified",
	"DATA_MISMATCH": "Data mismatch",
	"OK": "OK",
};

export const formatMatchingStatusColor = (status, warningOnDataNotSpecified) => {
	switch (status) {
		case "MATCH_NOT_FOUND":
			return "danger";
		case "VALUE_NOT_SPECIFIED":
			return (warningOnDataNotSpecified ? "warning" : "danger");
		case "DATA_MISMATCH":
			return "warning";
		case "OK":
			return "success";
		default:
			return "secondary";
	}
}

