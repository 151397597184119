import * as actionTypes from "./actionTypes";

export const customerInvoiceGetAll = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_ALL
	}
}

export const customerInvoiceGetUnallocated = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_UNALLOCATED
	}
}

export const customerInvoiceGetOverdue = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_OVERDUE
	}
}

export const customerInvoiceGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_BY_ID,
		id: id
	}
}

export const customerInvoiceGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_ERROR,
		error: thisError
	}
}

export const customerInvoiceGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_GET_SUCCESS,
		customerInvoices: data
	}
}

export const customerInvoiceCopy = (customerInvoiceId, history) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_COPY,
		customerInvoiceId: customerInvoiceId, 
		history: history
	}
}

export const customerInvoiceCopySuccess = (customerInvoice) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_COPY_SUCCESS,
		customerInvoice: customerInvoice
	}
}

export const customerInvoiceCopyError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_COPY_ERROR,
		error: thisError
	}
}

export const customerInvoiceCreate = (customerInvoice, history) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_CREATE,
		customerInvoice: customerInvoice, 
		history: history
	}
}

export const customerInvoiceCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_CREATE_ERROR,
		error: thisError
	}
}

export const customerInvoiceCreateSuccess = (customerInvoice) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_CREATE_SUCCESS,
		customerInvoice: customerInvoice
	}
}

export const customerInvoiceDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_DELETE,
		id: id,
		history: history
	}
}

export const customerInvoiceDeleteList = (customerInvoicesToDelete) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_DELETE_LIST,
		customerInvoicesToDelete: customerInvoicesToDelete
	}
}

export const customerInvoiceDeleteError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_DELETE_ERROR,
		error: thisError
	}
}

export const customerInvoiceDeleteSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_DELETE_SUCCESS
	}
}

export const customerInvoicePrintToPDF = (id) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF,
		id: id
	}
}

export const customerInvoicePrintToPDFSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF_SUCCESS
	}
}

export const customerInvoicePrintToPDFError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF_ERROR,
		error: thisError
	}
}

export const customerInvoicePrintList = (idList) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_PRINT_LIST_TO_PDF,
		idList: idList
	}
}

export const customerInvoiceSendList = (customerInvoicesToSend) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_LIST,
		customerInvoicesToSend: customerInvoicesToSend
	}
}

export const customerInvoiceSendError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_ERROR,
		error: thisError
	}
}

export const customerInvoiceSendSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_SUCCESS
	}
}

export const customerInvoiceSendRemindersList = (customerInvoicesToSendReminders) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_LIST,
		customerInvoicesToSendReminders: customerInvoicesToSendReminders
	}
}

export const customerInvoiceSendRemindersError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_ERROR,
		error: thisError
	}
}

export const customerInvoiceSendRemindersSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_SUCCESS
	}
}



export const customerInvoiceLineGetAll = (customerInvoiceId) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_GET_ALL,
		customerInvoiceId: customerInvoiceId
	}
}

export const customerInvoiceLineGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_GET_BY_ID,
		id: id
	}
}

export const customerInvoiceLineGetInProcess = () => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_GET_IN_PROCESS
	}
}

export const customerInvoiceLineGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_GET_ERROR,
		error: thisError
	}
}

export const customerInvoiceLineGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_GET_SUCCESS,
		customerInvoiceLines: data
	}
}

export const customerInvoiceLineCreate = (customerInvoiceLine, history) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_CREATE,
		customerInvoiceLine: customerInvoiceLine, 
		history: history
	}
}

export const customerInvoiceLineCreateSuccess = (customerInvoiceLine) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_CREATE_SUCCESS,
		customerInvoiceLine: customerInvoiceLine
	}
}

export const customerInvoiceLineCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_CREATE_ERROR,
		error: thisError
	}
}

export const customerInvoiceLineMoveUp = (id) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_MOVE_UP,
		id: id
	}
}

export const customerInvoiceLineMoveDown = (id) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_MOVE_DOWN,
		id: id
	}
}

export const customerInvoiceLineCopy = (idList) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_COPY,
		idList: idList
	}
}

export const customerInvoiceLineMoveSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_MOVE_SUCCESS,
		customerInvoiceLines: data
	}
}

export const customerInvoiceLineMoveError = (error) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_MOVE_ERROR,
		error: error
	}
}

export const customerInvoiceLineDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_DELETE,
		id: id,
		history: history
	}
}

export const customerInvoiceLineDeleteList = (idList) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_DELETE_LIST,
		idList: idList
	}
}

export const customerInvoiceLineDeleteSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_DELETE_SUCCESS,
		customerInvoiceLines: data
	}
}

export const customerInvoiceLineDeleteError = (error) => {
	return {
		type: actionTypes.CUSTOMER_INVOICE_LINE_DELETE_ERROR,
		error: error
	}
}