export const PAYMENT_FILE_DELETE = "PAYMENT_FILE_DELETE";
export const PAYMENT_FILE_DELETE_LIST = "PAYMENT_FILE_DELETE_LIST";
export const PAYMENT_FILE_DELETE_ERROR = "PAYMENT_FILE_DELETE_ERROR";
export const PAYMENT_FILE_DELETE_SUCCESS = "PAYMENT_FILE_DELETE_SUCCESS";

export const PAYMENT_FILE_GET = "PAYMENT_FILE_GET";
export const PAYMENT_FILE_GET_ERROR = "PAYMENT_FILE_GET_ERROR";
export const PAYMENT_FILE_GET_SUCCESS = "PAYMENT_FILE_GET_SUCCESS";

export const PAYMENT_FILE_GET_TOTAL = "PAYMENT_FILE_GET_TOTAL";
export const PAYMENT_FILE_GET_TOTAL_ERROR = "PAYMENT_FILE_GET_TOTAL_ERROR";
export const PAYMENT_FILE_GET_TOTAL_SUCCESS = "PAYMENT_FILE_GET_TOTAL_SUCCESS";

export const PAYMENT_FILE_REFRESH_STATUS = "PAYMENT_FILE_REFRESH_STATUS";
export const PAYMENT_FILE_REFRESH_STATUS_ERROR = "PAYMENT_FILE_REFRESH_STATUS_ERROR";
export const PAYMENT_FILE_REFRESH_STATUS_SUCCESS = "PAYMENT_FILE_REFRESH_STATUS_SUCCESS";

export const PAYMENT_FILE_RESET_FAILED_BULK_LIST = "PAYMENT_FILE_RESET_FAILED_BULK_LIST";
export const PAYMENT_FILE_RESET_FAILED_BULK_ERROR = "PAYMENT_FILE_RESET_FAILED_BULK_ERROR";
export const PAYMENT_FILE_RESET_FAILED_BULK_SUCCESS = "PAYMENT_FILE_RESET_FAILED_BULK_SUCCESS";

export const PAYMENT_FILE_SIGN_GET_CERTIFICATE = "PAYMENT_FILE_SIGN_GET_CERTIFICATE";
export const PAYMENT_FILE_SIGN_INIT = "PAYMENT_FILE_SIGN_INIT";
export const PAYMENT_FILE_SIGN_ERROR = "PAYMENT_FILE_SIGN_ERROR";
export const PAYMENT_FILE_SIGN_RESET = "PAYMENT_FILE_SIGN_RESET";
export const PAYMENT_FILE_SIGN_RESET_SUCCESS = "PAYMENT_FILE_SIGN_RESET_SUCCESS";
export const PAYMENT_FILE_SIGN_SUCCESS = "PAYMENT_FILE_SIGN_SUCCESS";

