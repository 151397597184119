import { numberFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as AllocationCriterionTrustLevel from '../enums/AllocationCriterionTrustLevel';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const description = {
	dataField: 'hasAmount',
	text: 'Description',
	filter: textFilter(),
	sort: true,
	formatter: (cell, row) => formatUtils.formatAllocationCriterionDescriptionText(row)
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: textFilter(),
	sort: true
};
export const sequenceNo = {
	dataField: 'sequenceNo',
	text: 'Sequence No.',
	filter: textFilter(),
	sort: true
};
export const trustLevel = {
	dataField: 'trustLevel',
	text: 'Trust level',
	filter: selectFilter({
		options: AllocationCriterionTrustLevel.AllocationCriterionTrustLevelAsSelectFilterOptions
	}),
	sort: true,
	formatter: (cell) => formatUtils.formatAllocationCriterionTrustLevel(cell)
};