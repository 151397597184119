import * as actionTypes from "./actionTypes";

export const paymentGetAll = () => {
	return {
		type: actionTypes.PAYMENT_GET_ALL
	}
}

export const paymentGetById = (id) => {
	return {
		type: actionTypes.PAYMENT_GET_BY_ID,
		id: id
	}
}

export const paymentGetErroneous = () => {
	return {
		type: actionTypes.PAYMENT_GET_ERRONEOUS
	}
}

export const paymentGetError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_GET_ERROR,
		error: thisError
	}
}

export const paymentGetExportedToBankAsFile = () => {
	return {
		type: actionTypes.PAYMENT_GET_EXPORTED_TO_BANK_AS_FILE
	}
}

export const paymentGetInProcess = () => {
	return {
		type: actionTypes.PAYMENT_GET_IN_PROCESS
	}
}

export const paymentGetSentToBankSigned = () => {
	return {
		type: actionTypes.PAYMENT_GET_SENT_TO_BANK_SIGNED
	}
}

export const paymentGetSentToBankUnsigned = () => {
	return {
		type: actionTypes.PAYMENT_GET_SENT_TO_BANK_UNSIGNED
	}
}

export const paymentGetSigned = () => {
	return {
		type: actionTypes.PAYMENT_GET_SIGNED
	}
}

export const paymentGetUnallocated = () => {
	return {
		type: actionTypes.PAYMENT_GET_UNALLOCATED
	}
}

export const paymentGetSuccess = (data) => {
	return {
		type: actionTypes.PAYMENT_GET_SUCCESS,
		payments: data
	}
}

export const paymentGetTotal = (selectedPayments, history) => {
	return {
		type: actionTypes.PAYMENT_GET_TOTAL,
		selectedPayments: selectedPayments,
		history: history
	}
}

export const paymentGetTotalError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_GET_TOTAL_ERROR,
		error: thisError
	}
}

export const paymentGetTotalFromSimulated = (selectedInvoices, bankAccountId, currencyId, doGroupPayments, history) => {
	return {
		type: actionTypes.PAYMENT_GET_TOTAL_FROM_SIMULATED,
		selectedInvoices: selectedInvoices, 
		bankAccountId: bankAccountId,
		currencyId: currencyId,
		doGroupPayments: doGroupPayments,
		history: history
	}
}

export const paymentGetTotalSuccess = (total) => {
	return {
		type: actionTypes.PAYMENT_GET_TOTAL_SUCCESS,
		total: total
	}
}

export const paymentGetUnsigned = () => {
	return {
		type: actionTypes.PAYMENT_GET_UNSIGNED
	}
}

export const paymentCreate = (payment, history) => {
	return {
		type: actionTypes.PAYMENT_CREATE,
		payment: payment, 
		history: history
	}
}

export const paymentCreateSuccess = (payment) => {
	return {
		type: actionTypes.PAYMENT_CREATE_SUCCESS,
		payment: payment
	}
}

export const paymentCreateError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_CREATE_ERROR,
		error: thisError
	}
}

export const paymentDelete = (id, history) => {
	return {
		type: actionTypes.PAYMENT_DELETE,
		id: id,
		history: history
	}
}

export const paymentDeleteList = (paymentsToDelete, history) => {
	return {
		type: actionTypes.PAYMENT_DELETE_LIST,
		paymentsToDelete: paymentsToDelete,
		history: history
	}
}

export const paymentDeleteError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_DELETE_ERROR,
		error: thisError
	}
}

export const paymentDeleteSuccess = () => {
	return {
		type: actionTypes.PAYMENT_DELETE_SUCCESS
	}
}

export const paymentSendToBankList = (paymentsToSendToBank, history) => {
	return {
		type: actionTypes.PAYMENT_SEND_TO_BANK_LIST,
		paymentsToSendToBank: paymentsToSendToBank,
		history: history
	}
}

export const paymentSendToBankError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_SEND_TO_BANK_ERROR,
		error: thisError
	}
}

export const paymentSendToBankSuccess = (exportFile) => {
	return {
		type: actionTypes.PAYMENT_SEND_TO_BANK_SUCCESS,
		exportFile: exportFile
	}
}

export const paymentSignGetCertificate = () => {
	return {
		type: actionTypes.PAYMENT_SIGN_GET_CERTIFICATE
	}
}

export const paymentSignInit = (paymentsToSign, signingCertificateObject, history) => {
	return {
		type: actionTypes.PAYMENT_SIGN_INIT,
		paymentsToSign: paymentsToSign,
		signingCertificateObject: signingCertificateObject,
		history: history
	}
}

export const paymentSignError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_SIGN_ERROR,
		error: thisError
	}
}

export const paymentSignReset = (paymentsToReset, history) => {
	return {
		type: actionTypes.PAYMENT_SIGN_RESET,
		paymentsToReset: paymentsToReset,
		history: history
	}
}

export const paymentSignResetSuccess = (payments) => {
	return {
		type: actionTypes.PAYMENT_SIGN_RESET_SUCCESS,
		payments: payments
	}
}

export const paymentSignSuccess = (payments) => {
	return {
		type: actionTypes.PAYMENT_SIGN_SUCCESS,
		payments: payments
	}
}

export const paymentLineGetAll = () => {
	return {
		type: actionTypes.PAYMENT_LINE_GET_ALL
	}
}

export const paymentLineGetPrepared = () => {
	return {
		type: actionTypes.PAYMENT_LINE_GET_PREPARED
	}
}

export const paymentLineGetError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_LINE_GET_ERROR,
		error: thisError
	}
}

export const paymentLineGetSuccess = (data) => {
	return {
		type: actionTypes.PAYMENT_LINE_GET_SUCCESS,
		paymentLines: data
	}
}

