import * as actionTypes from "./actionTypes";

export const smartIdLoginError = (error) => {
	return {
		type: actionTypes.SMART_ID_LOGIN_ERROR,
        error: error
	}
}

export const smartIdLoginInit = (data, history, saveUserData) => {
	return {
		type: actionTypes.SMART_ID_LOGIN_INIT,
        data: data,
        history: history,
        saveUserData: saveUserData
	}
}

export const smartIdLoginInitSuccess = (data) => {
	return {
		type: actionTypes.SMART_ID_LOGIN_INIT_SUCCESS,
        data: data
	}
}

export const smartIdLoginFinalize = () => {
	return {
		type: actionTypes.SMART_ID_LOGIN_FINALIZE
	}
}

export const smartIdLoginFinalizeSuccess = (data) => {
	return {
		type: actionTypes.SMART_ID_LOGIN_FINALIZE_SUCCESS,
        data: data
	}
}

export const smartIdLoginRefresh = () => {
	return {
		type: actionTypes.SMART_ID_LOGIN_REFRESH
	}
}

export const smartIdLoginRefreshSuccess = (data) => {
	return {
		type: actionTypes.SMART_ID_LOGIN_REFRESH_SUCCESS,
		data: data
	}
}

export const smartIdSignCM20PaymentFileError = (error) => {
	return {
		type: actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_ERROR,
        error: error
	}
}

export const smartIdSignCM20PaymentFileInit = (data) => {
	return {
		type: actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT,
        data: data
	}
}

export const smartIdSignCM20PaymentFileInitSuccess = (data) => {
	return {
		type: actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT_SUCCESS,
        data: data
	}
}

export const smartIdSignCM20PaymentFileFinalizeSuccess = () => {
	return {
		type: actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_FINALIZE_SUCCESS
	}
}

export const smartIdSignPaymentFileError = (error) => {
	return {
		type: actionTypes.SMART_ID_SIGN_PAYMENT_FILE_ERROR,
        error: error
	}
}

export const smartIdSignPaymentFileInit = (data) => {
	return {
		type: actionTypes.SMART_ID_SIGN_PAYMENT_FILE_INIT,
        data: data
	}
}

export const smartIdSignPaymentFileInitSuccess = (data) => {
	return {
		type: actionTypes.SMART_ID_SIGN_PAYMENT_FILE_INIT_SUCCESS,
        data: data
	}
}

export const smartIdSignPaymentFileFinalizeSuccess = () => {
	return {
		type: actionTypes.SMART_ID_SIGN_PAYMENT_FILE_FINALIZE_SUCCESS
	}
}

