export const columnSelectionButton = (launchColumnSelectionForm) =>
    <p style={{
        width: "100%",
        textAlign: "right",
        marginBottom: "0px"
    }}
    >
        <i
            className={"bx bx-cog"}
            style={{
                fontSize: "1.1rem",
                cursor: "pointer"
            }}
            onClick={launchColumnSelectionForm}
        />
    </p>

export const expandColumnRenderer = (expanded/*, rowKey, expandable*/) => (
    expanded ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
);

export const expandHeaderColumnRenderer = (isAnyExpands) => (
    isAnyExpands ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
);