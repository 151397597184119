import * as actionTypes from "./actionTypes";

const initialState = {
    reportsOfBankStatement: null,
    deleting: false,
    downloading: false,
    loading: false,
    error: null
}

export default function reportOfBankStatementReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.REPORT_OF_BANK_STATEMENT_DELETE:
            return {
                ...state,
                deleting: true,
                error: null
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_ERROR:
            return {
                ...state,
                deleting: false,
                error: action.error
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_SUCCESS:
            return {
                ...state,
                deleting: false,
                reportsOfBankStatement: action.data
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD:
            return {
                ...state,
                error: null,
                downloading: true
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD_ERROR:
            return {
                ...state,
                downloading: false,
                error: action.error
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false,
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_GET_ALL:
            return {
                ...state,
                error: null,
                loading: true
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case actionTypes.REPORT_OF_BANK_STATEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                reportsOfBankStatement: action.data
            }
        case actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD:
        case actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD:
            return {
                ...state,
                error: null,
                downloading: true
            }
        case actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR:
        case actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_ERROR:
            return {
                ...state,
                downloading: false,
                error: action.error
            }
        case actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS:
        case actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false,
            }
        default:
            return state;
    }
}