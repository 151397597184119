import React, { Component } from "react";
// import BootstrapTable from 'react-bootstrap-table-next';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsPayment from '../../store/Payment/actions';
import * as actionsPaymentBulk from '../../store/PaymentBulk/actions';
// import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsSupplierBankAccount from '../../store/SupplierBankAccount/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ChargeBearer } from "definitions/enums/ChargeBearer";
// import * as columnsPayment from "definitions/columns/payment";
import * as config from '../../config';
// import * as dateAndTimeUtils from '../../helpers/dateAndTimeUtils';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import { PaymentUrgency } from "definitions/enums/PaymentUrgency";

class PaymentEdit extends Component {

    constructor(props) {
        super(props);

        const defaultPaymentDate = new Date();					// today's date
        const defaultDueDate = new Date();
        defaultDueDate.setDate(defaultPaymentDate.getDate() + 7);	// today's date + 7

        this.state = {
            id: "",
            supplierId: "",
            supplierName: "",
            supplierBankAccountId: "",
            // supplierBankAccountNo: "",
            bankAccountId: "",
            // bankAccountNo: "",
            currencyId: "",
            currencyCode: "",
            urgency: "NURG",
            chargeBearer: "DEBT",
            paymentDate: defaultDueDate.toJSON().substring(0, 10),
            paymentDetails: "",
            totalAmount: 0,
            advanceNo: "",

            payments: [],
            errors: {},
            changed: false
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    filterSupplierBankAccountsBySelectedSupplier = (supplierId) => {
        return this.props.supplierBankAccounts
            ? this.props.supplierBankAccounts.filter(
                supplierBankAccountRow => supplierBankAccountRow.supplierId == supplierId)
            : null;
    }

    setSupplierBankAccountIdBySelectedSupplier = (supplierId) => {
        const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(supplierId);
        this.setState({
            supplierBankAccountId: supplierBankAccountsFiltered.length ? supplierBankAccountsFiltered[0].id : null
        });
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        if (thisObjectName === "supplierId") {
            this.setSupplierBankAccountIdBySelectedSupplier(thisValue);
        }
        this.setState({
            [thisObjectName]: thisValue,
            changed: true
        });
    }

    preparePaymentForInsertOrUpdate = () => {
        const payments = [];
        for (let i in this.state.payments) {
            const payment = this.state.payments[i];
            payments.push({
                id: payment.id,
                paymentDetails: this.state.paymentDetails
            });
        }
        let newOrUpdatedPayment = {
            bankAccountId: this.state.bankAccountId,
            // bankAccountNo: this.state.bankAccountNo,
            // currencyCode: this.state.currencyCode,
            currencyId: this.state.currencyId,
            paymentDate: this.state.paymentDate,
            paymentDetails: this.state.paymentDetails,
            supplierBankAccountId: this.state.supplierBankAccountId,
            // supplierBankAccountNo: this.state.supplierBankAccountNo,
            supplierId: this.state.supplierId,
            // supplierName: this.state.supplierName,
            urgency: this.state.urgency,
            chargeBearer: this.state.chargeBearer,
            totalAmount: this.state.totalAmount,
            advanceNo: this.state.advanceNo,
            payments: payments
        }
        if (this.state.id) {
            newOrUpdatedPayment = {
                id: this.state.id,
                ...newOrUpdatedPayment
            }
        }

        return newOrUpdatedPayment;
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        if (!this.state.id && !(this.state.paymentDate && this.state.totalAmount)) {
            return;
        }
        const newOrUpdatedPayment = this.preparePaymentForInsertOrUpdate();
        this.props.onEditPayment(newOrUpdatedPayment, this.props.history);	// Update the payment and return to Browse Payments
        this.setState({
            changed: false
        });
    }

    // onAddNewLine = () => {
    // 	const newOrUpdatedPayment = this.preparePaymentForInsertOrUpdate();
    // 	if (this.state.changed) {
    // 		this.props.onEditPayment(newOrUpdatedPayment, null);	// Update the payment without returing to Browse Payments
    // 	}
    // 	this.props.history.push(endpointsFrontend.PAYMENT_LINE_NEW.replace(":paymentid", this.state.id));
    // }

    onDeletePayment = () => {
        if (window.confirm("Are you sure you want to delete this payment?")) {
            this.props.onDeletePayment(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.onGetPaymentBulkById(id);
        this.props.onGetPaymentLines(id);
        this.props.onGetBankAccounts();
        this.props.onGetCurrencies();
        // this.props.onGetSuppliers();
        this.props.onGetSupplierBankAccounts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.bankAccounts !== this.props.bankAccounts) {
            if (!this.state.bankAccountId && this.props.bankAccounts[0]) {
                this.setState({
                    bankAccountId: this.props.bankAccounts[0].id
                })
            }
        }

        if (prevProps.currencies !== this.props.currencies) {
            if (!this.state.currencyId && this.props.currencies[0]) {
                this.setState({
                    currencyId: this.props.currencies[0].id,
                    // currencyCode: this.props.currencies[0].code
                })
            }
        }

        if (prevProps.suppliers !== this.props.suppliers) {
            if (!this.state.supplierId && this.props.suppliers[0]) {
                this.setState({
                    supplierId: this.props.suppliers[0].id
                });
            }
        }

        if (prevProps.supplierBankAccounts !== this.props.supplierBankAccounts) {
            if (!this.state.supplierBankAccountId && this.props.supplierBankAccounts[0]) {
                this.setState({
                    supplierBankAccountId: this.props.supplierBankAccounts[0].id
                })
            }
        }

        if (prevProps.paymentBulk !== this.props.paymentBulk) {
            if (this.props.paymentBulk) {
                this.setState({
                    id: this.props.paymentBulk.id,
                    supplierId: this.props.paymentBulk.payments[0].supplierId,
                    supplierName: this.props.paymentBulk.supplierName,
                    supplierBankAccountId: this.props.paymentBulk.supplierBankAccountId,
                    // supplierBankAccountNo: this.props.paymentBulk.supplierBankAccountNo,
                    // bankAccountNo: this.props.paymentBulk.bankAccountNo,
                    currencyCode: this.props.paymentBulk.currencyCode,
                    paymentDetails: this.props.paymentBulk.paymentDetails,
                    bankAccountId: this.props.paymentBulk.bankAccountId,
                    currencyId: this.props.paymentBulk.currencyId,
                    paymentDate: this.props.paymentBulk.paymentDate,
                    urgency: this.props.paymentBulk.urgency,
                    chargeBearer: this.props.paymentBulk.chargeBearer,
                    totalAmount: this.props.paymentBulk.totalAmount,
                    advanceNo: this.props.paymentBulk.advanceNo,
                    payments: this.props.paymentBulk.payments
                });
            } else {
                this.setState({
                    id: "",
                    supplierId: "",
                    supplierName: "",
                    // supplierBankAccountNo: "",
                    // bankAccountNo: "",
                    currencyCode: "",
                    paymentDetails: "",
                    bankAccountId: "",
                    currencyId: "",
                    paymentDate: "",
                    urgency: "",
                    chargeBearer: "",
                    totalAmount: 0,
                    advanceNo: "",
                    payments: []
                });
            }
        }
    }

    render() {
        const pageTitle = "Edit payment | " + config.AppName;
        const breadcrumbsTitle = "Payment";
        const breadcrumbsItem = "Edit payment";
        const returnLink = endpointsFrontend.PAYMENT_BROWSE_PREPARED;

        const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(this.state.supplierId);

        const bankAccountOptions = inputSelectUtils.generateOptionsFromData(this.props.bankAccounts, bankAccountRow => bankAccountRow.accountNo);
        const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
        // const supplierOptions = inputSelectUtils.generateOptionsFromData(this.props.suppliers, supplierRow => supplierRow.name);
        const supplierBankAccountOptions = inputSelectUtils.generateOptionsFromData(supplierBankAccountsFiltered, supplierBankAccountRow => supplierBankAccountRow.accountNo);
        const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(ChargeBearer, chargeBearerRow => chargeBearerRow.description);
        const urgencyOptions = inputSelectUtils.generateOptionsFromData(PaymentUrgency, row => row.description);

        // const columnEvents = {
        // 	onClick: (e, column, columnIndex, row, rowIndex) => {
        // 		// if (row.id) {
        // 		// 	this.props.history.push(endpointsFrontend.PAYMENT_LINE_EDIT.replace(":id", row.id));
        // 		// }
        // 	}
        // }

        // const columns = [
        // 	{
        // 		dataField: 'position',
        // 		text: 'Position',
        // 		events: columnEvents
        // 	},
        // 	{
        // 		dataField: 'description2',
        // 		text: 'Add. description',
        // 		events: columnEvents
        // 	},
        // 	{
        // 		dataField: 'unitNameLang0',
        // 		text: 'Unit',
        // 		events: columnEvents
        // 	},
        // 	{
        // 		dataField: 'quantity',
        // 		text: 'Quantity',
        // 		events: columnEvents,
        // 		headerStyle: { textAlign: "right" },
        // 		align: "right",
        // 	},
        // 	{
        // 		dataField: 'price',
        // 		text: 'Price',
        // 		events: columnEvents,
        // 		headerStyle: { textAlign: "right" },
        // 		align: "right",
        // 		formatter: (cell, row) => {
        // 			// return cell.toLocaleString('de-DE', { style: 'currency', currency: row.currencyCode }) 	// this also works but returns a currency symbol in the result
        // 			return cell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        // 		}
        // 	},
        // 	{
        // 		dataField: 'discount',
        // 		text: 'Discount, %',
        // 		events: columnEvents,
        // 		headerStyle: { textAlign: "right" },
        // 		align: "right",
        // 		formatter: (cell, row) => {
        // 			// return cell.toLocaleString('de-DE', { style: 'currency', currency: row.currencyCode }) 	// this also works but returns a currency symbol in the result
        // 			return cell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        // 		}
        // 	},
        // 	{
        // 		dataField: 'totalAmountWithoutVAT',
        // 		text: 'Amount excl. VAT',
        // 		events: columnEvents,
        // 		headerStyle: { textAlign: "right" },
        // 		align: "right",
        // 		formatter: (cell, row) => {
        // 			// return cell.toLocaleString('de-DE', { style: 'currency', currency: row.currencyCode }) 	// this also works but returns a currency symbol in the result
        // 			return cell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        // 		}
        // 	},
        // 	{
        // 		dataField: 'vatRate',
        // 		text: 'VAT rate, %',
        // 		events: columnEvents,
        // 		headerStyle: { textAlign: "right" },
        // 		align: "right",
        // 		events: columnEvents,
        // 		formatter: (cell, row) => {
        // 			// return cell.toLocaleString('de-DE', { style: 'currency', currency: row.currencyCode }) 	// this also works but returns a currency symbol in the result
        // 			return cell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        // 		}
        // 	},
        // 	// {
        // 	// 	dataField: "commandButtons",
        // 	// 	text: "",
        // 	// 	formatter: this.commandButtons
        // 	// }
        // ];

        // const rowEvents = {
        // 	onClick: (e, row, rowIndex) => {
        // 		this.props.history.push(endpointsFrontend.PAYMENT_LINE_EDIT.replace(":id", row.id));
        // 	}
        // };

        // const addNewLineButton =
        // 	<Button
        // 		color="primary"
        // 		onClick={this.onAddNewLine}
        // 	>
        // 		Add new line
        // 	</Button>

        // const paymentLines = this.props.match.params.id ? this.props.paymentLines : [];		// necessary, because this.props.paymentLines might be filled with previously viewed payment lines (observed 2022-05-26)

        const loading = this.props.loading
            // || this.props.loadingLines
            || this.props.loadingBankAccounts
            // || this.props.loadingCurrencies 
            // || this.props.loadingSuppliers
            || this.props.loadingSupplierBankAccounts;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.staticTextControl("supplierId", "Supplier", this.state.supplierName)}
                                        {editFormControls.selectControl("supplierBankAccountId", "Supplier bank account", this.onChange, this.state.supplierBankAccountId, supplierBankAccountOptions, false, !supplierBankAccountsFiltered)}
                                        {editFormControls.dateControl("paymentDate", "Payment execution date", this.onChange, this.state.paymentDate, false, !this.state.paymentDate)}
                                        {editFormControls.selectControl("bankAccountId", "Account to pay from", this.onChange, this.state.bankAccountId, bankAccountOptions)}
                                        {editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}
                                        {editFormControls.selectControl("chargeBearer", "Charge bearer", this.onChange, this.state.chargeBearer, chargeBearerOptions)}
                                        {editFormControls.selectControl("urgency", "Urgency", this.onChange, this.state.urgency, urgencyOptions)}
                                        {editFormControls.textAreaControl("paymentDetails", "Payment details", this.onChange, this.state.paymentDetails)}

                                        {/*this.state.id ? addNewLineButton : null*/}

                                        {/* {this.state.id && !loading ? table : null} */}

                                        {editFormControls.staticTextControl("totalAmount", "Total amount to pay, " + this.state.currencyCode, formatUtils.formatAmount(this.state.totalAmount))}

                                        {this.props.companySettings && this.props.companySettings.useAdvanceNo && editFormControls.staticTextControl("advanceNo", "Advance No.", this.state.advanceNo)}
                                    </div>
                                </div>

                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}

                                        <span> </span>

                                        {editFormControls.deleteButton(this.props.deleting, this.onDeletePayment, this.state.id)}
                                    </Col>
                                </Row>
                            </form>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs
                            title={breadcrumbsTitle}
                            breadcrumbItem={breadcrumbsItem}
                            link={returnLink}
                        />

                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading ? editForm : null}

                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ bankAccount, companySettings, currency, payment, paymentBulk, supplier, supplierBankAccount }) => ({
    bankAccounts: bankAccount.bankAccounts,
    companySettings: companySettings.companySettings,
    currencies: currency.currencies,
    deleting: payment.deleting,
    error: paymentBulk.error,
    errorLines: payment.errorLines,
    loading: paymentBulk.loading,
    loadingLines: payment.loadingLines,
    loadingBankAccounts: bankAccount.loading,
    // loadingCurrencies: currency.loading,
    // loadingSuppliers: supplier.loading,
    loadingSupplierBankAccounts: supplierBankAccount.loading,
    payments: payment.payments,
    paymentBulk: paymentBulk.paymentBulks && paymentBulk.paymentBulks.length ? paymentBulk.paymentBulks[0] : null,
    paymentLines: payment.paymentLines,
    suppliers: supplier.suppliers,
    supplierBankAccounts: supplierBankAccount.supplierBankAccounts,
    saving: paymentBulk.saving,
})

const mapDispatchToProps = dispatch => ({
    onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
    onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
    // onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll()),
    onGetSupplierBankAccounts: () => dispatch(actionsSupplierBankAccount.supplierBankAccountGetAll()),
    onGetPaymentLines: (paymentId) => dispatch(actionsPayment.paymentLineGetAll(paymentId)),
    onGetPaymentBulkById: (id) => dispatch(actionsPaymentBulk.paymentBulkGetById(id)),
    onEditPayment: (paymentBulk, history) => dispatch(actionsPaymentBulk.paymentBulkEdit(paymentBulk, history)),
    onDeletePayment: (id, history) => dispatch(actionsPaymentBulk.paymentBulkDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentEdit);
