import {
	USER_REGISTER_FROM_INVITATION,
	USER_REGISTER_FROM_INVITATION_ERROR,
	USER_REGISTER_FROM_INVITATION_SUCCESS
} from "./actionTypes";

export const userRegisterFromInvitation = (token, inviteeEmail, email, password, history) => {
	return {
		type: USER_REGISTER_FROM_INVITATION,
		payload: { token, inviteeEmail, email, password, history }
	}
}

export const userRegisterFromInvitationSuccess = message => {
	return {
		type: USER_REGISTER_FROM_INVITATION_SUCCESS,
		payload: message
	}
}

export const userRegisterFromInvitationError = message => {
	return {
		type: USER_REGISTER_FROM_INVITATION_ERROR,
		payload: message
	}
}