export const CounterType = [
	{ code: 10, id: "BANK_STATEMENT_RECORD_EXTERNAL_ID_CUSTOMER_INVOICE", description: "Bank statement record external ID: Customer invoice" }, 
	{ code: 15, id: "BANK_STATEMENT_RECORD_EXTERNAL_ID_PAYMENT_SUPPLIER_INVOICE", description: "Bank statement record external ID: Payment, Supplier invoice" }, 
	{ code: 16, id: "BANK_STATEMENT_RECORD_EXTERNAL_ID_PAYMENT_SUPPLIER_INVOICE_BANK_CHARGE", description: "Bank statement record external ID: Payment, Supplier invoice, Bank charge" }, 
	{ code: 20, id: "CUSTOMER_INVOICE_NO", description: "Customer invoice No." }, 
	{ code: 30, id: "CUSTOMER_QUOTE_NO", description: "Customer quote No." }, 
];

export const CounterTypeAsSelectFilterOptions = {
	"BANK_STATEMENT_RECORD_EXTERNAL_ID_CUSTOMER_INVOICE": "Bank statement record external ID: Customer invoice",
	"BANK_STATEMENT_RECORD_EXTERNAL_ID_PAYMENT_SUPPLIER_INVOICE": "Bank statement record external ID: Payment, Supplier invoice",
	"BANK_STATEMENT_RECORD_EXTERNAL_ID_PAYMENT_SUPPLIER_INVOICE_BANK_CHARGE": "Bank statement record external ID: Payment, Supplier invoice, Bank charge",
	"CUSTOMER_INVOICE_NO": "Customer invoice No.",
	"CUSTOMER_QUOTE_NO": "Customer quote No.",
};