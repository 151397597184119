import * as actionTypes from "./actionTypes";

export const supplierGetAll = () => {
	return {
		type: actionTypes.SUPPLIER_GET_ALL
	}
}

export const supplierGetById = (id) => {
	return {
		type: actionTypes.SUPPLIER_GET_BY_ID,
		id: id
	}
}

export const supplierGetInProcess = () => {
	return {
		type: actionTypes.SUPPLIER_GET_IN_PROCESS
	}
}

export const supplierGetError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_GET_ERROR,
		error: thisError
	}
}

export const supplierGetSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_GET_SUCCESS,
		suppliers: data
	}
}

export const supplierCreate = (supplier, history) => {
	return {
		type: actionTypes.SUPPLIER_CREATE,
		supplier: supplier, 
		history: history
	}
}

export const supplierCreateError = (thisError) => {
	return {
		type: actionTypes.SUPPLIER_CREATE_ERROR,
		error: thisError
	}
}

export const supplierCreateSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_CREATE_SUCCESS,
		data: data
	}
}

export const supplierCreateWithBankAccounts = (supplier) => {
	return {
		type: actionTypes.SUPPLIER_CREATE_WITH_BANK_ACCOUNTS,
		supplier: supplier
	}
}

export const supplierCreateAndMatchInvoiceCandidates = (supplier) => {
	return {
		type: actionTypes.SUPPLIER_CREATE_AND_MATCH_INVOICE_CANDIDATES,
		supplier: supplier
	}
}

export const supplierDelete = (id, history) => {
	return {
		type: actionTypes.SUPPLIER_DELETE,
		id: id,
		history: history
	}
}

export const supplierDeleteError = (error) => {
	return {
		type: actionTypes.SUPPLIER_DELETE_ERROR,
		error: error
	}
}

export const supplierDeleteSuccess = (data) => {
	return {
		type: actionTypes.SUPPLIER_DELETE_SUCCESS,
		data: data
	}
}