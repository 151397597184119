import React from 'react';

import Legal from './Legal';
import classes from './Legal.module.css';

const Terms1OnlineService = (props) => (
    <Legal {...props}>
        <h1>Terms and Conditions</h1>
        <h2>1. General Terms</h2>
        <p>1.1. These Terms and Conditions (hereinafter referred to as the Terms) apply to the use of the functionality of the web service (hereinafter referred to as the System) specified in the Contract (hereinafter referred to as the Service).</p>

        <h2>2. User, Company and Account</h2>
        <p>2.1. The User is a natural person who uses the Service on behalf of the Customer.</p>
        <p>2.2. The Customer shall familiarize the User with the terms applicable to the use of the Service and ensure that the User complies with them. The Customer shall compensate the Supplier for all losses resulting from the User's actions that are contrary to the terms applicable to the provision and use of the Service. A person who does not agree to the aforementioned terms may neither start nor continue to use the Service. If they have started to use the Service, they must immediately terminate their use of the Service.</p>
        <p>2.3. A Company is a data set created within the System, which defines and identifies the Customer within the System. An Account is a data set created within the System, which defines and identifies the User within the System. The Company and the Account within the System are used as tools for receiving the Service. One company is created for the Customer in the System. One or more accounts can be created within the Company, with the help of which Users use the Service. The Company is managed by the User using the account created in the System, to which the relevant permissions have been granted by the System settings (hereinafter referred to as the Administrator account). Several Administrator accounts can be created. If only one User account has been created within the Company, then it is also the Administrator account. The permissions granted to the account can be changed, if such an option is available in the System.</p>
        <p>2.4. The Supplier creates a company and the first account (Administrator account) within it within 7 days after the conclusion of the Contract and, if the Contract does not specify a Trial Period, then additionally after receiving the first payment for the Service fee. Subsequent accounts within the Company are created and deleted by the Customer using the Administrator account.</p>
        <p>2.5. The Customer ensures that the User provides true information requested for the creation of a company or account (for example, the full name or title of the Customer and the User, other data, a valid e-mail address, etc.).</p>
        <p>2.6. The Customer and the User keep confidential the non-public authentication data, information and tools related to the use of the Service that provide access to the account, and provide them only to the User who is authorized to use the Service on behalf of the Customer.</p>
        <p>2.7. The Customer is responsible for all actions taken and for all data processed using any account created within the Customer's company.</p>
        <p>2.8. The Customer may close (delete) both the company and any account at any time. By performing these actions, the Customer agrees to the following terms:</p>
        <p>2.8.1. When closing (deleting) an account, all data related to the closed (deleted) account (both all data defining this account and data linked to this account in the System) are deleted without the possibility of restoring them, except for data included in the registers and identifying this account (User) in connection with the actions performed in the System, and except for data stored in backup copies. However, such data are neither separately accessible nor usable, and the Supplier is not obliged to restore data deleted due to the closure (deletion) of the account from them.</p>
        <p>2.8.2. When closing (deleting) a company, all data related to the closed (deleted) company are deleted without the possibility of restoring them, and all accounts related to it are also closed (deleted) (and the data related to them are deleted without the possibility of restoring them). Backup copies of data related to the company are deleted gradually, but the data stored in them is neither accessible nor usable and the Supplier is not obliged to restore data from them that was deleted due to the company's closure (deletion).</p>
        <p>2.8.3. upon closing the last account, the Customer loses access to the Service and the Customer's data related to the company.</p>
        <p>2.9. The Supplier may, at its discretion, suspend the provision of the Service if the account used to receive it was created or is used in violation of these Terms and may close such an account.</p>
        <p>2.10. The Supplier may restrict the ability of the account to access the Service or part of it if the Supplier believes that there are circumstances that require it (for example, to prevent a threat to the Customer's data or the operation of the System).</p>

        <h2>3. Trial Period</h2>
        <p>3.1. Unless the Contract specifies a different start date for the Trial Period, the Trial Period shall begin on the date on which the Supplier has created the Customer's company and first account. The Trial Period shall end on the date of the Trial Period end specified in the Contract.</p>
        <p>3.2. The Customer shall not pay the Supplier any fees for the Service provided during the Trial Period.</p>
        <p>3.3. During the Trial Period, the Supplier shall provide the Customer with the opportunity to use the Service in full, or with limitations in terms of scope or functionality, at its discretion. All provisions of the Contract shall apply to the use of the Service during the Trial Period in the same way as they apply to the use of the Service at any other time.</p>
        <p>3.4. During the Trial Period, each party may withdraw from the Contract by giving the other party written notice. If the Contract is terminated during the Trial Period, the Supplier shall refund the Customer the fee for the Service, if the Customer has paid it to the Supplier for the Service period that would follow the Trial Period. The Party shall not compensate the other for any costs or losses incurred as a result of the Party withdrawing from the Contract during the Trial Period.</p>

        <h2>4. Service Period</h2>
        <p>4.1. The Service Period specified in the Contract is the period of time during which the Supplier provides constant Service rates, and the Customer undertakes to pay for the Service for the entire Service period.</p>
        <p>4.2. Each Party may withdraw from the Service (i.e. withdraw from the Contract in the part relating to the provision of the Service) by notifying the other Party in writing by the Withdrawal Deadline specified in the Contract.</p>
        <p>4.2.1. If the party has so refused the Service, then the Supplier is not obliged to provide the Service after the current Service period.</p>
        <p>4.2.2. If no party has so refused the Service, then it is deemed that the parties have agreed that the Supplier will also provide the same Service rates in the next Service period that were applicable in the current period, and the Customer undertakes to pay for the Service for the entire next Service period in accordance with these rates. The next Service period begins on the day after the end of the current Service period.</p>
        <p>4.3. The Supplier may offer the Customer to agree on amendments to the Service rates applicable in the next Service period. The Supplier shall submit such an offer to the Customer in writing, no later than 30 days before the Withdrawal Deadline. If the Customer has refused the Service in accordance with Clause 4.2 of these Terms before submitting the offer, then the offer submitted by the Supplier shall be invalid. In the Offer, the Supplier shall indicate the Service rates applicable to the Service to be provided in the next Service Period. If a Service rate is not indicated, it shall be deemed that the rate for such Service shall not be changed. If, after submitting the offer to the Customer, the Customer has not withdrawn from the Service by notifying the Supplier in writing by the Withdrawal Deadline specified in the Contract, it shall be deemed that the Customer accepts the Supplier's offer. If the Customer has accepted the Supplier's offer, it shall be deemed that the parties have agreed that the Supplier shall apply the Service rates specified in the offer in the next Service Period, and the Customer undertakes to pay for the Service for the entire next Service Period in accordance with these rates.</p>
        <p>4.4. If the Customer withdraws from the Contract before the end of the Service Period, and this withdrawal is not justified by the Supplier's failure to fulfill its obligations, then the fee already paid for the Service is not refundable and the Customer shall pay the Supplier for the Service for the entire period until the end of the relevant Service Period, within 10 days after receipt of the relevant invoice from the Supplier.</p>

        <h2>5. Service Fee</h2>
        <p>5.1. The Service Fee is the consideration paid by the Customer to the Supplier for the Service. The Service Fee shall be determined in accordance with the Service volume available to the Supplier, applying the Service Fee rates specified in the Contract.</p>
        <p>5.2. The Payment Period specified in the Contract is the period of time within the Service Period for which the Supplier shall submit an invoice to the Customer for the relevant Service Fee for the Services to be provided. The Payment Period may be equal to or shorter than the Service Period.</p>
        <p>5.3. If the Payment Period is shorter than the Service Period, then one Payment Period may be shorter than the other Payment Periods, or longer than the other Payment Periods, but not longer than 2 Payment Periods, if necessary in order not to submit an invoice for a period exceeding the Service Period.</p>
        <p>5.4. The Supplier shall submit to the Customer an invoice for the Service Fee for the Services to be provided in the current Payment Period before the Payment Period.</p>
        <p>5.5. If during the Payment Period the parties agree on changes in the scope of the Service, which result in a higher Service Fee, or if a higher Service Fee results from the amount of the Service actually used by the Customer, then the Supplier may, at its discretion, add the increase in the Service Fee for the current Payment Period to the invoice for the next Payment Period, or may submit to the Customer a separate invoice for the increase in the Service Fee for the current Payment Period.</p>
        <p>5.6. The Supplier has the right to suspend the provision of the Service if the Customer delays payment of the Supplier's invoice.</p>
        <p>5.7. The paid Service Fee is not refundable if the Customer does not use the Service when the Supplier had provided the opportunity to use it.</p>
        <p>5.8. The paid Service fee is not refundable for the time when the Service has been suspended or refused in accordance with these Terms.</p>

        <h2>6. Use of the Service</h2>
        <p>6.1. The Supplier provides the Service to the Customer after the parties have concluded the Contract.</p>
        <p>6.2. The User uses the Service using an account associated with the company. The number of accounts available within the company may depend on the fee paid by the Customer for the Service.</p>
        <p>6.3. The Customer independently and regularly makes copies of the Customer's data processed by the System. The Supplier may make copies of this data in fulfillment of the Supplier's obligation specified in regulatory enactments or at its own discretion to ensure the Supplier's operations and provision of the Service. The data copies made by the Supplier are not intended to be used, and they cannot be used as a backup copy of the Customer's data.</p>
        <p>6.4. The Service is intended for use only for the Customer's commercial, economic or professional activities. Use of the Service for the needs of the Customer or another person as a consumer is prohibited. The Customer ensures that the Service is used only for the User's or Customer's commercial, economic or professional purposes, and therefore neither the User nor the Customer are consumers under applicable law. The Customer shall reimburse the Supplier for all expenses related to the Supplier's obligation to respond to consumer claims in connection with the service used by the Customer or the User.</p>
        <p>6.5. The use of the Service for any illegal or unauthorized purposes is prohibited. Neither the Customer nor the User shall use the Service in cases where the use of the Service may result in a violation of regulatory enactments binding on the Customer, in particular in the field of export control, personal data processing or data processing security. The Customer and the User are jointly and severally liable for the legality of the use of the Service in their respective jurisdictions, as well as for obtaining any authorizations, permits, licenses, approvals, etc. necessary for the use of the Service.</p>
        <p>6.6. The Customer or User may inform the Supplier of any disruptions to the use of the Service and of any suggestions regarding the Service using the means of communication specified by the Supplier. If the disruption to the use of the Service is caused by a cause that depends on the Supplier or that is under the Supplier's control, the Supplier shall eliminate the relevant disruption to the use of the Service as soon as practically possible and reasonable under the circumstances.</p>
        <p>6.7. Unless otherwise specified in these Terms, the Supplier shall contact the Customer regarding the use of the Service using the Customer's contact email address specified in the Contract.</p>
        <p>6.8. The Customer and the User shall be jointly and severally liable for any breach of these Terms and shall compensate the Supplier for any expenses and damages related to such breach.</p>

        <h2>7. Service Specification</h2>
        <p>7.1. The purpose of the Service is to provide automation of business processes, preparation, sending of necessary documents and other capabilities that may facilitate certain operations related to the processing and storage of data used in the Customer's company for the Customer or other involved parties.</p>
        <p>7.2. The Service capabilities available to the User depend on the Service capabilities granted to the Customer and the permissions of the account used by the User.</p>
        <p>7.3. The Supplier may deploy new versions of the Service, update the functionality, capabilities of the Service, and make other changes to the Service. The Customer and Users may agree to use the modified Service as it is, or discontinue use of the Service if they are not satisfied with the modified Service. The Supplier shall notify the Customer (through the Users) in advance of any significant changes to the Service.</p>
        <p>7.4. The Customer and the User agree to use the Service as it is and as it is available, without any express or implied warranty of any kind, in particular with regard to fitness for any purpose, availability, operation without interruptions and errors, etc. The Service is provided without any express or implied warranty to ensure the User's or the Customer's compliance with any legal requirements, or to fulfill any legal obligation binding on the User or the Customer. The User and the Customer must verify whether the Service is compatible with the legal requirements applicable to them, and in case of doubt, they must refuse to use the Service.</p>
        <p>7.5. The Supplier does not promise uninterrupted availability of the Service. The Service may not be available for reasons related to or dependent on the Supplier, or within the Supplier's control, or for reasons unrelated to the Supplier, independent of the Supplier and beyond the Supplier's control. The Supplier shall, to the extent possible, inform the User (and the Customer through the User) of planned interruptions of the Service in advance by posting notices on the Service or using other means of communication.</p>
        <p>7.6. Both the User and the Customer waive any claims against the Supplier for transactions with third parties that they may conclude or conclude or execute using the Service. Both the User and the Customer shall jointly and severally indemnify the Supplier for all costs and losses related to claims brought against the Supplier in connection with the use of the Services for the conclusion or execution of any transaction.</p>

        <h2>8. Suspension and resumption of the Service</h2>
        <p>8.1. The Supplier may suspend the provision of the Service without prior notice:</p>
        <p>8.1.1. in cases expressly specified elsewhere in these Terms;</p>
        <p>8.1.2. if the Customer or the User violates the terms of the Contract concluded between the parties and such violation causes the Supplier to violate an obligation specified in regulatory enactments;</p>
        <p>8.1.3. if the Customer or the User violates the terms of the Contract concluded between the parties and such violation does not cause the Supplier to violate an obligation specified in regulatory enactments, and such violation has not been terminated or its consequences have not been eliminated within 7 days after the Supplier's request is submitted to the Customer.</p>
        <p>8.2. When suspending the provision of the Service, the Supplier may, at its discretion, deny each User the opportunity to use the Service, both in full and in part. The Supplier, at its discretion, chooses the solutions it uses to deny the opportunity to use the Service. During the suspension of the Service, the Supplier does not delete either the company, any account, or the data related to them. They are deleted only upon termination of the Service.</p>
        <p>8.3. The Supplier shall immediately notify the Customer, upon the Customer's request, of the reason for the suspension of the Service.</p>
        <p>8.4. The Supplier shall resume the provision of the Service when the reason for which the provision of the Service was suspended has been eliminated, if there is no other reason for which the provision of the Service may be suspended.</p>

        <h2>9. Termination of the Service</h2>
        <p>9.1. The Supplier may terminate the provision of the Service in part or in full, in advance of the Customer (this may occur through the User), by posting notices on the Service or using other means of communication. The Supplier shall provide the Customer with 7 days after posting the notice to download the Customer's data, which may not be available after the termination of the Service or part thereof. The Customer shall download the Customer's data immediately and no later than 7 days after receiving the notice.</p>
        <p>9.2. The Supplier shall terminate the provision of the Service:</p>
        <p>9.2.1. upon receipt of the Customer's notice, if the Customer has notified the Supplier in writing of his withdrawal from the Contract by the end of the Trial Period;</p>
        <p>9.2.2. when the Service period ends, if in accordance with clauses 4.2. or 4.3. of these Terms and Conditions it is not considered that the parties have agreed on the provision of the Service in the next Service period;</p>
        <p>9.2.3. when the Contract is terminated.</p>
        <p>9.3. Upon termination of the Contract, the Supplier shall terminate the provision of the Service in full, close the company and all related accounts, and delete the Customer's data from the computer systems used for the provision of the Service as soon as reasonably practicable by the Supplier. The Customer shall take the necessary measures to download the data required by the Customer in a timely manner prior to termination of the Contract.</p>
        <p>9.4. If the Supplier withdraws from the Contract on the grounds of the Customer's failure to perform its obligations, or if the Customer withdraws from the Contract without justifying it on the grounds of the Supplier's failure to perform its obligations, the Supplier shall not refund the Service fee paid by the Customer.</p>
        <p>9.5. If the Supplier withdraws from the Contract or terminates the Service without justifying it with the Customer's failure to fulfill its obligations, or if the Customer withdraws from the Contract with the Supplier's failure to fulfill its obligations, the Supplier shall refund to the Customer such part of the Service fee paid by the Customer for the last Service period as is proportional to the time from the termination of the Service to the planned end date of the relevant Service period.</p>

        <h2>10. Terms of External Services</h2>
        <p>10.1. Within the framework of the Service, the Customer is provided with the opportunity to use the services specified in the Contract, which are provided by third parties selected by the Supplier (hereinafter referred to as the External Service). The Supplier, at the request of the Customer or on its own initiative, indicates which person provides the External Service and the terms under which this person provides the External Service. The Supplier may select and change the External Service provider at its own discretion.</p>
        <p>10.2. The Supplier, in compliance with the Contract and these terms, ensures the possibility of accessing the External Service to the extent that it depends on the proper operation of the System. The Customer does not require the Supplier to be responsible, and the Supplier is not liable, for the operation, properties, specifications and suitability of the External Service for the Customer's needs, for the fulfillment of the obligations of the External Service Provider (for example, interruptions of the External Service, incorrect operation, etc.). The Customer uses the External Service by using the relevant System functionality.</p>
        <p>10.3. If the Supplier processes data before transferring it for processing to the External Service Provider, or processes data after receiving it from the External Service Provider, then the Supplier is responsible only for the processing of data performed by the Supplier itself. The provisions of clause 10.2 of these Terms and Conditions shall not be limited in connection with the processing of data performed by the Supplier before or after using the External Service.</p>

        <h2>11. Support Terms</h2>
        <p>11.1. Within the framework of support, the Supplier provides advice and recommendations to Users regarding the use of the Service and performs other activities, the scope and detailed specification of which are specified in the Contract. The Supplier provides support during the Service Period.</p>
        <p>11.2. The Supplier provides support using the communication channels specified in the Contract or using another communication platform specified and provided by the Supplier. If the Contract does not specify other support provision times, the Supplier responds to the Customer's support requests on business days from 9:00 to 17:00 (EEST time zone). Business days are Monday to Friday, except for holidays established in the Republic of Latvia.</p>
        <p>11.3. If the Customer requests information about the use of the Service in a situation described in the documents describing the Service available to the Customer (user manual, answers to frequently asked questions, etc.), then the Supplier may point the Customer to the relevant document and its section where the necessary information is available.</p>
        <p>11.4. Support does not include the investigation of problems with the operation of the Customer's computers and computer programs installed on them, nor the determination of their causes, nor their elimination. The Customer independently ensures the proper operation of its computer systems, if this is necessary for receiving the Service or performing the Contract. If the provision of support requires access to the Customer's computers or computer programs installed on them, then the Supplier provides support if the Customer has provided the Supplier with the aforementioned access.</p>
        <p>11.5. The Supplier may engage subcontractors to provide support. The Supplier is responsible for the results of the work of its subcontractors, which the Supplier delivers to the Customer.</p>
    </Legal>
);

export default Terms1OnlineService;