export const CURRENCY_GET_ALL = "CURRENCY_GET_ALL";
export const CURRENCY_GET_BY_ID = "CURRENCY_GET_BY_ID";
export const CURRENCY_GET_IN_PROCESS = "CURRENCY_GET_IN_PROCESS";
export const CURRENCY_GET_ERROR = "CURRENCY_GET_ERROR";
export const CURRENCY_GET_SUCCESS = "CURRENCY_GET_SUCCESS";

export const CURRENCY_CREATE = "CURRENCY_CREATE";
export const CURRENCY_CREATE_ERROR = "CURRENCY_CREATE_ERROR";
export const CURRENCY_CREATE_SUCCESS = "CURRENCY_CREATE_SUCCESS";

export const CURRENCY_DELETE = "CURRENCY_DELETE";

