const getMessageFromHttpError = (httpError) => {
    if (httpError && httpError.response && httpError.response.data && httpError.response.data.messages && httpError.response.data.messages[0]) {
        return httpError.response.data.messages[0];
    } else if (httpError && httpError.message) {
        return httpError.message;
    } else {
        return "";
    }
}

export const httpErrorMessageAndAdditionalText = (additionalText, httpError) => {
    const httpErrorMessage = getMessageFromHttpError(httpError);
    if (httpErrorMessage) {
        return additionalText + ": " + httpErrorMessage;
    } else {
        return additionalText;
    }
}