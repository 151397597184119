import * as actionTypes from "./actionTypes";

export const crmBankImplementationGetAll = () => {
	return {
		type: actionTypes.CRM_BANK_IMPLEMENTATION_GET_ALL
	}
}

export const crmBankImplementationGetError = (thisError) => {
	return {
		type: actionTypes.CRM_BANK_IMPLEMENTATION_GET_ERROR,
		error: thisError
	}
}

export const crmBankImplementationGetSuccess = (data) => {
	return {
		type: actionTypes.CRM_BANK_IMPLEMENTATION_GET_SUCCESS,
		crmBankImplementations: data
	}
}

