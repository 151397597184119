import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCRMBankImplementation from '../../store/CRMBankImplementation/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsSupplierBankAccount from '../../store/SupplierBankAccount/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class SupplierBankAccountEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			supplierId: "",
			externalId: "",
			accountNo: "",
			bankSwiftCode: "",
			bankAlternativeCode: "",
			bankName: "",
			corrAccountNo: "",
			corrSwiftCode: "",
			corrBankName: "",
			additionalInfo: "",
			accountCustomType: "",

			useCorrBank: false,
			currentBankImplementation: null,
			supplierBankAccounts: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	crmTemplateToRegex(crmTemplate) {
		return crmTemplate.replaceAll("?", ".").replaceAll("*", ".*?");
	}

	detectBankImplementation() {
		let matchingImplementation = null;
		if (!this.props.crmBankImplementations || !this.props.crmBankImplementations.length) {
			return;
		}
		const matchesByIBAN = this.props.crmBankImplementations.filter(item => this.state.accountNo.match(this.crmTemplateToRegex(item.ibanTemplate)));
		if (matchesByIBAN && matchesByIBAN.length) {
			matchingImplementation = matchesByIBAN[0];
		} else {
			const matchesBySWIFTCode = this.props.crmBankImplementations.filter(item => this.state.bankSwiftCode.match(this.crmTemplateToRegex(item.bankSwiftCode)));
			if (matchesBySWIFTCode && matchesBySWIFTCode.length) {
				matchingImplementation = matchesBySWIFTCode[0];
			}
		}
		this.setState({
			currentBankImplementation: matchingImplementation
		});
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedSupplierBankAccount = {
			supplierId: this.state.supplierId,
			externalId: this.state.externalId,
			accountNo: this.state.accountNo,
			bankSwiftCode: this.state.bankSwiftCode,
			bankAlternativeCode: this.state.bankAlternativeCode,
			bankName: this.state.bankName,
			corrAccountNo: this.state.useCorrBank ? this.state.corrAccountNo : "",
			corrSwiftCode: this.state.useCorrBank ? this.state.corrSwiftCode : "",
			corrBankName: this.state.useCorrBank ? this.state.corrBankName : "",
			additionalInfo: this.state.additionalInfo,
			accountCustomType: this.state.accountCustomType,
		}
		if (this.state.id) {
			newOrUpdatedSupplierBankAccount = {
				id: this.state.id,
				...newOrUpdatedSupplierBankAccount
			}
		}
		this.props.onCreateSupplierBankAccount(newOrUpdatedSupplierBankAccount, this.props.history);
	}

	onDeleteSupplierBankAccount = () => {
		if (window.confirm("Are you sure you want to delete this Bank account?")) {
			this.props.onDeleteSupplierBankAccount(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			// As of 2024.01.25, CRM bank implementations are included in the result of getSupplierBankAccountById()
			this.props.onGetSupplierBankAccountById(id);
		} else {
			this.props.onGetCRMBankImplementations();
		}
		this.props.onGetSuppliers();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.suppliers !== this.props.suppliers) {
			if (!this.state.supplierId && this.props.suppliers[0]) {
				this.setState({
					supplierId: this.props.suppliers[0].id
				})
			}
		}

		if (prevProps.supplierBankAccounts !== this.props.supplierBankAccounts) {
			if (this.props.supplierBankAccounts && this.props.supplierBankAccounts[0]) {

				this.setState({
					id: this.props.supplierBankAccounts[0].id,
					supplierId: this.props.supplierBankAccounts[0].supplierId,
					externalId: this.props.supplierBankAccounts[0].externalId,
					accountNo: this.props.supplierBankAccounts[0].accountNo,
					bankSwiftCode: this.props.supplierBankAccounts[0].bankSwiftCode,
					bankAlternativeCode: this.props.supplierBankAccounts[0].bankAlternativeCode,
					bankName: this.props.supplierBankAccounts[0].bankName,
					corrAccountNo: this.props.supplierBankAccounts[0].corrAccountNo,
					corrSwiftCode: this.props.supplierBankAccounts[0].corrSwiftCode,
					corrBankName: this.props.supplierBankAccounts[0].corrBankName,
					additionalInfo: this.props.supplierBankAccounts[0].additionalInfo,
					accountCustomType: this.props.supplierBankAccounts[0].accountCustomType,
					
					useCorrBank: ((this.props.supplierBankAccounts[0].corrAccountNo && this.props.supplierBankAccounts[0].corrAccountNo.length)
						&& ((this.props.supplierBankAccounts[0].corrSwiftCode && this.props.supplierBankAccounts[0].corrSwiftCode.length)
							|| (this.props.supplierBankAccounts[0].corrBankName && this.props.supplierBankAccounts[0].corrBankName.length)))
				});
			} else {
				this.setState({
					id: "",
					supplierId: "",
					externalId: "",
					accountNo: "",
					bankSwiftCode: "",
					bankAlternativeCode: "",
					bankName: "",
					corrAccountNo: "",
					corrSwiftCode: "",
					corrBankName: "",
					additionalInfo: "",
					accountCustomType: "",

					useCorrBank: false
				});
			}
		}

		if ((prevState.accountNo !== this.state.accountNo && this.state.accountNo && this.state.accountNo.length)
			|| (prevState.bankSwiftCode !== this.state.bankSwiftCode && this.state.bankSwiftCode && this.state.bankSwiftCode.length)) {

			this.detectBankImplementation();
		}

		if (prevState.currentBankImplementation !== this.state.currentBankImplementation && this.state.currentBankImplementation) {
			const currentBankImplementation = this.state.currentBankImplementation;
			this.setState({
				bankName: currentBankImplementation.bankName,
				bankSwiftCode: currentBankImplementation.bankSwiftCode.replaceAll("*", "")
			});
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " supplier bank account | " + config.AppName;;
		const breadcrumbsTitle = "Supplier bank accounts";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " supplier bank account";

		const bankAlternativeCodeContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.bankAlternativeCodeContents && this.state.currentBankImplementation.bankAlternativeCodeContents.length > 1)
			? this.state.currentBankImplementation.bankAlternativeCodeContents : null;

		const additionalInfoContents = (this.state.currentBankImplementation && this.state.currentBankImplementation.additionalInfoContents && this.state.currentBankImplementation.additionalInfoContents.length > 1)
			? this.state.currentBankImplementation.additionalInfoContents : null;

		const supplierOptions = this.props.suppliers ? this.props.suppliers.map(supplierRow => (
			<option
				value={supplierRow.id}
				key={supplierRow.id}
			>
				{supplierRow.name}
			</option>
		)) : null;

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">
										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("supplierId", "Supplier", this.onChange, this.state.supplierId, supplierOptions)}
										{editFormControls.textControl("accountNo", "Account No.", this.onChange, this.state.accountNo)}
										{editFormControls.textControl("bankSwiftCode", "Bank SWIFT-BIC code", this.onChange, this.state.bankSwiftCode)}
										{bankAlternativeCodeContents && editFormControls.textControl("bankAlternativeCode", bankAlternativeCodeContents, this.onChange, this.state.bankAlternativeCode, "Enter " + bankAlternativeCodeContents)}
										{editFormControls.textControl("bankName", "Bank name", this.onChange, this.state.bankName)}
										{editFormControls.checkboxControl("useCorrBank", "Use correspondent account", this.onChange, this.state.useCorrBank)}
										{this.state.useCorrBank && editFormControls.textControl("corrAccountNo", "Correspondent account No.", this.onChange, this.state.corrAccountNo)}
										{this.state.useCorrBank && editFormControls.textControl("corrSwiftCode", "Correspondent SWIFT-BIC code", this.onChange, this.state.corrSwiftCode)}
										{this.state.useCorrBank && editFormControls.textControl("corrBankName", "Correspondent bank name", this.onChange, this.state.corrBankName)}
										{additionalInfoContents && editFormControls.textControl("additionalInfo", additionalInfoContents, this.onChange, this.state.additionalInfo, "Enter " + additionalInfoContents)}
										{/* 2024.01.24: Do we need accountCustomType at all? As of now it is used neither in BankAccount nor in SupplierBankAccount
										{editFormControls.textControl("accountCustomType", "Account custom type", this.onChange, this.state.accountCustomType)} */}

										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteSupplierBankAccount, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading || this.props.loadingSuppliers)}

						{!this.props.loading && !this.props.loadingSuppliers ? editForm : null}
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ crmBankImplementation, supplier, supplierBankAccount }) => ({
	crmBankImplementations:
		supplierBankAccount.supplierBankAccounts && supplierBankAccount.supplierBankAccounts.length
			? supplierBankAccount.supplierBankAccounts[0].crmBankImplementations
			: crmBankImplementation.crmBankImplementations && crmBankImplementation.crmBankImplementations.length
				? crmBankImplementation.crmBankImplementations
				: [],
	suppliers: supplier.suppliers,
	supplierBankAccounts: supplierBankAccount.supplierBankAccounts,
	loading: supplierBankAccount.loading,
	loadingSuppliers: supplier.loading,
	error: supplierBankAccount.error
})

const mapDispatchToProps = dispatch => ({
	onGetCRMBankImplementations: () => dispatch(actionsCRMBankImplementation.crmBankImplementationGetAll()),
	onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll()),
	onGetSupplierBankAccountById: (id) => dispatch(actionsSupplierBankAccount.supplierBankAccountGetById(id)),
	onCreateSupplierBankAccount: (supplierBankAccount, history) => dispatch(actionsSupplierBankAccount.supplierBankAccountCreate(supplierBankAccount, history)),
	onDeleteSupplierBankAccount: (id, history) => dispatch(actionsSupplierBankAccount.supplierBankAccountDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierBankAccountEdit);
