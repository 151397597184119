import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import { downloadHelper } from '../../helpers/downloadHelper';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* deleteReportOfBankStatementSaga({ idList }) {
    try {
        const response = yield call(
            rest.del,
            endpointsBackend.REPORT_OF_BANK_STATEMENT_GET_ALL,
            { idList: idList }
        );
        yield put(actions.reportOfBankStatementDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.reportOfBankStatementDeleteError(e));
    }

}

function* downloadReportOfBankStatementByIdSaga({ id }) {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.REPORT_DOWNLOAD.replace("{reportId}", id),
        );
        yield call(downloadHelper, response);
        yield put(actions.reportOfBankStatementDownloadSuccess());
    } catch (e) {
        yield put(actions.reportOfBankStatementDownloadError(e));
    }
}

function* downloadReportOfCustomerInvoiceControlSumSaga() {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_XLS,
        );
        yield call(downloadHelper, response);
        yield put(actions.reportOfCustomerInvoiceControlSumDownloadSuccess());
    } catch (e) {
        yield put(actions.reportOfCustomerInvoiceControlSumDownloadError(e));
    }
}

function* downloadReportOfSupplierInvoiceControlSumSaga() {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_XLS,
        );
        yield call(downloadHelper, response);
        yield put(actions.reportOfSupplierInvoiceControlSumDownloadSuccess());
    } catch (e) {
        yield put(actions.reportOfSupplierInvoiceControlSumDownloadError(e));
    }
}

function* getAllReportOfBankStatementsSaga() {
    try {
        const response = yield call(
            rest.get,
            endpointsBackend.REPORT_OF_BANK_STATEMENT_GET_ALL
        );
        yield put(actions.reportOfBankStatementGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
    } catch (e) {
        yield put(actions.reportOfBankStatementGetError(e));
    }
}

function* reportOfBankStatementSaga() {
    yield (takeLatest(actionTypes.REPORT_OF_BANK_STATEMENT_DELETE, deleteReportOfBankStatementSaga));
    yield (takeLatest(actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD, downloadReportOfBankStatementByIdSaga));
    yield (takeLatest(actionTypes.REPORT_OF_BANK_STATEMENT_GET_ALL, getAllReportOfBankStatementsSaga));
    yield (takeLatest(actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD, downloadReportOfCustomerInvoiceControlSumSaga));
    yield (takeLatest(actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD, downloadReportOfSupplierInvoiceControlSumSaga));
}

export default reportOfBankStatementSaga;