import {
	USER_REGISTER_FROM_INVITATION,
	USER_REGISTER_FROM_INVITATION_SUCCESS,
	USER_REGISTER_FROM_INVITATION_ERROR,
} from "./actionTypes";

const initialState = {
	resetRequestError: null,
	resetRequestSuccessMessage: null,
	token: null,
	inviteeEmail: null,
	email: null,
	password: null,
	registerError: null,
	registering: false,
	registerSuccessMessage: null
}

const userRegister = (state = initialState, action) => {
	switch (action.type) {
		case USER_REGISTER_FROM_INVITATION:
			state = {
				...state,
				token: action.payload.token,
				inviteeEmail: action.payload.inviteeEmail,
				email: action.payload.email,
				password: action.payload.password,
				registerError: null,
				registering: true,
				registerSuccessMessage: null
			};
			break;
		case USER_REGISTER_FROM_INVITATION_ERROR:
			state = {
				...state,
				registerError: action.payload,
				registering: false,
				registerSuccessMessage: null
			}
			break;
		case USER_REGISTER_FROM_INVITATION_SUCCESS:
			state = {
				...state,
				registerError: null,
				registering: false,
				registerSuccessMessage: action.payload
			}
			break;
		default:
			break;
	}
	return state;
}

export default userRegister;