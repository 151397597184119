import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsTask from '../../store/Task/actions';
import * as actionsTimelog from '../../store/Timelog/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

class TimelogEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			externalId: "",
			taskId: "",
			date: "",
			hoursLogged: "",
			userLoggedId: "",
			comment: "",
			userLoggedInfo: "",
			taskTitle: "",

			timelogs: [],
			changed: false,
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			changed: true,
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedTimelog = {
			externalId: this.state.externalId,
			taskId: this.state.taskId,
			date: this.state.date,
			hoursLogged: this.state.hoursLogged,
			userLoggedId: this.state.userLoggedId,
			comment: this.state.comment,
			userLoggedInfo: this.state.userLoggedInfo,
			taskTitle: this.state.taskTitle,
		}
		if (this.state.id) {
			newOrUpdatedTimelog = {
				id: this.state.id,
				...newOrUpdatedTimelog
			}
		}
		this.setState({
			changed: false
		}); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
		this.props.onCreateTimelog(newOrUpdatedTimelog, this.props.history);
	}

	onDeleteTimelog = () => {
		if (window.confirm("Are you sure you want to delete this Timelog?")) {
			this.props.onDeleteTimelog(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetTasks();
			this.props.onGetTimelogById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.timelogs !== this.props.timelogs) {
			if (this.props.timelogs && this.props.timelogs[0]) {

				this.setState({
					id: this.props.timelogs[0].id,
					externalId: this.props.timelogs[0].externalId,
					taskId: this.props.timelogs[0].taskId,
					date: this.props.timelogs[0].date,
					hoursLogged: this.props.timelogs[0].hoursLogged,
					userLoggedId: this.props.timelogs[0].userLoggedId,
					comment: this.props.timelogs[0].comment,
					userLoggedInfo: this.props.timelogs[0].userLoggedInfo,
					taskTitle: this.props.timelogs[0].taskTitle,
				});
			} else {
				this.setState({
					id: "",
					externalId: "",
					taskId: "",
					date: "",
					hoursLogged: "",
					userLoggedId: "",
					comment: "",
					userLoggedInfo: "",
					taskTitle: "",
				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Timelog | " + config.AppName;
		const breadcrumbsTitle = "Timelog";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Timelog";
		const loading = this.props.loading || this.props.loadingTasks;

		const taskOptions = inputSelectUtils.generateOptionsFromData(
			this.props.tasks,
			taskRow => (taskRow.title),
			(task1, task2) => (task1.title > task2.title ? 1 : task1.title === task2.title ? 0 : -1)
		);

        const userAccountOptionsPlusNull = inputSelectUtils.addNullOption(inputSelectUtils.generateOptionsFromData(this.props.userAccounts, userAccountRow => (
            userAccountRow.firstName ? (
                userAccountRow.lastName ? (
                    userAccountRow.firstName + " " + userAccountRow.lastName + " <" + userAccountRow.username + ">"
                ) :
                    userAccountRow.firstName + " <" + userAccountRow.username + ">"
            ) :
                userAccountRow.username
        )));

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControlWithGoToButton("taskId", "Task", this.onChange, this.state.taskId, taskOptions, endpointsFrontend.TASK_EDIT.replace(':id', this.state.taskId), this.props.history)}
										{editFormControls.dateControl("date", "Date", this.onChange, this.state.date)}
										{editFormControls.timeControl("hoursLogged", "Hours logged", this.onChange, this.state.hoursLogged, 0.1)}
										{editFormControls.selectControl("userLoggedId", "User logged", this.onChange, this.state.userLoggedId, userAccountOptionsPlusNull)}
										{editFormControls.textAreaControl("comment", "Comment", this.onChange, this.state.comment)}

										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteTimelog, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.saveSuccessAlert(!this.state.changed && this.props.saveSuccess, "Timelog", "Browse timelogs", endpointsFrontend.TIMELOG_BROWSE)}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ task, timelog, userAccount }) => ({
	error: timelog.error,
	deleting: timelog.deleting,
	loading: timelog.loading,
	loadingTasks: task.loading,
	saveSuccess: timelog.saveSuccess,
	saving: timelog.saving,
	tasks: task.tasks,
	timelogs: timelog.timelogs,
	userAccounts: userAccount.userAccounts
})

const mapDispatchToProps = dispatch => ({
	onGetTasks: () => dispatch(actionsTask.taskGetAll()),
	onGetTimelogById: (id) => dispatch(actionsTimelog.timelogGetById(id)),
	onCreateTimelog: (timelog, history) => dispatch(actionsTimelog.timelogCreate(timelog, history)),
	onDeleteTimelog: (id, history) => dispatch(actionsTimelog.timelogDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimelogEdit);
