import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsCountry from '../../store/Country/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class SupplierEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			externalId: "",
			customerExternalId: "",
			forwardToExternalId: "",
			name: "",
			alternativeName: "",
			customCode: "",
			regNo: "",
			vatRegNo: "",
			addressCountryId: "",
			addressRegion: "",
			addressCity: "",
			addressPostCode: "",
			addressStreet: "",
			blocked: "",
			reference: "",
			contactEmail: "",
			contactFax: "",
			contactPhone: "",
			isIndividual: "",
			category: "",
			type: "",

			suppliers: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedSupplier = {
			externalId: this.state.externalId,
			customerExternalId: this.state.customerExternalId,
			forwardToExternalId: this.state.forwardToExternalId,
			name: this.state.name,
			alternativeName: this.state.alternativeName,
			customCode: this.state.customCode,
			regNo: this.state.regNo,
			vatRegNo: this.state.vatRegNo,
			addressCountryId: this.state.addressCountryId,
			addressRegion: this.state.addressRegion,
			addressCity: this.state.addressCity,
			addressPostCode: this.state.addressPostCode,
			addressStreet: this.state.addressStreet,
			blocked: this.state.blocked,
			reference: this.state.reference,
			contactEmail: this.state.contactEmail,
			contactFax: this.state.contactFax,
			contactPhone: this.state.contactPhone,
			isIndividual: this.state.isIndividual,
			category: this.state.category,
			type: this.state.type,

		}
		if (this.state.id) {
			newOrUpdatedSupplier = {
				id: this.state.id,
				...newOrUpdatedSupplier
			}
		}
		this.props.onCreateSupplier(newOrUpdatedSupplier, this.props.history);
	}

	onDeleteSupplier = () => {
		if (window.confirm("Are you sure you want to delete this Supplier?")) {
			this.props.onDeleteSupplier(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetSupplierById(id);
		}
		this.props.onGetCountries();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.countries !== this.props.countries) {
			if (!this.state.addressCountryId && this.props.countries[0]) {
				this.setState({
					addressCountryId: this.props.countries[0].id
				})
			}
		}

		if (prevProps.suppliers !== this.props.suppliers) {
			if (this.props.suppliers && this.props.suppliers[0]) {

				this.setState({
					id: this.props.suppliers[0].id,
					externalId: this.props.suppliers[0].externalId,
					customerExternalId: this.props.suppliers[0].customerExternalId,
					forwardToExternalId: this.props.suppliers[0].forwardToExternalId,
					name: this.props.suppliers[0].name,
					alternativeName: this.props.suppliers[0].alternativeName,
					customCode: this.props.suppliers[0].customCode,
					regNo: this.props.suppliers[0].regNo,
					vatRegNo: this.props.suppliers[0].vatRegNo,
					addressCountryId: this.props.suppliers[0].addressCountryId,
					addressRegion: this.props.suppliers[0].addressRegion,
					addressCity: this.props.suppliers[0].addressCity,
					addressPostCode: this.props.suppliers[0].addressPostCode,
					addressStreet: this.props.suppliers[0].addressStreet,
					blocked: this.props.suppliers[0].blocked,
					reference: this.props.suppliers[0].reference,
					contactEmail: this.props.suppliers[0].contactEmail,
					contactFax: this.props.suppliers[0].contactFax,
					contactPhone: this.props.suppliers[0].contactPhone,
					isIndividual: this.props.suppliers[0].isIndividual,
					category: this.props.suppliers[0].category,
					type: this.props.suppliers[0].type,

				});
			} else {
				this.setState({
					id: "",
					externalId: "",
					customerExternalId: "",
					forwardToExternalId: "",
					name: "",
					alternativeName: "",
					customCode: "",
					regNo: "",
					vatRegNo: "",
					addressCountryId: "",
					addressRegion: "",
					addressCity: "",
					addressPostCode: "",
					addressStreet: "",
					blocked: "",
					reference: "",
					contactEmail: "",
					contactFax: "",
					contactPhone: "",
					isIndividual: "",
					category: "",
					type: "",

				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Supplier | " + config.AppName;
		const breadcrumbsTitle = "Supplier";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Supplier";

		const countryOptions = this.props.countries ? this.props.countries.map(countryRow => (
			<option
				value={countryRow.id}
				key={countryRow.id}
			>
				{countryRow.code} {countryRow.nameLang0 ? "(" + countryRow.nameLang0 + ")" : ""}
			</option>
		)) : null;

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">
										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("name", "Name", this.onChange, this.state.name, "Enter supplier name")}
										{editFormControls.textControl("alternativeName", "Alternative name", this.onChange, this.state.alternativeName, "Enter supplier alternative name")}
										{editFormControls.textControl("customCode", "Custom code", this.onChange, this.state.customCode, "Enter supplier custom code")}
										{editFormControls.textControl("regNo", "Registration No.", this.onChange, this.state.regNo, "Enter supplier registration No.")}
										{editFormControls.textControl("vatRegNo", "VAT registration No.", this.onChange, this.state.vatRegNo, "Enter supplier VAT registration No.")}
										{editFormControls.selectControl("addressCountryId", "Address - Country", this.onChange, this.state.addressCountryId, countryOptions)}
										{editFormControls.textControl("addressRegion", "Address - Region", this.onChange, this.state.addressRegion, "Enter supplier region")}
										{editFormControls.textControl("addressCity", "Address - City", this.onChange, this.state.addressCity, "Enter supplier city")}
										{editFormControls.textControl("addressPostCode", "Address - Postal code", this.onChange, this.state.addressPostCode, "Enter supplier postal code")}
										{editFormControls.textControl("addressStreet", "Address - Street", this.onChange, this.state.addressStreet, "Enter supplier street address")}
										{editFormControls.checkboxControl("blocked", "Blocked", this.onChange, this.state.blocked)}
										{editFormControls.textControl("reference", "Reference", this.onChange, this.state.reference, "Enter supplier reference")}
										{editFormControls.textControl("contactEmail", "Contact email", this.onChange, this.state.contactEmail, "Enter supplier contact email")}
										{editFormControls.textControl("contactFax", "Contact fax", this.onChange, this.state.contactFax, "Enter supplier contact fax")}
										{editFormControls.textControl("contactPhone", "Contact phone", this.onChange, this.state.contactPhone, "Enter supplier contact phone")}
										{editFormControls.checkboxControl("isIndividual", "Is individual", this.onChange, this.state.isIndividual)}
										{editFormControls.textControl("category", "Category", this.onChange, this.state.category, "Enter supplier category")}
										{editFormControls.textControl("type", "Type", this.onChange, this.state.type, "Enter supplier type")}
										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId, "Enter supplier external ID")}
										{editFormControls.textControl("customerExternalId", "External ID as customer", this.onChange, this.state.customerExternalId, "Enter supplier external ID as customer")}
										{editFormControls.textControl("forwardToExternalId", "Forward-to external ID", this.onChange, this.state.forwardToExternalId, "Enter supplier forward-to external ID")}
									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteSupplier, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ country, supplier }) => ({
	countries: country.countries,
	suppliers: supplier.suppliers,
	loading: supplier.loading,
	error: supplier.error
})

const mapDispatchToProps = dispatch => ({
	onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
	onGetSupplierById: (id) => dispatch(actionsSupplier.supplierGetById(id)),
	onCreateSupplier: (supplier, history) => dispatch(actionsSupplier.supplierCreate(supplier, history)),
	onDeleteSupplier: (id, history) => dispatch(actionsSupplier.supplierDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierEdit);
