import * as actionTypes from "./actionTypes";

const initialState = {
	tenantLicenses: null,
	loading: false,
	error: null	
}

export default function tenantLicenseReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TENANT_LICENSE_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.TENANT_LICENSE_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				tenantLicenses: action.tenantLicenses
			}
		case actionTypes.TENANT_LICENSE_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}