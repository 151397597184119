import * as actionTypes from "./actionTypes";
import * as taskHelper from "../../helpers/taskHelper";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	saveSuccess: false,
	saving: false,
	tasks: null,
}

export default function taskReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TASK_CREATE:
			return {
				...state,
				error: null,
				saveSuccess: false,
				saving: true
			}
		case actionTypes.TASK_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.TASK_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saveSuccess: true,
				saving: false,
				tasks: taskHelper.updateTasks(state.tasks, action.task)
			}
		case actionTypes.TASK_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.TASK_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.TASK_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
				tasks: taskHelper.deleteTask(state.tasks, action.taskId)
			}
		case actionTypes.TASK_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.TASK_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.TASK_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				tasks: action.tasks
			}
		case actionTypes.TASK_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}