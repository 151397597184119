import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCountry from '../../store/Country/actions';
import * as actionsCustomer from '../../store/Customer/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

class CustomerEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			customerNo: "",
			conglomerateId: "",
			dealerId: "",
			name: "",
			regNo: "",
			vatRegNo: "",
			representedBy: "",
			legalAddress: "",
			contactName: "",
			contactAddress: "",
			contactEmails: "",
			contactPhone: "",
			registrationCountryId: "",
			registrationCountryCode: "",
			invoicingEmails: "",
			bankAccountNo: "",
			bankName: "",
			bankSwiftCode: "",
			customField1: "",
			customField2: "",
			externalId: "",

			customers: [],
			errors: {},
			registrationCountryNameLang0: ""
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue = e.target.value;
		if (thisObjectName === "exchangeRate") {
			//illegalCharacters = thisValue.replaceAll('[0-1]')
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		if (this.state.id) {
			const updatedCustomer = {
				id: this.state.id,
				customerNo: this.state.customerNo,
				conglomerateId: this.state.conglomerateId,
				dealerId: this.state.dealerId,
				name: this.state.name,
				regNo: this.state.regNo,
				vatRegNo: this.state.vatRegNo,
				representedBy: this.state.representedBy,
				legalAddress: this.state.legalAddress,
				contactName: this.state.contactName,
				contactAddress: this.state.contactAddress,
				contactEmails: this.state.contactEmails,
				contactPhone: this.state.contactPhone,
				registrationCountryId: this.state.registrationCountryId,
				registrationCountryCode: this.state.registrationCountryCode,
				invoicingEmails: this.state.invoicingEmails,
				bankAccountNo: this.state.bankAccountNo,
				bankName: this.state.bankName,
				bankSwiftCode: this.state.bankSwiftCode,
				customField1: this.state.customField1,
				customField2: this.state.customField2,
				externalId: this.state.externalId,
			}
			this.props.onCreateCustomer(updatedCustomer, this.props.history);
		} else {
			const newCustomer = {
				customerNo: this.state.customerNo,
				conglomerateId: this.state.conglomerateId,
				dealerId: this.state.dealerId,
				name: this.state.name,
				regNo: this.state.regNo,
				vatRegNo: this.state.vatRegNo,
				representedBy: this.state.representedBy,
				legalAddress: this.state.legalAddress,
				contactName: this.state.contactName,
				contactAddress: this.state.contactAddress,
				contactEmails: this.state.contactEmails,
				contactPhone: this.state.contactPhone,
				registrationCountryId: this.state.registrationCountryId,
				registrationCountryCode: this.state.registrationCountryCode,
				invoicingEmails: this.state.invoicingEmails,
				bankAccountNo: this.state.bankAccountNo,
				bankName: this.state.bankName,
				bankSwiftCode: this.state.bankSwiftCode,
				customField1: this.state.customField1,
				customField2: this.state.customField2,
				externalId: this.state.externalId,
			}
			this.props.onCreateCustomer(newCustomer, this.props.history);
		}
	}

	onDeleteCustomer = () => {
		if (window.confirm("Are you sure you want to delete this customer?")) {
			this.props.onDeleteCustomer(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetCustomerById(id);
		}
		this.props.onGetCountries();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.countries !== this.props.countries) {
			if (this.props.countries && this.props.countries[0] && !this.state.registrationCountryId) {
				this.setState({
					registrationCountryId: this.props.countries[0].id
				})
			}
		}

		if (prevProps.customers !== this.props.customers) {
			if (this.props.customers && this.props.customers[0]) {

				this.setState({
					id: this.props.customers[0].id,
					customerNo: this.props.customers[0].customerNo,
					conglomerateId: this.props.customers[0].conglomerateId,
					dealerId: this.props.customers[0].dealerId,
					name: this.props.customers[0].name,
					regNo: this.props.customers[0].regNo,
					vatRegNo: this.props.customers[0].vatRegNo,
					representedBy: this.props.customers[0].representedBy,
					legalAddress: this.props.customers[0].legalAddress,
					contactName: this.props.customers[0].contactName,
					contactAddress: this.props.customers[0].contactAddress,
					contactEmails: this.props.customers[0].contactEmails,
					contactPhone: this.props.customers[0].contactPhone,
					registrationCountryId: this.props.customers[0].registrationCountryId,
					registrationCountryCode: this.props.customers[0].registrationCountryCode,
					invoicingEmails: this.props.customers[0].invoicingEmails,
					bankAccountNo: this.props.customers[0].bankAccountNo,
					bankName: this.props.customers[0].bankName,
					bankSwiftCode: this.props.customers[0].bankSwiftCode,
					customField1: this.props.customers[0].customField1,
					customField2: this.props.customers[0].customField2,
					externalId: this.props.customers[0].externalId,
				});
			} else {
				this.setState({
					id: "",
					customerNo: "",
					conglomerateId: "",
					dealerId: "",
					name: "",
					regNo: "",
					vatRegNo: "",
					representedBy: "",
					legalAddress: "",
					contactAddress: "",
					contactEmails: "",
					contactPhone: "",
					registrationCountryId: "",
					registrationCountryCode: "",
					invoicingEmails: "",
					bankAccountNo: "",
					bankName: "",
					bankSwiftCode: "",
					customField1: "",
					customField2: "",
					externalId: "",
				});
			}
		}
	}

	render() {
		const loading = this.props.loadingCountries || this.props.loadingCustomers;

		const pageTitle = (this.state.id || loading ? "Edit" : "Create") + " customer | " + config.AppName;
		const breadcrumbsTitle = "Customer";
		const breadcrumbsItem = (this.state.id || loading ? "Edit" : "New") + " customer";

		const countryOptions = inputSelectUtils.generateOptionsFromData(this.props.countries, countryRow => (countryRow.code + (countryRow.nameLang0 ? " (" + countryRow.nameLang0 + ")" : "")));

		const registrationCountryAsArray = this.props.countries && this.props.countries.length && this.state.registrationCountryId
			? this.props.countries.filter(country => country.id == this.state.registrationCountryId) : [];

		const registrationCountryName = registrationCountryAsArray && registrationCountryAsArray.length ? registrationCountryAsArray[0].nameLang0 : "";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("customerNo", "No.", this.onChange, this.state.customerNo, "Enter customer No.")}
										{editFormControls.textControl("name", "Name", this.onChange, this.state.name)}
										{editFormControls.selectControl("registrationCountryId", "Registration country", this.onChange, this.state.registrationCountryId, countryOptions)}
										{editFormControls.textControl("regNo", "Registration No.", this.onChange, this.state.regNo)}
										{editFormControls.textControl("vatRegNo", "VAT registration No.", this.onChange, this.state.vatRegNo, "Enter VAT registration No.")}
										{editFormControls.textControl("representedBy", "Represented by", this.onChange, this.state.representedBy, "Enter the person(s) representing the customer and their position(s)")}
										{editFormControls.textControl("contactName", "Main contact name", this.onChange, this.state.contactName)}
										{editFormControls.textControl("legalAddress", "Legal address", this.onChange, this.state.legalAddress)}
										{editFormControls.textControl("contactAddress", "Contact address", this.onChange, this.state.contactAddress)}
										{editFormControls.textControl("bankAccountNo", "Bank account No.", this.onChange, this.state.bankAccountNo)}
										{editFormControls.textControl("bankSwiftCode", "Bank SWIFT code", this.onChange, this.state.bankSwiftCode)}
										{editFormControls.textControl("bankName", "Bank name", this.onChange, this.state.bankName)}
										{editFormControls.textControl("contactPhone", "Contact phone", this.onChange, this.state.contactPhone)}
										{editFormControls.textControl("contactEmails", "Contact emails", this.onChange, this.state.contactEmails)}
										{editFormControls.textControl("invoicingEmails", "Invoicing emails", this.onChange, this.state.invoicingEmails)}
										{editFormControls.textControl("conglomerateId", "Conglomerate ID", this.onChange, this.state.conglomerateId)}
										{editFormControls.textControl("dealerId", "Dealer ID", this.onChange, this.state.dealerId)}
										{editFormControls.textControl("customField1", "Custom field #1", this.onChange, this.state.customField1)}
										{editFormControls.textControl("customField2", "Custom field #2", this.onChange, this.state.customField2)}

										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										{" "}
										{editFormControls.copyToClipboardButton(
											"Customer No.: " + this.state.customerNo + "\n"
											+ "Name: " + this.state.name + "\n"
											+ "Registration country: " + registrationCountryName + "\n"
											+ "Registration No.: " + this.state.regNo + "\n"
											+ "VAT registration No.: " + this.state.vatRegNo + "\n"
											+ "Represented by: " + this.state.representedBy + "\n"
											+ "Contact name: " + this.state.contactName + "\n"
											+ "Legal address: " + this.state.legalAddress + "\n"
											+ "Contact address: " + this.state.contactAddress + "\n"
											+ "Bank account No.: " + this.state.bankAccountNo + "\n"
											+ "Bank SWIFT code: " + this.state.bankSwiftCode + "\n"
											+ "Bank name: " + this.state.bankName + "\n"
											+ "Contact phone: " + this.state.contactPhone + "\n"
											+ "Contact emails: " + this.state.contactEmails + "\n"
											+ "Invoicing emails: " + this.state.invoicingEmails + "\n"
											+ "External ID: " + this.state.externalId + "\n"
											+ "Conglomerate ID: " + this.state.conglomerateId + "\n"
											+ "Dealer ID: " + this.state.dealerId + "\n"
											+ "Custom field1: " + this.state.customField1 + "\n"
											+ "Custom field2: " + this.state.customField2 + "\n"
										)}
										{" "}
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteCustomer, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(loading)}
						
						{!loading && editForm}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ country, customer }) => ({
	countries: country.countries,
	customers: customer.customers,
	deleting: customer.deleting,
	error: customer.error,
	loadingCountries: country.loading,
	loadingCustomers: customer.loading,
	saving: customer.saving
});

const mapDispatchToProps = dispatch => ({
	onCreateCustomer: (customer, history) => dispatch(actionsCustomer.customerCreate(customer, history)),
	onDeleteCustomer: (id, history) => dispatch(actionsCustomer.customerDelete(id, history)),
	onGetCountries: () => dispatch(actionsCountry.countryGetAll()),
	onGetCustomerById: (id) => dispatch(actionsCustomer.customerGetById(id)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerEdit);

