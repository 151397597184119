export const getFieldValueOptions = (dataTable, fieldName) => {

	const allFieldValues = [];
	let fieldValueOptions = {};

	for (let i in dataTable) {
		const thisFieldValue = dataTable[i][fieldName];

		if (!allFieldValues.includes(thisFieldValue)) {
			allFieldValues.push(thisFieldValue);
			fieldValueOptions = {
				...fieldValueOptions,
				[thisFieldValue]: thisFieldValue
			}
		}
	}
	return {
		[fieldName]: fieldValueOptions
	}
}