import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const bankStatementRecordExternalId = {
	dataField: 'bankStatementRecordExternalId',
	text: 'Bank statement record external ID',
	filter: textFilter(),
	sort: true
};
export const bankStatementRecordDTO = {
	dataField: 'bankStatementRecordDTO',
	text: 'Bank statement record DTO',
	filter: textFilter(),
	sort: true
};
export const supplierInvoiceDTOSet = {
	dataField: 'supplierInvoiceDTOSet',
	text: 'Supplier invoice DTOSet',
	filter: textFilter(),
	sort: true
};
export const unallocatedAmount = {
	dataField: 'unallocatedAmount',
	text: 'Unallocated',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell, row) => formatUtils.formatAmountWithDCSign(cell, row.isDebit),
	sort: true
};
export const paymentDTOSet = {
	dataField: 'paymentDTOSet',
	text: 'Payment DTOSet',
	filter: textFilter(),
	sort: true
};
export const customerInvoiceDTOSet = {
	dataField: 'customerInvoiceDTOSet',
	text: 'Customer invoice DTOSet',
	filter: textFilter(),
	sort: true
};
export const bankChargeAmount = {
	dataField: 'bankChargeAmount',
	text: 'Bank charge amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const bankAccountExternalId = {
	dataField: 'bankAccountExternalId',
	text: 'Bank account external ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountCurrencyExternalId = {
	dataField: 'bankAccountCurrencyExternalId',
	text: 'Bank account currency external ID',
	filter: textFilter(),
	sort: true
};
