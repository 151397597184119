import * as actionTypes from "./actionTypes";

export const userAccountDelete = (id, history) => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE,
		id: id,
		history: history
	}
}

export const userAccountDeleteError = (thisError) => {
	return {
		type: actionTypes.USER_ACCOUNT_DELETE_ERROR,
		error: thisError
	}
}

export const userAccountGetAll = () => {
	return {
		type: actionTypes.USER_ACCOUNT_GET_ALL
	}
}

export const userAccountGetById = (id) => {
	return {
		type: actionTypes.USER_ACCOUNT_GET_BY_ID,
		id: id
	}
}

export const userAccountGetError = (thisError) => {
	return {
		type: actionTypes.USER_ACCOUNT_GET_ERROR,
		error: thisError
	}
}

export const userAccountGetSuccess = (data) => {
	return {
		type: actionTypes.USER_ACCOUNT_GET_SUCCESS,
		userAccounts: data
	}
}

export const userAccountInvite = (invitationData) => {
	return {
		type: actionTypes.USER_ACCOUNT_INVITE,
		invitationData: invitationData
	}
}

export const userAccountInviteError = (thisError) => {
	return {
		type: actionTypes.USER_ACCOUNT_INVITE_ERROR,
		error: thisError
	}
}

export const userAccountInviteSuccess = (invitationResponse) => {
	return {
		type: actionTypes.USER_ACCOUNT_INVITE_SUCCESS,
		invitationResponse: invitationResponse
	}
}

export const userAccountUpdateRights = (updatedUserAccount, history) => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE_RIGHTS,
		updatedUserAccount: updatedUserAccount,
		history: history
	}
}

export const userAccountUpdateRightsError = (thisError) => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE_RIGHTS_ERROR,
		error: thisError
	}
}

export const userAccountUpdateRightsSuccess = () => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE_RIGHTS_SUCCESS
	}
}

