import * as actionTypes from "./actionTypes";

export const reportOfBankStatementDelete = (idList) => {
    return {
        type: actionTypes.REPORT_OF_BANK_STATEMENT_DELETE,
        idList: idList
    }
}

export const reportOfBankStatementDeleteError = (error) => {
    return {
        type: actionTypes.REPORT_OF_BANK_STATEMENT_ERROR,
        error: error
    }
}

export const reportOfBankStatementDeleteSuccess = (data) => {
    return {
        type: actionTypes.REPORT_OF_BANK_STATEMENT_SUCCESS,
        data: data
    }
}

export const reportOfBankStatementDownload = (id) => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD,
		id: id
	}
}

export const reportOfBankStatementDownloadError = (error) => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD,
		error: error
	}
}

export const reportOfBankStatementDownloadSuccess = () => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_DOWNLOAD_SUCCESS
	}
}

export const reportOfBankStatementGetAll = () => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_GET_ALL
	}
}

export const reportOfBankStatementGetError = (error) => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_GET_ERROR,
		error: error
	}
}

export const reportOfBankStatementGetSuccess = (data) => {
	return {
		type: actionTypes.REPORT_OF_BANK_STATEMENT_GET_SUCCESS,
		data: data
	}
}

export const reportOfCustomerInvoiceControlSumDownload = () => {
	return {
		type: actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD
	}
}

export const reportOfCustomerInvoiceControlSumDownloadError = (error) => {
	return {
		type: actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD,
		error: error
	}
}

export const reportOfCustomerInvoiceControlSumDownloadSuccess = () => {
	return {
		type: actionTypes.REPORT_OF_CUSTOMER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS
	}
}

export const reportOfSupplierInvoiceControlSumDownload = () => {
	return {
		type: actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD
	}
}

export const reportOfSupplierInvoiceControlSumDownloadError = (error) => {
	return {
		type: actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD,
		error: error
	}
}

export const reportOfSupplierInvoiceControlSumDownloadSuccess = () => {
	return {
		type: actionTypes.REPORT_OF_SUPPLIER_INVOICE_CONTROL_SUM_DOWNLOAD_SUCCESS
	}
}

