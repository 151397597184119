import * as actionTypes from "./actionTypes";
import * as actionTypesUserAccountLogin from "../UserAccountLogin/actionTypes";

const initialState = {
    controlCode: null,
    controlCodeLoading: false,
    countryCode: null,
    documentNumber: null,
    error: null,
    identityNumber: null,
    identityType: null,
    loggedIn: false,
    signersCertificate: null,
    signing: false
}

export default function smartIdReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SMART_ID_LOGIN_INIT:
            return {
                ...state,
                controlCode: null,
                controlCodeLoading: true,
                error: null
            }
        case actionTypes.SMART_ID_LOGIN_ERROR:
            return {
                ...state,
                controlCodeLoading: false,
                error: action.error
            }
        case actionTypes.SMART_ID_LOGIN_INIT_SUCCESS:
            return {
                ...state,
                controlCode: action.data.verificationCode,
                controlCodeLoading: false
            }
        case actionTypes.SMART_ID_LOGIN_FINALIZE_SUCCESS:
            return {
                ...state,
                controlCode: null,
                controlCodeLoading: false,
                countryCode: action.data.smartIdCountryCode,
                documentNumber: action.data.smartIdDocumentNumber,
                identityNumber: action.data.smartIdIdentityNumber,
                identityType: action.data.smartIdIdentityType,
                loggedIn: true,
                signersCertificate: action.data.smartIdSignersCertificate
            }
        case actionTypes.SMART_ID_LOGIN_REFRESH_SUCCESS:
            return {
                ...state,
                countryCode: action.data.countryCode,
                documentNumber: action.data.documentNumber,
                identityNumber: action.data.identityNumber,
                identityType: action.data.identityType,
                loggedIn: action.data.loggedIn,
                signersCertificate: action.data.signersCertificate
            }
        case actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_ERROR:
        case actionTypes.SMART_ID_SIGN_PAYMENT_FILE_ERROR:
            return {
                ...state,
                controlCode: null,
                controlCodeLoading: false,
                error: action.error,
                signing: false
            }
        case actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT:
        case actionTypes.SMART_ID_SIGN_PAYMENT_FILE_INIT:
            return {
                ...state,
                controlCode: null,
                controlCodeLoading: true,
                error: null,
                signing: true
            }
        case actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT_SUCCESS:
        case actionTypes.SMART_ID_SIGN_PAYMENT_FILE_INIT_SUCCESS:
            return {
                ...state,
                controlCode: action.data.verificationCode,
                controlCodeLoading: false
            }
        case actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_FINALIZE_SUCCESS:
        case actionTypes.SMART_ID_SIGN_PAYMENT_FILE_FINALIZE_SUCCESS:
            return {
                ...state,
                controlCode: null,
                signing: false
            }
        case actionTypesUserAccountLogin.USER_LOGOUT:
        case actionTypesUserAccountLogin.USER_LOGOUT_SUCCESS:
            return {
                ...state,
                countryCode: null,
                documentNumber: null,
                identityNumber: null,
                identityType: null,
                loggedIn: false,
                signersCertificate: null
            }
        default:
            return state;
    }
}