import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Currency/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class CurrencyEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			externalId: "",
			code: "",
			name: "",
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		if (this.state.id) {
			const updatedCurrency = {
				id: this.state.id,
				externalId: this.state.externalId,
				code: this.state.code,
				name: this.state.name
			}
			this.props.onCreateCurrency(updatedCurrency, this.props.history);
		} else {
			const newCurrency = {
				externalId: this.state.externalId,
				code: this.state.code,
				name: this.state.name
			}
			this.props.onCreateCurrency(newCurrency, this.props.history);
		}
	}

	onDeleteCurrency = () => {
		if (window.confirm("Are you sure you want to delete this currency?")) {
			this.props.onDeleteCurrency(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetCurrencyById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (this.props.currencies && this.props.currencies[0]) {
				this.setState({
					id: this.props.currencies[0].id,
					externalId: this.props.currencies[0].externalId,
					code: this.props.currencies[0].code,
					name: this.props.currencies[0].name
				});
			} else {
				this.setState({
					id: "",
					externalId: "",
					code: "",
					name: "",
				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " currency | " + config.AppName;
		const breadcrumbsTitle = "Currency";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " currency";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("code", "Currency code", this.onChange, this.state.code, "Enter currency code (e.g. EUR)...")}
										{editFormControls.textControl("name", "Currency name", this.onChange, this.state.name, "Enter currency name (e.g. Euro)...")}
										{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteCurrency, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ currency }) => ({
	currencies: currency.currencies,
	loading: currency.loading,
	error: currency.error
})

const mapDispatchToProps = dispatch => ({
	onGetCurrencyById: (id) => dispatch(actions.currencyGetById(id)),
	onCreateCurrency: (currency, history) => dispatch(actions.currencyCreate(currency, history)),
	onDeleteCurrency: (id, history) => dispatch(actions.currencyDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CurrencyEdit);
