import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency code',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const accountNo = {
	dataField: 'accountNo',
	text: 'Account No.',
	filter: textFilter(),
	sort: true
};
export const bankSwiftCode = {
	dataField: 'bankSwiftCode',
	text: 'Bank SWIFT code',
	filter: textFilter(),
	sort: true
};
export const bankAlternativeCode = {
	dataField: 'bankAlternativeCode',
	text: 'Bank alternative code',
	filter: textFilter(),
	sort: true
};
export const bankName = {
	dataField: 'bankName',
	text: 'Bank name',
	filter: textFilter(),
	sort: true
};
export const corrAccountNo = {
	dataField: 'corrAccountNo',
	text: 'Corr account No.',
	filter: textFilter(),
	sort: true
};
export const corrSwiftCode = {
	dataField: 'corrSwiftCode',
	text: 'Corr swift code',
	filter: textFilter(),
	sort: true
};
export const corrBankName = {
	dataField: 'corrBankName',
	text: 'Corr bank name',
	filter: textFilter(),
	sort: true
};
export const additionalInfo = {
	dataField: 'additionalInfo',
	text: 'Additional info',
	filter: textFilter(),
	sort: true
};
export const accountCustomType = {
	dataField: 'accountCustomType',
	text: 'Account custom type',
	filter: textFilter(),
	sort: true
};
export const useDirectChannel = {
	dataField: 'useDirectChannel',
	text: 'Use direct channel',
	filter: textFilter(),
	sort: true
};
export const signPaymentsInCRM = {
	dataField: 'signPaymentsInCRM',
	text: 'Sign payments in c r m',
	filter: textFilter(),
	sort: true
};
export const requiredSignatoryCount = {
	dataField: 'requiredSignatoryCount',
	text: 'Required signatory count',
	filter: textFilter(),
	sort: true
};
export const certFilename = {
	dataField: 'certFilename',
	text: 'Cert filename',
	filter: textFilter(),
	sort: true
};
export const certPassword = {
	dataField: 'certPassword',
	text: 'Cert password',
	filter: textFilter(),
	sort: true
};
export const gatewayAgreementId = {
	dataField: 'gatewayAgreementId',
	text: 'Gateway agreement ID',
	filter: textFilter(),
	sort: true
};
export const useTestEnvironment = {
	dataField: 'useTestEnvironment',
	text: 'Use test environment',
	filter: textFilter(),
	sort: true
};
export const sftpUsername = {
	dataField: 'sftpUsername',
	text: 'Sftp username',
	filter: textFilter(),
	sort: true
};
export const hostFingerprint = {
	dataField: 'hostFingerprint',
	text: 'Host fingerprint',
	filter: textFilter(),
	sort: true
};
export const encryptionCertificateFilename = {
	dataField: 'encryptionCertificateFilename',
	text: 'Encryption certificate filename',
	filter: textFilter(),
	sort: true
};
export const lastBalance = {
	dataField: 'lastBalance',
	text: 'Balance',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const lastBalanceLocalCurrency = {
	dataField: 'lastBalanceLocalCurrency',
	text: 'Balance (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const lastBalanceDate = {
	dataField: 'lastBalanceDate',
	text: 'Balance date',
	filter: textFilter(),
	sort: true
};
