import { numberFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import { PeriodType, PeriodTypeAsSelectFilterOptions } from 'definitions/enums/PeriodType';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const customerId = {
	dataField: 'customerId',
	text: 'Customer ID',
	filter: textFilter(),
	sort: true
};
export const agreementNo = {
	dataField: 'agreementNo',
	text: 'Agreement No.',
	filter: textFilter(),
	sort: true
};
export const signatureType = {
	dataField: 'signatureType',
	text: 'Signature type',
	filter: textFilter(),
	sort: true
};
export const signatureDate = {
	dataField: 'signatureDate',
	text: 'Signature date',
	filter: textFilter(),
	sort: true
};
export const effectiveDate = {
	dataField: 'effectiveDate',
	text: 'Effective date',
	filter: textFilter(),
	sort: true
};
export const expirationDate = {
	dataField: 'expirationDate',
	text: 'Expiration date',
	filter: textFilter(),
	sort: true
};
export const renewalType = {
	dataField: 'renewalType',
	text: 'Renewal type',
	filter: selectFilter({ options: PeriodTypeAsSelectFilterOptions }),
	sort: true,
	formatter: (cell) => (cell ? PeriodType.filter(row => row.id === cell)[0].description : "")
};
export const invoicingPeriod = {
	dataField: 'invoicingPeriod',
	text: 'Invoicing period',
	filter: selectFilter({ options: PeriodTypeAsSelectFilterOptions }),
	sort: true,
	formatter: (cell) => (cell ? PeriodType.filter(row => row.id === cell)[0].description : "")
};
export const invoiceDueDays = {
	dataField: 'invoiceDueDays',
	text: 'Invoice due days',
	filter: textFilter(),
	sort: true
};
export const signer1 = {
	dataField: 'signer1',
	text: 'Signer 1',
	filter: textFilter(),
	sort: true
};
export const signer2 = {
	dataField: 'signer2',
	text: 'Signer 2',
	filter: textFilter(),
	sort: true
};
export const customerSigner1 = {
	dataField: 'customerSigner1',
	text: 'Customer signer 1',
	filter: textFilter(),
	sort: true
};
export const customerSigner2 = {
	dataField: 'customerSigner2',
	text: 'Customer signer 2',
	filter: textFilter(),
	sort: true
};
export const customerName = {
	dataField: 'customerName',
	text: 'Customer name',
	filter: textFilter(),
	sort: true
};
