import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCustomerQuote from '../../store/CustomerQuote/actions';
import * as actionsProduct from '../../store/Product/actions';
import * as actionsUnit from '../../store/Unit/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';

class CustomerQuoteLineEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            customerQuoteId: "",
            position: "",
            description2: "",
            productId: "",
            unitId: "",
            quantity: 0,
            price: 0,
            vatRate: 0,
            discount: 0,
            sequenceNo: -1,

            customerQuoteLines: [],
            errors: {}
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedCustomerQuoteLine = {
            customerQuoteId: this.state.customerQuoteId,
            position: this.state.position,
            description2: this.state.description2,
            productId: this.state.productId,
            unitId: this.state.unitId,
            quantity: this.state.quantity,
            price: this.state.price,
            vatRate: this.state.vatRate,
            discount: this.state.discount,
            sequenceNo: this.state.sequenceNo

        }
        if (this.state.id) {
            newOrUpdatedCustomerQuoteLine = {
                id: this.state.id,
                ...newOrUpdatedCustomerQuoteLine
            }
        }
        this.props.onCreateCustomerQuoteLine(newOrUpdatedCustomerQuoteLine, this.props.history);
    }

    onDeleteCustomerQuoteLine = () => {
        if (window.confirm("Are you sure you want to delete this CustomerQuoteLine?")) {
            this.props.onDeleteCustomerQuoteLine(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.props.onGetCustomerQuoteLineById(id);
        }
        this.props.onGetProducts();
        this.props.onGetUnits();
        this.setState({
            customerQuoteId: this.props.match.params.quoteid
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.errorQuoteLines !== this.props.errorQuoteLines) {
            if (this.props.errorQuoteLines) {
                this.setState({
                    errors: this.props.errorQuoteLines
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.units !== this.props.units) {
            if (!this.state.unitId && this.props.units) {
                this.setState({
                    unitId: this.props.units[0].id
                });
            }
        }

        if (prevProps.customerQuoteLines !== this.props.customerQuoteLines) {
            if (this.props.customerQuoteLines && this.props.customerQuoteLines[0]) {

                this.props.onGetCustomerQuoteById(this.props.customerQuoteLines[0].customerQuoteId);

                this.setState({
                    id: this.props.customerQuoteLines[0].id,
                    customerQuoteId: this.props.customerQuoteLines[0].customerQuoteId,
                    position: this.props.customerQuoteLines[0].position,
                    description2: this.props.customerQuoteLines[0].description2,
                    productId: this.props.customerQuoteLines[0].productId,
                    unitId: this.props.customerQuoteLines[0].unitId,
                    quantity: this.props.customerQuoteLines[0].quantity,
                    price: this.props.customerQuoteLines[0].price,
                    vatRate: this.props.customerQuoteLines[0].vatRate,
                    discount: this.props.customerQuoteLines[0].discount,
                    sequenceNo: this.props.customerQuoteLines[0].sequenceNo

                });
            } else {
                this.setState({
                    id: "",
                    position: "",
                    description2: "",
                    productId: "",
                    unitId: "",
                    quantity: 0,
                    price: 0,
                    vatRate: 0,
                    discount: 0,
                    sequenceNo: -1
                });
            }
        }

    }

    render() {
        const pageTitle = (this.state.id || this.props.loadingQuoteLines ? "Edit" : "Create") + " Customer quote line | " + config.AppName;
        const breadcrumbsTitle = "Customer quote";
        const breadcrumbsItem = (this.state.id || this.props.loadingQuoteLines ? "Edit" : "New") + " line";
        const returnLink = this.state.customerQuoteId ? endpointsFrontend.CUSTOMER_QUOTE_EDIT.replace(":id", this.state.customerQuoteId) : "";

        const productOptions = this.props.products ? this.props.products.map(productRow => (
            <option
                value={productRow.id}
                key={productRow.id}
            >
                {productRow.nameLang0}
            </option>
        )) : null;

        const productOptionsPlusNull = productOptions ?
            [
                (<option
                    value=""
                    key="-1"
                >
                </option>),
                ...productOptions
            ] : null;

        const unitOptions = this.props.units ? this.props.units.map(unitRow => (
            <option
                value={unitRow.id}
                key={unitRow.id}
            >
                {unitRow.nameLang0}
            </option>
        )) : null;

        const totalAmountWOVAT = Math.round((this.state.price * this.state.quantity * (100 - this.state.discount) / 100) * 100) / 100;
        const totalAmountWVAT = totalAmountWOVAT * (100 + parseFloat(this.state.vatRate)) / 100;
        const currencyCode = this.props.customerQuotes[0].currencyCode;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">
                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.hiddenValueControl("customerQuoteId", this.onChange, this.state.customerQuoteId)}
                                        {editFormControls.hiddenValueControl("sequenceNo", this.onChange, this.state.sequenceNo)}
                                        {editFormControls.textControl("position", "Position", this.onChange, this.state.position)}
                                        {editFormControls.textControl("description2", "Alternative description (optional)", this.onChange, this.state.description2)}
                                        {editFormControls.selectControl("productId", "Product", this.onChange, this.state.productId, productOptionsPlusNull)}
                                        {editFormControls.selectControl("unitId", "Unit", this.onChange, this.state.unitId, unitOptions)}
                                        {editFormControls.numberControl("quantity", "Quantity", this.onChange, this.state.quantity, 0.5)}
                                        {editFormControls.numberControl("price", "Price, " + currencyCode, this.onChange, this.state.price, 0.01)}
                                        {editFormControls.numberControl("vatRate", "VAT rate, %", this.onChange, this.state.vatRate, 1, "Enter VAT rate, %")}
                                        {editFormControls.numberControl("discount", "Discount, %", this.onChange, this.state.discount, 5)}
                                        {editFormControls.staticTextControl("totalAmountWOVAT", "Total amount excluding VAT, " + currencyCode, formatUtils.formatAmount(totalAmountWOVAT))}
                                        {editFormControls.staticTextControl("totalAmountWVAT", "Total amount including VAT, " + currencyCode, formatUtils.formatAmount(totalAmountWVAT))}
                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteCustomerQuoteLine, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loadingQuoteLines || this.props.loadingQuotes || this.props.loadingProducts || this.props.loadingUnits;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs
                            title={breadcrumbsTitle}
                            breadcrumbItem={breadcrumbsItem}
                            link={returnLink}
                        />

                        {editFormControls.errorAlert(this.props.errorQuoteLines)}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && this.state.customerQuoteId ? editForm : null}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ customerQuote, product, unit }) => ({
    units: unit.units,
    products: product.products,
    customerQuotes: customerQuote.customerQuotes,
    customerQuoteLines: customerQuote.customerQuoteLines,
    loadingQuotes: customerQuote.loading,
    loadingQuoteLines: customerQuote.loadingLines,
    loadingProducts: product.loading,
    loadingUnits: unit.loading,
    saving: customerQuote.saving,
    errorQuotes: customerQuote.error,
    errorQuoteLines: customerQuote.errorLines,
    errorUnits: unit.error
})

const mapDispatchToProps = dispatch => ({
    onGetCustomerQuoteLineById: (id) => dispatch(actionsCustomerQuote.customerQuoteLineGetById(id)),
    onGetCustomerQuoteById: (id) => dispatch(actionsCustomerQuote.customerQuoteGetById(id)),
    onGetUnits: () => dispatch(actionsUnit.unitGetAll()),
    onGetProducts: () => dispatch(actionsProduct.productGetAll()),
    onCreateCustomerQuoteLine: (customerQuoteLine, history) => dispatch(actionsCustomerQuote.customerQuoteLineCreate(customerQuoteLine, history)),
    onDeleteCustomerQuoteLine: (id, history) => dispatch(actionsCustomerQuote.customerQuoteLineDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerQuoteLineEdit);
