export const getEntriesFromResponseData = (responseData) => {
    const entries = [];
    for (let key in responseData) {
        entries.push({
            ...responseData[key]
        });
    }

    return entries;
}

export const updateEntries = (stateField, updatedEntry) => {
	const entries = [...stateField];
	let updated = false;
	for (let i = 0; i < entries.length; ++i) {
		if (entries[i].id === updatedEntry.id) {
			entries[i] = updatedEntry;
			updated = true;
			break;
		}
	}
	if (!updated) {
		entries.push(updatedEntry);
	}
	return entries;
}

