import * as actionTypes from "./actionTypes";

export const paymentFileDelete = (id, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_DELETE,
		id: id,
		history: history
	}
}

export const paymentFileDeleteList = (paymentFilesToDelete, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_DELETE_LIST,
		paymentFilesToDelete: paymentFilesToDelete,
		history: history
	}
}

export const paymentFileDeleteError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_FILE_DELETE_ERROR,
		error: thisError
	}
}

export const paymentFileDeleteSuccess = () => {
	return {
		type: actionTypes.PAYMENT_FILE_DELETE_SUCCESS
	}
}

export const paymentFileGetError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET_ERROR,
		error: thisError
	}
}

export const paymentFileGet = (filter) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET,
		filter: filter
	}
}

export const paymentFileGetSuccess = (data) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET_SUCCESS,
		paymentFiles: data
	}
}

export const paymentFileGetTotal = (selectedPaymentFiles, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET_TOTAL,
		selectedPaymentFiles: selectedPaymentFiles,
		history: history
	}
}

export const paymentFileGetTotalError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET_TOTAL_ERROR,
		error: thisError
	}
}

export const paymentFileGetTotalSuccess = (total) => {
	return {
		type: actionTypes.PAYMENT_FILE_GET_TOTAL_SUCCESS,
		total: total
	}
}

export const paymentFileRefreshStatus = (filter) => {
	return {
		type: actionTypes.PAYMENT_FILE_REFRESH_STATUS,
		filter: filter
	}
}

export const paymentFileRefreshStatusError = () => {
	return {
		type: actionTypes.PAYMENT_FILE_REFRESH_STATUS_ERROR
	}
}

export const paymentFileRefreshStatusSuccess = () => {
	return {
		type: actionTypes.PAYMENT_FILE_REFRESH_STATUS_SUCCESS
	}
}

export const paymentFileResetFailedBulkList = (paymentFilesToReset, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_LIST,
		paymentFilesToReset: paymentFilesToReset,
		history: history
	}
}

export const paymentFileResetFailedBulkError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_ERROR,
		error: thisError
	}
}

export const paymentFileResetFailedBulkSuccess = () => {
	return {
		type: actionTypes.PAYMENT_FILE_RESET_FAILED_BULK_SUCCESS
	}
}

export const paymentFileSignGetCertificate = () => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_GET_CERTIFICATE
	}
}

export const paymentFileSignInit = (paymentFileIdList, signingCertificate, signingCertificateObject, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_INIT,
		paymentFileIdList: paymentFileIdList,
		signingCertificate: signingCertificate,
		signingCertificateObject: signingCertificateObject,
		history: history
	}
}

export const paymentFileSignError = (thisError) => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_ERROR,
		error: thisError
	}
}

export const paymentFileSignReset = (paymentFilesToReset, history) => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_RESET,
		paymentFilesToReset: paymentFilesToReset,
		history: history
	}
}

export const paymentFileSignResetSuccess = (paymentFiles) => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_RESET_SUCCESS,
		paymentFiles: paymentFiles
	}
}

export const paymentFileSignSuccess = (paymentFiles) => {
	return {
		type: actionTypes.PAYMENT_FILE_SIGN_SUCCESS,
		paymentFiles: paymentFiles
	}
}

