import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actions from '../../store/Counter/actions';
import * as CounterType from '../../definitions/enums/CounterType';
import Backdrop from '../Common/Backdrop';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

import classes from './Pages.module.css';

const fieldDefaultValues = {
    id: 0,
    currentValue: null,
    prefix: "",
    postfix: "",
    type: "",
}

class EditCounter extends Component {

    constructor(props) {
        super(props);

        const CounterTypeOptions = inputSelectUtils.generateOptionsFromData(
            CounterType.CounterType,
            row => row.description
        );

        this.state = {
            ...fieldDefaultValues,

            changed: false,
            CounterTypeOptions: CounterTypeOptions
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue,
            changed: true
        });
    }

    setStateFromCounter() {
        if (this.props.counter && this.props.counter.id) {
            this.setState({
                id: this.props.counter.id,
                currentValue: this.props.counter.currentValue,
                prefix: this.props.counter.prefix,
                postfix: this.props.counter.postfix,
                type: this.props.counter.type,
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            });
        }
    }

    setCounterTypeOptions() {
        // Only those counter types are allowed that are either not already assigned to other counters
        // or that is assigned to the counter currently being edited.
        const alreadyAssignedCounterTypes = this.props.counters.map(row => row.type);
        const availableCounterTypes = CounterType.CounterType.filter(row => !alreadyAssignedCounterTypes.includes(row.id) || (this.props.counter && this.props.counter.id && row.id === this.props.counter.type));
        const CounterTypeOptions = inputSelectUtils.generateOptionsFromData(
            availableCounterTypes,
            row => row.description
        );
        const thisCounterType = (this.props.counter && this.props.counter.id ? this.props.counter.type : availableCounterTypes[0].id);
        this.setState({
            CounterTypeOptions: CounterTypeOptions,
            type: thisCounterType
        });
    }

    componentDidMount() {
        this.setStateFromCounter();
        this.setCounterTypeOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.counter !== this.props.counter) {
            this.setStateFromCounter();
        }

        if (prevProps.counter !== this.props.counter || prevProps.counters !== this.props.counters) {
            this.setCounterTypeOptions();
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        if (this.state.currentValue == null || this.state.currentValue === "") {
            const currentValueInput = document.getElementById("currentValue");
            this.setState({
                changed: true   // for new counters
            });
            currentValueInput.focus();
            return;
        }
        let newOrUpdatedCounter = {
            currentValue: this.state.currentValue,
            prefix: this.state.prefix,
            postfix: this.state.postfix,
            type: this.state.type,
        };
        if (this.state.id) {
            newOrUpdatedCounter = {
                id: this.state.id,
                ...newOrUpdatedCounter
            };
        }
        this.props.onSaveCounter(newOrUpdatedCounter);
        this.setState({
            changed: false
        });
        this.props.onClose();
    }

    closeForm = () => {
        this.props.onClose();
    }

    render() {

        return (

            <div>
                <Backdrop
                    show
                    onClick={this.closeForm}
                >
                </Backdrop>
                <div
                    className={classes.RequestBankStatementContainer}
                >
                    <Card
                        // className={classes.PageCard} // See ColummSelectionForm, SelectCustomerInvoices etc
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.counter ? "Edit" : "Create"} counter
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.closeForm}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>

                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}

                                {editFormControls.selectControl("type", "Type", this.onChange, this.state.type, this.state.CounterTypeOptions)}
                                {editFormControls.textControl("prefix", "Prefix", this.onChange, this.state.prefix)}
                                {editFormControls.numberControl("currentValue", "Current value", this.onChange, this.state.currentValue, 1, "Enter current value", false, this.state.changed && (this.state.currentValue == null || this.state.currentValue === ""))}
                                {editFormControls.textControl("postfix", "Postfix", this.onChange, this.state.postfix)}

                                <Row>
                                    <Col></Col>
                                    <Col>
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                    </Col>
                                </Row>
                            </form>

                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ counter }) => ({
    counters: counter.counters,
    saving: counter.saving,
});

const mapDispatchToProps = dispatch => ({
    onSaveCounter: (counter) => dispatch(actions.counterCreate(counter))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCounter);