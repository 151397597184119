import React from 'react';

import classes from './Backdrop.module.css'

const Backdrop = (props) => (
	props.show
		? <div
			className={classes.Backdrop}
			onClick={props.onClick}
			style={props.zIndex ? { zIndex: props.zIndex } : null}
		/>
		: null
);

export default Backdrop;