import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import { downloadHelper } from '../../helpers/downloadHelper';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* getExternalDataExportFileSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.EXTERNAL_DATA_EXPORT_GET_FILE_BY_ID.replace("{fileId}", id)
		);
		yield call(downloadHelper, response);

		yield put(actions.externalDataExportFileGetSuccess());
	} catch (e) {
		yield put(actions.externalDataExportFileGetError(e));
	}
}

function* getAllExternalDataSyncRequestsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL
		);
		yield put(actions.externalDataSyncRequestGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.externalDataSyncRequestGetError(e));
	}
}

function* createExternalDataSyncRequestSaga({ externalDataSyncRequest }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.EXTERNAL_DATA_SYNC_REQUEST,
			externalDataSyncRequest
		);
		yield put(actions.externalDataSyncRequestCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.externalDataSyncRequestCreateError(e));
	}
}

function* deleteExternalDataSyncRequestSaga({ id }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.EXTERNAL_DATA_SYNC_REQUEST_GET_BY_ID.replace("{externalDataSyncRequestId}", id)
		);
		yield put(actions.externalDataSyncRequestDeleteSuccess(response.data));
	} catch (e) {
		yield put(actions.externalDataSyncRequestDeleteError(e));
	}
}

function* externalDataSyncRequestSaga() {
	yield (takeLatest(actionTypes.EXTERNAL_DATA_EXPORT_FILE_GET, getExternalDataExportFileSaga));
	yield (takeLatest(actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL, getAllExternalDataSyncRequestsSaga));
	yield (takeLatest(actionTypes.EXTERNAL_DATA_SYNC_REQUEST_GET_ALL_WITHOUT_SPINNER, getAllExternalDataSyncRequestsSaga));
	yield (takeLatest(actionTypes.EXTERNAL_DATA_SYNC_REQUEST_CREATE, createExternalDataSyncRequestSaga));
	yield (takeLatest(actionTypes.EXTERNAL_DATA_SYNC_REQUEST_DELETE, deleteExternalDataSyncRequestSaga));
}

export default externalDataSyncRequestSaga;