import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import { getEntriesFromResponseData } from 'helpers/sagaAndReducerHelper';
import { modules } from 'definitions/modules';
import * as rest from "../../helpers/restHelper";

function* deleteUserAccountSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.USER_ACCOUNT_GET_BY_ID.replace("{userAccountId}", id)
		);
		history.push(endpointsFrontend.USER_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.userAccountDeleteError(e));
	}
}

function* getAllUserAccountsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.USER_ACCOUNT_GET_ALL
		);

		const entries = [];
		for (let key in response.data) {

			const entry = response.data[key];

			let companyInfo = "";
			if (entry.companies.length === 0) {
				companyInfo = "-";
			} else {
				for (let j in entry.companies) {
					companyInfo += (companyInfo.length ? ", " : "") + entry.companies[j].name
				}
			}

			let userRoleInfo = "";
			if (entry.userRoleNames.length === 0) {
				userRoleInfo = "-";
			} else {
				for (let j in entry.userRoleNames) {
					const roleDescription = entry.userRoleNames[j] === "ROLE_ADMIN" ? "Administrator" : entry.userRoleNames[j];
					userRoleInfo += (userRoleInfo.length ? ", " : "") + roleDescription;
				}
			}

			let userAuthorizationInfo = "";
			if (entry.userAuthorizationNames.length === 0) {
				userAuthorizationInfo = "-";
			} else {
				const moduleDescriptions = modules
					.filter(module => entry.userAuthorizationNames.includes("AUTHORIZED_" + module.name))
					.map(module => module.description);
				for (let j in moduleDescriptions) {
					userAuthorizationInfo += (userAuthorizationInfo.length ? ", " : "") + moduleDescriptions[j];
				}
			}

			entries.push({
				...entry,
				companyInfo: companyInfo,
				userRoleInfo: userRoleInfo,
				userAuthorizationInfo: userAuthorizationInfo
			});
		}


		yield put(actions.userAccountGetSuccess(getEntriesFromResponseData(entries)));
	} catch (e) {
		yield put(actions.userAccountGetError(e));
	}
}

function* getUserAccountByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.USER_ACCOUNT_GET_BY_ID.replace("{userAccountId}", id)
		);

		yield put(actions.userAccountGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.userAccountGetError(e));
	}
}

function* inviteUserSaga({ invitationData }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.USER_INVITATION_CREATE,
			invitationData
		);
		yield put(actions.userAccountInviteSuccess(response.data));
	} catch(e) {
		yield put(actions.userAccountInviteError(e));
	}
}

function* updateRightsUserAccountSaga({ updatedUserAccount, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_UPDATE_RIGHTS,
			updatedUserAccount
		);
		yield put(actions.userAccountUpdateRightsSuccess());
		history.push(endpointsFrontend.USER_ACCOUNT_BROWSE);
	} catch (e) {
		yield put(actions.userAccountUpdateRightsError(e));
	}
}

function* userAccountSaga() {
	yield (takeLatest(actionTypes.USER_ACCOUNT_DELETE, deleteUserAccountSaga));
	yield (takeLatest(actionTypes.USER_ACCOUNT_GET_ALL, getAllUserAccountsSaga));
	yield (takeLatest(actionTypes.USER_ACCOUNT_GET_BY_ID, getUserAccountByIdSaga));
	yield (takeLatest(actionTypes.USER_ACCOUNT_INVITE, inviteUserSaga));
	yield (takeLatest(actionTypes.USER_ACCOUNT_UPDATE_RIGHTS, updateRightsUserAccountSaga));
}

export default userAccountSaga;