import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import profile from "../../assets/images/cm-logo-transparent.png";

class RegisterSuccess extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
                                        <img src={profile} alt="" className="img-fluid" />
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                Thank you for registering!
                                            </Alert>
                                        </div>
                                        <div className="text-center">
                                            <p>
                                                Your account has been successfully activated.
                                                <br />
                                                You can now{" "}
                                                <Link
                                                    to={endpointsFrontend.USER_ACCOUNT_LOGIN}
                                                    className="fw-medium text-primary"
                                                >
                                                    Login
                                                </Link>
                                            </p>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Copyright © {new Date().getFullYear()} D.Vision SIA
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default RegisterSuccess;