import { connect } from "react-redux";

import * as actionsCounter from '../../store/Counter/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsCustomerQuote from '../../store/CustomerQuote/actions';
import * as actionsCustomer from '../../store/Customer/actions';

import CustomerQuoteInvoiceEdit from "pages/CustomerQuoteInvoice/customer-quote-invoice-edit";

const mapStateToProps = ({ counter, currency, customer, customerQuote }) => ({
	targetNameLowercase: "customer quote",
	targetNameCapitalized: "Customer quote",
	targetNameShort: "quote",
	targetNameShortCapitalized: "Quote",
	allocations: null,
	copying: customerQuote.copying,
	copyingLines: customerQuote.copyingLines,
	copySuccess: customerQuote.copySuccess,
	counterValue: counter.value,
	currencies: currency.currencies,
	customerQuotes: customerQuote.customerQuotes,
	customerQuoteLines: customerQuote.customerQuoteLines,
	customers: customer.customers,
	deleting: customerQuote.deleting,
	deletingLines: customerQuote.deletingLines,
	error: customerQuote.error,
	errorCounters: counter.error,
	errorLines: customerQuote.errorLines,
	loading: customerQuote.loading,
	loadingAllocations: false,
	loadingLines: customerQuote.loadingLines,
	loadingCounters: counter.loading,
	loadingCurrencies: currency.loading,
	loadingCustomers: customer.loading,
	movingLines: customerQuote.movingLines,
	printing: customerQuote.printing,
	saving: customerQuote.saving
});

const mapDispatchToProps = dispatch => ({
	onCopyCustomerQuote: (id) => dispatch(actionsCustomerQuote.customerQuoteCopy(id)),
	onCopyCustomerQuoteLines: (idList) => dispatch(actionsCustomerQuote.customerQuoteLineCopy(idList)),
	onCreateCustomerQuote: (customerQuote, history) => dispatch(actionsCustomerQuote.customerQuoteCreate(customerQuote, history)),
	onDeleteCustomerQuote: (id, history) => dispatch(actionsCustomerQuote.customerQuoteDelete(id, history)),
	onDeleteCustomerQuoteLines: (idList) => dispatch(actionsCustomerQuote.customerQuoteLineDeleteList(idList)),
	onGetAndUpdateCounter: () => dispatch(actionsCounter.counterGetAndUpdate("CUSTOMER_QUOTE_NO")),
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetCustomers: () => dispatch(actionsCustomer.customerGetAll()),
	onGetCustomerQuoteLines: (customerQuoteId) => dispatch(actionsCustomerQuote.customerQuoteLineGetAll(customerQuoteId)),
	onGetCustomerQuoteById: (id) => dispatch(actionsCustomerQuote.customerQuoteGetById(id)),
	onMoveCustomerQuoteLineUp: (id) => dispatch(actionsCustomerQuote.customerQuoteLineMoveUp(id)),
	onMoveCustomerQuoteLineDown: (id) => dispatch(actionsCustomerQuote.customerQuoteLineMoveDown(id)),
	onPrintCustomerQuote: (id) => dispatch(actionsCustomerQuote.customerQuotePrintToPDF(id))
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerQuoteInvoiceEdit);
