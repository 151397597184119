import { dateFilter, numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const supplierId = {
	dataField: 'supplierId',
	text: 'Supplier ID',
	filter: textFilter(),
	sort: true
};
export const invoiceNo = {
	dataField: 'invoiceNo',
	text: 'Invoice No.',
	filter: textFilter(),
	sort: true
};
export const invoiceNoAlt = {
	dataField: 'invoiceNoAlt',
	text: 'Alt. invoice No.',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const invoiceDate = {
	dataField: 'invoiceDate',
	text: 'Invoice date',
	filter: textFilter(),
	sort: true
};
export const dueDate = {
	dataField: 'dueDate',
	text: 'Due date',
	filter: dateFilter(),
	sort: true
};
export const bookEntryDate = {
	dataField: 'bookEntryDate',
	text: 'Book entry date',
	filter: textFilter(),
	sort: true
};
export const invoiceAmount = {
	dataField: 'invoiceAmount',
	text: 'Amount incl. VAT',
	filter: numberFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const paidAmountOutsideCRM = {
	dataField: 'paidAmountOutsideCRM',
	text: 'Paid amount outside CM',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const paidAmountLocalCurrency = {
	dataField: 'paidAmountLocalCurrency',
	text: 'Paid amount (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const unpaidAmountLocalCurrency = {
	dataField: 'unpaidAmountLocalCurrency',
	text: 'Unpaid amount (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const totalPaidAmountLocalCurrency = {
	dataField: 'totalPaidAmountLocalCurrency',
	text: 'Total paid amount (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const validPaymentDiscountLocalCurrency = {
	dataField: 'validPaymentDiscountLocalCurrency',
	text: 'Valid payment discount (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const vatAmount = {
	dataField: 'vatAmount',
	text: 'VAT amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const withholdAmount = {
	dataField: 'withholdAmount',
	text: 'Withhold amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const allocatedAmount = {
	dataField: 'allocatedAmount',
	text: 'Allocated amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const unallocatedAmount = {
	dataField: 'unallocatedAmount',
	text: 'Unallocated amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const discountPercent1 = {
	dataField: 'discountPercent1',
	text: 'Discount 1, %',
	filter: textFilter(),
	sort: true
};
export const discountPercent2 = {
	dataField: 'discountPercent2',
	text: 'Discount 2, %',
	filter: textFilter(),
	sort: true
};
export const discountPercent3 = {
	dataField: 'discountPercent3',
	text: 'Discount 3, %',
	filter: textFilter(),
	sort: true
};
export const discountDueDate1 = {
	dataField: 'discountDueDate1',
	text: 'Discount 1 due date',
	filter: dateFilter(),
	sort: true
};
export const discountDueDate2 = {
	dataField: 'discountDueDate2',
	text: 'Discount 2 due date',
	filter: dateFilter(),
	sort: true
};
export const discountDueDate3 = {
	dataField: 'discountDueDate3',
	text: 'Discount 3 due date',
	filter: dateFilter(),
	sort: true
};
export const customExchangeRate = {
	dataField: 'customExchangeRate',
	text: 'Custom exchange rate',
	filter: textFilter(),
	sort: true
};
export const reference = {
	dataField: 'reference',
	text: 'Reference',
	filter: textFilter(),
	sort: true
};
export const blocked = {
	dataField: 'blocked',
	text: 'Blocked',
	filter: textFilter(),
	sort: true
};
export const dimension1 = {
	dataField: 'dimension1',
	text: 'Dimension 1',
	filter: textFilter(),
	sort: true
};
export const dimension2 = {
	dataField: 'dimension2',
	text: 'Dimension 2',
	filter: textFilter(),
	sort: true
};
export const dimension3 = {
	dataField: 'dimension3',
	text: 'Dimension 3',
	filter: textFilter(),
	sort: true
};
export const supplierName = {
	dataField: 'supplierName',
	text: 'Supplier name',
	filter: textFilter(),
	sort: true
};
export const supplierRegNo = {
	dataField: 'supplierRegNo',
	text: 'Supplier registration No.',
	filter: textFilter(),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const unpaidAmount = {
	dataField: 'unpaidAmount',
	text: 'Unpaid amount',
	filter: numberFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const invoiceAmountWithoutVAT = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'invoiceAmountWithoutVAT',
	text: 'Total amount without VAT'
};
export const vatRate = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'vatRate',
	text: 'VAT rate, %'
};
export const showPaidAmount = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'showPaidAmount',
	text: 'Paid amount'
};
export const useWithholdingTax = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'useWithholdingTax',
	text: 'Use withholding tax'
};
export const useCashDiscounts = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'useCashDiscounts',
	text: 'Use cash discounts'
};
export const useCustomExchangeRate = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'useCustomExchangeRate',
	text: 'Use custom exchange rate'
};
export const showERPRelatedFields = {
	// Used in supplier-invoice-edit for column selection only
	dataField: 'showERPRelatedFields',
	text: 'Show ERP-related fields'
};
export const externalDocumentType = {
	dataField: 'externalDocumentType',
	text: 'External document type',
	filter: textFilter(),
	sort: true
};
export const externalDocumentNo = {
	dataField: 'externalDocumentNo',
	text: 'External document No.',
	filter: textFilter(),
	sort: true
};
export const category = {
	dataField: 'category',
	text: 'Category',
	filter: textFilter(),
	sort: true
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: textFilter(),
	sort: true
};
export const preferredPurposeCode = {
	dataField: 'preferredPurposeCode',
	text: 'Preferred purpose code',
	filter: textFilter(),
	sort: true
};
export const supplierExternalId = {
	dataField: 'supplierExternalId',
	text: 'Supplier external ID',
	filter: textFilter(),
	sort: true
};
