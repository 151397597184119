import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

import * as actionsTag from '../../store/Tag/actions';
import * as actionsTask from '../../store/Task/actions';
import * as actionsTimelog from '../../store/Timelog/actions';
import * as actionsTimelogTimer from '../../store/TimelogTimer/actions';
import * as actionsUserAccount from '../../store/UserAccount/actions';
import AddTimelogEntry from "components/Pages/AddTimelogEntry";
import Backdrop from '../Common/Backdrop';
import * as columnsTimelog from '../../definitions/columns/timelog';
import * as dateAndTimeUtils from '../../helpers/dateAndTimeUtils';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import * as tagHelper from "../../helpers/tagHelper";
import { TaskStatus } from "definitions/enums/TaskStatus";
import TimelogTimerStartPauseControl from "components/CommonForBoth/TimelogTimerStartPauseControl";

import classes from './Pages.module.css';

const fieldDefaultValues = {
    id: "",
    externalId: "",
    createdByUserAccountId: "",
    assignedToUserAccountId: "",
    createdOn: "",
    completedOn: "",
    taskStatus: "ACTIVE",
    startDate: "",
    dueDate: "",
    title: "",
    description: "",
    createdByUserAccountFirstName: "",
    createdByUserAccountLastName: "",
    createdByUserAccountUsername: "",
    assignedToUserAccountFirstName: "",
    assignedToUserAccountLastName: "",
    assignedToUserAccountUsername: "",
    totalHoursLogged: 0,
    isStarred: false,
    tags: [],
}

class EditTask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...fieldDefaultValues,

            hoveredTag: null,
            showAddNewTag: false,
            tagSearchInput: "",
            tasks: [],
            changed: false,
            frontendTimerHoursPassed: 0,
            showAddTimelogEntryForm: false,
            showTimelog: false,
            errors: {},
            timelogTimers: [],
            crmLexicalEditorInitialScrollUp: false,

        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        const thisObjectType = e.target.type;
        let thisValue;
        if (thisObjectType == "checkbox") {
            thisValue = e.target.checked;
        } else if (thisObjectType === "crm-lexical-editor") {
            thisValue = this.visualizeTask(e.target.value);
        } else {
            thisValue = e.target.value;
        }
        // After all components have been loaded, crmLexicalEditor gets updated with a new value, which causes its own onChange() to get fired 
        // and the browser then scrolls down to the end of crmLexicalEitor. 
        // To workaround it, we scroll up after crmLexicalEditor's onChange() has been fired for the first time.
        if (thisObjectType === "crm-lexical-editor") {
            if (!this.state.crmLexicalEditorInitialScrollUp) {
                const scrollableControlsDiv = document.getElementById("scrollableControlsDiv");
                if (scrollableControlsDiv) {
                    scrollableControlsDiv.scrollTop = 0;
                }
                this.setState({
                    crmLexicalEditorInitialScrollUp: true
                });
            } else {
                this.setState({
                    changed: true,
                    [thisObjectName]: thisValue
                });
            }
        } else {
            this.setState({
                changed: true,
                [thisObjectName]: thisValue
            });
        }
    }

    onAttemptCloseForm = (changed) => {
        if (changed) {
            if (window.confirm("Are you sure you want to discard changes?")) {
                this.props.onClose();
            }
        } else {
            this.props.onClose();
        }
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedTask = {
            externalId: this.state.externalId,
            createdByUserAccountId: this.state.createdByUserAccountId,
            assignedToUserAccountId: this.state.assignedToUserAccountId,
            createdOn: this.state.createdOn,
            completedOn: this.state.completedOn,
            taskStatus: this.state.taskStatus,
            startDate: this.state.startDate,
            dueDate: this.state.dueDate,
            title: this.state.title,
            description: this.state.description,
            createdByUserAccountFirstName: this.state.createdByUserAccountFirstName,
            createdByUserAccountLastName: this.state.createdByUserAccountLastName,
            createdByUserAccountUsername: this.state.createdByUserAccountUsername,
            assignedToUserAccountFirstName: this.state.assignedToUserAccountFirstName,
            assignedToUserAccountLastName: this.state.assignedToUserAccountLastName,
            assignedToUserAccountUsername: this.state.assignedToUserAccountUsername,
            isStarred: this.state.isStarred,
            tags: this.state.tags
        }
        if (this.state.id) {
            newOrUpdatedTask = {
                id: this.state.id,
                ...newOrUpdatedTask
            }
        }
        this.setState({
            changed: false
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
        this.props.onCreateTask(newOrUpdatedTask, this.props.history);
        this.onAttemptCloseForm(false);
    }

    visualizeTask = (htmlString) => {
        // const taskLinkBase = [
        //     "https://app.cashmanager.lv" + endpointsFrontend.TASK_EDIT.replace(":id", ""),
        //     "https://app.garciems.lv" + endpointsFrontend.TASK_EDIT.replace(":id", ""),
        //     "http://localhost:3000" + endpointsFrontend.TASK_EDIT.replace(":id", "")
        // ];

        // console.log("visualize")
        // for (let i in taskLinkBase) {
        //     if (htmlString.includes(taskLinkBase[i])) {
        //         const regexPattern = '<a href="' + taskLinkBase[i] + '.*?>(.*?)<\/a>';
        //         console.log(htmlString.match(regexPattern));
        //     }
        // }

        return htmlString;
    }

    onDeleteTask = () => {
        if (window.confirm("Are you sure you want to delete this Task?")) {
            this.props.onDeleteTask(this.state.id, this.props.history);
            this.onAttemptCloseForm(false);
        }
    }

    getTaskData = () => {
        if (this.props.task) {
            this.setState({
                id: this.props.task.id,
                externalId: this.props.task.externalId,
                createdByUserAccountId: this.props.task.createdByUserAccountId,
                assignedToUserAccountId: this.props.task.assignedToUserAccountId,
                createdOn: this.props.task.createdOn,
                completedOn: this.props.task.completedOn,
                taskStatus: this.props.task.taskStatus,
                startDate: this.props.task.startDate,
                dueDate: this.props.task.dueDate,
                title: this.props.task.title,
                description: this.props.task.description,
                createdByUserAccountFirstName: this.props.task.createdByUserAccountFirstName,
                createdByUserAccountLastName: this.props.task.createdByUserAccountLastName,
                createdByUserAccountUsername: this.props.task.createdByUserAccountUsername,
                assignedToUserAccountFirstName: this.props.task.assignedToUserAccountFirstName,
                assignedToUserAccountLastName: this.props.task.assignedToUserAccountLastName,
                assignedToUserAccountUsername: this.props.task.assignedToUserAccountUsername,
                totalHoursLogged: 0,
                isStarred: this.props.task.isStarred,
                tags: tagHelper.setTagShortTitles(this.props.task.tags)
            });
        } else {
            this.setState({
                ...fieldDefaultValues
            })
        }
    }

    getTimelogData = () => {
        if (this.props.task) {
            const { id } = this.props.task;
            if (id) {
                this.props.onGetTimelogsByTaskId(id);
            }
        }
    }

    componentDidMount() {
        this.props.onGetUserAccounts();
        this.props.onGetTags();
        this.getTaskData();
        this.getTimelogData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.task !== this.props.task && this.props.task) {
            this.getTaskData();
            this.getTimelogData();
        }

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.timelogTimers !== this.props.timelogTimers) {
            this.setState({
                timelogTimers: this.props.timelogTimers
            });
        }

        if (prevProps.timelogs !== this.props.timelogs && this.props.timelogs && this.props.timelogs.length) {
            let totalHoursLogged = 0;
            this.props.timelogs.map(timelog => totalHoursLogged += timelog.hoursLogged);
            this.setState({
                totalHoursLogged: totalHoursLogged
            })
            // if (this.state.showTimelog) {
            //     window.scrollBy(0, document.body.scrollHeight);
            // }
        }

        if (prevState.startDate != this.state.startDate && this.state.startDate) {
            if (this.state.dueDate && this.state.dueDate < this.state.startDate) {
                this.setState({
                    dueDate: this.state.startDate
                })
            }
        }

        if (prevState.dueDate != this.state.dueDate && this.state.dueDate) {
            if (this.state.startDate && this.state.dueDate < this.state.startDate) {
                this.setState({
                    startDate: this.state.dueDate
                })
            }
        }

        if (!prevState.showAddNewTag && this.state.showAddNewTag) {
            const tagSearchInput = document.getElementById("tagSearchInput");
            if (tagSearchInput) {
                tagSearchInput.focus();
            }
        }

        if ((prevProps.saveSuccess !== this.props.saveSuccess) || (prevProps.error !== this.props.error)) {
            window.scrollBy(0, -document.body.scrollHeight);
        }
    }

    toggleShowTimelog = () => {
        const showTimelog = !this.state.showTimelog;
        this.setState({
            showTimelog: showTimelog
        });
        if (showTimelog) {
            this.getTimelogData();
        }
    }

    toggleShowAddNewTag = () => {
        const showAddNewTag = !this.state.showAddNewTag;
        this.setState({
            tagSearchInput: "",
            showAddNewTag
        });
    }

    addTag = (tag) => {
        const tags = this.state.tags;
        tags.push(tag);

        this.setState({
            changed: true,
            tags
        });
    }

    removeTag = (tag) => {
        const tags = this.state.tags.filter(filteredTag => filteredTag.id !== tag.id);

        this.setState({
            changed: true,
            tags
        });
    }

    render() {

        const totalHoursLoggedAsInterval = dateAndTimeUtils.hoursToTimeInterval(this.state.totalHoursLogged);
        const totalHoursLoggedFormatted = totalHoursLoggedAsInterval.hours + ":" + formatUtils.formatNumberWith2Digits(totalHoursLoggedAsInterval.minutes) + ":" + formatUtils.formatNumberWith2Digits(totalHoursLoggedAsInterval.seconds);

        const taskTags = this.state.tags ? this.state.tags : [];
        const alreadyIncludedTagIds = taskTags.map(tag => tag.id);
        const tagsExceptAlreadyIncluded = this.props.tags && this.props.tags.filter(tag => !alreadyIncludedTagIds.includes(tag.id));

        const tagControls =
            <React.Fragment>
                {taskTags.map(tag =>
                    editFormControls.removableItem(
                        tag.shortTitle, tag.id,
                        () => this.removeTag(tag),
                        () => this.setState({ hoveredTag: tag.id }),
                        () => this.setState({ hoveredTag: null }),
                        (this.state.hoveredTag === tag.id)
                    )
                )}
                <span
                    style={{ cursor: "pointer" }}
                    onClick={this.toggleShowAddNewTag}
                >
                    +
                </span>
                {this.state.showAddNewTag &&
                    <div
                        className={classes.Background}
                        onClick={this.toggleShowAddNewTag}
                    >
                        <div className={classes.TagSelectionContainer}>
                            <Card style={{ height: "100%" }}>
                                <CardBody style={{ height: "100%" }}>
                                    <input
                                        id="tagSearchInput"
                                        name="tagSearchInput"
                                        type="text"
                                        onChange={this.onChange}
                                        value={this.state.tagSearchInput}
                                        style={{ width: "100%" }}
                                    />
                                    <div className={classes.TagSelectionList}>
                                        {tagsExceptAlreadyIncluded.filter(tag => tag.title && tag.title.toLocaleLowerCase().includes(this.state.tagSearchInput.toLocaleLowerCase())).map(tag => (
                                            <div
                                                key={tag.id}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => this.addTag(tag)}
                                            >
                                                {tag.title}
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>}
            </React.Fragment>

        let timer = {
            hoursPassed: 0,
            status: "PAUSED",
            taskId: this.state.id
        };
        this.state.timelogTimers.map(timelogTimer => {
            if (timelogTimer.taskId === this.state.id) {
                timer = timelogTimer;
            }
        });

        const columns = [
            columnsTimelog.date,
            columnsTimelog.hoursLogged,
            columnsTimelog.userLoggedInfo,
            columnsTimelog.comment
        ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.history.push(endpointsFrontend.TIMELOG_EDIT.replace(":id", row.id));
            }
        };

        const table =
            <div className="mt-3">
                <BootstrapTable
                    keyField='id'
                    data={this.props.timelogs}
                    columns={columns}
                    pagination={paginationFactory()}
                    rowEvents={rowEvents}
                    rowStyle={{ cursor: "pointer" }}
                    filter={filterFactory()}
                    sort={{
                        dataField: 'date',
                        order: 'desc'
                    }}
                />
            </div>

        const userAccountOptionsPlusNull = inputSelectUtils.addNullOption(inputSelectUtils.generateOptionsFromData(this.props.userAccounts, userAccountRow => (
            userAccountRow.firstName ? (
                userAccountRow.lastName ? (
                    userAccountRow.firstName + " " + userAccountRow.lastName + " <" + userAccountRow.username + ">"
                ) :
                    userAccountRow.firstName + " <" + userAccountRow.username + ">"
            ) :
                userAccountRow.username
        )));

        const taskStatusOptions = inputSelectUtils.generateOptionsFromData(TaskStatus, row => row.description);

        const AddTimelogEntryForm =
            <AddTimelogEntry
                onClose={() => this.setState({ showAddTimelogEntryForm: false })}
                timer={timer}
                frontendTimerHoursPassed={this.state.frontendTimerHoursPassed}
            />

        const timelogControls =
            <React.Fragment>
                <div>
                    <Button
                        type="button"
                        size="sm"
                        color="secondary"
                        onClick={this.toggleShowTimelog}
                    >
                        {this.props.loadingTimelogs && editFormControls.buttonSpinner()}
                        &nbsp;
                        {(this.state.showTimelog ? "Hide" : "Show") + " timelog"}
                    </Button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <TimelogTimerStartPauseControl
                        hoursPassed={timer.hoursPassed}
                        timerStatus={timer.status}
                        startOrPauseTimer={() => this.startOrPauseTimer(timer)}
                        updating={this.props.updating}
                        onPauseTimer={() => this.props.onChangeTimerStatus("PAUSE", this.state.id)}
                        onStartTimer={() => this.props.onChangeTimerStatus("START", this.state.id)}
                        timerCallbackFunction={(frontendTimerHoursPassed) => this.setState({ frontendTimerHoursPassed: frontendTimerHoursPassed })}
                    />
                    &nbsp;
                    /
                    &nbsp;
                    {totalHoursLoggedFormatted}
                    &nbsp;
                    &nbsp;
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => this.setState({ showAddTimelogEntryForm: true })}
                    >
                        Add entry
                    </Button>
                </div>
                {this.props.timelogs && this.state.showTimelog ? table : <br />}
                <br />
            </React.Fragment>

        const editForm = (
            <form
                className={classes.IntermediaryNonScrollableDiv}
                onSubmit={this.onSubmit}
            >
                <div id="scrollableControlsDiv" className={classes.EditTaskScrollableDiv}>

                    {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                    <InputGroup>
                        {editFormControls.textControlWithoutLabel("title", this.onChange, this.state.title, "Task title")}
                        <Button
                            color={this.state.isStarred ? "primary" : "secondary"}
                            onClick={() => this.setState({ isStarred: !this.state.isStarred, changed: true })}
                        >
                            <i className={"bx " + (this.state.isStarred ? "bxs-star" : "bx-star")} />
                        </Button>
                    </InputGroup>
                    <br />
                    {tagControls}
                    <br />
                    <br />
                    {editFormControls.selectControl("assignedToUserAccountId", "Assigned to", this.onChange, this.state.assignedToUserAccountId, userAccountOptionsPlusNull)}
                    {editFormControls.selectControl("taskStatus", "Status", this.onChange, this.state.taskStatus, taskStatusOptions)}
                    {editFormControls.dateControl("startDate", "Start date", this.onChange, this.state.startDate)}
                    {editFormControls.dateControl("dueDate", "Due date", this.onChange, this.state.dueDate)}

                    {this.state.id ? timelogControls : null}

                    {editFormControls.richTextControlWithoutLabel("description", "Description", this.onChange, this.state.description)}
                    {/*editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)*/}
                </div>
            </form>
        );

        const loading = this.props.loading || this.props.loadingUserAccounts;

        const updateButton =
            <Button
                color="primary"
                onClick={this.onSubmit}
                disabled={this.props.saving}
            >
                {this.state.id ? "Update" : "Create"}
                {" "}
                {this.props.saving ? editFormControls.buttonSpinner() : null}
            </Button>

        return (

            <React.Fragment>
                <Backdrop
                    show
                    onClick={() => this.onAttemptCloseForm(this.state.changed)}
                >
                </Backdrop>
                <div className={classes.EditTaskOuterDiv}>
                    <Card className={classes.EditTaskCard} style={{ height: "100%" }}>  {/* Setting height of PageCard in css is ignored */}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    {this.props.task ? "Edit" : "Create"} task
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={() => this.onAttemptCloseForm(this.state.changed)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody className={classes.EditTaskCardBody}>

                            {this.state.showAddTimelogEntryForm && AddTimelogEntryForm}

                            {editFormControls.errorAlert(this.props.error)}
                            {editFormControls.formLoadingSpinner(loading)}

                            {!loading ? editForm : null}

                            <div style={{ marginTop: "-3rem", width: "100%", zIndex: 10003, position: "relative" }}>
                                <div style={{ display: "flex", marginRight: "2rem", justifyContent: "end" }}>
                                    {this.state.changed && updateButton}
                                    <span>&nbsp;</span>
                                    {editFormControls.deleteButton(this.props.deleting, this.onDeleteTask, this.state.id)}
                                </div>
                            </div>

                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ tag, task, timelog, timelogTimer, userAccount }) => ({
    error: task.error,
    deleting: task.deleting,
    loading: task.loading,
    loadingTimelogs: timelog.loading,
    loadingUserAccounts: userAccount.loading,
    saveSuccess: task.saveSuccess,
    saving: task.saving,
    tags: tag.tags,
    tasks: task.tasks,
    timelogs: timelog.timelogs,
    timelogTimers: timelogTimer.timelogTimers,
    updating: timelogTimer.updating,
    userAccounts: userAccount.userAccounts
})

const mapDispatchToProps = dispatch => ({
    onChangeTimerStatus: (action, taskId) => dispatch(actionsTimelogTimer.timelogTimerChangeStatus({ action, taskId })),
    onCreateTask: (task, history) => dispatch(actionsTask.taskCreate(task, history)),
    onGetTags: (id) => dispatch(actionsTag.tagGetAll(id)),
    onGetTaskById: (id) => dispatch(actionsTask.taskGetById(id)),
    onGetTimelogsByTaskId: (taskId) => dispatch(actionsTimelog.timelogGetByTask(taskId)),
    onGetUserAccounts: () => dispatch(actionsUserAccount.userAccountGetAll()),
    onDeleteTask: (id, history) => dispatch(actionsTask.taskDelete(id, history))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTask);