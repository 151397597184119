import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Counter/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsCounter from '../../definitions/columns/counter';
import * as config from '../../config';
import { CounterType  } from "definitions/enums/CounterType";
import EditCounter from '../../components/Pages/EditCounter';
import * as editFormControls from '../../helpers/editFormControls';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class CounterBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			counterType: "",
			selectedCounter: null,
			selectedRows: [],
			showEditCounterForm: false
		}
	}

	componentDidMount() {
		this.props.onGetCounters();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.moving != this.props.moving) {
			this.setState({
				moving: this.props.moving
			});
		}
	}

	newCounter = () => {
		this.setState({
			selectedCounter: {
				type: this.state.counterType
			},
			showEditCounterForm: true
		})
	}

	onDeleteCounters = () => {
		if (window.confirm("Are you sure you want to delete the selected counters?")) {
			this.props.onDeleteCounters(this.state.selectedRows, this.state.counterType);
            this.setState({
                selectedRows: []
            });
		}
	}

	render() {

		const pageTitle = "Counters | " + config.AppName;
		const breadcrumbsTitle = "Counters";
		const breadcrumbItem = "Edit counters"

		const EditCounterFormAsVar =
			<EditCounter
				onClose={() => this.setState({ showEditCounterForm: false })}
				counter={this.state.selectedCounter}
			/>

		let columns = [
			columnsCounter.type,
			columnsCounter.currentValue,
		];

		const columnEvents = {
			onClick: (e, column, columnIndex, row, rowIndex) => {
				if (row.id) {
					// this.props.onSetAllocationRecord(row);
					this.setState({
						selectedCounter: row,
						showEditCounterForm: true
					});
				}
			}
		}

		for (let i in columns) {
			columns[i] = {
				...columns[i],
				events: columnEvents
			}
		}

        const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.counters}
					columns={columns}
					// rowEvents={rowEvents}
					filter={filterFactory()}
					selectRow={selectRow}
					rowStyle={{ cursor: "pointer" }}
				/>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showEditCounterForm ? EditCounterFormAsVar : null}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										<div
											style={{
												position: "relative"
											}}
										>
											<OverlayWithSpinner
												show={this.state.moving}
											/>
											{!this.props.loading && this.props.counters ? table : null}

											{editFormControls.newButton(this.props.saving, this.newCounter, (this.props.counters && this.props.counters.length && this.props.counters.length == CounterType.length))}

											{" "}

											{editFormControls.deleteButton(false, this.onDeleteCounters, true, !this.state.selectedRows.length)}
										</div>

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ counter }) => ({
	counters: counter.counters,
	deleting: counter.deleting,
	error: counter.error,
	loading: counter.loading,
	moving: counter.moving,
	saving: counter.saving
})

const mapDispatchToProps = dispatch => ({
	onDeleteCounters: (idList, counterType) => dispatch(actions.counterDelete(idList, counterType)),
	onGetCounters: () => dispatch(actions.counterGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CounterBrowse));
