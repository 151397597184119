import * as actionTypes from "./actionTypes";

export const customerQuoteGetAll = () => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_GET_ALL
	}
}

export const customerQuoteGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_GET_BY_ID,
		id: id
	}
}

export const customerQuoteGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_GET_ERROR,
		error: thisError
	}
}

export const customerQuoteGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_GET_SUCCESS,
		customerQuotes: data
	}
}

export const customerQuoteCopy = (customerQuoteId, history) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_COPY,
		customerQuoteId: customerQuoteId, 
		history: history
	}
}

export const customerQuoteCopySuccess = (customerQuote) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_COPY_SUCCESS,
		customerQuote: customerQuote
	}
}

export const customerQuoteCopyError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_COPY_ERROR,
		error: thisError
	}
}

export const customerQuoteCreate = (customerQuote, history) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_CREATE,
		customerQuote: customerQuote, 
		history: history
	}
}

export const customerQuoteCreateSuccess = (customerQuote) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_CREATE_SUCCESS,
		customerQuote: customerQuote
	}
}

export const customerQuoteCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_CREATE_ERROR,
		error: thisError
	}
}

export const customerQuoteDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_DELETE,
		id: id,
		history: history
	}
}

export const customerQuoteDeleteList = (customerQuotesToDelete) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_DELETE_LIST,
		customerQuotesToDelete: customerQuotesToDelete
	}
}

export const customerQuoteDeleteError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_DELETE_ERROR,
		error: thisError
	}
}

export const customerQuoteDeleteSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_DELETE_SUCCESS
	}
}

export const customerQuotePrintToPDF = (id) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF,
		id: id
	}
}

export const customerQuotePrintToPDFSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF_SUCCESS
	}
}

export const customerQuotePrintToPDFError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF_ERROR,
		error: thisError
	}
}

export const customerQuoteLineGetAll = (customerQuoteId) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_GET_ALL,
		customerQuoteId: customerQuoteId
	}
}

export const customerQuoteLineGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_GET_BY_ID,
		id: id
	}
}

export const customerQuoteLineGetInProcess = () => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_GET_IN_PROCESS
	}
}

export const customerQuoteLineGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_GET_ERROR,
		error: thisError
	}
}

export const customerQuoteLineGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_GET_SUCCESS,
		customerQuoteLines: data
	}
}

export const customerQuoteLineCreate = (customerQuoteLine, history) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_CREATE,
		customerQuoteLine: customerQuoteLine, 
		history: history
	}
}

export const customerQuoteLineCreateSuccess = (customerQuoteLine) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_CREATE_SUCCESS,
		customerQuoteLine: customerQuoteLine
	}
}

export const customerQuoteLineCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_CREATE_ERROR,
		error: thisError
	}
}

export const customerQuoteLineMoveUp = (id) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_MOVE_UP,
		id: id
	}
}

export const customerQuoteLineMoveDown = (id) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_MOVE_DOWN,
		id: id
	}
}

export const customerQuoteLineCopy = (idList) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_COPY,
		idList: idList
	}
}

export const customerQuoteLineMoveSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_MOVE_SUCCESS,
		customerQuoteLines: data
	}
}

export const customerQuoteLineMoveError = (error) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_MOVE_ERROR,
		error: error
	}
}

export const customerQuoteLineDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_DELETE,
		id: id,
		history: history
	}
}

export const customerQuoteLineDeleteList = (idList) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_DELETE_LIST,
		idList: idList
	}
}

export const customerQuoteLineDeleteSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_DELETE_SUCCESS,
		customerQuoteLines: data
	}
}

export const customerQuoteLineDeleteError = (error) => {
	return {
		type: actionTypes.CUSTOMER_QUOTE_LINE_DELETE_ERROR,
		error: error
	}
}