import * as actionTypes from "./actionTypes";

export const bankAccountCreate = (bankAccount, history) => {
	return {
		type: actionTypes.BANK_ACCOUNT_CREATE,
		bankAccount: bankAccount, 
		history: history
	}
}

export const bankAccountCreateError = (thisError) => {
	return {
		type: actionTypes.BANK_ACCOUNT_CREATE_ERROR,
		error: thisError
	}
}

export const bankAccountCreateSuccess = () => {
	return {
		type: actionTypes.BANK_ACCOUNT_CREATE_SUCCESS
	}
}

export const bankAccountDelete = (id, history) => {
	return {
		type: actionTypes.BANK_ACCOUNT_DELETE,
		id: id,
		history: history
	}
}

export const bankAccountDeleteError = (thisError) => {
	return {
		type: actionTypes.BANK_ACCOUNT_DELETE_ERROR,
		error: thisError
	}
}

export const bankAccountDeleteSuccess = () => {
	return {
		type: actionTypes.BANK_ACCOUNT_DELETE_SUCCESS
	}
}

export const bankAccountGetAll = () => {
	return {
		type: actionTypes.BANK_ACCOUNT_GET_ALL
	}
}

export const bankAccountGetById = (id) => {
	return {
		type: actionTypes.BANK_ACCOUNT_GET_BY_ID,
		id: id
	}
}

export const bankAccountGetError = (thisError) => {
	return {
		type: actionTypes.BANK_ACCOUNT_GET_ERROR,
		error: thisError
	}
}

export const bankAccountGetSuccess = (data) => {
	return {
		type: actionTypes.BANK_ACCOUNT_GET_SUCCESS,
		bankAccounts: data
	}
}

export const bankAccountTestConnection = (id) => {
	return {
		type: actionTypes.BANK_ACCOUNT_TEST_CONNECTION,
		id: id
	}
}

export const bankAccountTestConnectionError = (thisError) => {
	return {
		type: actionTypes.BANK_ACCOUNT_TEST_CONNECTION_ERROR,
		error: thisError
	}
}

export const bankAccountTestConnectionSuccess = () => {
	return {
		type: actionTypes.BANK_ACCOUNT_TEST_CONNECTION_SUCCESS
	}
}

