import {
	USER_PASSWORD_RESET_REQUEST,
	USER_PASSWORD_RESET_REQUEST_ERROR,
	USER_PASSWORD_RESET_REQUEST_SUCCESS,
	USER_PASSWORD_RESET,
	USER_PASSWORD_RESET_ERROR,
	USER_PASSWORD_RESET_SUCCESS
} from "./actionTypes"

export const userPasswordResetRequest = username => {
	return {
		type: USER_PASSWORD_RESET_REQUEST,
		payload: { username }
	}
}

export const userPasswordResetRequestSuccess = message => {
	return {
		type: USER_PASSWORD_RESET_REQUEST_SUCCESS,
		payload: message
	}
}

export const userPasswordResetRequestError = message => {
	return {
		type: USER_PASSWORD_RESET_REQUEST_ERROR,
		payload: message
	}
}

export const userPasswordReset = (token, newPassword, history) => {
	return {
		type: USER_PASSWORD_RESET,
		payload: { token, newPassword, history }
	}
}

export const userPasswordResetSuccess = message => {
	return {
		type: USER_PASSWORD_RESET_SUCCESS,
		payload: message
	}
}

export const userPasswordResetError = message => {
	return {
		type: USER_PASSWORD_RESET_ERROR,
		payload: message
	}
}