import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

import * as actionsCM20PaymentFiles from '../../store/CM20PaymentFile/actions';
import * as actionsSmartId from '../../store/SmartId/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from "../../helpers/browseFormControls";
import * as browseFormTools from "../../helpers/browseFormTools";
import * as columnsCM20Payment from "../../definitions/columns/cm20Payment";
import * as columnsCM20PaymentBulk from "../../definitions/columns/cm20PaymentBulk";
import * as columnsCM20PaymentFile from "../../definitions/columns/cm20PaymentFile";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsBackendFilters from '../../definitions/endpoints/endpoints-backend-filters';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as hwcryptoHelper from '../../helpers/hwcryptoHelper';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class CM20PaymentBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			filter: null,
			paymentBulksAreExpandable: false,
			selectedRows: [],
			showRefreshStatusButton: false,
			showSignButton: false
		}
	}

	setExpandable() {
		let paymentBulksAreExpandable = false;
		for (let i in this.props.cm20PaymentFiles) {

			const thisCM20PaymentFile = this.props.cm20PaymentFiles[i];
			for (let j in thisCM20PaymentFile.cm20PaymentBulks) {

				const thisCM20PaymentBulk = thisCM20PaymentFile.cm20PaymentBulks[j];
				if (thisCM20PaymentBulk.isExpandable) {
					paymentBulksAreExpandable = true;
					break;
				}
			}

			if (paymentBulksAreExpandable) {
				break;
			}
		}
		this.setState({
			paymentBulksAreExpandable: paymentBulksAreExpandable
		});
	}

	initData = () => {
		if (this.props.location.pathname === endpointsFrontend.CM20PAYMENT_BROWSE_ERRONEOUS) {
			this.props.onGetErroneousCM20Payments();
			this.setState({
				filter: endpointsBackendFilters.CM20PAYMENT_FILE_FAILED,
				showRefreshStatusButton: true,
				showSignButton: false
			});
		} else if (this.props.location.pathname === endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED) {
			this.props.onGetUnsignedCM20Payments();
			this.setState({
				filter: endpointsBackendFilters.CM20PAYMENT_FILE_TO_SIGN_IN_CRM,
				showRefreshStatusButton: false,
				showSignButton: true
			});
		} else {
			this.props.onGetSignedCM20Payments();
			this.setState({
				filter: endpointsBackendFilters.CM20PAYMENT_FILE_PROCESSED,
				showRefreshStatusButton: true,
				showSignButton: false
			});
		}
	}

	componentDidMount() {
		if (this.props.selectedCompany) {
			this.initData();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.error != this.props.error) {
			this.setState({
				error: this.props.error
			})
		}
		if ((prevProps.cm20PaymentFiles != this.props.cm20PaymentFiles) && this.props.cm20PaymentFiles && this.props.cm20PaymentFiles.length) {
			this.setExpandable();
		}
		// 2023.11.27: fix: 231123-CompanyId
		if (prevProps.selectedCompany != this.props.selectedCompany) {
			this.initData();
		}
	}

	containsPastDates = () => {
		const selectedRowArray = this.props.cm20PaymentFiles.filter(row => this.state.selectedRows.includes(row.id));
		for (let i in selectedRowArray) {
			for (let j in selectedRowArray[i].cm20PaymentBulks) {
				const paymentDateAsObject = new Date(selectedRowArray[i].cm20PaymentBulks[j].paymentDate);
				const currentDateAsObject = new Date();
				paymentDateAsObject.setHours(0, 0, 0, 0);
				currentDateAsObject.setHours(0, 0, 0, 0);
				if (paymentDateAsObject < currentDateAsObject) {
					return true;
				}
			}
		}
		return false;
	}

	onSignPaymentsViaIDCard = () => {
		if (this.containsPastDates()) {
			this.setState({
				error: { message: "The selected lines contain payments with dates in the past." }
			});
		} else {
			hwcryptoHelper.signPayments(
				this.state.selectedRows,
				this.props.onGetCertificate,
				this.props.onSignPaymentsViaIDCard,
				this.props.history,
				(error) => {
					this.setState({
						error: error
					});
				}
			);
		}
	}

	onSignPaymentFilesViaSmartId = () => {
		if (this.containsPastDates()) {
			this.setState({
				error: { message: "The selected lines contain payments with dates in the past." }
			});
		} else {
			this.props.onSignPaymentFilesViaSmartId({
				paymentFileIdList: this.state.selectedRows,
				signingCertificate: this.props.smartIdSignersCertificate,
				testMode: false,
				documentNumber: this.props.smartIdDocumentNumber
			});
		}
	}

	onResetSignCM20Payments = () => {
		if (window.confirm("Are you sure you want to reset all signatures of the selected payments?")) {
			this.props.onResetSignCM20Payments(this.state.selectedRows, this.props.history);
		}
	}

	render() {

		const pageTitle = "Payments | " + config.AppName;;
		const breadcrumbsTitle = "Payments";
		const breadcrumbsItem = "Browse payments";

		const columns = [
			columnsCM20PaymentFile.createdDatetime,
			columnsCM20PaymentFile.bankAccountNo,
			columnsCM20PaymentFile.totalAmount,
			columnsCM20PaymentFile.currencyCode,
			columnsCM20PaymentFile.cm20PaymentBulkCount,
			columnsCM20PaymentFile.filename,
			columnsCM20PaymentFile.signingStatusSummary,
			columnsCM20PaymentFile.paymentStatus
		];

		if (this.props.location.pathname === endpointsFrontend.CM20PAYMENT_BROWSE_ERRONEOUS) {
			columns.push(
				columnsCM20PaymentFile.bankStatusMessage
			);
		}

		const rowEvents = {
			/*onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.CURRENCY_EXCHRATE_EDIT.replace(":id", row.id));
			}*/
		};

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect)
				this.setState({
					selectedRows: selectedRows
				});
			}
		};

		const expandPaymentBulkRow = {
			renderer: (row) => {
				const columns = browseFormTools.removeFiltersFromColumns([
					columnsCM20Payment.bankAccountNo,
					columnsCM20Payment.supplierName,
					columnsCM20Payment.totalAmountToPay,
					columnsCM20Payment.currencyCode,
					columnsCM20Payment.supplierBankAccountNo,
					columnsCM20Payment.paymentDetails
				]);

				return (
					<table className="table">
						<tbody style={{ borderColor: "#FFFFFF" }}>
							<tr>
								<td style={{ border: "0px transparent" }}></td>
								<td style={{ border: "0px transparent" }}>
									<div
										style={{
											position: "relative"
										}}
									>
										<BootstrapTable
											keyField='id'
											data={row.cm20Payments}
											columns={columns}
											condensed
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
		}

		const expandPaymentFileRow = {
			renderer: (row) => {
				const columns = browseFormTools.removeFiltersFromColumns([
					columnsCM20PaymentBulk.bankAccountNo,
					columnsCM20PaymentBulk.supplierName,
					columnsCM20PaymentBulk.totalAmount,
					columnsCM20PaymentBulk.currencyCode,
					columnsCM20PaymentBulk.paymentDate,
					columnsCM20PaymentBulk.supplierBankAccountNo,
					columnsCM20PaymentBulk.paymentDetails
				]);

				let cm20PaymentBulkTable;
				if (this.state.paymentBulksAreExpandable) {
					cm20PaymentBulkTable =
						<BootstrapTable
							keyField='id'
							data={row.cm20PaymentBulks}
							columns={columns}
							expandRow={expandPaymentBulkRow}
							condensed
						/>
				} else {
					cm20PaymentBulkTable =
						<BootstrapTable
							keyField='id'
							data={row.cm20PaymentBulks}
							columns={columns}
							condensed
						/>
				}

				return (
					<table className="table">
						<tbody style={{ borderColor: "#FFFFFF" }}>
							<tr>
								<td style={{ border: "0px transparent" }}></td>
								<td style={{ border: "0px transparent" }}>
									<div
										style={{
											position: "relative"
										}}
									>
										{cm20PaymentBulkTable}
										{editFormControls.copyTableToClipboardButton(
											row.cm20PaymentBulks, columns, "sm"
										)}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
		}

		let cm20PaymentFileTable;
		if (this.state.showSignButton) {
			cm20PaymentFileTable =
				<div className="mt-3">
					<BootstrapTable
						keyField='id'
						data={this.props.cm20PaymentFiles}
						columns={columns}
						pagination={paginationFactory()}
						rowEvents={rowEvents}
						filter={filterFactory()}
						selectRow={selectRow}
						expandRow={expandPaymentFileRow}
						defaultSorted={[{
							dataField: 'createdDatetime',
							order: 'desc'
						}]}
					/>
				</div>
		} else {
			cm20PaymentFileTable =
				<div className="mt-3">
					<BootstrapTable
						keyField='id'
						data={this.props.cm20PaymentFiles}
						columns={columns}
						pagination={paginationFactory()}
						rowEvents={rowEvents}
						filter={filterFactory()}
						expandRow={expandPaymentFileRow}
						defaultSorted={[{
							dataField: 'createdDatetime',
							order: 'desc'
						}]}
					/>
				</div>
		}

		const signViaIDCardButton =
			<Button
				color="primary"
				disabled={this.state.selectedRows.length === 0 || this.props.signingWithIDCard}
				onClick={this.onSignPaymentsViaIDCard}
			>
				Sign with ID card
				{" "}
				{this.props.signingWithIDCard ? editFormControls.buttonSpinner() : null}
			</Button>

		const signViaSmartIdButton =
			<Button
				color="primary"
				disabled={this.state.selectedRows.length === 0 || this.props.signingWithSmartId}
				onClick={this.onSignPaymentFilesViaSmartId}
			>
				Sign with Smart-ID
				{" "}
				{this.props.signingWithSmartId ? editFormControls.buttonSpinner() : null}
			</Button>

		const signResetButton =
			<Button
				color="danger"
				disabled={this.state.selectedRows.length === 0}
				onClick={this.onResetSignCM20Payments}
			>
				Reset signatures
				{" "}
				{this.props.resetting ? editFormControls.buttonSpinner() : null}
			</Button>

		const refreshStatusButton =
			<Button
				color="primary"
				onClick={() => this.props.onRefreshPaymentStata(this.state.filter)}
				disabled={this.props.receiving}
			>
				Refresh status
				{" "}
				{this.props.receiving ? editFormControls.buttonSpinner() : null}
			</Button>

		const smartIdControlCodeControls =
			<React.Fragment>
				<Label>
					Control code:
				</Label>
				<br />
				{this.props.smartIdControlCodeLoading ? editFormControls.bigSpinner() : (<h1>{this.props.smartIdControlCode}</h1>)}
			</React.Fragment>


		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.state.error)}
										{editFormControls.errorAlert(this.props.errorSmartId)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.cm20PaymentFiles ?
											cm20PaymentFileTable
											: null}

										<br />

										{this.state.showSignButton && !this.props.smartIdLoggedIn ? signViaIDCardButton : null}

										{this.state.showSignButton && this.props.smartIdLoggedIn ? signViaSmartIdButton : null}

										{" "}

										{this.state.showRefreshStatusButton ? refreshStatusButton : null}

										{" "}

										{editFormControls.copyTableToClipboardButton(
											this.props.cm20PaymentFiles, columns
										)}

										{" "}

										{this.state.showSignButton ? signResetButton : null}

										<br />

										{(this.props.smartIdControlCode || this.props.smartIdControlCodeLoading) && !this.props.errorSmartId && smartIdControlCodeControls}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ cm20PaymentFile, company, smartId }) => ({
	cm20PaymentFiles: cm20PaymentFile.cm20PaymentFiles,
	loading: cm20PaymentFile.loading,
	error: cm20PaymentFile.error,
	errorSmartId: smartId.error,
	receiving: cm20PaymentFile.receiving,
	resetting: cm20PaymentFile.resetting,
	selectedCompany: company.selectedCompany,
	signingWithIDCard: cm20PaymentFile.signing,
	signingWithSmartId: smartId.signing,
	smartIdControlCode: smartId.controlCode,
	smartIdControlCodeLoading: smartId.controlCodeLoading,
	smartIdDocumentNumber: smartId.documentNumber,
	smartIdLoggedIn: smartId.loggedIn,
	smartIdSignersCertificate: smartId.signersCertificate,
})

const mapDispatchToProps = dispatch => ({
	onGetCertificate: () => dispatch(actionsCM20PaymentFiles.cm20PaymentFileSignGetCertificate()),
	onGetErroneousCM20Payments: () => dispatch(actionsCM20PaymentFiles.cm20PaymentFileGet(endpointsBackendFilters.CM20PAYMENT_FILE_FAILED)),
	onGetSignedCM20Payments: () => dispatch(actionsCM20PaymentFiles.cm20PaymentFileGet(endpointsBackendFilters.CM20PAYMENT_FILE_PROCESSED)),
	onGetUnsignedCM20Payments: () => dispatch(actionsCM20PaymentFiles.cm20PaymentFileGet(endpointsBackendFilters.CM20PAYMENT_FILE_TO_SIGN_IN_CRM)),
	onRefreshPaymentStata: (filter) => dispatch(actionsCM20PaymentFiles.cm20PaymentFileRefreshStatus(filter)),
	onResetSignCM20Payments: (cm20PaymentFilesToReset, history) => dispatch(actionsCM20PaymentFiles.cm20PaymentFileSignReset(cm20PaymentFilesToReset, history)),
	onSignPaymentsViaIDCard: (paymentsToSign, signingCertificate, signingCertificateObject, history) => dispatch(actionsCM20PaymentFiles.cm20PaymentFileSignInit(paymentsToSign, signingCertificate, signingCertificateObject, history)),
	onSignPaymentFilesViaSmartId: (data) => dispatch(actionsSmartId.smartIdSignCM20PaymentFileInit(data))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CM20PaymentBrowse));
