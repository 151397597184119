export const CUSTOMER_GET_ALL = "CUSTOMER_GET_ALL";
export const CUSTOMER_GET_BY_ID = "CUSTOMER_GET_BY_ID";
export const CUSTOMER_GET_ERROR = "CUSTOMER_GET_ERROR";
export const CUSTOMER_GET_SUCCESS = "CUSTOMER_GET_SUCCESS";

export const CUSTOMER_CREATE = "CUSTOMER_CREATE";
export const CUSTOMER_CREATE_ERROR = "CUSTOMER_CREATE_ERROR";
export const CUSTOMER_CREATE_SUCCESS = "CUSTOMER_CREATE_SUCCESS";

export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_DELETE_ERROR = "CUSTOMER_DELETE_ERROR";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";


