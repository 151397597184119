import React from 'react';

import Legal from './Legal';
import classes from './Legal.module.css';

const Terms2ServiceOnDemand = (props) => (
    <Legal {...props}>
        <h1>Terms of Service on Demand</h1>
        <h2>1. General Terms</h2>
        <p>1.1. Service on Demand is a service, the provision of which is agreed upon by the parties in accordance with the procedure specified in these Terms. Service on Demand (hereinafter referred to in these Terms as the Service) may include, but is not limited to, training of persons specified by the Customer, installation of computer programs and their updates in the computer environment specified by the Customer, configuration or configuration changes of the Online Service provided to the Customer, correction of data due to incorrect use of the Online Service, etc.</p>
        <p>1.2. Deliverables are the result of the provision of the service, which the Supplier delivers to the Customer.</p>

        <h2>2. Agreement on the Service</h2>
        <p>2.1. The Customer may submit a request to the Supplier for the provision of the service, indicating the content of the required deliverables. In the request, the Customer may also indicate that the Supplier submits to the Customer an offer for the provision of the service (hereinafter referred to as the offer). If the Customer has not indicated that an offer must be submitted, it shall be deemed that the Customer has requested to commence the provision of the service without submitting an offer.</p>
        <p>2.2. If the Customer has requested the Supplier to submit an offer, the Supplier shall submit an offer to the Customer, which shall indicate the specification of the deliverables and the remuneration to the Supplier for the provision of this service, as well as other terms and conditions applicable in the relevant case of provision of the service, or shall indicate to the Customer that the provision of the service is not possible. The Supplier's offer shall be valid for 7 days after its submission to the Customer. During this period, the Customer may accept the offer by submitting to the Supplier a copy of the offer signed by the Customer, or by sending a notification of acceptance of the Supplier's offer to the Supplier's electronic mail address specified in the contract. The Parties shall be bound by the Supplier's offer, which the Customer has accepted.</p>
        <p>2.3. If the Customer has requested the Supplier to provide a service without submitting an offer, the Supplier shall commence the provision of the service, or, if the Customer's request cannot be fulfilled immediately because the request is unclear, the Supplier shall submit an offer to the Customer specifying the specification of the deliverables, or shall indicate to the Customer that the provision of the service is not possible. If the Supplier has neither submitted an offer to the Customer nor indicated that the provision of the service is not possible, the Supplier shall commence the provision of the service within the period specified by the Supplier. If the Supplier has submitted an offer to the Customer, the parties shall proceed as specified in clause 2.2 of these Terms and Conditions.</p>
        <p>2.4. If the contract specifies the price of the relevant service or the hourly rate for its provision, the offer shall specify (apply) the price or hourly rate specified in the contract for this service. The Supplier may offer the Customer to agree on amendments to the price and hourly rates for the service, which shall be applicable in the next Service Period. Such an offer shall be submitted by the Supplier to the Customer in writing as part of the offer specified in clause 4.3 of the Online Service Terms. If the Customer accepts the said offer, the Supplier shall apply the prices and rates of the service specified in the offer in the next Service Period, and the Customer undertakes to pay for the service provided in the next Service Period in accordance with these rates.</p>

        <h2>3. Service provision</h2>
        <p>3.1. In the course of the provision of the service, the party shall communicate with the other party by e-mail, sending messages to the other party's e-mail address specified in the contract, or using another communication platform provided by the Supplier.</p>
        <p>3.2. The Supplier may engage subcontractors to provide the service. The Supplier shall be responsible for the results of the work of the subcontractors that the Supplier delivers to the Customer.</p>
        <p>3.3. If the Customer requests that work be performed outside the Supplier's office, the Supplier may request that the Customer reimburse the Supplier for the expenses of the personnel assigned to provide the service from the Supplier's office to the place of performance of the work and back, as well as for the time spent on the way, and if necessary - for accommodation. The Supplier shall not be obliged to perform the relevant work if the parties have not agreed on the amount of such costs.</p>
        <p>3.4. The personnel assigned by the Supplier shall comply with the work procedures and rules of conduct specified by the Customer when performing work on the Customer's premises. The Supplier shall not be liable for failure to perform or delay in performance of its obligations if the work procedures or rules of conduct specified by the Customer significantly hinder or slow down the performance of the work, or prevent their performance, or if the Customer has not informed the Supplier of these rules before assigning personnel to work at the Customer's premises.</p>
        <p>3.5. The Customer shall provide the Supplier with all information, data and documents and shall perform all activities specified in the order as the Customer's contribution. If the parties have not agreed on the deadline for providing the Customer's contribution, the Customer shall provide the Supplier with the contribution within two business days of receiving the Supplier's request.</p>
        <p>3.6. The Customer shall respond to the Supplier's questions related to the provision of the service within two business days of receiving the question.</p>
        <p>3.7. If the service includes training, the Customer shall assign to the training such persons who have the necessary background knowledge to enable them to understand and acquire the knowledge to be provided within the framework of the training. The Supplier shall not be required to repeat the training because the trainee was unable to acquire the knowledge due to insufficient prior preparation.</p>

        <h2>4. Acceptance of Deliverables</h2>
        <p>4.1. The Supplier delivers the Deliverables to the Customer in the manner agreed upon by the parties in each case of service provision. If there is no such agreement, the provisions of this section on the method of delivery of the Deliverables shall apply.</p>
        <p>4.2. If the Deliverables are configuration files and the Customer has provided the Supplier with remote access to the Customer's computer environment, the Supplier delivers them to the Customer by installing them on the Customer's computer environment. If the Deliverables are configuration files and the Customer has not provided the Supplier with remote access to the Customer's computer environment, the Supplier delivers them to the Customer by sending them to the Customer's representative's e-mail address or makes them available to the Customer for downloading from a data storage provided by the Supplier and sends the information necessary for access to the Customer's representative's e-mail address. The Customer downloads the files within two business days of receiving the invitation.</p>
        <p>4.3. If the Deliverables are computer programs and unless the parties have agreed otherwise, the Supplier shall deliver the computer programs to the Customer as computer program code, prepared for installation on a previously agreed upon Customer device (server) and written instructions in English (or another language specified in the relevant order) on the installation of the computer programs.</p>
        <p>4.4. If the Deliverables are neither configuration files nor computer programs and are recorded in electronic form, the Supplier shall send them to the Customer's representative's e-mail address or make them available to the Customer for downloading from a data storage provided by the Supplier and shall send the information necessary for access to the Customer's e-mail address. The Customer shall download the Deliverables within two business days of receiving the invitation.</p>
        <p>4.5. If the Deliverables are tangible items, the Supplier shall deliver them to a place agreed upon by the parties. If such a place is not specified, the Supplier shall deliver them to the Customer's legal address.</p>
        <p>4.6. If the deliverables are specific activities that do not result in either physical or electronically recorded deliverables (e.g. training, configuration, etc.), then the Supplier shall submit a report to the Customer on the service provided.</p>
        <p>4.7. The Customer may submit objections to the Supplier regarding the compliance of the deliverables with the agreed requirements within 5 days of the delivery of the deliverables, or, as the case may be, of the submission of the report to the Customer (verification period). Objections shall be submitted in writing and shall state all objections that the Customer has in relation to the relevant deliverables.</p>
        <p>4.8. If the Customer's objections are justified, then the Supplier shall correct the relevant deliverables and submit the corrected deliverables or the relevant part to the Customer.</p>
        <p>4.9. If the Customer does not submit any objections to the Supplier during the inspection period or uses the relevant deliverables in any way or pays for them, then the Customer shall be deemed to have accepted the relevant deliverables without objection and as they are, even if the Customer has rejected the deliverables before use or payment.</p>
        <p>4.10. The Service is completed when the Customer has accepted the relevant deliverables.</p>
        <p>4.11. The Customer may request that the Supplier terminate the provision of a specific service. In such a case, the Supplier shall immediately suspend the provision of the relevant service and shall deliver the result of the service to the Customer as it is at the time of suspension of the service. The Customer shall pay the Supplier for the time spent by the Supplier in providing such service in accordance with the hourly rate specified in the contract. If the parties had agreed on a fixed fee for such deliverable, then the fee payable to the Supplier may not exceed the agreed fixed fee.</p>

        <h2>5. Payment</h2>
        <p>5.1. The Supplier shall submit to the Customer an invoice for the service on a monthly basis, requesting payment for the services completed during the previous month and for the services terminated during the previous month (clause 4.10 of these Terms). If the remuneration payable for the services included in the invoice is to be determined in accordance with the Supplier's used work volume and hourly rates, then the Supplier shall submit to the Customer together with the invoice a report on the number of working hours used to provide this service or include this information in the invoice.</p>
        <p>5.2. The Customer shall pay such Supplier's invoice within 7 days of the invoice being submitted to the Customer, or by such later date as may be specified in the invoice.</p>
        <p>5.3. If the Supplier has submitted to the Customer a report on the amount of work used to provide the service (having included such information in the invoice), then the Customer may object to the amount of work specified by the Supplier by submitting written, reasoned objections to the Supplier by the 2nd day before the invoice payment deadline, by sending them to the Supplier's e-mail. If the Customer has not submitted objections to the Supplier within this period, then it shall be deemed that the Customer agrees to pay for the entire amount of work specified by the Supplier. If the Customer objects to part of the amount of work specified by the Supplier, then the Customer shall pay such part of the Supplier's invoice as corresponds to the amount of work to which the Customer does not object.</p>
        <p>5.4. If the parties have agreed on a different payment procedure in connection with certain deliveries, then the terms of the agreement between the parties shall apply to the payment for these deliveries.</p>
    </Legal>
);

export default Terms2ServiceOnDemand;