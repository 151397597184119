import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import { userPasswordResetRequest } from "../../store/actions";
import profile from "../../assets/images/cm-logo-transparent.png";

class PasswordResetRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		// handleValidSubmit
		this.handleValidSubmit = this.handleValidSubmit.bind(this);
	}

	// handleValidSubmit
	handleValidSubmit(event, values) {
		this.props.userPasswordResetRequest(values.email, this.props.history);
	}

	render() {
		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
										<img src={profile} alt="" className="img-fluid" />
									</div>
									<CardBody className="pt-0">
										<div className="p-2">
											{this.props.resetRequestError ? (
												<Alert color="danger" style={{ marginTop: "13px" }}>
													{this.props.resetRequestError.message ? this.props.resetRequestError.message : "Error sending the password reset link"}
												</Alert>
											) : null}
											{this.props.resetRequestSuccessMessage ? (
												<Alert color="success" style={{ marginTop: "13px" }}>
													{this.props.resetRequestSuccessMessage}
												</Alert>
											) : null}

											<AvForm
												className="form-horizontal"
												onValidSubmit={this.handleValidSubmit}
											>
												<br />
												Forgot your password? Enter your email address below.
												<br />
												<br />
												<br />
												<div className="mb-3">
													<AvField
														name="email"
														label="Email address"
														className="form-control"
														placeholder="Enter email"
														type="email"
														required
													/>
												</div>
												<div className="text-end">
													<button
														className="btn btn-primary w-md"
														type="submit"
													>
														Submit
													</button>
												</div>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Go back to{" "}
										<Link
											to={endpointsFrontend.USER_ACCOUNT_LOGIN}
											className="fw-medium text-primary"
										>
											Login
										</Link>{" "}
									</p>
									<p>
										Copyright © {new Date().getFullYear()} D.Vision SIA
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	const { resetRequestError, resetRequestSuccessMessage } = state.userPasswordReset;
	return { resetRequestError, resetRequestSuccessMessage };
};

export default withRouter(
	connect(mapStateToProps, { userPasswordResetRequest })(PasswordResetRequest)
);