import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import * as actions from '../../store/UserActivate/actions';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as errorMessageHelper from '../../helpers/errorMessageHelper';
import profile from "../../assets/images/cm-logo-transparent.png";

class ProfileActivation extends Component {
    constructor(props) {
        super(props);
        this.props.onUserActivate(this.props.match.params.token);
    }

    render() {
        let thisAlert = null;
        if (this.props.successMessage) {
            thisAlert = (
                <Alert color="success" style={{ marginTop: "13px" }}>
                    Account successfully activated
                </Alert>
            );
        }
        if (this.props.error) {
            const errorMessage = errorMessageHelper.httpErrorMessageAndAdditionalText("Error activating account", this.props.error);
            thisAlert = (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                    {errorMessage}
                </Alert>
            );
        }

        let waitNotice = null;
        if (thisAlert == null) {
            waitNotice = (
                <p align="center">
                    <br />
                    Activating account...
                </p>
            );
        }
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft px-5 py-3">
                                        <img src={profile} alt="" className="img-fluid" />
                                    </div>
                                    <CardBody className="pt-0">
                                        {waitNotice}
                                        {thisAlert}
                                        <div className="p-2">
                                            <p align="center">
                                                Go to{" "}
                                                <Link
                                                    to={endpointsFrontend.USER_ACCOUNT_LOGIN}
                                                    className="fw-medium text-primary"
                                                >
                                                    Login
                                                </Link>
                                            </p>

                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Copyright © {new Date().getFullYear()} D.Vision SIA
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        error: state.userActivate.error,
        successMessage: state.userActivate.successMessage
    };
};

const mapDispatchToProps = dispatch => ({
    onUserActivate: (token) => dispatch(actions.userActivate(token))
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProfileActivation)
);
