import * as actionTypes from "./actionTypes";

const initialState = {
	copying: false,
	copyingLines: false,
	copySuccess: false,
	customerQuotes: null,
	customerQuoteLines: null,
	deleting: false,
	deletingLines: false,
	error: null,
	errorLines: null,
	loading: false,
	loadingLines: false,
	movingLines: false,
	printing: false,
	saving: false
}

export default function customerQuoteReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CUSTOMER_QUOTE_COPY:
			return {
				...state,
				copying: true,
				copySuccess: false,
				error: null
			}
		case actionTypes.CUSTOMER_QUOTE_COPY_ERROR:
			return {
				...state,
				copying: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_COPY_SUCCESS:
			return {
				...state,
				copying: false,
				copySuccess: true,
				error: null,
				customerQuotes: [action.customerQuote]
			}
		case actionTypes.CUSTOMER_QUOTE_CREATE:
			return {
				...state,
				saving: true,
				error: null
			}
		case actionTypes.CUSTOMER_QUOTE_CREATE_ERROR:
			return {
				...state,
				saving: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				customerQuotes: [action.customerQuote]
			}
		case actionTypes.CUSTOMER_QUOTE_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.CUSTOMER_QUOTE_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.CUSTOMER_QUOTE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.CUSTOMER_QUOTE_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.CUSTOMER_QUOTE_GET_BY_ID:
			return {
				...state,
				copySuccess: false,
				error: null,
				loading: true
			}
		case actionTypes.CUSTOMER_QUOTE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				customerQuotes: action.customerQuotes
			}
		case actionTypes.CUSTOMER_QUOTE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_GET_ALL:
			return {
				...state,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_GET_BY_ID:
			return {
				...state,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_GET_IN_PROCESS:
			return {
				...state,
				loadingLines: true
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_GET_SUCCESS:
			return {
				...state,
				loadingLines: false,
				customerQuoteLines: action.customerQuoteLines
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_GET_ERROR:
			return {
				...state,
				loadingLines: false,
				errorLines: action.errorLines
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_CREATE:
			return {
				...state,
				saving: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_CREATE_ERROR:
			return {
				...state,
				saving: false,
				errorLines: action.errorLines
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_MOVE_UP:
			return {
				...state,
				movingLines: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_MOVE_DOWN:
			return {
				...state,
				movingLines: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_COPY:
			return {
				...state,
				copyingLines: true,
				movingLines: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_MOVE_SUCCESS:
			return {
				...state,
				copyingLines: false,
				movingLines: false,
				errorLines: null,
				customerQuoteLines: action.customerQuoteLines
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_MOVE_ERROR:
			return {
				...state,
				copyingLines: false,
				movingLines: false,
				errorLines: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF:
			return {
				...state,
				printing: true
			}
		case actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF_ERROR:
			return {
				...state,
				printing: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_QUOTE_PRINT_TO_PDF_SUCCESS:
			return {
				...state,
				printing: false
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_DELETE_LIST:
			return {
				...state,
				deletingLines: true
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_DELETE_ERROR:
			return {
				...state,
				deletingLines: false
			}
		case actionTypes.CUSTOMER_QUOTE_LINE_DELETE_SUCCESS:
			return {
				...state,
				deletingLines: false,
				customerQuoteLines: action.customerQuoteLines
			}
		default:
			return state;
	}
}