import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCustomer from '../../store/Customer/actions';
import * as actionsCustomerAgreement from '../../store/CustomerAgreement/actions';
import { AgreementSignatureType } from "definitions/enums/AgreementSignatureType";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
import { PeriodType } from "definitions/enums/PeriodType";
import { signatureType } from "definitions/columns/customerAgreement";

class CustomerAgreementEdit extends Component {

	constructor(props) {
		super(props);

		const defaultDate = new Date();					// today's date

		this.state = {
			id: "",
			customerId: "",
			agreementNo: "",
			signatureType: AgreementSignatureType[0].id,
			signatureDate: defaultDate.toJSON().substring(0, 10),
			effectiveDate: defaultDate.toJSON().substring(0, 10),
			expirationDate: "",
			renewalType: PeriodType[0].id,
			invoicingPeriod: PeriodType[4].id,
			invoiceDueDays: 7,
			signer1: "",
			signer2: "",
			customerSigner1: "",
			customerSigner2: "",

			doesNotExpire: false,

			customerAgreements: [],
			errors: {},
			registrationCountryNameLang0: ""
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedCustomerAgreement = {
			customerId: this.state.customerId,
			agreementNo: this.state.agreementNo,
			signatureType: this.state.signatureType,
			signatureDate: this.state.signatureDate,
			effectiveDate: this.state.effectiveDate,
			expirationDate: this.state.expirationDate,
			renewalType: this.state.renewalType,
			invoicingPeriod: this.state.invoicingPeriod,
			invoiceDueDays: this.state.invoiceDueDays,
			signer1: this.state.signer1,
			signer2: this.state.signer2,
			customerSigner1: this.state.customerSigner1,
			customerSigner2: this.state.customerSigner2,
		};
		if (this.state.id) {
			newOrUpdatedCustomerAgreement = {
				id: this.state.id,
				...newOrUpdatedCustomerAgreement
			};
		}
		this.props.onCreateCustomerAgreement(newOrUpdatedCustomerAgreement, this.props.history);
	}

	onDeleteCustomerAgreement = () => {
		if (window.confirm("Are you sure you want to delete this customer agreement?")) {
			this.props.onDeleteCustomerAgreement(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetCustomerAgreementById(id);
		}
		this.props.onGetCustomers();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.countries !== this.props.countries) {
			if (this.props.countries && this.props.countries[0] && !this.state.registrationCountryId) {
				this.setState({
					registrationCountryId: this.props.countries[0].id
				})
			}
		}

		if (prevProps.customerAgreements !== this.props.customerAgreements) {
			if (this.props.customerAgreements && this.props.customerAgreements[0]) {
				this.setState({
					id: this.props.customerAgreements[0].id,
					customerId: this.props.customerAgreements[0].customerId,
					agreementNo: this.props.customerAgreements[0].agreementNo,
					signatureType: (this.props.customerAgreements[0].signatureType ? this.props.customerAgreements[0].signatureType : AgreementSignatureType[0].id),
					signatureDate: this.props.customerAgreements[0].signatureDate,
					effectiveDate: this.props.customerAgreements[0].effectiveDate,
					expirationDate: this.props.customerAgreements[0].expirationDate,
					renewalType: (this.props.customerAgreements[0].renewalType ? this.props.customerAgreements[0].renewalType : PeriodType[0].id),
					invoicingPeriod: (this.props.customerAgreements[0].invoicingPeriod ? this.props.customerAgreements[0].invoicingPeriod : PeriodType[4].id),
					invoiceDueDays: this.props.customerAgreements[0].invoiceDueDays,
					signer1: this.props.customerAgreements[0].signer1,
					signer2: this.props.customerAgreements[0].signer2,
					customerSigner1: this.props.customerAgreements[0].customerSigner1,
					customerSigner2: this.props.customerAgreements[0].customerSigner2,

					doesNotExpire: !this.props.customerAgreements[0].expirationDate,
				});
			} else {
				this.setState({
					id: "",
					customerId: "",
					agreementNo: "",
					signatureType: "",
					signatureDate: "",
					effectiveDate: "",
					expirationDate: "",
					renewalType: "",
					invoicingPeriod: "",
					invoiceDueDays: "",
					signer1: "",
					signer2: "",
					customerSigner1: "",
					customerSigner2: "",

					doesNotExpire: false
				});
			}
		}

		if (prevProps.customers !== this.props.customers && this.props.customers && this.props.customers.length) {
			this.setState({
				customerId: this.props.customers[0].id
			});
		}
	}

	render() {
		const loading = this.props.loadingCustomers || this.props.loadingCustomerAgreements;

		const pageTitle = (this.state.id || loading ? "Edit" : "Create") + " customer agreement | " + config.AppName;
		const breadcrumbsTitle = "Customer agreement";
		const breadcrumbsItem = (this.state.id || loading ? "Edit" : "New") + " customer agreement";

		const agreementSignatureTypeOptions = inputSelectUtils.generateOptionsFromData(AgreementSignatureType, row => row.description);
		const customerOptions = inputSelectUtils.generateOptionsFromData(this.props.customers,
			customer => customer.name,
			(customer1, customer2) => (customer1.name > customer2.name ? 1 : customer1.name === customer2.name ? 0 : -1)
		);
		const periodTypeOptions = inputSelectUtils.generateOptionsFromData(PeriodType, row => row.description);
		const periodTypeOptionsMinusNone = inputSelectUtils.generateOptionsFromData(PeriodType.filter(row => row.id !== "NONE"), row => row.description);

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("customerId", "Customer", this.onChange, this.state.customerId, customerOptions)}
										{editFormControls.textControl("agreementNo", "Agreement No.", this.onChange, this.state.agreementNo)}
										{editFormControls.selectControl("signatureType", "Signature type", this.onChange, this.state.signatureType, agreementSignatureTypeOptions)}
										{editFormControls.dateControl("signatureDate", "Signature date", this.onChange, this.state.signatureDate)}
										{editFormControls.dateControl("effectiveDate", "Effective date", this.onChange, this.state.effectiveDate)}
										{editFormControls.checkboxControl("doesNotExpire", "Does not expire", this.onChange, this.state.doesNotExpire)}
										{!this.state.doesNotExpire && editFormControls.dateControl("expirationDate", "Expiration date", this.onChange, this.state.expirationDate)}
										{editFormControls.selectControl("renewalType", "Renewal type", this.onChange, this.state.renewalType, periodTypeOptions)}
										{editFormControls.selectControl("invoicingPeriod", "Invoicing period", this.onChange, this.state.invoicingPeriod, periodTypeOptionsMinusNone)}
										{editFormControls.numberControl("invoiceDueDays", "Invoice due days", this.onChange, this.state.invoiceDueDays, 1)}
										{editFormControls.textControl("signer1", "Signer1", this.onChange, this.state.signer1)}
										{editFormControls.textControl("signer2", "Signer2", this.onChange, this.state.signer2)}
										{editFormControls.textControl("customerSigner1", "Customer signer1", this.onChange, this.state.customerSigner1)}
										{editFormControls.textControl("customerSigner2", "Customer signer2", this.onChange, this.state.customerSigner2)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										{" "}
										{editFormControls.copyToClipboardButton(
											"Customer ID: " + this.state.customerId + "\n"
											+ "Agreement No.: " + this.state.agreementNo + "\n"
											+ "Signature type: " + (this.state.signatureType ? AgreementSignatureType.filter(signatureType => signatureType.id === this.state.signatureType)[0].description : "") + "\n"
											+ "Signature date: " + this.state.signatureDate + "\n"
											+ "Effective date: " + this.state.effectiveDate + "\n"
											+ "Valid till: " + this.state.expirationDate + "\n"
											+ "Renewal type: " + (this.state.renewalType ? PeriodType.filter(periodType => periodType.id === this.state.renewalType)[0].description : "") + "\n"
											+ "Signer1: " + this.state.signer1 + "\n"
											+ "Signer2: " + this.state.signer2 + "\n"
											+ "Customer signer1: " + this.state.customerSigner1 + "\n"
											+ "Customer signer2: " + this.state.customerSigner2 + "\n"
										)}
										{" "}
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteCustomerAgreement, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading && editForm}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ country, customer, customerAgreement }) => ({
	countries: country.countries,
	customerAgreements: customerAgreement.customerAgreements,
	customers: customer.customers,
	deleting: customerAgreement.deleting,
	error: customerAgreement.error,
	loadingCountries: country.loading,
	loadingCustomers: customer.loading,
	loadingCustomerAgreements: customerAgreement.loading,
	saving: customerAgreement.saving
});

const mapDispatchToProps = dispatch => ({
	onCreateCustomerAgreement: (customerAgreement, history) => dispatch(actionsCustomerAgreement.customerAgreementCreate(customerAgreement, history)),
	onDeleteCustomerAgreement: (id, history) => dispatch(actionsCustomerAgreement.customerAgreementDelete(id, history)),
	onGetCustomers: () => dispatch(actionsCustomer.customerGetAll()),
	onGetCustomerAgreementById: (id) => dispatch(actionsCustomerAgreement.customerAgreementGetById(id)),
});


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerAgreementEdit);

