import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/CustomerAgreement/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsCustomerAgreement from '../../definitions/columns/customerAgreement';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class CustomerAgreementBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date()
		}
	}

	componentDidMount() {
		this.props.onGetCustomerAgreements();
	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	render() {

		const pageTitle = "Customer agreements | " + config.AppName;
		const breadcrumbsTitle = "Customer agreement";
		const breadcrumbsItem = "Browse customer agreements";
		const errorText = "Error getting customer agreements" + (this.props.error && this.props.error.message ? ": " + this.props.error.message : "");

		const columns = [
			columnsCustomerAgreement.agreementNo,
			columnsCustomerAgreement.customerName,
			columnsCustomerAgreement.effectiveDate,
			columnsCustomerAgreement.expirationDate,
			columnsCustomerAgreement.renewalType
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.CUSTOMER_AGREEMENT_EDIT.replace(":id", row.id));
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.customerAgreements}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
				/>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.customerAgreements ?
											table
											: null}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ customerAgreement }) => ({
	customerAgreements: customerAgreement.customerAgreements,
	loading: customerAgreement.loading,
	error: customerAgreement.error
})

const mapDispatchToProps = dispatch => ({
	onGetCustomerAgreements: () => dispatch(actions.customerAgreementGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CustomerAgreementBrowse));
