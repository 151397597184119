import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const createdByUserAccountId = {
	dataField: 'createdByUserAccountId',
	text: 'Created by user account ID',
	filter: textFilter(),
	sort: true
};
export const assignedToUserAccountId = {
	dataField: 'assignedToUserAccountId',
	text: 'Assigned to user account ID',
	filter: textFilter(),
	sort: true
};
export const createdOn = {
	dataField: 'createdOn',
	text: 'Created on',
	filter: textFilter(),
	sort: true
};
export const completedOn = {
	dataField: 'completedOn',
	text: 'Completed on',
	filter: textFilter(),
	sort: true
};
export const taskStatus = {
	dataField: 'taskStatus',
	text: 'Task status',
	filter: textFilter(),
	sort: true
};
export const startDate = {
	dataField: 'startDate',
	text: 'Start date',
	filter: textFilter(),
	sort: true
};
export const dueDate = {
	dataField: 'dueDate',
	text: 'Due date',
	filter: textFilter(),
	sort: true
};
export const title = {
	dataField: 'title',
	text: 'Title',
	filter: textFilter(),
	sort: true
};
export const description = {
	dataField: 'description',
	text: 'Description',
	filter: textFilter(),
	sort: true
};
export const tags = {
	dataField: 'tagsAsString',
	text: 'Tags',
	filter: textFilter(),
	sort: true,
	formatter: (cell, row) => formatUtils.formatTags(row.tags)
};
export const createdByUserAccountFirstName = {
	dataField: 'createdByUserAccountFirstName',
	text: 'Created by user account first name',
	filter: textFilter(),
	sort: true
};
export const createdByUserAccountLastName = {
	dataField: 'createdByUserAccountLastName',
	text: 'Created by user account last name',
	filter: textFilter(),
	sort: true
};
export const createdByUserAccountUsername = {
	dataField: 'createdByUserAccountUsername',
	text: 'Created by',
	filter: textFilter(),
	sort: true
};
export const assignedToUserAccountFirstName = {
	dataField: 'assignedToUserAccountFirstName',
	text: 'Assigned to (first name)',
	filter: textFilter(),
	sort: true
};
export const assignedToUserAccountLastName = {
	dataField: 'assignedToUserAccountLastName',
	text: 'Assigned to (last name)',
	filter: textFilter(),
	sort: true
};
export const assignedToUserAccountUsername = {
	dataField: 'assignedToUserAccountUsername',
	text: 'Assigned to',
	filter: textFilter(),
	sort: true
};
