import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsProduct from '../../store/Product/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class ProductEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			nameLang0: "",
			nameLang1: "",
			products: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedProduct = {
			nameLang0: this.state.nameLang0,
			nameLang1: this.state.nameLang1,

		}
		if (this.state.id) {
			newOrUpdatedProduct = {
				id: this.state.id,
				...newOrUpdatedProduct
			}
		}
		this.props.onCreateProduct(newOrUpdatedProduct, this.props.history);
	}

	onDeleteProduct = () => {
		if (window.confirm("Are you sure you want to delete this Product?")) {
			this.props.onDeleteProduct(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetProductById(id);
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.products !== this.props.products) {
			if (this.props.products && this.props.products[0]) {

				this.setState({
					id: this.props.products[0].id,
					nameLang0: this.props.products[0].nameLang0,
					nameLang1: this.props.products[0].nameLang1,

				});
			} else {
				this.setState({
					id: "",
					nameLang0: "",
					nameLang1: "",

				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " Product | " + config.AppName;
		const breadcrumbsTitle = "Product";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " Product";

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.textControl("nameLang0", "Name", this.onChange, this.state.nameLang0)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										<span> </span>
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteProduct, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ product }) => ({
	products: product.products,
	loading: product.loading,
	error: product.error
})

const mapDispatchToProps = dispatch => ({
	onGetProductById: (id) => dispatch(actionsProduct.productGetById(id)),
	onCreateProduct: (product, history) => dispatch(actionsProduct.productCreate(product, history)),
	onDeleteProduct: (id, history) => dispatch(actionsProduct.productDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProductEdit);
