import React from 'react';
import { Spinner } from "reactstrap";

import classes from './OverlayWithSpinner.module.css'

// In order to overlay a certain object, must be placed next to it in a div with position:relative

const captionComponent = (caption) => {
    return (
        <h3>
            {caption}
        </h3>
    );
}

const OverlayWithSpinner = (props) => (
    props.show ? (
        <div
            className={classes.OverlayWithSpinner}
            onClick={props.onClick}
        >
            {props.caption && captionComponent(props.caption)}
            <Spinner />
            {props.children}
        </div>
    ) : null
);

export default OverlayWithSpinner;