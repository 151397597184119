import { all, fork } from "redux-saga/effects";

import allocationCriterionSaga from "./AllocationCriterion/saga";
import availableModuleSaga from "./AvailableModule/saga";
import AuthSaga from "./UserAccountLogin/saga";
import bankAccountSaga from "./BankAccount/saga";
import allocationSaga from "./Allocation/saga";
import bankStatementSaga from "./BankStatement/saga";
import cm20PaymentSaga from "./CM20Payment/saga";
import cm20PaymentFileSaga from "./CM20PaymentFile/saga";
import companySaga from "./Company/saga";
import companySettingsSaga from "./CompanySettings/saga";
import counterSaga from "./Counter/saga";
import countrySaga from "./Country/saga";
import crmBankImplementationSaga from "./CRMBankImplementation/saga";
import crmMaintenanceSaga from "./CRMMaintenance/saga";
import currencySaga from "./Currency/saga";
import customerAgreementSaga from "./CustomerAgreement/saga";
import customerInvoiceSaga from "./CustomerInvoice/saga";
import customerQuoteSaga from "./CustomerQuote/saga";
import customerSaga from "./Customer/saga";
import dashboardDataSaga from "./DashboardData/saga";
import dataAnalysisSaga from "./DataAnalysis/saga";
import exchRateSaga from "./CurrencyExchangeRate/saga";
import externalDataSyncRequestSaga from "./ExternalDataSync/saga";
import PaymentSaga from "./Payment/saga";
import PaymentBulkSaga from "./PaymentBulk/saga";
import PaymentFileSaga from "./PaymentFile/saga";
import productSaga from "./Product/saga";
import reportSaga from "./Report/saga";
import scheduledTaskSaga from "./ScheduledTask/saga";
import smartIdSaga from "./SmartId/saga";
import supplierSaga from "./Supplier/saga";
import supplierBankAccountSaga from "./SupplierBankAccount/saga";
import supplierInvoiceSaga from "./SupplierInvoice/saga";
import tagSaga from "./Tag/saga";
import taskSaga from "./Task/saga";
import timelogSaga from "./Timelog/saga";
import timelogTimerSaga from "./TimelogTimer/saga";
import tenantSaga from "./Tenant/saga";
import tenantLicenseSaga from "./TenantLicense/saga";
import unitSaga from "./Unit/saga";
import userAccountSaga from "./UserAccount/saga";
import userActivateCommonSaga from "./UserActivate/saga";
import UserPasswordResetSaga from "./UserPasswordReset/saga";
import userRegisterSaga from "./UserRegister/saga";
import vatRateSaga from "./VATRate/saga";

import ProfileSaga from "./unused/auth-profile/saga";
import LayoutSaga from "./layout/saga";
import ecommerceSaga from "./unused/e-commerce/saga";
import calendarSaga from "./unused/calendar/saga";
import cryptoSaga from "./unused/crypto/saga";
import invoiceSaga from "./unused/invoices/saga";
import projectsSaga from "./unused/projects/saga";
import tasksSaga from "./unused/tasks/saga";
import mailsSaga from "./unused/mails/saga";
import contactsSaga from "./unused/contacts/saga";

export default function* rootSaga() {
	yield all([
		fork(allocationCriterionSaga),
		fork(availableModuleSaga),
		fork(AuthSaga),
		fork(bankAccountSaga),
		fork(allocationSaga),
		fork(bankStatementSaga),
		fork(cm20PaymentSaga),
		fork(cm20PaymentFileSaga),
		fork(companySaga),
		fork(companySettingsSaga),
		fork(counterSaga),
		fork(countrySaga),
		fork(crmBankImplementationSaga),
		fork(crmMaintenanceSaga),
		fork(currencySaga),
		fork(customerAgreementSaga),
		fork(customerInvoiceSaga),
		fork(customerQuoteSaga),
		fork(customerSaga),
		fork(dashboardDataSaga),
		fork(dataAnalysisSaga),
		fork(exchRateSaga),
		fork(externalDataSyncRequestSaga),
		fork(PaymentSaga),
		fork(PaymentBulkSaga),
		fork(PaymentFileSaga),
		fork(productSaga),
		fork(reportSaga),
		fork(scheduledTaskSaga),
		fork(smartIdSaga),
		fork(supplierSaga),
		fork(supplierBankAccountSaga),
		fork(supplierInvoiceSaga),
		fork(tagSaga),
		fork(taskSaga),
		fork(tenantSaga),
		fork(tenantLicenseSaga),
		fork(timelogSaga),
		fork(timelogTimerSaga),
		fork(unitSaga),
		fork(userAccountSaga),
		fork(userActivateCommonSaga),
		fork(UserPasswordResetSaga),
		fork(userRegisterSaga),
		fork(vatRateSaga),
				
		fork(ProfileSaga),
		fork(LayoutSaga),
		fork(ecommerceSaga),
		fork(calendarSaga),
		fork(mailsSaga),
		fork(cryptoSaga),
		fork(invoiceSaga),
		fork(projectsSaga),
		fork(tasksSaga),
		fork(contactsSaga),
	])
};
