import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "./store/UserAccountLogin/actions";
import AppRoute from "./routes/route";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";
import timerService from "./sessionTimerService";
// import SessionTimerInit from "./sessionTimerInit";

import "./assets/scss/theme.scss";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleLogin = () => {
		const logoutCallback = () => {
			this.props.onLogout();
		};
		timerService.setLogoutCallback(logoutCallback);
		timerService.startTimer();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.loggedIn !== this.props.loggedIn) {
			if (this.props.loggedIn) {
				this.handleLogin();
			} else {

			}
		}
	}

	render() {
		const Layout = VerticalLayout;

		return (
			<React.Fragment>
				<Router basename="/">
					{/* <SessionTimerInit /> */}
					<Switch>
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								exact
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ Layout, userLogin }) => {
	return {
		layout: Layout,
		loggedIn: userLogin.loggedIn,
	}
}

const mapDispatchToProps = dispatch => ({
	onLogout: () => dispatch(actions.logoutUser())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(App);
