import * as actionTypes from "./actionTypes";

export const customerAgreementCreate = (customerAgreement, history) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_CREATE,
		customerAgreement: customerAgreement, 
		history: history
	}
}

export const customerAgreementCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_CREATE_ERROR,
		error: thisError
	}
}

export const customerAgreementCreateSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_CREATE_SUCCESS
	}
}

export const customerAgreementDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_DELETE,
		id: id,
		history: history
	}
}

export const customerAgreementDeleteError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_DELETE_ERROR,
		error: thisError
	}
}

export const customerAgreementDeleteSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_DELETE_SUCCESS
	}
}

export const customerAgreementGetAll = () => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_GET_ALL
	}
}

export const customerAgreementGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_GET_BY_ID,
		id: id
	}
}

export const customerAgreementGetByCustomerId = (customerId) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID,
		customerId: customerId
	}
}

export const customerAgreementGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_GET_ERROR,
		error: thisError
	}
}

export const customerAgreementGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_AGREEMENT_GET_SUCCESS,
		customerAgreements: data
	}
}

