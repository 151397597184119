import axios from "axios"
import * as restHelper from "./restHelper";
import * as endpointsFrontend from "../definitions/endpoints/endpoints-frontend"

async function get(url, config) {
  const response = await restHelper.get(url, config);
  return response.data;
}

async function post(url, data, config) {
  const response = await restHelper.post(url, data, config);
  return response.data;
}

async function put(url, data, config) {
  const response = await restHelper.put(url, data, config);
  return response.data;
}

async function del(url, data, config) {
  const response = await restHelper.del(url, data, config);
  return response.data;
}

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(endpointsFrontend.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// postForgetPwd
const postFakeForgetPwd = data => post(endpointsFrontend.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(endpointsFrontend.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(endpointsFrontend.POST_EDIT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// postForgetPwd
const postJwtForgetPwd = data => post(endpointsFrontend.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(endpointsFrontend.SOCIAL_LOGIN, data)

// get Products
export const getProducts = () => get(endpointsFrontend.GET_PRODUCTS)

// get Product detail
export const getProductDetail = id =>
  get(`${endpointsFrontend.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(endpointsFrontend.GET_EVENTS)

// add Events
export const addNewEvent = event => post(endpointsFrontend.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(endpointsFrontend.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(endpointsFrontend.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(endpointsFrontend.GET_CATEGORIES)

// get orders
export const getOrders = () => get(endpointsFrontend.GET_ORDERS)

// add order
export const addNewOrder = order => post(endpointsFrontend.ADD_NEW_ORDER, order)

// update order
export const updateOrder = order => put(endpointsFrontend.UPDATE_ORDER, order)

// delete order
export const deleteOrder = order =>
  del(endpointsFrontend.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(endpointsFrontend.GET_CART_DATA)

// get customers
export const getCustomers = () => get(endpointsFrontend.GET_CUSTOMERS)

// add customer
export const addNewCustomer = customer => post(endpointsFrontend.ADD_NEW_CUSTOMER, customer)

// update customer
export const updateCustomer = customer => put(endpointsFrontend.UPDATE_CUSTOMER, customer)

// delete customer
export const deleteCustomer = customer =>
  del(endpointsFrontend.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(endpointsFrontend.GET_SHOPS)

// get wallet
export const getWallet = () => get(endpointsFrontend.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(endpointsFrontend.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(endpointsFrontend.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${endpointsFrontend.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(endpointsFrontend.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${endpointsFrontend.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// add project
export const addNewProject = project => post(endpointsFrontend.ADD_NEW_PROJECT, project)

// update project
export const updateProject = project => put(endpointsFrontend.UPDATE_PROJECT, project)

// delete project
export const deleteProject = project =>
  del(endpointsFrontend.DELETE_PROJECT, { headers: { project } })

// get tasks
export const getTasks = () => get(endpointsFrontend.GET_TASKS)

// get contacts
export const getUsers = () => get(endpointsFrontend.GET_USERS)

// add user
export const addNewUser = user => post(endpointsFrontend.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(endpointsFrontend.UPDATE_USER, user)

// delete user
export const deleteUser = user =>
  del(endpointsFrontend.DELETE_USER, { headers: { user } })

export const getUserProfile = () => get(endpointsFrontend.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(endpointsFrontend.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail => post(endpointsFrontend.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(endpointsFrontend.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(endpointsFrontend.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(endpointsFrontend.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(endpointsFrontend.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(endpointsFrontend.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(endpointsFrontend.GET_DRAFT_MAILS)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtForgetPwd,
  postJwtProfile
}
