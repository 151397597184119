import * as actionTypes from "./actionTypes";

const initialState = {
	error: null,
	deleting: false,
	loading: false,
	saveSuccess: false,
	saving: false,
	timelogs: null,
}

export default function timelogReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TIMELOG_CREATE:
			return {
				...state,
				error: null,
				saveSuccess: false,
				saving: true
			}
		case actionTypes.TIMELOG_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.TIMELOG_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saveSuccess: true,
				saving: false,
				timelogs: action.timelogs
			}
		case actionTypes.TIMELOG_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.TIMELOG_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.TIMELOG_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.TIMELOG_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.TIMELOG_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.TIMELOG_GET_BY_TASK:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.TIMELOG_GET_SUCCESS:
			return {
				...state,
				loading: false,
				timelogs: action.timelogs
			}
		case actionTypes.TIMELOG_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		default:
			return state;
	}
}