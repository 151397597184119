import * as actionTypes from "./actionTypes";

const initialState = {
	cm20Payments: null,
	error: null,
	loading: false,
	resetting: false,
	signing: false
}

export default function cm20PaymentReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CM20PAYMENT_GET_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.CM20PAYMENT_GET_SIGNED:
			return {
				...state,
				error: null
			}
		case actionTypes.CM20PAYMENT_GET_UNSIGNED:
			return {
				...state,
				error: null
			}
		case actionTypes.CM20PAYMENT_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.CM20PAYMENT_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.CM20PAYMENT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				cm20Payments: action.cm20Payments
			}
		case actionTypes.CM20PAYMENT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.CM20PAYMENT_CREATE:
			return {
				...state,
				error: null
			}
		case actionTypes.CM20PAYMENT_CREATE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.CM20PAYMENT_SIGN_GET_CERTIFICATE:
			return {
				...state,
				error: null,
				signing: true
			}
		case actionTypes.CM20PAYMENT_SIGN_INIT:
			return state;
		case actionTypes.CM20PAYMENT_SIGN_ERROR:
			return {
				...state,
				error: action.error,
				resetting: false,
				signing: false
			}
		case actionTypes.CM20PAYMENT_SIGN_RESET:
			return {
				...state,
				error: null,
				resetting: true
			}
		case actionTypes.CM20PAYMENT_SIGN_RESET_SUCCESS:
			return {
				...state,
				cm20Payments: action.cm20Payments,
				resetting: false
			}
		case actionTypes.CM20PAYMENT_SIGN_SUCCESS:
			return {
				...state,
				error: null,
				cm20Payments: action.cm20Payments,
				signing: false
			}

		default:
			return state;
	}
}