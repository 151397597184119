import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCustomer from '../../store/Customer/actions';
import * as actionsCustomerAgreement from '../../store/CustomerAgreement/actions';
import * as actionsCustomerInvoice from '../../store/CustomerInvoice/actions';
import * as actionsProduct from '../../store/Product/actions';
import * as actionsUnit from '../../store/Unit/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';

class CustomerInvoiceLineEdit extends Component {

    constructor(props) {
        super(props);

        const defaultPeriodStartDate = new Date();					// today's date

        this.state = {
            id: "",
            customerInvoiceId: "",
            position: "",
            description2: "",
            productId: "",
            unitId: "",
            quantity: 0,
            price: 0,
            vatRate: 0,
            discount: 0,
            sequenceNo: -1,

            customerInvoiceLines: [],
            customerAgreementId: "",
            endCustomerId: "",
            periodStart: defaultPeriodStartDate.toJSON().substring(0, 10),
            periodEnd: "",
            errors: {}
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    unitName(unitId) {
        const selectedUnitAsArray = this.props.units ? (this.props.units.filter(unit => unit.id == unitId)) : null;
        return (selectedUnitAsArray && selectedUnitAsArray.length) ? (selectedUnitAsArray[0].nameLang0) : "";
    }

    showPeriodControls(unitId) {
        const unitName = this.unitName(unitId);
        return (unitName === "mon" || unitName === "days");
    }

    objectValueFromLatestChange(targetObjectName, objectNameFromChangeEvent, valueFromChangeEvent) {
        if (objectNameFromChangeEvent === targetObjectName) {
            return valueFromChangeEvent;
        } else {
            return this.state[targetObjectName];
        }
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });

        const unitId = this.objectValueFromLatestChange("unitId", thisObjectName, thisValue);
        const unitName = this.unitName(unitId);
        const showPeriodControls = this.showPeriodControls(unitId);

        if (showPeriodControls && (thisObjectName === "periodStart" || thisObjectName === "unitId" || thisObjectName === "quantity")) {
            const quantity = this.objectValueFromLatestChange("quantity", thisObjectName, thisValue);
            const quantityInMonths = unitName === "mon" ? quantity : quantity / 30;
            const quantityInFullMonths = Math.floor(quantityInMonths);
            const quantityAdditionalDays = Math.round((quantityInMonths - quantityInFullMonths) * 30);

            const periodStart = this.objectValueFromLatestChange("periodStart", thisObjectName, thisValue);
            if (periodStart && quantityInMonths) {
                const periodStartAsDate = new Date(Date.parse(periodStart));

                // The code below needs to get and set a UTC month and a UTC date, 
                // otherwise e.g. with periodStart = 2023-01-01 and quantity = 3 we might get 2023-03-30 instead of 2023-03-31
                // due to the changes in the timezone
                const periodStartPlusMonths = new Date(periodStartAsDate.setUTCMonth(periodStartAsDate.getUTCMonth() + quantityInFullMonths));
                const periodEndAsDate = new Date(periodStartPlusMonths.setUTCDate(periodStartPlusMonths.getUTCDate() + quantityAdditionalDays - 1));

                this.setState({
                    periodEnd: periodEndAsDate.toJSON().substring(0, 10)
                });
            }
        }

    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedCustomerInvoiceLine = {
            customerInvoiceId: this.state.customerInvoiceId,
            position: this.state.position,
            description2: this.state.description2,
            customerAgreementId: this.state.customerAgreementId,
            endCustomerId: this.state.endCustomerId,
            productId: this.state.productId,
            unitId: this.state.unitId,
            quantity: this.state.quantity,
            price: this.state.price,
            vatRate: this.state.vatRate,
            discount: this.state.discount,
            sequenceNo: this.state.sequenceNo
        }
        if (this.state.id) {
            newOrUpdatedCustomerInvoiceLine = {
                id: this.state.id,
                ...newOrUpdatedCustomerInvoiceLine
            }
        }
        if (this.showPeriodControls(this.state.unitId)) {
            newOrUpdatedCustomerInvoiceLine = {
                periodStart: this.state.periodStart,
                periodEnd: this.state.periodEnd,
                ...newOrUpdatedCustomerInvoiceLine
            }
        }
        this.props.onCreateCustomerInvoiceLine(newOrUpdatedCustomerInvoiceLine, this.props.history);
    }

    onDeleteCustomerInvoiceLine = () => {
        if (window.confirm("Are you sure you want to delete this CustomerInvoiceLine?")) {
            this.props.onDeleteCustomerInvoiceLine(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const { invoiceid } = this.props.match.params;
        if (id || invoiceid) {
            if (id) {
                this.props.onGetCustomerInvoiceLineById(id);
            }
            this.props.onGetCustomers();
            const customerInvoice = this.props.customerInvoices[0];
            if (customerInvoice) {
                const customerId = customerInvoice.customerId;
                if (customerId) {
                    this.props.onGetCustomerAgreementsByCustomerId(customerId);
                }
            }
        }
        this.props.onGetProducts();
        this.props.onGetUnits();
        this.setState({
            customerInvoiceId: this.props.match.params.invoiceid
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.errorInvoiceLines !== this.props.errorInvoiceLines) {
            if (this.props.errorInvoiceLines) {
                this.setState({
                    errors: this.props.errorInvoiceLines
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.units !== this.props.units) {
            if (!this.state.unitId && this.props.units) {
                this.setState({
                    unitId: this.props.units[0].id
                });
            }
        }

        if (prevProps.customerInvoiceLines !== this.props.customerInvoiceLines) {
            if (this.props.customerInvoiceLines && this.props.customerInvoiceLines[0]) {

                this.props.onGetCustomerInvoiceById(this.props.customerInvoiceLines[0].customerInvoiceId);

                this.setState({
                    id: this.props.customerInvoiceLines[0].id,
                    customerInvoiceId: this.props.customerInvoiceLines[0].customerInvoiceId,
                    position: this.props.customerInvoiceLines[0].position,
                    description2: this.props.customerInvoiceLines[0].description2,
                    customerAgreementId: this.props.customerInvoiceLines[0].customerAgreementId,
                    endCustomerId: this.props.customerInvoiceLines[0].endCustomerId,
                    productId: this.props.customerInvoiceLines[0].productId,
                    unitId: this.props.customerInvoiceLines[0].unitId,
                    periodStart: this.props.customerInvoiceLines[0].periodStart,
                    periodEnd: this.props.customerInvoiceLines[0].periodEnd,
                    quantity: this.props.customerInvoiceLines[0].quantity,
                    price: this.props.customerInvoiceLines[0].price,
                    vatRate: this.props.customerInvoiceLines[0].vatRate,
                    discount: this.props.customerInvoiceLines[0].discount,
                    sequenceNo: this.props.customerInvoiceLines[0].sequenceNo

                });
            } else {
                this.setState({
                    id: "",
                    position: "",
                    description2: "",
                    customerAgreementId: "",
                    endCustomerId: "",
                    productId: "",
                    unitId: "",
                    periodStart: "",
                    periodEnd: "",
                    quantity: 0,
                    price: 0,
                    vatRate: 0,
                    discount: 0,
                    sequenceNo: -1
                });
            }
        }

    }

    render() {
        const pageTitle = (this.state.id || this.props.loadingInvoiceLines ? "Edit" : "Create") + " Customer invoice line | " + config.AppName;
        const breadcrumbsTitle = "Customer invoice";
        const breadcrumbsItem = (this.state.id || this.props.loadingInvoiceLines ? "Edit" : "New") + " line";
        const returnLink = this.state.customerInvoiceId ? endpointsFrontend.CUSTOMER_INVOICE_EDIT.replace(":id", this.state.customerInvoiceId) : "";

        const customerAgreementOptions = inputSelectUtils.generateOptionsFromData(this.props.customerAgreements, customerAgreement => customerAgreement.agreementNo);
        const customerAgreementOptionsPlusNull = inputSelectUtils.addNullOption(customerAgreementOptions);

        const customerOptions = inputSelectUtils.generateOptionsFromData(this.props.customers,
            customer => customer.name,
            (customer1, customer2) => (customer1.name > customer2.name ? 1 : customer1.name === customer2.name ? 0 : -1)
        );
        const customerOptionsPlusNull = inputSelectUtils.addNullOption(customerOptions);

        const productOptions = inputSelectUtils.generateOptionsFromData(this.props.products, product => product.nameLang0);
        const productOptionsPlusNull = inputSelectUtils.addNullOption(productOptions);

        const unitOptions = inputSelectUtils.generateOptionsFromData(this.props.units, unit => unit.nameLang0);

        const showPeriodControls = this.showPeriodControls(this.state.unitId);

        const totalAmountWOVAT = Math.round((this.state.price * this.state.quantity * (100 - this.state.discount) / 100) * 100) / 100;
        const totalAmountWVAT = totalAmountWOVAT * (100 + parseFloat(this.state.vatRate)) / 100;
        const currencyCode = this.props.customerInvoices[0].currencyCode;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.hiddenValueControl("customerInvoiceId", this.onChange, this.state.customerInvoiceId)}
                                        {editFormControls.hiddenValueControl("sequenceNo", this.onChange, this.state.sequenceNo)}
                                        {editFormControls.textControl("position", "Position", this.onChange, this.state.position)}
                                        {editFormControls.textControl("description2", "Alternative description (optional)", this.onChange, this.state.description2)}
                                        {editFormControls.selectControl("customerAgreementId", "Agreement (optional)", this.onChange, this.state.customerAgreementId, customerAgreementOptionsPlusNull)}
                                        {editFormControls.selectControl("endCustomerId", "End customer (optional)", this.onChange, this.state.endCustomerId, customerOptionsPlusNull)}
                                        {editFormControls.selectControl("productId", "Product (optional)", this.onChange, this.state.productId, productOptionsPlusNull)}
                                        {editFormControls.selectControl("unitId", "Unit", this.onChange, this.state.unitId, unitOptions)}
                                        {editFormControls.numberControl("quantity", "Quantity", this.onChange, this.state.quantity, 0.5)}
                                        {showPeriodControls ? editFormControls.dateControl("periodStart", "Period start", this.onChange, this.state.periodStart) : null}
                                        {showPeriodControls ? editFormControls.staticTextControl("periodEnd", "Period end", this.state.periodEnd) : null}
                                        {editFormControls.numberControl("price", "Price, " + currencyCode, this.onChange, this.state.price, 0.01)}
                                        {editFormControls.numberControl("vatRate", "VAT rate, %", this.onChange, this.state.vatRate, 1, "Enter VAT rate, %")}
                                        {editFormControls.numberControl("discount", "Discount, %", this.onChange, this.state.discount, 5)}
                                        {editFormControls.staticTextControl("totalAmountWOVAT", "Total amount excluding VAT, " + currencyCode, formatUtils.formatAmount(totalAmountWOVAT))}
                                        {editFormControls.staticTextControl("totalAmountWVAT", "Total amount including VAT, " + currencyCode, formatUtils.formatAmount(totalAmountWVAT))}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteCustomerInvoiceLine, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loadingCustomers || this.props.loadingCustomerAgreements || this.props.loadingInvoiceLines || this.props.loadingInvoices
            || this.props.loadingProducts || this.props.loadingUnits;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs
                            title={breadcrumbsTitle}
                            breadcrumbItem={breadcrumbsItem}
                            link={returnLink}
                        />

                        {editFormControls.errorAlert(this.props.errorInvoiceLines)}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && (!this.props.match.params.id || this.state.customerInvoiceId) ? editForm : null}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ customer, customerAgreement, customerInvoice, product, unit }) => ({
    units: unit.units,
    products: product.products,
    customers: customer.customers,
    customerAgreements: customerAgreement.customerAgreements,
    customerInvoices: customerInvoice.customerInvoices,
    customerInvoiceLines: customerInvoice.customerInvoiceLines,
    loadingCustomers: customer.loading,
    loadingCustomerAgreements: customerAgreement.loading,
    loadingInvoices: customerInvoice.loading,
    loadingInvoiceLines: customerInvoice.loadingLines,
    loadingProducts: product.loading,
    loadingUnits: unit.loading,
    saving: customerInvoice.saving,
    errorCustomers: customer.error,
    errorCustomerAgreements: customerAgreement.error,
    errorInvoices: customerInvoice.error,
    errorInvoiceLines: customerInvoice.errorLines,
    errorUnits: unit.error
})

const mapDispatchToProps = dispatch => ({
    onGetCustomers: () => dispatch(actionsCustomer.customerGetAll()),
    onGetCustomerAgreementsByCustomerId: (customerId) => dispatch(actionsCustomerAgreement.customerAgreementGetByCustomerId(customerId)),
    onGetCustomerInvoiceLineById: (id) => dispatch(actionsCustomerInvoice.customerInvoiceLineGetById(id)),
    onGetCustomerInvoiceById: (id) => dispatch(actionsCustomerInvoice.customerInvoiceGetById(id)),
    onGetUnits: () => dispatch(actionsUnit.unitGetAll()),
    onGetProducts: () => dispatch(actionsProduct.productGetAll()),
    onCreateCustomerInvoiceLine: (customerInvoiceLine, history) => dispatch(actionsCustomerInvoice.customerInvoiceLineCreate(customerInvoiceLine, history)),
    onDeleteCustomerInvoiceLine: (id, history) => dispatch(actionsCustomerInvoice.customerInvoiceLineDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInvoiceLineEdit);
