import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row, Spinner } from "reactstrap";

import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsExchRate from '../../store/CurrencyExchangeRate/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from "../../helpers/editFormControls";
import * as formatUtils from "../../helpers/formatUtils";

class ExchRateEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			currencyId: "",
			code: "",
			dateFrom: "",
			dateTo: "",
			exchangeRate: 1,
			currencies: [],
			errors: {}
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue = e.target.value;
		if (thisObjectName === "exchangeRate") {
			//illegalCharacters = thisValue.replaceAll('[0-1]')
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedExchRate = {
			currencyId: this.state.currencyId,
			dateFrom: formatUtils.addTimeToDatetime(this.state.dateFrom),
			dateTo: formatUtils.addTimeToDatetime(this.state.dateTo),
			exchangeRate: this.state.exchangeRate
		}
		if (this.state.id) {
			newOrUpdatedExchRate = {
				id: this.state.id,
				...newOrUpdatedExchRate
			}
		}
		this.props.onCreateExchRate(newOrUpdatedExchRate, this.props.history);
	}

	onDeleteExchRate = () => {
		if (window.confirm("Are you sure you want to delete this exchange rate?")) {
			this.props.onDeleteExchRate(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetExchRateById(id);
		}
		this.props.onGetCurrencies();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id
				})
			}
		}

		if (prevProps.exchRates !== this.props.exchRates) {
			if (this.props.exchRates && this.props.exchRates[0]) {
				this.setState({
					id: this.props.exchRates[0].id,
					dateFrom: this.props.exchRates[0].dateFrom,
					dateTo: this.props.exchRates[0].dateTo,
					currencyId: this.props.exchRates[0].currencyId,
					exchangeRate: this.props.exchRates[0].exchangeRate,
					currencyCode: this.props.exchRates[0].currencyCode,
				});
			} else {
				this.setState({
					id: "",
					dateFrom: "",
					dateTo: "",
					currencyId: "",
					exchangeRate: "",
					currencyCode: "",
				});
			}
		}
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " exchange rate | " + config.AppName;
		const breadcrumbsTitle = "Currency";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " exchange rate";
		const errorText = "Error " + (this.state.id ? "updating" : "creating") + " exchange rate" + (this.props.error && this.props.error.message ? ": " + this.props.error.message : "");

		const currencyOptions = this.props.currencies ? this.props.currencies.map(currencyRow => (
			<option
				value={currencyRow.id}
				key={currencyRow.id}
			>
				{currencyRow.code} {currencyRow.name ? "(" + currencyRow.name + ")" : ""}
			</option>
		)) : null;

		const deleteButton = (
			<Button
				color="danger"
				onClick={this.onDeleteExchRate}
			>
				Delete
			</Button>
		);

		const editForm = (
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions, this.state.id)}
										{editFormControls.dateControl("dateFrom", "Starting date (inclusive)", this.onChange, this.state.dateFrom, this.state.id)}
										{editFormControls.dateControl("dateTo", "Ending date (exclusive)", this.onChange, this.state.dateTo, this.state.id)}
										{editFormControls.numberControl("exchangeRate", "Exchange rate", this.onChange, this.state.exchangeRate, 0.0000000000001)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										<Button type="submit" color="primary">
											{this.state.id ? "Update" : "Create"}
										</Button>
										<span> </span>
										{this.state.id ? deleteButton : null}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(this.props.loading)}

						{!this.props.loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ currency, exchRate }) => ({
	currencies: currency.currencies,
	exchRates: exchRate.exchRates,
	loading: exchRate.loading,
	error: exchRate.error
})

const mapDispatchToProps = dispatch => ({
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetExchRateById: (id) => dispatch(actionsExchRate.exchRateGetById(id)),
	onCreateExchRate: (exchRate, history) => dispatch(actionsExchRate.exchRateCreate(exchRate, history)),
	onDeleteExchRate: (id, history) => dispatch(actionsExchRate.exchRateDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExchRateEdit);

