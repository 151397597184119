import React from "react";
import { Redirect } from "react-router-dom";

import * as endpointsFrontend from "../definitions/endpoints/endpoints-frontend";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RegisterFromInvitation from "../pages/Authentication/RegisterFromInvitation";
import RegisterSuccess from "../pages/Authentication/RegisterSuccess";
import RegisterSuccessActivationPending from "../pages/Authentication/RegisterSuccessActivationPending";
import ProfileActivation from "pages/Authentication/ProfileActivation";
import ProfileEdit from "pages/UserAccount/user-account-profile-edit";
import PasswordResetRequest from "../pages/Authentication/PasswordResetRequest";
import PasswordReset from "../pages/Authentication/PasswordReset";

import StartPageRedirect from "../pages/StartPageRedirect/index";

import AllocationCriterionBrowse from "pages/AllocationCriterion/allocation-criterion-browse";
// import AllocationCriterionEdit from "pages/AllocationCriterion/allocation-criterion-edit";

import BankAccountBrowse from "pages/BankAccount/bank-account-browse";
import BankAccountEdit from "pages/BankAccount/bank-account-edit";

import BankStatementBrowse from "pages/BankStatement/bank-statement-browse";
import BankStatementReconcile from "pages/BankStatement/bank-statement-reconcile";
import BankStatementReports from "pages/BankStatement/bank-statement-reports";

import CompanyBrowse from "pages/Company/company-browse";
import CompanyEdit from "pages/Company/company-edit";

import CompanySettingsEdit from "pages/CompanySettings/settings-edit";
import CompanySettingsEdit_SupplierInvoiceProcessing from "pages/CompanySettings/settings-supplier-invoice-processing";

import CounterBrowse from "pages/Counter/counter-browse";

import CurrencyBrowse from "pages/Currency/currency-browse";
import CurrencyEdit from "pages/Currency/currency-edit";
import ExchangeRateBrowse from "pages/Currency/exchange-rate-edit";
import ExchangeRateEdit from "pages/Currency/exchange-rate-browse";

import CountryBrowse from "pages/Country/country-browse";
import CountryEdit from "pages/Country/country-edit";

import CustomerBrowse from "pages/Customer/customer-browse";
import CustomerEdit from "pages/Customer/customer-edit";

import CustomerAgreementBrowse from "pages/CustomerAgreement/customer-agreement-browse";
import CustomerAgreementEdit from "pages/CustomerAgreement/customer-agreement-edit";

import CustomerInvoiceBrowse from "pages/CustomerInvoice/customer-invoice-browse";
import CustomerInvoiceEdit from "pages/CustomerInvoice/customer-invoice-edit";
import CustomerInvoiceLineEdit from "pages/CustomerInvoice/customer-invoice-line-edit";

import CustomerQuoteBrowse from "pages/CustomerQuote/customer-quote-browse";
import CustomerQuoteInvoiceEdit from "pages/CustomerQuote/customer-quote-edit";
import CustomerQuoteLineEdit from "pages/CustomerQuote/customer-quote-line-edit";

import CM20PaymentsBrowse from "pages/CM20Payments/cm20-payments-browse";
import CM20PaymentsSignSuccess from "pages/CM20Payments/cm20-payments-sign-success";

import Dashboard from "../pages/Dashboard/dashboard";

import DataAnalysisCashFlow from "../pages/DataAnalysis/cash-flow-forecast";

import ExternalDataSyncRequestBrowse from "pages/ExternalDataSync/external-data-sync-request-browse";

import PaymentCreate from "pages/Payment/payment-create";
import PaymentEdit from "pages/Payment/payment-edit";
import PaymentSignSuccess from "pages/Payment/payment-sign-success";

import PaymentBulkBrowse from "pages/PaymentBulk/payment-bulk-browse";

import PaymentFileBrowse from "pages/PaymentFile/payment-file-browse";

import ProductBrowse from "pages/Product/product-browse";
import ProductEdit from "pages/Product/product-edit";

import ScheduledTaskBrowse from "pages/ScheduledTask/scheduled-task-browse";

import SupplierBrowse from "pages/Supplier/supplier-browse";
import SupplierEdit from "pages/Supplier/supplier-edit";

import SupplierBankAccountBrowse from "pages/SupplierBankAccount/supplier-bank-account-browse";
import SupplierBankAccountEdit from "pages/SupplierBankAccount/supplier-bank-account-edit";

import SupplierInvoiceBrowse from "pages/SupplierInvoice/supplier-invoice-browse";
import SupplierInvoiceEdit from "pages/SupplierInvoice/supplier-invoice-edit";

import SupplierInvoiceCandidateBrowse from "pages/SupplierInvoice/supplier-invoice-candidate-browse";

import TagBrowse from "pages/Task/tag-browse";
import TagEdit from "pages/Task/tag-edit";

import TaskBrowse from "pages/Task/task-browse";
import TaskEdit from "pages/Task/task-edit";

import TenantBrowse from "pages/Tenant/tenant-browse";
import TenantEdit from "pages/Tenant/tenant-edit";
import TenantNewAdmin from "pages/Tenant/new-admin-create";

import TimelogBrowse from "pages/Task/timelog-browse";
import TimelogEdit from "pages/Task/timelog-edit";

import UnitBrowse from "pages/Unit/unit-browse";
import UnitEdit from "pages/Unit/unit-edit";

import UserAccountBrowse from "pages/UserAccount/user-account-browse";
import UserAccountEdit from "pages/UserAccount/user-account-edit";
import UserAccountInvite from "pages/UserAccount/user-account-invite";

import VATRateBrowse from "pages/VATRate/vat-rate-browse";
import VATRateEdit from "pages/VATRate/vat-rate-edit";



import FileManager from "../pages/unused/FileManager/index";

import Calendar from "../pages/unused/Calendar/index";

import UserProfile from "../pages/Authentication/UserProfile";

import TasksList from "../pages/unused/Tasks/tasks-list";
import TasksKanban from "../pages/unused/Tasks/tasks-kanban";
import TasksCreate from "../pages/unused/Tasks/tasks-create";

import ProjectsGrid from "../pages/unused/Projects/projects-grid";
import ProjectsList from "../pages/unused/Projects/projects-list";
import ProjectsOverview from "../pages/unused/ProjectOverview/projects-overview";
import ProjectsCreate from "../pages/unused/Projects/projects-create";

import EcommerceProducts from "../pages/unused/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/unused/EcommerceProducts/EcommerceProductDetail";
import EcommerceCart from "../pages/unused/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/unused/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/unused/EcommerceShops/index";
import EcommerceAddProduct from "../pages/unused/Ecommerce/EcommerceAddProduct";

import EmailInbox from "../pages/unused/Email/email-inbox";
import EmailRead from "../pages/unused/Email/email-read";
import EmailBasicTemplte from "../pages/unused/Email/email-basic-templte";
import EmailAlertTemplte from "../pages/unused/Email/email-template-alert";
import EmailTemplateBilling from "../pages/unused/Email/email-template-billing";

import InvoicesList from "../pages/unused/Invoices/invoices-list";
import InvoiceDetail from "../pages/unused/Invoices/invoices-detail";

import LockScreen from "../pages/unused/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/unused/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/unused/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/unused/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/unused/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/unused/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/unused/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/unused/AuthenticationInner/auth-two-step-verification-2";

// import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet";
// import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
// import CryptoExchange from "../pages/Crypto/crypto-exchange";
// import CryptoLending from "../pages/Crypto/crypto-lending";
// import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders";
// import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
import CryptoIcoLanding from "../pages/unused/CryptoIcoLanding/index";

// Charts;
import ChartApex from "../pages/unused/Charts/Apexcharts";
import ChartjsChart from "../pages/unused/Charts/ChartjsChart";
import SparklineChart from "../pages/unused/Charts/SparklineChart";
import ChartsKnob from "../pages/unused/Charts/charts-knob";
import ReCharts from "../pages/unused/Charts/ReCharts";

// Maps;
import MapsGoogle from "../pages/unused/Maps/MapsGoogle";
import MapsVector from "../pages/unused/Maps/MapsVector";
import MapsLeaflet from "../pages/unused/Maps/MapsLeaflet";

//Icons;
import IconBoxicons from "../pages/unused/Icons/IconBoxicons";
import IconDripicons from "../pages/unused/Icons/IconDripicons";
import IconMaterialdesign from "../pages/unused/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/unused/Icons/IconFontawesome";

//Tables;
import BasicTables from "../pages/unused/Tables/BasicTables";
import ResponsiveTables from "../pages/unused/Tables/ResponsiveTables";
import EditableTables from "../pages/unused/Tables/EditableTables";
import DragDropTables from "../pages/unused/Tables/DragDropTables";
import DualListbox from "../pages/unused/Tables/DualListbox";

// Forms;
import FormElements from "../pages/unused/FormElements/index";
import FormLayouts from "../pages/unused/Forms/FormLayouts";
import FormAdvanced from "../pages/unused/Forms/FormAdvanced";
import FormEditors from "../pages/unused/Forms/FormEditors";
import FormValidations from "../pages/unused/Forms/FormValidations";
import FormMask from "../pages/unused/Forms/FormMask";
import FormRepeater from "../pages/unused/Forms/FormRepeater";
import FormUpload from "../pages/unused/Forms/FormUpload";
import FormWizard from "../pages/unused/Forms/FormWizard";
import FormXeditable from "../pages/unused/Forms/FormXeditable";

//Ui;
//Pages;
import PagesStarter from "../pages/unused/Utility/pages-starter";
import PagesMaintenance from "../pages/unused/Utility/pages-maintenance";
import PagesComingsoon from "../pages/unused/Utility/pages-comingsoon";
import PagesTimeline from "../pages/unused/Utility/pages-timeline";
import PagesFaqs from "../pages/unused/Utility/pages-faqs";
import PagesPricing from "../pages/unused/Utility/pages-pricing";
import Pages404 from "../pages/unused/Utility/pages-404";
import Pages500 from "../pages/unused/Utility/pages-500";

//Contacts;
import ContactsGrid from "../pages/unused/Contacts/contacts-grid";

const authProtectedRoutes = [
	{ path: endpointsFrontend.START_PAGE_REDIRECT, component: StartPageRedirect },

	{ path: endpointsFrontend.ALLOCATION_CRITERION_BROWSE_BANK_CHARGE, component: AllocationCriterionBrowse },
	{ path: endpointsFrontend.ALLOCATION_CRITERION_BROWSE_CUSTOMER_INVOICE, component: AllocationCriterionBrowse },
	{ path: endpointsFrontend.ALLOCATION_CRITERION_BROWSE_SUPPLIER_INVOICE, component: AllocationCriterionBrowse },
	{ path: endpointsFrontend.ALLOCATION_CRITERION_BROWSE_PAYMENT, component: AllocationCriterionBrowse },
	// { path: endpointsFrontend.ALLOCATION_CRITERION_NEW, component: AllocationCriterionEdit },
	// { path: endpointsFrontend.ALLOCATION_CRITERION_EDIT, component: AllocationCriterionEdit },

	{ path: endpointsFrontend.BANK_ACCOUNT_BROWSE, component: BankAccountBrowse },
	{ path: endpointsFrontend.BANK_ACCOUNT_NEW, component: BankAccountEdit },
	{ path: endpointsFrontend.BANK_ACCOUNT_EDIT, component: BankAccountEdit },

	{ path: endpointsFrontend.BANK_STATEMENT_BROWSE, component: BankStatementBrowse },
	{ path: endpointsFrontend.BANK_STATEMENT_RECONCILE, component: BankStatementReconcile },
	{ path: endpointsFrontend.BANK_STATEMENT_REPORTS, component: BankStatementReports },
	
	{ path: endpointsFrontend.COMPANY_BROWSE, component: CompanyBrowse },
	{ path: endpointsFrontend.COMPANY_EDIT, component: CompanyEdit },
	{ path: endpointsFrontend.COMPANY_NEW, component: CompanyEdit },

	{ path: endpointsFrontend.COMPANY_SETTINGS_EDIT, component: CompanySettingsEdit },
	{ path: endpointsFrontend.COMPANY_SETTINGS_SUPPLIER_INVOICE_PROCESSING, component: CompanySettingsEdit_SupplierInvoiceProcessing },
	
	{ path: endpointsFrontend.COUNTER_BROWSE, component: CounterBrowse },
	
	{ path: endpointsFrontend.CURRENCY_BROWSE, component: CurrencyBrowse },
	{ path: endpointsFrontend.CURRENCY_NEW, component: CurrencyEdit },
	{ path: endpointsFrontend.CURRENCY_EDIT, component: CurrencyEdit },
	{ path: endpointsFrontend.CURRENCY_EXCHRATE_BROWSE, component: ExchangeRateEdit },
	{ path: endpointsFrontend.CURRENCY_EXCHRATE_NEW, component: ExchangeRateBrowse },
	{ path: endpointsFrontend.CURRENCY_EXCHRATE_EDIT, component: ExchangeRateBrowse },

	{ path: endpointsFrontend.COUNTRY_BROWSE, component: CountryBrowse },
	{ path: endpointsFrontend.COUNTRY_NEW, component: CountryEdit },
	{ path: endpointsFrontend.COUNTRY_EDIT, component: CountryEdit },

	{ path: endpointsFrontend.CUSTOMER_BROWSE, component: CustomerBrowse },
	{ path: endpointsFrontend.CUSTOMER_NEW, component: CustomerEdit },
	{ path: endpointsFrontend.CUSTOMER_EDIT, component: CustomerEdit },

	{ path: endpointsFrontend.CUSTOMER_AGREEMENT_BROWSE, component: CustomerAgreementBrowse },
	{ path: endpointsFrontend.CUSTOMER_AGREEMENT_NEW, component: CustomerAgreementEdit },
	{ path: endpointsFrontend.CUSTOMER_AGREEMENT_EDIT, component: CustomerAgreementEdit },

	{ path: endpointsFrontend.CUSTOMER_INVOICE_BROWSE_ALL, component: CustomerInvoiceBrowse },
	{ path: endpointsFrontend.CUSTOMER_INVOICE_BROWSE_UNPAID, component: CustomerInvoiceBrowse },
	{ path: endpointsFrontend.CUSTOMER_INVOICE_BROWSE_OVERDUE, component: CustomerInvoiceBrowse },
	{ path: endpointsFrontend.CUSTOMER_INVOICE_NEW, component: CustomerInvoiceEdit },
	{ path: endpointsFrontend.CUSTOMER_INVOICE_EDIT, component: CustomerInvoiceEdit },

	{ path: endpointsFrontend.CUSTOMER_INVOICE_LINE_NEW, component: CustomerInvoiceLineEdit },
	{ path: endpointsFrontend.CUSTOMER_INVOICE_LINE_EDIT, component: CustomerInvoiceLineEdit },

	{ path: endpointsFrontend.CUSTOMER_QUOTE_BROWSE, component: CustomerQuoteBrowse },
	{ path: endpointsFrontend.CUSTOMER_QUOTE_NEW, component: CustomerQuoteInvoiceEdit },
	{ path: endpointsFrontend.CUSTOMER_QUOTE_EDIT, component: CustomerQuoteInvoiceEdit },
	
	{ path: endpointsFrontend.CUSTOMER_QUOTE_LINE_NEW, component: CustomerQuoteLineEdit },
	{ path: endpointsFrontend.CUSTOMER_QUOTE_LINE_EDIT, component: CustomerQuoteLineEdit },

	{ path: endpointsFrontend.CM20PAYMENT_BROWSE_UNSIGNED, component: CM20PaymentsBrowse },
	{ path: endpointsFrontend.CM20PAYMENT_BROWSE_ERRONEOUS, component: CM20PaymentsBrowse },
	{ path: endpointsFrontend.CM20PAYMENT_BROWSE_SIGNED, component: CM20PaymentsBrowse },
	{ path: endpointsFrontend.CM20PAYMENT_SIGN_SUCCESS, component: CM20PaymentsSignSuccess },

	{ path: endpointsFrontend.DASHBOARD, component: Dashboard },

	{ path: endpointsFrontend.DATA_ANALYSIS_CASH_FLOW, component: DataAnalysisCashFlow },

	{ path: endpointsFrontend.EXTERNAL_DATA_SYNC_REQUEST_BROWSE, component: ExternalDataSyncRequestBrowse },
	
	{ path: endpointsFrontend.PAYMENT_BROWSE_PREPARED, component: PaymentBulkBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_UNSIGNED, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_UNSIGNED, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_SIGNED, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_EXPORTED_TO_BANK_AS_FILE, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_PROCESSED, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_BROWSE_ERRONEOUS, component: PaymentFileBrowse },
	{ path: endpointsFrontend.PAYMENT_NEW, component: PaymentCreate },
	{ path: endpointsFrontend.PAYMENT_EDIT, component: PaymentEdit },
	{ path: endpointsFrontend.PAYMENT_SIGN_SUCCESS, component: PaymentSignSuccess },

	{ path: endpointsFrontend.PRODUCT_BROWSE, component: ProductBrowse },
	{ path: endpointsFrontend.PRODUCT_NEW, component: ProductEdit },
	{ path: endpointsFrontend.PRODUCT_EDIT, component: ProductEdit },

	{ path: endpointsFrontend.SCHEDULED_TASK_BROWSE, component: ScheduledTaskBrowse },

	{ path: endpointsFrontend.SUPPLIER_BROWSE, component: SupplierBrowse },
	{ path: endpointsFrontend.SUPPLIER_NEW, component: SupplierEdit },
	{ path: endpointsFrontend.SUPPLIER_EDIT, component: SupplierEdit },

	{ path: endpointsFrontend.SUPPLIER_BANK_ACCOUNT_BROWSE, component: SupplierBankAccountBrowse },
	{ path: endpointsFrontend.SUPPLIER_BANK_ACCOUNT_NEW, component: SupplierBankAccountEdit },
	{ path: endpointsFrontend.SUPPLIER_BANK_ACCOUNT_EDIT, component: SupplierBankAccountEdit },

	{ path: endpointsFrontend.SUPPLIER_INVOICE_BROWSE_ALL, component: SupplierInvoiceBrowse },
	{ path: endpointsFrontend.SUPPLIER_INVOICE_BROWSE_UNPAID, component: SupplierInvoiceBrowse },
	{ path: endpointsFrontend.SUPPLIER_INVOICE_NEW, component: SupplierInvoiceEdit },
	{ path: endpointsFrontend.SUPPLIER_INVOICE_EDIT, component: SupplierInvoiceEdit },

	{ path: endpointsFrontend.SUPPLIER_INVOICE_CANDIDATE_BROWSE, component: SupplierInvoiceCandidateBrowse },

	{ path: endpointsFrontend.TAG_BROWSE, component: TagBrowse },
	{ path: endpointsFrontend.TAG_NEW, component: TagEdit },
	{ path: endpointsFrontend.TAG_EDIT, component: TagEdit },

	{ path: endpointsFrontend.TASK_BROWSE, component: TaskBrowse },
	{ path: endpointsFrontend.TASK_NEW, component: TaskEdit },
	{ path: endpointsFrontend.TASK_EDIT, component: TaskEdit },

	{ path: endpointsFrontend.TENANT_BROWSE, component: TenantBrowse },
	{ path: endpointsFrontend.TENANT_NEW, component: TenantEdit },
	{ path: endpointsFrontend.TENANT_CREATE_ADMIN, component: TenantNewAdmin },
	{ path: endpointsFrontend.TENANT_EDIT, component: TenantEdit },

	{ path: endpointsFrontend.TIMELOG_BROWSE, component: TimelogBrowse },
	{ path: endpointsFrontend.TIMELOG_NEW, component: TimelogEdit },
	{ path: endpointsFrontend.TIMELOG_EDIT, component: TimelogEdit },

	{ path: endpointsFrontend.UNIT_BROWSE, component: UnitBrowse },
	{ path: endpointsFrontend.UNIT_NEW, component: UnitEdit },
	{ path: endpointsFrontend.UNIT_EDIT, component: UnitEdit },

	{ path: endpointsFrontend.USER_ACCOUNT_BROWSE, component: UserAccountBrowse },
	{ path: endpointsFrontend.USER_ACCOUNT_EDIT, component: UserAccountEdit },
	{ path: endpointsFrontend.USER_ACCOUNT_PROFILE_EDIT, component: ProfileEdit },
	{ path: endpointsFrontend.USER_ACCOUNT_INVITE, component: UserAccountInvite },

	{ path: endpointsFrontend.VAT_RATE_BROWSE, component: VATRateBrowse },
	{ path: endpointsFrontend.VAT_RATE_NEW, component: VATRateEdit },
	{ path: endpointsFrontend.VAT_RATE_EDIT, component: VATRateEdit },

	// //Crypto
	// { path: "/crypto-wallet", component: CryptoWallet },
	// { path: "/crypto-buy-sell", component: CryptoBuySell },
	// { path: "/crypto-exchange", component: CryptoExchange },
	// { path: "/crypto-lending", component: CryptoLending },
	// { path: "/crypto-orders", component: CryptoOrders },
	// { path: "/crypto-kyc-application", component: CryptoKYCApplication },

	//profile
	{ path: "/profile", component: UserProfile },

	//File Manager
	{ path: "/apps-filemanager", component: FileManager },

	//calendar
	{ path: "/calendar", component: Calendar },

	//Ecommerce
	// { path: "/ecommerce-products/:id", component: EcommerceProducts },
	{ path: "/ecommerce-products", component: EcommerceProducts },
	{ path: "/ecommerce-product-detail/:id", component: EcommerceProductDetail },

	{ path: "/ecommerce-cart", component: EcommerceCart },
	{ path: "/ecommerce-checkout", component: EcommerceCheckout },
	{ path: "/ecommerce-shops", component: EcommerceShops },
	{ path: "/ecommerce-add-product", component: EcommerceAddProduct },

	//Email
	{ path: "/email-inbox", component: EmailInbox },
	{ path: "/email-read", component: EmailRead },
	{ path: "/email-template-basic", component: EmailBasicTemplte },
	{ path: "/email-template-alert", component: EmailAlertTemplte },
	{ path: "/email-template-billing", component: EmailTemplateBilling },

	//Invoices
	{ path: "/invoices-list", component: InvoicesList },
	{ path: "/invoices-detail", component: InvoiceDetail },
	{ path: "/invoices-detail/:id", component: InvoiceDetail },

	// Tasks
	{ path: "/tasks-list", component: TasksList },
	{ path: "/tasks-kanban", component: TasksKanban },
	{ path: "/tasks-create", component: TasksCreate },

	//Projects
	{ path: "/projects-grid", component: ProjectsGrid },
	{ path: "/projects-list", component: ProjectsList },
	{ path: "/projects-overview", component: ProjectsOverview },
	{ path: "/projects-overview/:id", component: ProjectsOverview },
	{ path: "/projects-create", component: ProjectsCreate },

	// Contacts
	{ path: "/contacts-grid", component: ContactsGrid },

	//Charts
	{ path: "/apex-charts", component: ChartApex },
	{ path: "/chartjs-charts", component: ChartjsChart },
	{ path: "/sparkline-charts", component: SparklineChart },
	{ path: "/charts-knob", component: ChartsKnob },
	{ path: "/re-charts", component: ReCharts },

	// Icons
	{ path: "/icons-boxicons", component: IconBoxicons },
	{ path: "/icons-dripicons", component: IconDripicons },
	{ path: "/icons-materialdesign", component: IconMaterialdesign },
	{ path: "/icons-fontawesome", component: IconFontawesome },

	// Tables
	{ path: "/tables-basic", component: BasicTables },
	{ path: "/tables-responsive", component: ResponsiveTables },
	{ path: "/tables-editable", component: EditableTables },
	{ path: "/tables-dragndrop", component: DragDropTables },
	{ path: "/dual-listbox", component: DualListbox },

	// Maps
	{ path: "/maps-google", component: MapsGoogle },
	{ path: "/maps-vector", component: MapsVector },
	{ path: "/maps-leaflet", component: MapsLeaflet },

	// Forms
	{ path: "/form-elements", component: FormElements },
	{ path: "/form-layouts", component: FormLayouts },
	{ path: "/form-advanced", component: FormAdvanced },
	{ path: "/form-editors", component: FormEditors },
	{ path: "/form-mask", component: FormMask },
	{ path: "/form-repeater", component: FormRepeater },
	{ path: "/form-uploads", component: FormUpload },
	{ path: "/form-wizard", component: FormWizard },
	{ path: "/form-validation", component: FormValidations },
	{ path: "/form-xeditable", component: FormXeditable },

	//Utility
	{ path: "/pages-starter", component: PagesStarter },
	{ path: "/pages-timeline", component: PagesTimeline },
	{ path: "/pages-faqs", component: PagesFaqs },
	{ path: "/pages-pricing", component: PagesPricing },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to={endpointsFrontend.START_PAGE_REDIRECT} /> },
]

const publicRoutes = [
	{ path: endpointsFrontend.USER_ACCOUNT_LOGIN, component: Login },
	{ path: endpointsFrontend.USER_ACCOUNT_LOGOUT, component: Logout },
	{ path: endpointsFrontend.USER_ACCOUNT_REGISTER, component: Register },
	{ path: endpointsFrontend.USER_ACCOUNT_REGISTER_FROM_INVITATION, component: RegisterFromInvitation },
	{ path: endpointsFrontend.USER_ACCOUNT_REGISTER_SUCCESS, component: RegisterSuccess },
	{ path: endpointsFrontend.USER_ACCOUNT_REGISTER_SUCCESS_ACTIVATION_PENDING, component: RegisterSuccessActivationPending },
	{ path: endpointsFrontend.USER_PASSWORD_RESET_REQUEST, component: PasswordResetRequest },
	{ path: endpointsFrontend.USER_PASSWORD_RESET, component: PasswordReset },
	{ path: endpointsFrontend.USER_ACCOUNT_ACTIVATE, component: ProfileActivation },

	{ path: "/pages-maintenance", component: PagesMaintenance },
	{ path: "/pages-comingsoon", component: PagesComingsoon },
	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },
	{ path: "/crypto-ico-landing", component: CryptoIcoLanding },

	{ path: "/auth-lock-screen", component: LockScreen },
	{ path: "/auth-lock-screen-2", component: LockScreen2 },
	{ path: "/page-confirm-mail", component: ConfirmMail },
	{ path: "/page-confirm-mail-2", component: ConfirmMail2 },
	{ path: "/auth-email-verification", component: EmailVerification },
	{ path: "/auth-email-verification-2", component: EmailVerification2 },
	{ path: "/auth-two-step-verification", component: TwostepVerification },
	{ path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { authProtectedRoutes, publicRoutes }
