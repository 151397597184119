import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as actions from "../../store/UserRegister/actions";
import * as editFormControls from '../../helpers/editFormControls';
import profile from "../../assets/images/cm-logo-transparent.png";

class RegisterFromInvitation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formErrors: null
		};

		this.handleValidSubmit = this.handleValidSubmit.bind(this);
	}

	handleValidSubmit(event, values) {
		if (values.password !== values.password_confirm) {
			this.setState({
				formErrors: { message: "Passwords don't match" }
			});
		} else {
			this.setState({
				formErrors: null
			});
			if (this.props.match.params.token && this.props.match.params.token) {
				this.props.onUserRegisterFromInvitation(this.props.match.params.token, this.props.match.params.email, values.email, values.password, this.props.history);
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-soft px-5 py-3">	{/* change class from bg-primary bg-soft to landing-footer to obtain the dark background */}
										<img src={profile} alt="" className="img-fluid" />
									</div>
									<CardBody className="pt-0">
										<div className="p-2">
											{editFormControls.errorAlert(this.props.registerError, this.state.formErrors)}

											<AvForm
												className="form-horizontal"
												onValidSubmit={this.handleValidSubmit}
											>
												<br />
												<div className="mb-3">
													<AvField
														name="email"
														label="Email"
														className="form-control"
														value={this.props.match.params.email}
														placeholder="Enter email"
														type="email"
														required
													/>
												</div>

												<br />
												<div className="mb-3">
													<AvField
														name="password"
														label="Password"
														className="form-control"
														placeholder="Enter password"
														type="password"
														required
													/>
													<br />
													<AvField
														name="password_confirm"
														label="Confirm password"
														className="form-control"
														placeholder="Confirm password"
														type="password"
														required
													/>
												</div>
												{/*
												<br />
												<p className="mb-0">
													By registering you agree to the CashManager{" "}
													<Link to="#" className="text-primary">
														Terms of Use
													</Link>
													.
												</p>
												*/}
												<br />
												<div className="text-end">
													<button
														className="btn btn-primary w-md"
														type="submit"
													>
														Register
														{" "}
														{this.props.registering ? editFormControls.buttonSpinner() : ""}
													</button>
												</div>
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Already registered?{" "}
										<Link
											to={endpointsFrontend.USER_ACCOUNT_LOGIN}
											className="fw-medium text-primary"
										>
											Login
										</Link>
									</p>
									<p>
										Copyright © {new Date().getFullYear()} D.Vision SIA
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ userRegister }) => {
	return {
		registerError: userRegister.registerError,
		registering: userRegister.registering,
		registerSuccessMessage: userRegister.registerSuccessMessage
	};
};

const mapDispatchToProps = dispatch => ({
	onUserRegisterFromInvitation: (token, inviteeEmail, email, password, history) => dispatch(actions.userRegisterFromInvitation(token, inviteeEmail, email, password, history))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(RegisterFromInvitation)
);