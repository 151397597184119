import * as actionTypes from "./actionTypes";

export const dashboardDataGetAll = () => {
	return {
		type: actionTypes.DASHBOARD_DATA_GET_ALL
	}
}

export const dashboardDataGetError = (thisError) => {
	return {
		type: actionTypes.DASHBOARD_DATA_GET_ERROR,
		error: thisError
	}
}

export const dashboardDataGetSuccess = (data) => {
	return {
		type: actionTypes.DASHBOARD_DATA_GET_SUCCESS,
		dashboardData: data
	}
}

