export const TIMELOG_GET_ALL = "TIMELOG_GET_ALL";
export const TIMELOG_GET_BY_ID = "TIMELOG_GET_BY_ID";
export const TIMELOG_GET_BY_TASK = "TIMELOG_GET_BY_TASK";
export const TIMELOG_GET_ERROR = "TIMELOG_GET_ERROR";
export const TIMELOG_GET_SUCCESS = "TIMELOG_GET_SUCCESS";

export const TIMELOG_CREATE = "TIMELOG_CREATE";
export const TIMELOG_CREATE_ERROR = "TIMELOG_CREATE_ERROR";
export const TIMELOG_CREATE_SUCCESS = "TIMELOG_CREATE_SUCCESS";

export const TIMELOG_DELETE = "TIMELOG_DELETE";
export const TIMELOG_DELETE_ERROR = "TIMELOG_DELETE_ERROR";
export const TIMELOG_DELETE_SUCCESS = "TIMELOG_DELETE_SUCCESS";

