export const PAYMENT_BULK_CREATE_FILES = "PAYMENT_BULK_CREATE_FILES";
export const PAYMENT_BULK_CREATE_FILES_ERROR = "PAYMENT_BULK_CREATE_FILES_ERROR";
export const PAYMENT_BULK_CREATE_FILES_SUCCESS = "PAYMENT_BULK_CREATE_FILES_SUCCESS";

export const PAYMENT_BULK_CREATE_FROM_INVOICES = "PAYMENT_BULK_CREATE_FROM_INVOICES";

export const PAYMENT_BULK_CREATE_ERROR = "PAYMENT_BULK_CREATE_ERROR";
export const PAYMENT_BULK_CREATE_PARTIAL_SUCCESS = "PAYMENT_BULK_CREATE_PARTIAL_SUCCESS";
export const PAYMENT_BULK_CREATE_SUCCESS = "PAYMENT_BULK_CREATE_SUCCESS";

export const PAYMENT_BULK_DELETE = "PAYMENT_BULK_DELETE";
export const PAYMENT_BULK_DELETE_LIST = "PAYMENT_BULK_DELETE_LIST";
export const PAYMENT_BULK_DELETE_ERROR = "PAYMENT_BULK_DELETE_ERROR";
export const PAYMENT_BULK_DELETE_SUCCESS = "PAYMENT_BULK_DELETE_SUCCESS";

export const PAYMENT_BULK_EDIT = "PAYMENT_BULK_EDIT";
export const PAYMENT_BULK_EDIT_ERROR = "PAYMENT_BULK_EDIT_ERROR";
export const PAYMENT_BULK_EDIT_SUCCESS = "PAYMENT_BULK_EDIT_SUCCESS";

export const PAYMENT_BULK_GET_BY_ID = "PAYMENT_BULK_GET_BY_ID";
export const PAYMENT_BULK_GET_ERROR = "PAYMENT_BULK_GET_ERROR";
export const PAYMENT_BULK_GET_PREPARED = "PAYMENT_BULK_GET_PREPARED";
export const PAYMENT_BULK_GET_SUCCESS = "PAYMENT_BULK_GET_SUCCESS";
export const PAYMENT_BULK_GET_UNALLOCATED = "PAYMENT_BULK_GET_UNALLOCATED";

export const PAYMENT_BULK_GET_TOTAL = "PAYMENT_BULK_GET_TOTAL";
export const PAYMENT_BULK_GET_TOTAL_ERROR = "PAYMENT_BULK_GET_TOTAL_ERROR";
export const PAYMENT_BULK_GET_TOTAL_SUCCESS = "PAYMENT_BULK_GET_TOTAL_SUCCESS";

export const PAYMENT_BULK_MESSAGE_TO_USER_RESET = "PAYMENT_BULK_MESSAGE_TO_USER_RESET";