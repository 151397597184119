import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";

function* getDashboardDataSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.DASHBOARD_DATA_GET
		);

		yield put(actions.dashboardDataGetSuccess(response.data));
	} catch (e) {
		yield put(actions.dashboardDataGetError(e));
	}
}

function* dashboardDataSaga() {
	yield (takeLatest(actionTypes.DASHBOARD_DATA_GET_ALL, getDashboardDataSaga));
}

export default dashboardDataSaga;