import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Row, Col, Card, CardBody } from "reactstrap";

class CashFlowChart extends Component {
	constructor(props) {
		super(props)
		this.state = {
			options: {
				chart: {
					id: "cashFlowChart",
					// toolbar: "false",	// Uncommenting this leads to the "'c' is undefined" error
					stacked: true
				},
				dataLabels: {
					enabled: !1,
				},
				colors: ["#616187", "#f19e9e", "#a7e9c8"],
				stroke: {
					curve: "smooth",
					width: 3,
				},
				xaxis: {
					categories: []
				}
			},
			series: []
		}
	}

	updateChartFromProps = () => {
		const cashFlowData = [];
		let cashFlowValue = 0;
		for (let i in this.props.supplierInvoices) {
			if (i < this.props.customerInvoices.length) {
				cashFlowValue += this.props.supplierInvoices[i] + this.props.customerInvoices[i];
			} else {
				cashFlowValue += this.props.supplierInvoices[i];
			}
			cashFlowData.push(Math.round(cashFlowValue * 100) / 100);
		}
		for (let i = this.props.supplierInvoices.length; i < this.props.customerInvoices.length; ++i) {
			cashFlowValue += this.props.customerInvoices[i];
			cashFlowData.push(Math.round(cashFlowValue * 100) / 100);
		}
		this.setState({
			options: {
				xaxis: {
					id: "cashFlowChart",
					toolbar: "false",
					stacked: true,
					categories: this.props.categories
				}
			},
			series: [
				{
					name: "Cash flow",
					type: "line",
					data: cashFlowData,
				},
				{
					name: "Supplier invoices",
					type: "bar",
					data: this.props.supplierInvoices,
				},
				{
					name: "Customer invoices",
					type: "bar",
					data: this.props.customerInvoices,
				},
			]
		})
	}

	componentDidMount() {
		this.updateChartFromProps();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.customerInvoices !== this.props.customerInvoices && this.props.customerInvoices &&
			prevProps.supplierInvoices !== this.props.supplierInvoices && this.props.supplierInvoices
		) {
			this.updateChartFromProps();
		}
	}

	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<h4 className="card-title mb-4">Cash flow</h4>
						{/* <Row>
							<Col lg="3">
								<div className="text-muted">
									<div className="mb-4">
										<p>This month</p>
										<h4>$2453.35</h4>
										<div>
											<span className="badge badge-soft-success font-size-12 me-1">
												{" "}
												+ 0.2%{" "}
											</span>{" "}
											From previous period
										</div>
									</div>


									<div className="mt-4">
										<p className="mb-2">Last month</p>
										<h5>$2281.04</h5>
									</div>
								</div>
							</Col>

							<Col> */}
								<div id="line-chart" className="apex-charts" dir="ltr">
									<Chart
										series={this.state.series}
										options={this.state.options}
										type="bar"
										height={320}
									// className="apex-charts"
									/>
								</div>
							{/* </Col>
						</Row> */}
					</CardBody>
				</Card>
			</React.Fragment>
		)
	}
}

export default CashFlowChart;