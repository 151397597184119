import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/CustomerQuote/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsCustomerQuote from "../../definitions/columns/customerQuote";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class CustomerQuoteBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			selectedRows: []
		}
	}

	componentDidMount() {
		this.props.onGetCustomerQuotes();
	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	onDeleteQuotes = () => {
		if (window.confirm("Are you sure you want to delete the selected quotes?")) {
			this.props.onDeleteQuotes(this.state.selectedRows, this.props.history);
		}
	}

	render() {

		const pageTitle = "Customer Quotes | " + config.AppName;
		const breadcrumbsTitle = "Customer quote";
		const breadcrumbsItem = "Browse customer quotes";

		const columns = [
			columnsCustomerQuote.quoteNo,
			columnsCustomerQuote.customerName,
			columnsCustomerQuote.amountWithoutVAT,
			columnsCustomerQuote.currencyCode,
			columnsCustomerQuote.quoteDate
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.CUSTOMER_QUOTE_EDIT.replace(":id", row.id));
			}
		};

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
				// this.props.onGetPaymentTotal(selectedRows);
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
				// this.props.onGetPaymentTotal(selectedRows);
			}
		};

		const table =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.customerQuotes}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					selectRow={selectRow}
					defaultSorted={[{
						dataField: 'quoteDate',
						order: 'desc'
					},
					{
						dataField: 'quoteNo',
						order: 'desc'
					}]}
				/>
			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{/* <Button color="primary">Add new quote</Button> */}
										{" "}
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.customerQuotes ?
											table
											: null}

										<br />

										{editFormControls.deleteButton(this.props.deleting, this.onDeleteQuotes, true, this.state.selectedRows.length === 0)}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ customerQuote }) => ({
	customerQuotes: customerQuote.customerQuotes,
	loading: customerQuote.loading,
	deleting: customerQuote.deleting,
	error: customerQuote.error
})

const mapDispatchToProps = dispatch => ({
	onGetCustomerQuotes: () => dispatch(actions.customerQuoteGetAll()),
	onDeleteQuotes: (selectedRows) => dispatch(actions.customerQuoteDeleteList(selectedRows))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CustomerQuoteBrowse));
