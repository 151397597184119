import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllCurrenciesSaga() {
	yield put(actions.currencyGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CURRENCY_GET_ALL
		);

		const currencies = [];
		for (let key in response.data) {
			currencies.push({
				...response.data[key]
				//id: key
			});
		}
		yield put(actions.currencyGetSuccess(currencies));
	} catch (e) {
		yield put(actions.currencyGetError(e));
	}
}

function* getCurrencyByIdSaga({ id }) {
	yield put(actions.currencyGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CURRENCY_GET_BY_ID.replace("{currencyId}", id)
		);

		yield put(actions.currencyGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.currencyGetError(e));
	}
}

function* createCurrencySaga({ currency, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.CURRENCY_CREATE_OR_UPDATE,
			currency
		);
		history.push(endpointsFrontend.CURRENCY_BROWSE);
	} catch (e) {
		// console.log("createCurrencySaga failed")
		// console.log(e);
		yield put(actions.currencyCreateError(e));
	}
}

function* deleteCurrencySaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.CURRENCY_GET_BY_ID.replace("{currencyId}", id)
		);
		history.push(endpointsFrontend.CURRENCY_BROWSE);
	} catch (e) {
		// console.log("createCurrencySaga failed")
		// console.log(e);
		yield put(actions.currencyCreateError(e));
	}
}

function* currencySaga() {
	yield (takeEvery(actionTypes.CURRENCY_GET_ALL, getAllCurrenciesSaga));
	yield (takeEvery(actionTypes.CURRENCY_GET_BY_ID, getCurrencyByIdSaga));
	yield (takeEvery(actionTypes.CURRENCY_CREATE, createCurrencySaga));
	yield (takeEvery(actionTypes.CURRENCY_DELETE, deleteCurrencySaga));
}

export default currencySaga;