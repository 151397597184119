import * as actionTypes from "./actionTypes";

const initialState = {
	cashFlowError: null,
	cashFlowLoading: false,
	cashFlow: null,
}

export default function dataAnalysisReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CASH_FLOW_GET_ALL:
			return {
				...state,
				cashFlowError: null,
				cashFlowLoading: true
			}
		case actionTypes.CASH_FLOW_GET_ERROR:
			return {
				...state,
				cashFlowError: action.error,
				cashFlowLoading: false
			}
		case actionTypes.CASH_FLOW_GET_SUCCESS:
			return {
				...state,
				cashFlowError: null,
				cashFlowLoading: false,
				cashFlow: action.cashFlow
			}
		default:
			return state;
	}
}