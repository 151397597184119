import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsCompany from '../../store/Company/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class CompanyEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            name: "",
            registrationNo: "",
            company: [],
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue = e.target.value;
        if (thisObjectName === "exchangeRate") {
            //illegalCharacters = thisValue.replaceAll('[0-1]')
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing
        let newOrUpdatedCompany = {
            name: this.state.name,
            registrationNo: this.state.registrationNo,
        };
        if (this.state.id) {
            newOrUpdatedCompany = {
                id: this.state.id,
                ...newOrUpdatedCompany
            }
        }
        this.props.onCreateCompany(newOrUpdatedCompany, this.props.history);
    }

    onDeleteCompany = () => {
        if (window.confirm("Are you sure you want to delete this company?\nAll company data will be irreversibly deleted!")) {
            this.props.onDeleteCompany(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({
                id: id
            });
            this.props.onGetCompanyById(id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    errors: this.props.error
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.company !== this.props.company) {
            if (this.props.company) {
                this.setState({
                    name: this.props.company.name,
                    registrationNo: this.props.company.registrationNo,
                });
            } else {
                this.setState({
                    name: "",
                    registrationNo: "",
                });
            }
        }

    }

    render() {
        const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " company | " + config.AppName;
        const breadcrumbsTitle = "Company";
        const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " company";

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.textControl("name", "Name", this.onChange, this.state.name)}
                                        {editFormControls.textControl("registrationNo", "Registration No.", this.onChange, this.state.registrationNo)}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteCompany, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.formLoadingSpinner(this.props.loading)}

                        {!this.props.loading ? editForm : null}

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ company }) => ({
    company: company.company,
    deleting: company.deleting,
    error: company.error,
    loading: company.loadingSingle,
    saving: company.saving
})

const mapDispatchToProps = dispatch => ({
    onGetCompanyById: (id) => dispatch(actionsCompany.companyGetById(id)),
    onCreateCompany: (company, history) => dispatch(actionsCompany.companyCreate(company, history)),
    onDeleteCompany: (id, history) => dispatch(actionsCompany.companyDelete(id, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanyEdit);

