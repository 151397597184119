import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Row } from "reactstrap";

import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import * as actionsExternalDataSync from '../../store/ExternalDataSync/actions';
import * as actionsReport from '../../store/Report/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsExternalDataSyncRequest from 'definitions/columns/externalDataSyncRequest';
import * as config from '../../config';
import * as dateAndTimeUtils from "helpers/dateAndTimeUtils";
import * as editFormControls from '../../helpers/editFormControls';
import OverlayWithSpinner from "components/Common/OverlayWithSpinner";

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

class ExternalDataSyncRequestBrowse extends Component {

	constructor(props) {
		super(props);
		const currentDateTime = dateAndTimeUtils.convertToTimezone(new Date(), "Europe/Riga");
		this.state = {
			requestedSyncTimeType: 0,
			requestedSyncDate: currentDateTime.date,
			requestedSyncTime: currentDateTime.time.substring(0, 5),
			notifyCreatedUserByEmail: false,
			reportDropdownOpen: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.refreshTimer = null;
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		const externalDataSyncRequest = {
			requestedSyncDateTime: this.state.requestedSyncTimeType == 0 ? null : this.state.requestedSyncDate + " " + this.state.requestedSyncTime,
			syncType: "IMPORT_TO_CRM",
			notifyCreatedUserByEmail: this.state.notifyCreatedUserByEmail
		}
		this.props.onCreateExternalDataSyncRequest(externalDataSyncRequest);
	}

	componentDidMount() {
		this.props.onGetExternalDataSyncRequests();
		if (!this.props.localTimezone) {
			this.props.onGetCompanySettings();
		}
		this.refreshTimer = setInterval(() => {
			this.props.onRefreshExternalDataSyncRequests();
		}, 15000);
	}

	componentWillUnmount() {
		clearInterval(this.refreshTimer);
		this.refreshTimer = null;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.localTimezone !== this.props.localTimezone && this.props.localTimezone) {

			const currentDateTime = dateAndTimeUtils.convertToTimezone(new Date(), this.props.localTimezone);
			this.setState({
				requestedSyncDate: currentDateTime.date,
				requestedSyncTime: currentDateTime.time.substring(0, 5)
			});
		}
	}

	onCancelRequest = (id) => {
		if (window.confirm("Are you sure you want to cancel this data sync request?")) {
			this.props.onCancelExternalDataSyncRequest(id);
		}
	}

	commandButtons = (cell, row, rowIndex, formatExtraData) => {
		return (
			<React.Fragment>
				{row.status === "PREPARED" &&
					<div style={{
						width: "1.3rem",
						height: "1.3rem"
					}}>
						<span className="avatar-title rounded-circle bg-secondary font-size-18">	{/* Can add bg-soft to the classes to make the colors make mellower */}
							<i
								className="bx bx-x"
								onClick={() => { this.onCancelRequest(row.id); }}
							/>
						</span>
					</div>
				}
			</React.Fragment>
		);
	};

	onCustomerInvoicesControlSumReport = () => {
		this.props.onDownloadCustomerInvoiceControlSumReport();
	}

	onSupplierInvoicesControlSumReport = () => {
		this.props.onDownloadSupplierInvoiceControlSumReport();
	}

	render() {

		const pageTitle = "External data sync | " + config.AppName;
		const breadcrumbsTitle = "External data sync";
		const breadcrumbsItem = "Status";

		const columns = [
			columnsExternalDataSyncRequest.startDateTime,
			columnsExternalDataSyncRequest.finishDateTime,
			columnsExternalDataSyncRequest.syncType,
			columnsExternalDataSyncRequest.status,
			columnsExternalDataSyncRequest.createdByUserFullName,
			columnsExternalDataSyncRequest.createdDateTime,
			{
				dataField: "commandButtons",
				text: "",
				formatter: (cell, row, rowIndex) => this.commandButtons(cell, row, rowIndex, {})
			}
		];

		const expandRow = {
			renderer: (row, rowIndex) => {

				let exportFiles = null;
				if (row.syncType === "EXPORT_FROM_CRM" && row.exportFiles) {
					exportFiles = row.exportFiles.map((exportFile, key) =>
						<div key={key}>
							<a href="#" onClick={() => this.props.onGetFile(exportFile.id)}>
								{exportFile.filename}
							</a>
						</div>)
				}

				return (
					<div className="react-bootstrap-table">
						<table className="table">
							<tbody style={{ borderColor: "#FFFFFF" }}>
								<tr>
									<td style={{ border: "0px transparent" }}></td>
									<td style={{ border: "0px transparent" }}>
										{/* {editFormControls.staticTextControl("lastExecutionStartDateTime", "Last execution start", row.lastExecutionStartDateTime)} */}
										{/* {row.lastExecutionStatus === "COMPLETED" && editFormControls.staticTextControl("lastExecutionFinishDateTime", "Last execution finish", row.lastExecutionFinishDateTime)} */}
										{row.status === "FAILED" && editFormControls.staticTextAreaControl("errorMessage", "Error message", row.errorMessage)}
										{exportFiles}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				)
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded, rowKey, expandable }) => (
				expanded ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => (
				isAnyExpands ? <i className="bx bxs-minus-square" /> : <i className="bx bxs-plus-square" />
			)
		};

		const tableAndScheduleControls =
			<div>
				<Row>
					<Col>
						<Input
							name="requestedSyncTimeType"
							type="radio"
							onChange={this.onChange}
							checked={this.state.requestedSyncTimeType == 0}
							value={0}
						/>
						&nbsp;&nbsp;
						<Label>Now</Label>
					</Col>
					<Col>
						<Input
							name="requestedSyncTimeType"
							type="radio"
							onChange={this.onChange}
							value={1}
						/>
						&nbsp;&nbsp;
						<Label>On specific date and time:</Label>
						<InputGroup>
							<Input
								id="requestedSyncDate"
								name="requestedSyncDate"
								type="date"
								className={"form-control"}
								onChange={this.onChange}
								value={this.state.requestedSyncDate}
								disabled={this.state.requestedSyncTimeType == 0}
							/>
							<Input
								id="requestedSyncTime"
								name="requestedSyncTime"
								type="time"
								className={"form-control"}
								onChange={this.onChange}
								value={this.state.requestedSyncTime}
								disabled={this.state.requestedSyncTimeType == 0}
							/>
						</InputGroup>
					</Col>
					<Col>
						<Label style={{ float: "right" }} >
							Email me when finished
						</Label>
						<span style={{ float: "right" }} >
							&nbsp;
						</span>
						<Input
							id="notifyCreatedUserByEmail"
							name="notifyCreatedUserByEmail"
							type="checkbox"
							onChange={this.onChange}
							value={this.state.notifyCreatedUserByEmail ? this.state.notifyCreatedUserByEmail : ""}
							checked={this.state.notifyCreatedUserByEmail ? true : false}
							style={{ float: "right" }}
						/>
					</Col>
					<Col>
						<Button
							color="primary"
							style={{
								height: "100%",
								float: "right"
							}}
							onClick={this.onSubmit}
						>
							Prepare
						</Button>
					</Col>
				</Row>
				<hr />
				<div className="mt-3">
					<OverlayWithSpinner
						show={this.props.updating}
					/>
					<BootstrapTable
						keyField='id'
						data={this.props.externalDataSyncRequests}
						columns={columns}
						pagination={paginationFactory()}
						rowStyle={{ cursor: "pointer" }}
						filter={filterFactory()}
						expandRow={expandRow}
						defaultSorted={[
							{ dataField: 'createdDateTime', order: 'desc' },
							{ dataField: 'requestedSyncDateTime', order: 'desc' }
						]}
					/* The data is also sorted on the backend, in case we decide to remove the createdDateTime column from the frontend
					(in that case default sorting on frontend will not work). */
					/>
				</div>
			</div>

		const reportDropdown =
			<Dropdown
				isOpen={this.state.reportDropdownOpen}
				toggle={() => this.setState({
					reportDropdownOpen: !this.state.reportDropdownOpen
				})}
			>
				<DropdownToggle
					color="secondary"
					disabled={this.props.downloading}
					caret
				>
					Report...
					{" "}
					{this.props.downloading ? editFormControls.buttonSpinner() : null}
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={this.onCustomerInvoicesControlSumReport}>Customer invoices control sum</DropdownItem>
					<DropdownItem onClick={this.onSupplierInvoicesControlSumReport}>Supplier invoices control sum</DropdownItem>
				</DropdownMenu>
			</Dropdown>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.externalDataSyncRequests ?
											tableAndScheduleControls
											: null}
										{!this.props.loading && this.props.externalDataSyncRequests ? reportDropdown : null}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ companySettings, externalDataSync, report }) => ({
	externalDataSyncRequests: externalDataSync.externalDataSyncRequests,
	downloading: report.downloading,
	loading: externalDataSync.loading,
	localTimezone: companySettings.companySettings ? companySettings.companySettings.localTimezone : null,
	error: externalDataSync.error,
	updating: externalDataSync.updating
})

const mapDispatchToProps = dispatch => ({
	onCancelExternalDataSyncRequest: (id) => dispatch(actionsExternalDataSync.externalDataSyncRequestDelete(id)),
	onCreateExternalDataSyncRequest: (request) => dispatch(actionsExternalDataSync.externalDataSyncRequestCreate(request)),
	onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
	onGetFile: (id) => dispatch(actionsExternalDataSync.externalDataExportFileGet(id)),
	onGetExternalDataSyncRequests: () => dispatch(actionsExternalDataSync.externalDataSyncRequestGetAll()),
	onDownloadCustomerInvoiceControlSumReport: () => dispatch(actionsReport.reportOfCustomerInvoiceControlSumDownload()),
	onDownloadSupplierInvoiceControlSumReport: () => dispatch(actionsReport.reportOfSupplierInvoiceControlSumDownload()),
	onRefreshExternalDataSyncRequests: () => dispatch(actionsExternalDataSync.externalDataSyncRequestGetAllWithoutSpinner()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ExternalDataSyncRequestBrowse));
