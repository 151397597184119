import React, { Component } from 'react';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Label, Input, Row, Spinner } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from "react-redux";

import * as actions from '../../store/CustomerInvoice/actions';
import * as actionsAllocation from '../../store/Allocation/actions';
import Backdrop from '../Common/Backdrop';
import * as browseFormControls from '../../helpers/browseFormControls';
import * as columnsCustomerInvoice from "definitions/columns/customerInvoice";
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as formatUtils from '../../helpers/formatUtils';
import * as selectRowUtils from '../../helpers/selectRowUtils';
import * as tableUtils from '../../helpers/tableUtils';

import classes from './Pages.module.css';

const virtualPathname = "components\Pages\SelectCustomerInvoices";

class SelectCustomerInvoices extends Component {

    constructor(props) {
        super(props);
        this.filterByCustomerName = this.filterByCustomerName.bind(this);
        this.filterByCustomerRegNo = this.filterByCustomerRegNo.bind(this);
        this.filterByAmount = this.filterByAmount.bind(this);
        this.state = {
            selectedRows: [],
            totalAmount: null,
            columns: null,
            showColumnSelectionForm: false,
        };
    }

    componentDidMount() {
        this.props.onGetUnallocatedCustomerInvoices();
        const defaultColumnSetup = "";
        this.setState({
            columns: tableUtils.getSavedColumnSetup(columnsCustomerInvoice, virtualPathname, defaultColumnSetup)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.totals !== this.props.totals) {
            if (this.props.totals && this.props.totals.amount) {
                this.setState({
                    totalAmount: this.props.totals.amount
                });
            } else {
                this.setState({
                    totalAmount: null
                });
            }
        }
    }

    launchColumnSelectionForm = () => {
        this.setState({
            showColumnSelectionForm: true
        });
    }

    applySelectedColumns = (selectedColumns) => {
        tableUtils.setSavedColumnSetup(selectedColumns, virtualPathname);
        this.setState({
            columns: tableUtils.applySavedColumnSetup(columnsCustomerInvoice, selectedColumns)
        });
    }

    filterByCustomerName(value) {
        this.customerNameFilter(value);
    }

    filterByCustomerRegNo(value) {
        this.customerRegNoFilter(value);
    }

    filterByAmount(value) {
        this.amountFilter(value);
    }

    onCreateAllocation = () => {
        const customerInvoices = [];
        this.state.selectedRows.map(selectedRow => {
            customerInvoices.push({
                id: selectedRow
            });
        });

        // 2024.07.26: fix: 240702-TwoCreditNotes // Sorting customerInvoices in the ascending order of unallocatedAmount
        customerInvoices.sort((id1, id2) => {
            const invoice1 = this.props.customerInvoices.filter(invoice => invoice.id === id1.id)[0];
            const invoice2 = this.props.customerInvoices.filter(invoice => invoice.id === id2.id)[0];
            return (invoice1.unallocatedAmount > invoice2.unallocatedAmount
                ? 1
                : invoice1.unallocatedAmount < invoice2.unallocatedAmount
                    ? -1
                    : 0);
        });
        // end 2024.07.26: fix: 240702-TwoCreditNotes // Sorting customerInvoices in the ascending order of unallocatedAmount

        const allocation = {
            bankStatementRecordId: this.props.allocationRecord.id,
            customerInvoices: customerInvoices
        }
        this.props.onCreateAllocation(allocation);
        this.props.onClose();
    }

    onCalculateTotals = (selectedRows) => {
        if (!this.props.allocationRecord) {
            return;
        }
        const filteredInvoices = this.props.customerInvoices.filter(customerInvoice => selectedRows.includes(customerInvoice.id));
        let totalAmount = 0;
        let hasDifferentCurrencies = false;
        filteredInvoices.map(invoice => {
            if (invoice.currencyCode === this.props.allocationRecord.currencyCode) {
                totalAmount += invoice.unallocatedAmount;
            } else {
                hasDifferentCurrencies = true;
            }
        });
        if (hasDifferentCurrencies) {
            this.setState({
                totalAmount: null
            });
            /*this.props.onGetTotals(selectedRows, this.props.allocationRecord.currencyCode, this.props.allocationRecord.paymentDate);*/
        } else {
            this.setState({
                totalAmount: totalAmount
            });
        }
    }

    render() {

        const loading = this.props.loading;

        const ColumnSelectionFormWithProps =
            <ColumnSelectionForm
                availableColumns={[
                    columnsCustomerInvoice.amountWithVAT,
                    columnsCustomerInvoice.invoiceNoAlt,
                    columnsCustomerInvoice.dimension1,
                    columnsCustomerInvoice.dimension2,
                    columnsCustomerInvoice.dimension3
                ]}
                selectedColumns={this.state.columns}
                applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
                onClose={() => this.setState({ showColumnSelectionForm: false })}
            />

        const allocationRecordForm =
            <React.Fragment>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryName"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary name
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryName"
                            name="beneficiaryName"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryName}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.customerInvoices}
                                onClick={() => this.filterByCustomerName(this.props.allocationRecord.beneficiaryName)}
                            >
                                Filter by this name
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="beneficiaryRegNo"
                        className="col-form-label col-lg-2"
                    >
                        Beneficiary reg. No.
                    </Label>
                    <Col lg="4">
                        <Input
                            id="beneficiaryRegNo"
                            name="beneficiaryRegNo"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.beneficiaryRegNo}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.customerInvoices}
                                onClick={() => this.filterByCustomerRegNo(this.props.allocationRecord.beneficiaryRegNo)}
                            >
                                Filter by this reg. No.
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="amount"
                        className="col-form-label col-lg-2"
                    >
                        Unallocated amount
                    </Label>
                    <Col lg="4">
                        <Input
                            id="amount"
                            name="amount"
                            type="text"
                            className="form-control-plaintext"
                            value={
                                formatUtils.formatAmountWithDCSign(this.props.allocationRecord.unallocatedAmount, this.props.allocationRecord.isDebit)
                                + " "
                                + this.props.allocationRecord.currencyCode
                            }
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                    <Col>
                        <div className={classes.VerticallyCentered}>
                            <Button
                                size="sm"
                                disabled={loading || !this.props.customerInvoices}
                                onClick={() => this.filterByAmount(this.props.allocationRecord.unallocatedAmount)}
                            >
                                Filter by this amount
                            </Button>
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDate"
                        className="col-form-label col-lg-2"
                    >
                        Payment date
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDate"
                            name="paymentDate"
                            type="text"
                            className="form-control-plaintext"
                            value={formatUtils.formatLocalDate(this.props.allocationRecord.paymentDate)}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        htmlFor="paymentDetails"
                        className="col-form-label col-lg-2"
                    >
                        Payment details
                    </Label>
                    <Col lg="6">
                        <Input
                            id="paymentDetails"
                            name="paymentDetails"
                            type="text"
                            className="form-control-plaintext"
                            value={this.props.allocationRecord.paymentDetails}
                            onChange={() => { }/* needed to avoid warnings in the browser console */}
                        />
                    </Col>
                </FormGroup>
            </React.Fragment>

        const columns = [
            columnsCustomerInvoice.customerName,
            columnsCustomerInvoice.customerRegNo,
            columnsCustomerInvoice.invoiceNo,
            columnsCustomerInvoice.currencyCode,
            columnsCustomerInvoice.unallocatedAmount,
            columnsCustomerInvoice.invoiceDate,
            columnsCustomerInvoice.dueDate,
        ];

        const applyFieldValueToColumn = (thisColumnDataField, thisFieldName, thisValue) => {
            columns.filter(column => column.dataField === thisColumnDataField)[0][thisFieldName] = thisValue;
        }

        applyFieldValueToColumn("customerName", "filter", textFilter({ getFilter: (filter) => { this.customerNameFilter = filter; } }));
        applyFieldValueToColumn("customerRegNo", "filter", textFilter({ getFilter: (filter) => { this.customerRegNoFilter = filter; } }));
        applyFieldValueToColumn("unallocatedAmount", "filter", textFilter({ getFilter: (filter) => { this.amountFilter = filter; } }));
        applyFieldValueToColumn("unallocatedAmount", "formatter", (cell) => formatUtils.formatAmountWithDCSign(cell));

        const keysColumnsCustomerInvoice = Object.keys(columnsCustomerInvoice);
        for (let i in keysColumnsCustomerInvoice) {
            const thisColumn = columnsCustomerInvoice[keysColumnsCustomerInvoice[i]];
            if (this.state.columns && this.state.columns.includes(thisColumn)) {
                columns.push(thisColumn);
            }
        }

        const selectRow = {
            mode: 'checkbox',

            onSelect: (row, isSelect, rowIndex, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
                this.setState({
                    selectedRows: selectedRows
                });
                this.onCalculateTotals(selectedRows);
            },

            onSelectAll: (isSelect, rows, e) => {
                const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect)
                this.setState({
                    selectedRows: selectedRows
                });
                this.onCalculateTotals(selectedRows);
            }
        };

        const table =
            <React.Fragment>
                {browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
                <div className="mt-3">
                    <BootstrapTable
                        keyField='id'
                        data={this.props.customerInvoices}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPageList: [5, 10, 25] })}
                        filter={filterFactory()}
                        selectRow={selectRow}
                        condensed
                    />
                </div>
            </React.Fragment>

        const showAmountExceedAlert = (this.state.totalAmount && this.state.totalAmount - this.props.allocationRecord.unallocatedAmount > 0.01 && this.state.selectedRows.length > 1);

        const amountExceedAlert = (
            <Alert
                color="warning"
            >
                You have selected multiple invoices with total amount of {formatUtils.formatAmountWithDCSign(this.state.totalAmount)} {this.props.allocationRecord.currencyCode},
                which exceeds the payment amount of {formatUtils.formatAmountWithDCSign(this.props.allocationRecord.unallocatedAmount, this.props.allocationRecord.isDebit)} {this.props.allocationRecord.currencyCode}.
                If you continue, some of those invoices will not be allocated with the payment or will be allocated partially.
            </Alert>
        );

        return (

            <React.Fragment>
                {this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}
                <Backdrop
                    show
                    onClick={this.props.onClose}
                >
                </Backdrop>
                <div
                    className={classes.PageCardContainer}
                >
                    <Card
                        // className={classes.PageCard} // Like in ColumnSelectionForm, for some reason specifying these parameters in CSS does not work 
                        style={{
                            height: "100%",
                            overflowX: "auto",
                            overflowY: "auto"
                        }}
                    >
                        {showAmountExceedAlert ? amountExceedAlert : null}
                        <CardTitle>
                            <div className={classes.CardTitleDiv}>
                                <div className={classes.CardTitleSubDiv}>
                                    Select customer invoices
                                </div>
                                <div className={classes.CloseButtonDiv}>
                                    <p className={classes.CloseButtonDivP}>
                                        <i
                                            className="bx bx-x"
                                            onClick={this.props.onClose}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </p>
                                </div>
                            </div>
                            <br />
                        </CardTitle>
                        <CardBody>
                            {this.props.allocationRecord ? allocationRecordForm : null}

                            {loading ? (
                                <div width="100%" height="100%" align="center">
                                    <Spinner animation="border"></Spinner>
                                    <br /><br />
                                </div>
                            ) : null}

                            {!loading && this.props.customerInvoices ? table : null}

                            <Container fluid>
                                <Row>
                                    <Col lg="2">
                                        <div style={{
                                            display: "flex",
                                            height: "100%",
                                            alignItems: "end"
                                        }}>
                                            {this.props.loadingTotals ? <Spinner animation="border" /> : null}
                                            <Label>
                                                {this.props.allocationRecord && this.state.totalAmount && !this.props.loadingTotals ? formatUtils.formatAmountWithDCSign(this.state.totalAmount) + " " + this.props.allocationRecord.currencyCode : null}
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Button
                                            color="primary"
                                            onClick={this.onCreateAllocation}
                                            disabled={this.state.selectedRows.length == 0}
                                        >
                                            Allocate
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                            <br />
                            <br />
                        </CardBody>
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({ allocation, payment, customerInvoice }) => ({
    allocationRecord: allocation.allocationRecord,
    loading: customerInvoice.loading,
    loadingTotals: customerInvoice.loadingTotals,
    customerInvoices: customerInvoice.customerInvoices,
    totals: customerInvoice.totals,
    error: customerInvoice.error,
});

const mapDispatchToProps = dispatch => ({
    onGetUnallocatedCustomerInvoices: () => dispatch(actions.customerInvoiceGetUnallocated()),
    /*onGetTotals: (selectedInvoices, currencyCode, date) => dispatch(actions.customerInvoiceGetTotalsForUnallocated(selectedInvoices, currencyCode, date)),*/
    onCreateAllocation: (allocation) => dispatch(actionsAllocation.allocationCreate(allocation))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectCustomerInvoices);