export const COUNTER_CREATE = "COUNTER_CREATE";
export const COUNTER_CREATE_ERROR = "COUNTER_CREATE_ERROR";
export const COUNTER_CREATE_SUCCESS = "COUNTER_CREATE_SUCCESS";

export const COUNTER_GET_ALL = "COUNTER_GET_ALL";
export const COUNTER_GET_AND_UPDATE = "COUNTER_GET_AND_UPDATE";
export const COUNTER_GET_BY_ID = "COUNTER_GET_BY_ID";
export const COUNTER_GET_ERROR = "COUNTER_GET_ERROR";
export const COUNTER_GET_SUCCESS = "COUNTER_GET_SUCCESS";

export const COUNTER_GET_VALUE_ERROR = "COUNTER_GET_VALUE_ERROR";
export const COUNTER_GET_VALUE_SUCCESS = "COUNTER_GET_VALUE_SUCCESS";

export const COUNTER_UPLOAD = "COUNTER_UPLOAD";
export const COUNTER_UPLOAD_ERROR = "COUNTER_UPLOAD_ERROR";
export const COUNTER_UPLOAD_SUCCESS = "COUNTER_UPLOAD_SUCCESS";

export const COUNTER_DELETE = "COUNTER_DELETE";
export const COUNTER_DELETE_ERROR = "COUNTER_DELETE_ERROR";
export const COUNTER_DELETE_SUCCESS = "COUNTER_DELETE_SUCCESS";

