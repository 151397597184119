import React, { Component } from "react";
import { Alert, Card, CardBody, Col, Container, Row, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import profile from "../../assets/images/cm-logo-transparent.png";
import * as rest from "../../helpers/restHelper";

class RegisterSuccessActivationPending extends Component {
    onResendActivationEmail = () => {
        rest.post(endpointsBackend.USER_ACCOUNT_RESEND_ACTIVATION,
            {
                "username": this.props.match.params.email
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft px-5 py-3">
                                        <img src={profile} alt="" className="img-fluid" />
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="p-2">
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                We sent an email with instructions to activate your account to {this.props.match.params.email}.
                                            </Alert>
                                            <p>
                                                Didn't receive the email? Check your junk mail box, or press the button to send the activation email again:
                                            </p>
                                            <Button
                                                color="primary"
                                                onClick={this.onResendActivationEmail}
                                            >
                                                Resend activation
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        <Link
                                            to={endpointsFrontend.USER_ACCOUNT_LOGIN}
                                            className="fw-medium text-primary"
                                        >
                                            Login
                                        </Link>
                                    </p>
                                    <p>
                                        Copyright © {new Date().getFullYear()} D.Vision SIA
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(RegisterSuccessActivationPending);