import { takeEvery, put, call } from "redux-saga/effects";

import { USER_PASSWORD_RESET_REQUEST, USER_PASSWORD_RESET } from "./actionTypes";
import { userPasswordResetRequestSuccess, userPasswordResetRequestError, userPasswordResetSuccess, userPasswordResetError } from "./actions"
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from "../../definitions/endpoints/endpoints-backend";


function* userPasswordResetRequestSaga({ payload }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_PASSWORD_RESET_REQUEST,
			{
				username: payload.username
			},
			{
				withCredentials: false
			}
		);

		yield put(userPasswordResetRequestSuccess("An email with a link to reset your password has been sent to " + payload.username));
		
	} catch (error) {

		yield put(userPasswordResetRequestError(error));

	}
}


function* userPasswordResetSaga({ payload }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACCOUNT_PASSWORD_RESET,
			{
				token: payload.token,
				newPassword: payload.newPassword
			},
			{
				withCredentials: false
			}
		);

		yield put(userPasswordResetSuccess("Password reset successfully"));
		
	} catch (error) {

		yield put(userPasswordResetError(error));

	}
}

function* userPasswordResetCommonSaga() {
	yield takeEvery(USER_PASSWORD_RESET_REQUEST, userPasswordResetRequestSaga);
	yield takeEvery(USER_PASSWORD_RESET, userPasswordResetSaga);
}

export default userPasswordResetCommonSaga;
