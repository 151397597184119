import * as actionTypes from "./actionTypes";

const initialState = {
	creatingAdmin: false,
	error: null,
	loading: false,
	tenants: null,
}

export default function tenantReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TENANT_ADMIN_CREATE:
			return {
				...state,
				creatingAdmin: true,
				error: null
			}
		case actionTypes.TENANT_ADMIN_CREATE_ERROR:
			return {
				...state,
				creatingAdmin: false,
				error: action.error
			}
		case actionTypes.TENANT_ADMIN_CREATE_SUCCESS:
			return {
				...state,
				creatingAdmin: false
			}
		case actionTypes.TENANT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.TENANT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.TENANT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				tenants: action.tenants
			}
		case actionTypes.TENANT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.TENANT_CREATE:
			return {
				...state,
				error: null
			}
		case actionTypes.TENANT_CREATE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}