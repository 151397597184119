import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsBankAccount from '../../store/BankAccount/actions';
import * as actionsCompanySettings from '../../store/CompanySettings/actions';
import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsPayment from '../../store/Payment/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import * as actionsSupplierBankAccount from '../../store/SupplierBankAccount/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as browseFormControls from '../../helpers/browseFormControls';
import { ChargeBearer } from "definitions/enums/ChargeBearer";
import * as columnsPayment from "definitions/columns/payment";
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as inputSelectUtils from '../../helpers/inputSelectUtils';
// import * as formatUtils from '../../helpers/formatUtils';
import { PaymentUrgency } from "definitions/enums/PaymentUrgency";
import * as tableUtils from '../../helpers/tableUtils';

class PaymentEdit extends Component {

	constructor(props) {
		super(props);

		// const defaultPaymentDate = new Date();					// today's date
		const defaultDueDate = new Date();
		// defaultDueDate.setDate(defaultPaymentDate.getDate() + 7);	// today's date + 7

		this.state = {
			id: "",
			supplierId: "",
			supplierExternalId: "",
			supplierName: "",
			supplierBankAccountId: "",
			supplierBankAccountNo: "",
			bankAccountId: "",
			bankAccountNo: "",
			currencyId: "",
			currencyCode: "",
			urgency: "NURG",
			chargeBearer: "DEBT",
			paymentDate: defaultDueDate.toJSON().substring(0, 10),
			paymentDetails: "",
			amountToPay: 0,

			advanceNo: "",

			payments: [],
			errors: {},
			changed: false,
			advanceNoIsEmpty: false,
			amountToPayIs0: false,

			showColumnSelectionForm: false,
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	filterSupplierBankAccountsBySelectedSupplier = (supplierId) => {
		return this.props.supplierBankAccounts
			? this.props.supplierBankAccounts.filter(
				supplierBankAccountRow => supplierBankAccountRow.supplierId == supplierId)
			: [];
	}

	setSupplierBankAccountIdBySelectedSupplier = (supplierId) => {
		const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(supplierId);
		this.setState({
			supplierBankAccountId: supplierBankAccountsFiltered.length ? supplierBankAccountsFiltered[0].id : null
		});
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type == "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		switch (thisObjectName) {
			case "advanceNo":
				this.setState({
					advanceNoIsEmpty: !thisValue || !thisValue.length
				});
				break;
			case "amountToPay":
				this.setState({
					amountToPayIs0: !thisValue
				});
				break;
			case "supplierId":
				this.setSupplierBankAccountIdBySelectedSupplier(thisValue);
				break;
		}
		this.setState({
			[thisObjectName]: thisValue,
			changed: true
		});
	}

	preparePaymentForInsertOrUpdate = () => {
		let newOrUpdatedPayment = {
			bankAccountId: this.state.bankAccountId,
			bankAccountNo: this.state.bankAccountNo,
			currencyCode: this.state.currencyCode,
			currencyId: this.state.currencyId,
			paymentDate: this.state.paymentDate,
			paymentDetails: this.state.paymentDetails,
			supplierBankAccountId: this.state.supplierBankAccountId,
			supplierBankAccountNo: this.state.supplierBankAccountNo,
			supplierId: this.state.supplierId,
			supplierName: this.state.supplierName,
			urgency: this.state.urgency,
			chargeBearer: this.state.chargeBearer,
			amountToPay: this.state.amountToPay,
			advanceNo: this.state.advanceNo,
		}
		if (this.state.id) {
			newOrUpdatedPayment = {
				id: this.state.id,
				...newOrUpdatedPayment
			}
		}

		return newOrUpdatedPayment;
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		if (!this.state.id) {
			if (!this.state.amountToPay) {
				const amountToPayInput = document.getElementById("amountToPay");
				if (amountToPayInput) {
					amountToPayInput.focus();
				}
				this.setState({
					amountToPayIs0: true
				});
				return;
			}
			if (this.props.companySettings.useAdvanceNo && !this.state.advanceNo.length) {
				const advanceNoInput = document.getElementById("advanceNo");
				if (advanceNoInput) {
					advanceNoInput.focus();
				}
				this.setState({
					advanceNoIsEmpty: true
				})
				return;
			} 
			if (!this.state.paymentDate) {
				return;
			}
		}
		const newOrUpdatedPayment = this.preparePaymentForInsertOrUpdate();
		this.props.onCreatePayment(newOrUpdatedPayment, this.props.history);		// Create the payment and return to Browse Payments
		// this.props.onCreatePayment(newOrUpdatedPayment, null);					// Create the payment without returning to Browse Payments, so that it's possible to add lines
		this.setState({
			changed: false
		});
	}

	onDeletePayment = () => {
		if (window.confirm("Are you sure you want to delete this payment?")) {
			this.props.onDeletePayment(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetPaymentById(id);
			this.props.onGetPaymentLines(id);
		}
		if (!this.props.companySettings) {
			this.props.onGetCompanySettings();
		}
		this.props.onGetBankAccounts();
		this.props.onGetCurrencies();
		this.props.onGetSuppliers();
		this.props.onGetSupplierBankAccounts();
		this.setState({
			columns: tableUtils.getSavedColumnSetup(columnsPayment, this.props.location.pathname, [])
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.bankAccounts !== this.props.bankAccounts) {
			if (!this.state.bankAccountId && this.props.bankAccounts[0]) {
				this.setState({
					bankAccountId: this.props.bankAccounts[0].id
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id,
					currencyCode: this.props.currencies[0].code
				})
			}
		}

		if (prevProps.suppliers !== this.props.suppliers) {
			if (!this.state.supplierId && this.props.suppliers[0]) {
				this.setState({
					supplierId: this.props.suppliers[0].id
				});
			}
		}

		if (prevProps.supplierBankAccounts !== this.props.supplierBankAccounts) {
			if (!this.state.supplierBankAccountId && this.props.supplierBankAccounts[0]) {
				this.setState({
					supplierBankAccountId: this.props.supplierBankAccounts[0].id
				})
			}
		}
	}

	launchColumnSelectionForm = () => {
		this.setState({
			showColumnSelectionForm: true
		});
	}

	applySelectedColumns = (selectedColumns) => {
		tableUtils.setSavedColumnSetup(selectedColumns, this.props.location.pathname);
		this.setState({
			columns: tableUtils.applySavedColumnSetup(columnsPayment, selectedColumns)
		});
	}

	render() {
		const pageTitle = "Create payment | " + config.AppName;
		const breadcrumbsTitle = "Payment";
		const breadcrumbsItem = "New payment";
		const returnLink = endpointsFrontend.PAYMENT_BROWSE_PREPARED;

		const ColumnSelectionFormWithProps =
			<ColumnSelectionForm
				availableColumns={[
					columnsPayment.supplierExternalId
				]}
				selectedColumns={this.state.columns}
				applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
				onClose={() => this.setState({ showColumnSelectionForm: false })}
			/>

		const supplierBankAccountsFiltered = this.filterSupplierBankAccountsBySelectedSupplier(this.state.supplierId);

		const bankAccountOptions = inputSelectUtils.generateOptionsFromData(this.props.bankAccounts, bankAccountRow => bankAccountRow.accountNo);
		const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
		const supplierOptions = inputSelectUtils.generateOptionsFromData(this.props.suppliers, supplierRow => supplierRow.name);
		const supplierBankAccountOptions = inputSelectUtils.generateOptionsFromData(supplierBankAccountsFiltered, supplierBankAccountRow => supplierBankAccountRow.accountNo);
		const chargeBearerOptions = inputSelectUtils.generateOptionsFromData(ChargeBearer, chargeBearerRow => chargeBearerRow.description);
		const urgencyOptions = inputSelectUtils.generateOptionsFromData(PaymentUrgency, row => row.description);

		const currencyCode = this.props.currencies && this.props.currencies.length && this.state.currencyId ? this.props.currencies.filter(row => row.id == this.state.currencyId)[0].code : "";
		const supplierExternalId = this.props.suppliers && this.props.suppliers.length && this.state.supplierId ? this.props.suppliers.filter(row => row.id == this.state.supplierId)[0].externalId : "";

		const editForm = (
			<Row>
				{browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("supplierId", "Supplier", this.onChange, this.state.supplierId, supplierOptions)}
										{this.state.columns && this.state.columns.length && this.state.columns.includes(columnsPayment.supplierExternalId) ? editFormControls.staticTextControl("supplierExternalId", "Supplier external ID", supplierExternalId) : null}
										{editFormControls.selectControl("supplierBankAccountId", "Supplier bank account", this.onChange, this.state.supplierBankAccountId, supplierBankAccountOptions, false, !supplierBankAccountsFiltered.length)}
										{editFormControls.dateControl("paymentDate", "Payment execution date", this.onChange, this.state.paymentDate, false, !this.state.paymentDate)}
										{editFormControls.selectControl("bankAccountId", "Account to pay from", this.onChange, this.state.bankAccountId, bankAccountOptions)}
										{editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}
										{editFormControls.selectControl("chargeBearer", "Charge bearer", this.onChange, this.state.chargeBearer, chargeBearerOptions)}
										{editFormControls.selectControl("urgency", "Urgency", this.onChange, this.state.urgency, urgencyOptions)}
										{editFormControls.textAreaControl("paymentDetails", "Payment details", this.onChange, this.state.paymentDetails)}
										{editFormControls.numberControl("amountToPay", "Amount to pay, " + currencyCode, this.onChange, this.state.amountToPay, 0.01, "", false, this.state.amountToPayIs0)}
										{this.props.companySettings && this.props.companySettings.useAdvanceNo && editFormControls.textControl("advanceNo", "Advance No.", this.onChange, this.state.advanceNo, "Enter advance No.", null, this.state.advanceNoIsEmpty)}

									</div>
								</div>

								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}

										<span> </span>

										{editFormControls.deleteButton(this.props.deleting, this.onDeletePayment, this.state.id)}
									</Col>
								</Row>
							</form>

						</CardBody>
					</Card>
				</Col>
			</Row>

		);

		const loading = this.props.loading || this.props.loadingBankAccounts || this.props.loadingCurrencies || this.props.loadingSuppliers;

		return (
			<React.Fragment>
				{this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs
							title={breadcrumbsTitle}
							breadcrumbItem={breadcrumbsItem}
							link={returnLink}
						/>

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment >
		)
	}
}

const mapStateToProps = ({ bankAccount, companySettings, currency, supplier, supplierBankAccount, payment }) => ({
	bankAccounts: bankAccount.bankAccounts,
	companySettings: companySettings.companySettings,
	currencies: currency.currencies,
	deleting: payment.deleting,
	error: payment.error,
	errorLines: payment.errorLines,
	loading: payment.loading,
	loadingLines: payment.loadingLines,
	loadingBankAccounts: bankAccount.loading,
	loadingCurrencies: currency.loading,
	loadingSuppliers: supplier.loading,
	loadingSupplierBankAccounts: supplierBankAccount.loading,
	payments: payment.payments,
	paymentLines: payment.paymentLines,
	suppliers: supplier.suppliers,
	supplierBankAccounts: supplierBankAccount.supplierBankAccounts,
	saving: payment.saving,
})

const mapDispatchToProps = dispatch => ({
	onGetBankAccounts: () => dispatch(actionsBankAccount.bankAccountGetAll()),
    onGetCompanySettings: () => dispatch(actionsCompanySettings.companySettingsGet()),
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll()),
	onGetSupplierBankAccounts: () => dispatch(actionsSupplierBankAccount.supplierBankAccountGetAll()),
	onGetPaymentLines: (paymentId) => dispatch(actionsPayment.paymentLineGetAll(paymentId)),
	onGetPaymentById: (id) => dispatch(actionsPayment.paymentGetById(id)),
	onCreatePayment: (payment, history) => dispatch(actionsPayment.paymentCreate(payment, history)),
	onDeletePayment: (id, history) => dispatch(actionsPayment.paymentDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentEdit);
