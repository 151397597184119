	export const CM20PAYMENT_FILE_TO_SIGN_IN_CRM = "to-sign-in-crm";
	export const CM20PAYMENT_FILE_PROCESSED = "processed";
	export const CM20PAYMENT_FILE_FAILED = "failed";

	export const PAYMENT_FILE_TO_SIGN_IN_CRM = "to-sign-in-crm";
//	export const PAYMENT_FILE_TO_SIGN_IN_BANK = "to-sign-in-bank";
//	export const PAYMENT_FILE_SIGNED_IN_CRM_AND_SENT_TO_BANK = "signed-in-crm-and-sent-to-bank";
//	export const PAYMENT_FILE_SENT_AS_FILE_TO_BANK = "sent-as-file-to-bank";
	export const PAYMENT_FILE_PROCESSED = "processed";
	export const PAYMENT_FILE_FAILED = "failed";

	export const ALLOCATIONS_CONFIRM_ALL = "all";
	export const ALLOCATIONS_CONFIRM_ALL_HIGH_TRUST = "all-high-trust";
	export const ALLOCATIONS_CONFIRM_CHARGES = "charges";
	export const ALLOCATIONS_CONFIRM_INCOMING = "incoming";
	export const ALLOCATIONS_CONFIRM_INCOMING_AND_CHARGES = "incoming-and-charges";
	export const ALLOCATIONS_CONFIRM_OUTGOING = "outgoing";
	export const ALLOCATIONS_CONFIRM_OUTGOING_AND_CHARGES = "outgoing-and-charges";

