import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import * as actionsTenant from '../../store/Tenant/actions';
import * as actionsUserLogin from '../../store/UserAccountLogin/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';


class UserAccountProfileEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            tenantExternalId: "",
            formErrors: null,
            showPassword: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing

        let data = {
            username: this.state.username,
            tenantExternalId: this.state.tenantExternalId,
            password: this.state.password,
        };

        this.setState({
            formErrors: null,
        }); // Should be before calling the Redux action to avoid the small delay between scrolling up and showing the alert
        this.props.onCreateAdmin(data, this.props.history);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            this.setState({
                tenantExternalId: id
            });
        } else {
            this.setState({
                formErrors: { message: "tenantExternalId is null" }
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.errorUserAccounts !== this.props.errorUserAccounts) {
            if (this.props.errorUserAccounts) {
                this.setState({
                    errors: this.props.errorUserAccounts
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

    }

	showPassword = (doShowPassword) => {
		this.setState({
			showPassword: doShowPassword
		});
	}

    render() {
        const pageTitle = "Create new admin | " + config.AppName;
        const breadcrumbsTitle = "Tenants";
        const breadcrumbsItem = "Create new admin" + (this.state.tenantExternalId ? (" for " + this.state.tenantExternalId) : "");

        const passwordInput =
            <div className="mb-3">
                <FormGroup className="mb-4" row>
                    <Label
                        htmlFor={"password"}
                        className={editFormControls.labelClass}
                    >
                        Password
                    </Label>
                    <Col lg={editFormControls.valueColSize}>
                        <InputGroup>
                            <Input
                                id="password"
                                name="password"
                                type={this.state.showPassword ? 'text' : 'password'}
                                invalid={this.state.attemptedSubmission && !this.state.password.length}
                                placeholder={"Enter password"}
                                onChange={this.onChange}
                                value={this.state.password}
                            />
                            <InputGroupText
                                onMouseDown={() => this.showPassword(true)}
                                onTouchStart={() => this.showPassword(true)}
                                onMouseUp={() => this.showPassword(false)}
                                onTouchCancel={() => this.showPassword(false)}
                                onTouchEnd={() => this.showPassword(false)}
                                onContextMenu={(e) => e.preventDefault() /* prevents the context menu on touch and hold */}
                            >
                                {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroupText>
                        </InputGroup>
                    </Col>
                </FormGroup>
            </div>

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("tenantExternalId", this.onChange, this.state.tenantExternalId)}
                                        {editFormControls.textControl("username", "Username", this.onChange, this.state.username, "Username")}
                                        {passwordInput}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, false)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loadingTenantLicenses || this.props.loadingUserAccounts;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

                        {editFormControls.errorAlert(this.state.formErrors)}
                        {editFormControls.errorAlert(this.props.error)}

                        {editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ tenant }) => ({
    error: tenant.error,
    saving: tenant.creatingAdmin
})

const mapDispatchToProps = dispatch => ({
    onCreateAdmin: (data, history) => dispatch(actionsTenant.tenantAdminCreate(data, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountProfileEdit);

