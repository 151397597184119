import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actionsTenantLicense from '../../store/TenantLicense/actions';
import * as actionsUserAccount from '../../store/UserAccount/actions';
import { modules } from "definitions/modules";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';

class UserAccountEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: "",
            firstName: "",
            lastName: "",
            username: "",
            isActivated: "",
            companies: "",
            userRoleNames: "",
            userAuthorizationNames: "",

            userAccount: [],
            errors: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        const thisObjectName = e.target.name;
        let thisValue;
        if (e.target.type == "checkbox") {
            thisValue = e.target.checked;
        } else {
            thisValue = e.target.value;
        }
        this.setState({
            [thisObjectName]: thisValue
        });
    }

    onSubmit(e) {
        e.preventDefault();		// prevent the form from refreshing

        const companies = this.props.companiesForTenant
            .filter(company => {
                const stateFieldName = "hasCOMPANY_" + company.id;
                return this.state[stateFieldName];
            })
            .map(company => company.id);

        const userRoleNames = [];
        if (this.state.hasROLE_ADMIN) {
            userRoleNames.push("ROLE_ADMIN");
        }

        const userAuthorizationNames = this.props.tenantLicenses
            .filter(tenantLicense => {
                const stateFieldName = "has" + tenantLicense.replace("LICENSED_", "");
                return this.state[stateFieldName];
            })
            .map(tenantLicense => tenantLicense.replace("LICENSED_", "AUTHORIZED_"));

        let newOrUpdatedUserAccount = {
            companyIdList: companies,
            userRoleNameList: userRoleNames,
            userAuthorizationNameList: userAuthorizationNames
        };
        if (this.state.id) {
            newOrUpdatedUserAccount = {
                id: this.state.id,
                ...newOrUpdatedUserAccount
            }
        }
        this.props.onUpdateRights(newOrUpdatedUserAccount, this.props.history);
    }

    onDeleteUserAccount = () => {
        if (window.confirm("Are you sure you want to delete this user account?\nAll user account data will be irreversibly deleted!")) {
            this.props.onDeleteUserAccount(this.state.id, this.props.history);
        }
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({
                id: this.props.match.params.id
            });
            this.props.onGetUserAccountById(this.props.match.params.id);
        }
        this.props.onGetTenantLicenses();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.errorUserAccounts !== this.props.errorUserAccounts) {
            if (this.props.errorUserAccounts) {
                this.setState({
                    errors: this.props.errorUserAccounts
                });
            } else {
                this.setState({
                    errors: ""
                })
            }
        }

        if (prevProps.userAccounts !== this.props.userAccounts) {
            if (this.props.userAccounts && this.props.userAccounts[0]) {
                this.setState({
                    firstName: this.props.userAccounts[0].firstName,
                    lastName: this.props.userAccounts[0].lastName,
                    username: this.props.userAccounts[0].username,
                    isActivated: this.props.userAccounts[0].isActivated,
                    companies: this.props.userAccounts[0].companies,
                    userRoleNames: this.props.userAccounts[0].userRoleNames,
                    userAuthorizationNames: this.props.userAccounts[0].userAuthorizationNames
                });

                const allCompanies = this.props.userAccounts[0].companies;
                for (let key in allCompanies) {
                    const stateFieldName = "hasCOMPANY_" + allCompanies[key].id;
                    this.setState({
                        [stateFieldName]: true
                    })
                }

                const allRoleNames = this.props.userAccounts[0].userRoleNames;
                for (let key in allRoleNames) {
                    const stateFieldName = "has" + allRoleNames[key];
                    this.setState({
                        [stateFieldName]: true
                    })
                }

                const allAuthorizationNames = this.props.userAccounts[0].userAuthorizationNames;
                for (let key in allAuthorizationNames) {
                    const stateFieldName = "has" + allAuthorizationNames[key].replace("AUTHORIZED_", "");
                    this.setState({
                        [stateFieldName]: true
                    })
                }
            } else {
                this.setState({
                    firstName: "",
                    lastName: "",
                    username: "",
                    isActivated: "",
                    companies: "",
                    userRoleNames: "",
                    userAuthorizationNames: "",
                });
            }

        }

    }

    render() {
        const pageTitle = (this.state.id || this.props.loadingUserAccounts ? "Edit" : "Create") + " user account | " + config.AppName;
        const breadcrumbsTitle = "User Account";
        const breadcrumbsItem = (this.state.id || this.props.loadingUserAccounts ? "Edit" : "New") + " user account";

        const companySelectionControls = this.props.companiesForTenant
            ? this.props.companiesForTenant.map((company, key) => {
                const stateFieldName = "hasCOMPANY_" + company.id;
                return (
                    <div key={key}>
                        {editFormControls.checkboxControl(stateFieldName, company.name, this.onChange, this.state[stateFieldName])}
                    </div>
                );
            })
            : null;

        const roleSelectionControls = editFormControls.checkboxControl("hasROLE_ADMIN", "Administrator", this.onChange, this.state.hasROLE_ADMIN);

        const moduleSelectionControls = this.props.tenantLicenses
            ? (modules
                .filter(module => this.props.tenantLicenses.includes("LICENSED_" + module.name) && !(module.name === "MODULE_SMART_ID"))    // Smart-ID module cannot be authorized or not to a user account
                .map((module, key) => {
                    const stateFieldName = "has" + module.name;
                    return (
                        <div key={key}>
                            {editFormControls.checkboxControl(stateFieldName, module.description, this.onChange, this.state[stateFieldName])}
                        </div>
                    );
                }))
            : null;

        const editForm = (
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <form
                                className="outer-repeater"
                                onSubmit={this.onSubmit}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">

                                        {editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
                                        {editFormControls.staticTextControl("username", "Username", this.state.username)}
                                        {editFormControls.staticTextControl("firstName", "First name", this.state.firstName)}
                                        {editFormControls.staticTextControl("lastName", "Last name", this.state.lastName)}
                                        {editFormControls.staticTextControl("isActivated", "Activation status", this.state.isActivated ? "Activated" : "Not activated")}

                                        <br />
                                        <h4>Authorized companies</h4>
                                        <br />

                                        {companySelectionControls}

                                        <br />
                                        <h4>Authorized roles</h4>
                                        <br />

                                        {roleSelectionControls}

                                        <br />
                                        <h4>Authorized modules and data</h4>
                                        <br />

                                        {moduleSelectionControls}

                                    </div>
                                </div>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {editFormControls.saveButton(this.props.saving, this.state.id)}
                                        <span> </span>
                                        {editFormControls.deleteButton(this.props.deleting, this.onDeleteUserAccount, this.state.id)}
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );

        const loading = this.props.loadingTenantLicenses || this.props.loadingUserAccounts;

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>{pageTitle}</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.errorLicenses, this.props.errorUserAccounts)}

						{editFormControls.formLoadingSpinner(loading)}

                        {!loading && editForm}
                    </Container>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = ({ company, tenantLicense, userAccount }) => ({
    companiesForTenant: company.companiesForTenant,
    deleting: userAccount.deleting,
    errorLicenses: tenantLicense.error,
    errorUserAccounts: userAccount.error,
    loadingTenantLicenses: tenantLicense.loading,
    loadingUserAccounts: userAccount.loading,
    saving: userAccount.saving,
    tenantLicenses: tenantLicense.tenantLicenses,
    userAccounts: userAccount.userAccounts
})

const mapDispatchToProps = dispatch => ({
    onDeleteUserAccount: (id, history) => dispatch(actionsUserAccount.userAccountDelete(id, history)),
    onGetTenantLicenses: () => dispatch(actionsTenantLicense.tenantLicenseGetAll()),
    onGetUserAccountById: (id) => dispatch(actionsUserAccount.userAccountGetById(id)),
    onUpdateRights: (updatedUserAccount, history) => dispatch(actionsUserAccount.userAccountUpdateRights(updatedUserAccount, history))
})


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserAccountEdit);

