import { Buffer } from 'buffer';

export const downloadHelper = (response) => {
    if (response.data && response.data.contents && response.data.contents.length) {
        // Download the file.
        // It is encoded as AttachmentAsArray object in response.data,
        // with the "contents" property being encoded in Base64
        const filename = response.data.filename;
        const contentType = response.data.contentType;
        const contents = Buffer.from(response.data.contents, 'base64');

        const link = document.createElement("a");
        link.target = "_blank";
        link.download = filename;
        link.href = URL.createObjectURL(
            new Blob([contents], { type: contentType })
        );
        link.click();
    }
}