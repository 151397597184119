export const CUSTOMER_AGREEMENT_CREATE = "CUSTOMER_AGREEMENT_CREATE";
export const CUSTOMER_AGREEMENT_CREATE_ERROR = "CUSTOMER_AGREEMENT_CREATE_ERROR";
export const CUSTOMER_AGREEMENT_CREATE_SUCCESS = "CUSTOMER_AGREEMENT_CREATE_SUCCESS";

export const CUSTOMER_AGREEMENT_GET_ALL = "CUSTOMER_AGREEMENT_GET_ALL";
export const CUSTOMER_AGREEMENT_GET_BY_ID = "CUSTOMER_AGREEMENT_GET_BY_ID";
export const CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID = "CUSTOMER_AGREEMENT_GET_BY_CUSTOMER_ID";
export const CUSTOMER_AGREEMENT_GET_ERROR = "CUSTOMER_AGREEMENT_GET_ERROR";
export const CUSTOMER_AGREEMENT_GET_SUCCESS = "CUSTOMER_AGREEMENT_GET_SUCCESS";

export const CUSTOMER_AGREEMENT_DELETE = "CUSTOMER_AGREEMENT_DELETE";
export const CUSTOMER_AGREEMENT_DELETE_ERROR = "CUSTOMER_AGREEMENT_DELETE_ERROR";
export const CUSTOMER_AGREEMENT_DELETE_SUCCESS = "CUSTOMER_AGREEMENT_DELETE_SUCCESS";
