import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper"

function* changeStatusTimelogTimerSaga({ data }) {
	try {
		let response;
		switch (data.action) {
			case "DELETE":
				response = yield call(
					rest.del,
					endpointsBackend.TIMELOG_TIMER_DELETE.replace("{taskId}", data.taskId)
				);
				break;
			case "PAUSE":
				response = yield call(
					rest.post,
					endpointsBackend.TIMELOG_TIMER_PAUSE.replace("{taskId}", data.taskId)
				);
				break;
			case "START":
				response = yield call(
					rest.post,
					endpointsBackend.TIMELOG_TIMER_START.replace("{taskId}", data.taskId)
				);
				break;
			case "STOP":
				response = yield call(
					rest.post,
					endpointsBackend.TIMELOG_TIMER_STOP.replace("{taskId}", data.taskId),
					{
						"taskId": data.taskId,
						"date": data.date,
						"hoursLogged": data.hoursLogged,
						"comment": data.comment
					}
				)
		}
		yield put(actions.timelogTimerChangeStatusSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.timelogTimerChangeStatusError(e));
	}
}

function* findAllActiveTimelogTimerSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TIMELOG_TIMER_GET_ALL_ACTIVE
		);
		yield put(actions.timelogTimerFindAllActiveSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.timelogTimerFindAllActiveError(e));
	}
}


function* timelogTimerSaga() {
	yield (takeLatest(actionTypes.TIMELOG_TIMER_CHANGE_STATUS, changeStatusTimelogTimerSaga));
	yield (takeLatest(actionTypes.TIMELOG_TIMER_FIND_ALL_ACTIVE, findAllActiveTimelogTimerSaga));
}

export default timelogTimerSaga;