import * as actionTypes from "./actionTypes";

export const tenantAdminCreate = (tenantAdmin, history) => {
	return {
		type: actionTypes.TENANT_ADMIN_CREATE,
		tenantAdmin: tenantAdmin, 
		history: history
	}
}

export const tenantAdminCreateError = (thisError) => {
	return {
		type: actionTypes.TENANT_ADMIN_CREATE_ERROR,
		error: thisError
	}
}

export const tenantAdminCreateSuccess = () => {
	return {
		type: actionTypes.TENANT_ADMIN_CREATE_SUCCESS
	}
}

export const tenantGetAll = () => {
	return {
		type: actionTypes.TENANT_GET_ALL
	}
}

export const tenantGetById = (id) => {
	return {
		type: actionTypes.TENANT_GET_BY_ID,
		id: id
	}
}

export const tenantGetError = (thisError) => {
	return {
		type: actionTypes.TENANT_GET_ERROR,
		error: thisError
	}
}

export const tenantGetSuccess = (data) => {
	return {
		type: actionTypes.TENANT_GET_SUCCESS,
		tenants: data
	}
}

export const tenantCreate = (tenant, history) => {
	return {
		type: actionTypes.TENANT_CREATE,
		tenant: tenant, 
		history: history
	}
}

export const tenantCreateError = (thisError) => {
	return {
		type: actionTypes.TENANT_CREATE_ERROR,
		error: thisError
	}
}

export const tenantDelete = (id, history) => {
	return {
		type: actionTypes.TENANT_DELETE,
		id: id,
		history: history
	}
}