import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper"

function* createTagSaga({ tag, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.TAG_CREATE_OR_UPDATE,
			tag
		);
		yield put(actions.tagCreateSuccess());
		history.push(endpointsFrontend.TAG_BROWSE);
	} catch (e) {
		yield put(actions.tagCreateError(e));
	}
}

function* deleteTagSaga({ id, history }) {
	try {
		yield call(
			rest.del,
			endpointsBackend.TAG_GET_BY_ID.replace("{tagId}", id)
		);
		yield put(actions.tagDeleteSuccess());
		history.push(endpointsFrontend.TAG_BROWSE);
	} catch (e) {
		yield put(actions.tagDeleteError(e));
	}
}

function* getAllTagsSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TAG_GET_ALL
		);

		yield put(actions.tagGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.tagGetError(e));
	}
}

function* getTagByIdSaga({ id }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.TAG_GET_BY_ID.replace("{tagId}", id)
		);

		yield put(actions.tagGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.tagGetError(e));
	}
}

function* tagSaga() {
	yield (takeLatest(actionTypes.TAG_CREATE, createTagSaga));
	yield (takeLatest(actionTypes.TAG_DELETE, deleteTagSaga));
	yield (takeLatest(actionTypes.TAG_GET_ALL, getAllTagsSaga));
	yield (takeLatest(actionTypes.TAG_GET_BY_ID, getTagByIdSaga));
}

export default tagSaga;