import { takeEvery, put, call } from "redux-saga/effects";

import { USER_ACTIVATE } from "./actionTypes";
import { userActivateSuccess, userActivateError } from "./actions"
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from "../../definitions/endpoints/endpoints-backend";


function* userActivateSaga({ payload }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.USER_ACTIVATE.replace("{token}", payload),
			{ },
			{
				withCredentials: false
			}
		);

		yield put(userActivateSuccess("Actovated successfully"));
		
	} catch (error) {

		yield put(userActivateError(error));

	}
}

function* userActivateCommonSaga() {
	yield takeEvery(USER_ACTIVATE, userActivateSaga);
}

export default userActivateCommonSaga;
