import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';
import * as tableUtils from 'helpers/tableUtils';

import * as PaymentStatus from '../enums/PaymentStatus';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountId = {
	dataField: 'bankAccountId',
	text: 'Bank account ID',
	filter: textFilter(),
	sort: true
};
export const bankAccountNo = {
	dataField: 'bankAccountNo',
	text: 'Bank account No.',
	filter: textFilter(),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const totalAmount = {
	dataField: 'totalAmount',
	text: 'Total amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const cm20PaymentBulks = {
	dataField: 'cm20PaymentBulks',
	text: 'Payment bulks',
	filter: textFilter(),
	sort: true
};
export const cm20PaymentBulkCount = {
	dataField: 'cm20PaymentBulkCount',
	text: 'Payments',
	filter: textFilter(),
	sort: true
};
export const filename = {
	dataField: 'filename',
	text: 'Filename',
	filter: textFilter(),
	sort: true
};
export const signingStatusSummary = {
	dataField: 'signedFileData.signingStatusSummary',
	text: 'Signing status',
	filter: textFilter(),
	sort: true
};
export const createdDatetime = {
	dataField: 'createdDatetime',
	text: 'Created date/time',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatLocalDateTime(cell),
	sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const updatedDatetime = {
	dataField: 'updatedDatetime',
	text: 'Updated date/time',
	filter: textFilter(),
	formatter: (cell) => formatUtils.formatLocalDateTime(cell),
	sortFunc: (a, b, order) => tableUtils.sortFuncForLocalDateTime(a, b, order),
	sort: true
};
export const paymentStatus = {
	dataField: 'paymentStatus',
	text: 'Payment status',
	formatter: (cell) => formatUtils.formatPaymentStatus(cell),
	filter: selectFilter({
		options: PaymentStatus.PaymentStatusAsSelectFilterOptions
	}),
	sort: true
};
export const externalIdBank = {
	dataField: 'externalIdBank',
	text: 'External id bank',
	filter: textFilter(),
	sort: true
};
export const bankStatusMessage = {
	dataField: 'bankStatusMessage',
	text: 'Bank status message',
	filter: textFilter(),
	sort: true
};
