export const setTagShortTitles = (tags) => {
	const updatedTags = [];
	tags.map(tag => {
		const tagTitleComponents = tag.title.split("/");
		if (tagTitleComponents.length > 1) {
			let shortTitle = tagTitleComponents[tagTitleComponents.length - 1];
			let found = false;
			let i = tagTitleComponents.length - 2;
			tags.map(tag2 => {
				if (tag2.id !== tag.id && (tag2.title === shortTitle || tag2.title.endsWith("/" + shortTitle))) {
					found = true;
				}
			});
			while (found && i >= 0) {
				shortTitle = tagTitleComponents[i] + "/" + shortTitle;
				--i;
				found = false;
				tags.map(tag2 => {
					if (tag2.id !== tag.id && (tag2.title === shortTitle || tag2.title.endsWith("/" + shortTitle))) {
						found = true;
					}
				});
			}
			updatedTags.push({
				...tag,
				shortTitle: shortTitle
			});
		} else {
			updatedTags.push({
				...tag,
				shortTitle: tag.title
			});
		}
	});
	return updatedTags;
}

