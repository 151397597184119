import * as actionTypes from "./actionTypes";
import * as actionTypesTask from "../Task/actionTypes";
import * as taskHelper from "../../helpers/taskHelper";

const initialState = {
	error: null,
	loading: false,
	dashboardData: null,
}

export default function dashboardDataReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.DASHBOARD_DATA_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.DASHBOARD_DATA_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				dashboardData: action.dashboardData
			}
		case actionTypes.DASHBOARD_DATA_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case actionTypesTask.TASK_CREATE_SUCCESS:
			const tasksRecentExceptUpdated = state.dashboardData.tasksRecent.filter(task => task.id !== action.task.id);
			return {
				...state,
				dashboardData: {
					...state.dashboardData,
					tasksDueThisMonth: action.task.taskStatus === "ACTIVE"
						? taskHelper.updateTasks(state.dashboardData.tasksDueThisMonth, action.task)
						: taskHelper.deleteTask(state.dashboardData.tasksDueThisMonth, action.task.id),
					tasksStarred: action.task.taskStatus === "ACTIVE" && action.task.isStarred
						? taskHelper.updateTasks(state.dashboardData.tasksStarred, action.task)
						: taskHelper.deleteTask(state.dashboardData.tasksStarred, action.task.id),
					tasksRecent: [
						action.task,
						...tasksRecentExceptUpdated
					]
				}
			}
		case actionTypesTask.TASK_DELETE_SUCCESS:
			return {
				...state,
				dashboardData: {
					...state.dashboardData,
					tasksDueThisMonth: taskHelper.deleteTask(state.dashboardData.tasksDueThisMonth, action.taskId),
					tasksStarred: taskHelper.deleteTask(state.dashboardData.tasksStarred, action.taskId),
					tasksRecent: taskHelper.deleteTask(state.dashboardData.tasksRecent, action.taskId),
				}
			}
		default:
			return state;
	}
}