import { call, put, takeEvery } from 'redux-saga/effects';

import * as actions from "./actions";
import * as rest from "../../helpers/restHelper";
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as actionTypes from './actionTypes';

function* getAllAvailableModulesSaga() {
	yield put(actions.availableModuleGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.AVAILABLE_MODULES_GET_ALL
		);

		const AvailableModules = [];
		for (let key in response.data) {
			AvailableModules.push({
				...response.data[key]
			});
		}

		yield put(actions.availableModuleGetSuccess(AvailableModules));
	} catch (e) {
		yield put(actions.availableModuleGetError(e));
	}
}


function* availableModuleSaga() {
	yield (takeEvery(actionTypes.AVAILABLE_MODULE_GET_ALL, getAllAvailableModulesSaga));
}

export default availableModuleSaga;