import * as actionTypes from "./actionTypes";

export const crmMaintenanceNoticeGetPending = () => {
	return {
		type: actionTypes.CRM_MAINTENANCE_NOTICE_GET_PENDING
	}
}

export const crmMaintenanceNoticeGetInProcess = () => {
	return {
		type: actionTypes.CRM_MAINTENANCE_NOTICE_GET_IN_PROCESS
	}
}

export const crmMaintenanceNoticeGetError = (thisError) => {
	return {
		type: actionTypes.CRM_MAINTENANCE_NOTICE_GET_ERROR,
		error: thisError
	}
}

export const crmMaintenanceNoticeGetSuccess = (data) => {
	return {
		type: actionTypes.CRM_MAINTENANCE_NOTICE_GET_SUCCESS,
		crmMaintenanceNotices: data
	}
}
