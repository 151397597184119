export const TENANT_ADMIN_CREATE = "TENANT_ADMIN_CREATE";
export const TENANT_ADMIN_CREATE_ERROR = "TENANT_ADMIN_CREATE_ERROR";
export const TENANT_ADMIN_CREATE_SUCCESS = "TENANT_ADMIN_CREATE_SUCCESS";

export const TENANT_GET_ALL = "TENANT_GET_ALL";
export const TENANT_GET_BY_ID = "TENANT_GET_BY_ID";
export const TENANT_GET_ERROR = "TENANT_GET_ERROR";
export const TENANT_GET_SUCCESS = "TENANT_GET_SUCCESS";

export const TENANT_CREATE = "TENANT_CREATE";
export const TENANT_CREATE_ERROR = "TENANT_CREATE_ERROR";
export const TENANT_CREATE_SUCCESS = "TENANT_CREATE_SUCCESS";

export const TENANT_DELETE = "TENANT_DELETE";

