import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionsSupplierInvoice from '../SupplierInvoice/actions';
import * as actionTypes from './actionTypes';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* getAllSuppliersSaga() {
	yield put(actions.supplierGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_GET_ALL
		);
		yield put(actions.supplierGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierGetError(e));
	}
}

function* getSupplierByIdSaga({ id }) {
	yield put(actions.supplierGetInProcess());
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_GET_BY_ID.replace("{supplierId}", id)
		);
		yield put(actions.supplierGetSuccess([{ ...response.data }]));
	} catch (e) {
		yield put(actions.supplierGetError(e));
	}
}

function* createSupplierSaga({ supplier, history }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_CREATE_OR_UPDATE,
			supplier
		);
		history.push(endpointsFrontend.SUPPLIER_BROWSE);
	} catch (e) {
		yield put(actions.supplierCreateError(e));
	}
}

function* createSupplierWithBankAccountsSaga({ supplier }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.SUPPLIER_CREATE_OR_UPDATE_WITH_BANK_ACCOUNTS,
			supplier
		);
		yield put(actions.supplierCreateSuccess(response.data));
	} catch (e) {
		yield put(actions.supplierCreateError(e));
	}
}

function* createSupplierAndMatchInvoiceCandidatesSaga({ supplier }) {
	try {
		yield call(
			rest.post,
			endpointsBackend.SUPPLIER_CREATE_OR_UPDATE_WITH_BANK_ACCOUNTS,
			supplier
		);
		const response = yield call(
			rest.get,
			endpointsBackend.SUPPLIER_INVOICE_CANDIDATE_REMATCH
		);
		yield put(actionsSupplierInvoice.supplierInvoiceCandidateMatchSuccess(response.data));
	} catch (e) {
		yield put(actionsSupplierInvoice.supplierInvoiceCandidateMatchError(e));
	}
}

function* deleteSupplierSaga({ id, history }) {
	try {
		const response = yield call(
			rest.del,
			endpointsBackend.SUPPLIER_GET_BY_ID.replace("{supplierId}", id)
		);
		// history.push(endpointsFrontend.SUPPLIER_BROWSE);
		yield put(actions.supplierDeleteSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.supplierDeleteError(e));
	}
}

function* supplierSaga() {
	yield (takeLatest(actionTypes.SUPPLIER_GET_ALL, getAllSuppliersSaga));
	yield (takeLatest(actionTypes.SUPPLIER_GET_BY_ID, getSupplierByIdSaga));
	yield (takeLatest(actionTypes.SUPPLIER_CREATE, createSupplierSaga));
	yield (takeLatest(actionTypes.SUPPLIER_CREATE_WITH_BANK_ACCOUNTS, createSupplierWithBankAccountsSaga));
	yield (takeLatest(actionTypes.SUPPLIER_CREATE_AND_MATCH_INVOICE_CANDIDATES, createSupplierAndMatchInvoiceCandidatesSaga));
	yield (takeLatest(actionTypes.SUPPLIER_DELETE, deleteSupplierSaga));
}

export default supplierSaga;