import * as actionTypes from "./actionTypes";

export const cm20PaymentGetAll = () => {
	return {
		type: actionTypes.CM20PAYMENT_GET_ALL
	}
}

export const cm20PaymentGetErroneous = () => {
	return {
		type: actionTypes.CM20PAYMENT_GET_ERRONEOUS
	}
}

export const cm20PaymentGetSigned = () => {
	return {
		type: actionTypes.CM20PAYMENT_GET_SIGNED
	}
}

export const cm20PaymentGetUnsigned = () => {
	return {
		type: actionTypes.CM20PAYMENT_GET_UNSIGNED
	}
}

export const cm20PaymentGetById = (id) => {
	return {
		type: actionTypes.CM20PAYMENT_GET_BY_ID,
		id: id
	}
}

export const cm20PaymentGetInProcess = () => {
	return {
		type: actionTypes.CM20PAYMENT_GET_IN_PROCESS
	}
}

export const cm20PaymentGetError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_GET_ERROR,
		error: thisError
	}
}

export const cm20PaymentGetSuccess = (data) => {
	return {
		type: actionTypes.CM20PAYMENT_GET_SUCCESS,
		cm20Payments: data
	}
}

export const cm20PaymentCreate = (cm20Payment, history) => {
	return {
		type: actionTypes.CM20PAYMENT_CREATE,
		cm20Payment: cm20Payment, 
		history: history
	}
}

export const cm20PaymentCreateError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_CREATE_ERROR,
		error: thisError
	}
}

export const cm20PaymentDelete = (id, history) => {
	return {
		type: actionTypes.CM20PAYMENT_DELETE,
		id: id,
		history: history
	}
}

export const cm20PaymentSignGetCertificate = () => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_GET_CERTIFICATE
	}
}

export const cm20PaymentSignInit = (cm20PaymentsToSign, signingCertificateObject, history) => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_INIT,
		cm20PaymentsToSign: cm20PaymentsToSign,
		signingCertificateObject: signingCertificateObject,
		history: history
	}
}

export const cm20PaymentSignError = (thisError) => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_ERROR,
		error: thisError
	}
}

export const cm20PaymentSignReset = (cm20PaymentsToReset, history) => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_RESET,
		cm20PaymentsToReset: cm20PaymentsToReset,
		history: history
	}
}

export const cm20PaymentSignResetSuccess = (cm20Payments) => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_RESET_SUCCESS,
		cm20Payments: cm20Payments
	}
}

export const cm20PaymentSignSuccess = (cm20Payments) => {
	return {
		type: actionTypes.CM20PAYMENT_SIGN_SUCCESS,
		cm20Payments: cm20Payments
	}
}
