import * as actionTypes from "./actionTypes";

const initialState = {
	counters: null,
	value: null,
	loading: false,
	uploading: false,
	error: null
}

const updateCounters = (state, newOrUpdatedCounter) => {
	const counters = [...state.counters];
	let updated = false;
	for (let i = 0; i < counters.length; ++i) {
		if (counters[i].id === newOrUpdatedCounter.id) {
			counters[i] = newOrUpdatedCounter;
			updated = true;
			break;
		}
	}
	if (!updated) {
		counters.push(newOrUpdatedCounter);
	}
	return counters;
}

export default function counterReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.COUNTER_CREATE_SUCCESS:
			return {
				...state,
				counters: updateCounters(state, action.Counter)
			}
		case actionTypes.COUNTER_DELETE_SUCCESS:
			return {
				...state,
				counters: action.counters
			}
		case actionTypes.COUNTER_GET_ALL:
		case actionTypes.COUNTER_GET_AND_UPDATE:
		case actionTypes.COUNTER_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.COUNTER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				counters: action.Counters
			}
		case actionTypes.COUNTER_GET_VALUE_SUCCESS:
			return {
				...state,
				loading: false,
				value: action.value
			}
		case actionTypes.COUNTER_GET_ERROR:
		case actionTypes.COUNTER_GET_VALUE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.COUNTER_UPLOAD:
			return {
				...state,
				uploading: true,
				error: null
			}
		case actionTypes.COUNTER_UPLOAD_ERROR:
			return {
				...state,
				uploading: false,
				error: action.error
			}
		case actionTypes.COUNTER_UPLOAD_SUCCESS:
			return {
				...state,
				uploading: false
			}
		default:
			return state;
	}
}