import { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import { CounterType } from "definitions/enums/CounterType";
import * as formatUtils from "helpers/formatUtils";

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const currentValue = {
	dataField: 'currentValue',
	text: 'Current value',
	filter: textFilter(),
	sort: true,
	formatter: (cell, row) => row.prefix + cell + row.postfix
};
export const prefix = {
	dataField: 'prefix',
	text: 'Prefix',
	filter: textFilter(),
	sort: true
};
export const postfix = {
	dataField: 'postfix',
	text: 'Postfix',
	filter: textFilter(),
	sort: true
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: textFilter(),
	sort: true,
	formatter: (cell) => formatUtils.formatCounterType(cell)
};
