import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

import * as actions from '../../store/Task/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as columnsTask from '../../definitions/columns/task';
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import EditTask from "components/Pages/EditTask";

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class TaskBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			selectedTask: null,
			selectedRows: [],
			showEditTaskForm: false
		}
	}

	componentDidMount() {
		this.props.onGetTasks();
	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	render() {

		const pageTitle = "Tasks | " + config.AppName;
		const breadcrumbsTitle = "Task";
		const breadcrumbsItem = "Browse tasks";

		const EditTaskFormAsVar =
			<EditTask
				onClose={() => this.setState({ showEditTaskForm: false })}
				task={this.state.selectedTask}
			/>

		const columns = [
			columnsTask.id,
			columnsTask.title,
			columnsTask.tags,
			columnsTask.taskStatus,
			columnsTask.assignedToUserAccountUsername,
			columnsTask.startDate,
			columnsTask.dueDate
		];

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				if (row.id) {
					this.setState({
						selectedTask: row,
						showEditTaskForm: true
					});
				}
			}
		};

		const tableAndControls =
			<div className="mt-3">
				<BootstrapTable
					keyField='id'
					data={this.props.tasks}
					columns={columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
				/>
				<Button
					color="primary"
					onClick={() => this.setState({
						selectedTask: null,
						showEditTaskForm: true
					})}
				>
					New
				</Button>

			</div>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>

					{this.state.showEditTaskForm ? EditTaskFormAsVar : null}

					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.tasks ?
											tableAndControls
											: null}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment >
		);

	}
}

const mapStateToProps = ({ task }) => ({
	tasks: task.tasks,
	loading: task.loading,
	error: task.error
})

const mapDispatchToProps = dispatch => ({
	onGetTasks: () => dispatch(actions.taskGetAll())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TaskBrowse));
