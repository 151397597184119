// Docs: https://lexical.dev/
import { useState } from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes, $getSelection } from 'lexical';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import EditorTheme from "./LexicalEditorTheme";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import TreeViewPlugin from "./plugins/TreeViewPlugin";

import './LexicalEditor.css';

function Placeholder(props) {
	return <div className="editor-placeholder">{props.placeholderText}</div>;
}

const editorConfig = {
	// The editor theme
	theme: EditorTheme,
	// Handling of errors during update
	onError(error) {
		throw error;
	},
	// Any custom nodes go here
	nodes: [
		HeadingNode,
		ListNode,
		ListItemNode,
		QuoteNode,
		CodeNode,
		CodeHighlightNode,
		TableNode,
		TableCellNode,
		TableRowNode,
		AutoLinkNode,
		LinkNode
	]
};

// function SerializeButton() {
// 	const [editor] = useLexicalComposerContext();
// 	return (
// 		<button
// 			type="button"
// 			onClick={() => {
// 				//const jsonString = JSON.stringify(editor.getEditorState().toJSON());
// 				editor.update(() => {
// 					const htmlString = $generateHtmlFromNodes(editor, null);	// As of Lexical v0.6.5, only possible to call $generateHtmlFromNodes from within a hook such as editor.update()
// 					console.log(htmlString)
// 				});
// 			}}
// 		>
// 			Serialize
// 		</button>
// 	)
// }

// function DeserializeButton(props) {
// 	const [editor] = useLexicalComposerContext();
// 	return (
// 		<button
// 			type="button"
// 			onClick={() => {
// 				//const jsonString = JSON.stringify(editor.getEditorState().toJSON());
// 				editor.update(() => {
// 					console.log(props)

// 					const parser = new DOMParser();
// 					const dom = parser.parseFromString(props.contentsAsHTML, "text/html");
// 					const nodes = $generateNodesFromDOM(editor, dom);

// 					// Select the root
// 					$getRoot().select();

// 					const selection = $getSelection();
// 					selection.insertNodes(nodes);
// 				});
// 			}}
// 		>
// 			Deserialize
// 		</button>
// 	)
// }

export default function LexicalEditor(props) {

	let [didDeserialize, setDidDeserialize] = useState(false);	// Needed to prevent the infinite loop onChange() => DeserializePlugin() => onChange() => ...

	function DeserializePlugin(props) {
		if (!didDeserialize) {
			const [editor] = useLexicalComposerContext();
			if (props.contentsAsJSON) {
				editor.setEditorState(editor.parseEditorState(props.contentsAsJSON));

				// Calling setDidDeserialize() only when either contentsAsJSON or contentsAsHTML are non-empty, because DeserializePlugin() is called several times upon component load, first couple times with empty props
				setDidDeserialize(true);
			}
			if (props.contentsAsHTML) {
				editor.update(() => {
					const parser = new DOMParser();
					const dom = parser.parseFromString(props.contentsAsHTML, "text/html");
					const nodes = $generateNodesFromDOM(editor, dom);

					// Select the root
					$getRoot().select();


					const selection = $getSelection();
					selection.insertNodes(nodes);
					/*
					try {
						$insertNodes(nodes);
					} catch (e) {
						// As of v0.6.5, sometimes $insertNodes fails with "Only element or decorator nodes can be inserted in to the root node".
						// As of 2022.12.09, the error cannot be found in the Internet.
						// A sample contentsAsHTML which causes the error:
						// <a href="#">a</a><br />
						// The contents of the <a> tag are not important.
						// Rearranging the tags, or surrounding them with different elements sometimes helps avoid the error.
						// When the error occurs, usually (or always?) it can be seen that this code is called twice, 
						// while it is only called once with those contentsAsHTML values which do not cause the error.
						// Try/catching the error seems to be an effective solution as of 2022.12.09.
					}
					*/

					// Calling setDidDeserialize() only when either contentsAsJSON or contentsAsHTML are non-empty, because DeserializePlugin() is called several times upon component load, first couple times with empty props
					setDidDeserialize(true);
				});
			}
		}
		return null;
	}

	const onChange = (editor, propsOnChange, propsId) => {
		editor.update(() => {
			const htmlString = $generateHtmlFromNodes(editor, null);
			const e = {
				target: {
					name: propsId,
					type: "crm-lexical-editor",
					value: htmlString
				}
			}
			propsOnChange(e);
		});
	}

	return (
		<div>
			<LexicalComposer initialConfig={editorConfig}>
				<div className="editor-container">
					{/*<SerializeButton />*/}
					{<DeserializePlugin
						contentsAsHTML={props.contentsAsHTML}
					/>}
					{/* <DeserializeButton
						contentsAsHTML={props.contentsAsHTML}
					/> */}
					<ToolbarPlugin />
					<div className="editor-inner">
						<RichTextPlugin
							contentEditable={<ContentEditable className="editor-input" />}
							placeholder={<Placeholder placeholderText={props.placeholderText} />}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<HistoryPlugin />
						{/*<TreeViewPlugin />*/}
						<AutoFocusPlugin />
						<CodeHighlightPlugin />
						<ListPlugin />
						<LinkPlugin />
						<AutoLinkPlugin />
						<OnChangePlugin
							onChange={(editorState, editor) => {
								onChange(editor, props.onChange, props.id);
							}}
							ignoreSelectionChange
						//ignoreHistoryMergeTagChange
						/>
						<ListMaxIndentLevelPlugin maxDepth={7} />
						<MarkdownShortcutPlugin transformers={TRANSFORMERS} />
					</div>
				</div>
			</LexicalComposer>
		</div>
	);
}
