import * as actionTypes from "./actionTypes";
import * as actionTypesSupplier from "../Supplier/actionTypes";

const initialState = {
	converting: false,
	deleting: false,
	error: null,
	loading: false,
	loadingTotals: false,
	matching: false,
	saving: false,
	supplierInvoices: null,
	supplierInvoiceCandidates: null,
	totals: null
}

export default function supplierInvoiceReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SUPPLIER_INVOICE_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.SUPPLIER_INVOICE_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.SUPPLIER_INVOICE_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saving: false
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE:
			return {
				...state,
				deleting: true,
				error: null,
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
			}
		case actionTypes.SUPPLIER_INVOICE_GET_ALL:
		case actionTypes.SUPPLIER_INVOICE_GET_UNPAID:
		case actionTypes.SUPPLIER_INVOICE_GET_UNALLOCATED:
		case actionTypes.SUPPLIER_INVOICE_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.SUPPLIER_INVOICE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				supplierInvoices: action.supplierInvoices
			}
		case actionTypes.SUPPLIER_INVOICE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_GET_TOTALS_FOR_UNALLOCATED:
			return {
				...state,
				loadingTotals: true,
				totals: null
			}
		case actionTypes.SUPPLIER_INVOICE_GET_TOTALS_SUCCESS:
			return {
				...state,
				loadingTotals: false,
				totals: action.totals
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT:
			return {
				...state,
				converting: true,
				error: null
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT_ERROR:
			return {
				...state,
				converting: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_CONVERT_SUCCESS:
			return {
				...state,
				converting: false,
				error: null
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
				supplierInvoiceCandidates: action.supplierInvoiceCandidates
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_ALL:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true,
				saveSuccess: false
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_SUCCESS:
			return {
				...state,
				error: null,
				loading: false,
				supplierInvoiceCandidates: action.supplierInvoiceCandidates
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH:
			return {
				...state,
				error: null,
				matching: true
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH_ERROR:
			return {
				...state,
				error: action.error,
				matching: false
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_MATCH_SUCCESS:
			return {
				...state,
				error: null,
				matching: false,
				supplierInvoiceCandidates: action.data
			}
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE:
		case actionTypes.SUPPLIER_INVOICE_CANDIDATE_UPDATE_SUPPLIER:
			return {
				...state,
				error: null,
				matching: true
			}
		case actionTypesSupplier.SUPPLIER_CREATE_AND_MATCH_INVOICE_CANDIDATES:
			return {
				...state,
				matching: true
			}
		default:
			return state;
	}
}