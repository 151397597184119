import axios from "axios";

import addTenantAndCompanyToUrl from "./addTenantAndCompanyToUrl";
import timerService from "sessionTimerService";

const axiosApi = axios.create({
	baseURL: "https://app.cashmanager.lv:8443",
	// baseURL: "https://app.garciems.lv:8443",
	withCredentials: true
});


export async function get(url, config = {}) {
	timerService.resetTimer();
	return await axiosApi
		.get(
			addTenantAndCompanyToUrl(url),
			{ ...config }
		)
		.then(
			response => response
		);
}

export async function post(url, data, config = {}) {
	timerService.resetTimer();
	return axiosApi
		.post(
			addTenantAndCompanyToUrl(url),
			{ ...data },
			{ ...config }
		)
		.then(
			response => response
		);
}

export async function put(url, data, config = {}) {
	timerService.resetTimer();
	return axiosApi
		.put(
			addTenantAndCompanyToUrl(url),
			{ ...data },
			{ ...config }
		)
		.then(
			response => response
		);
}

export async function del(url, data, config = {}) {
	timerService.resetTimer();
	return await axiosApi
		.delete(
			addTenantAndCompanyToUrl(url),
			{ 
				data: { ...data },
				...config 
			}
		)
		.then(
			response => response
		);
}
