import * as actionTypes from "./actionTypes";

const initialState = {
    deleting: false,
    error: null,
    loading: false,
    saving: false,
    vatRates: null
}

export default function vatRateReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.VAT_RATE_CREATE:
            return {
                ...state,
                error: null,
                saving: true
            }
        case actionTypes.VAT_RATE_CREATE_ERROR:
            return {
                ...state,
                error: action.error,
                saving: false,
            }
        case actionTypes.VAT_RATE_CREATE_SUCCESS:
            return {
                ...state,
                error: null,
                saving: false,
            }
        case actionTypes.VAT_RATE_DELETE:
            return {
                ...state,
                deleting: true,
                error: null
            }
        case actionTypes.VAT_RATE_DELETE_ERROR:
            return {
                ...state,
                deleting: false,
                error: action.error,
            }
        case actionTypes.VAT_RATE_DELETE_SUCCESS:
            return {
                ...state,
                deleting: false,
                error: null
            }
        case actionTypes.VAT_RATE_GET_ALL:
            return {
                ...state,
                error: null,
                loading: true
            }
        case actionTypes.VAT_RATE_GET_BY_ID:
            return {
                ...state,
                error: null,
                loading: true
            }
        case actionTypes.VAT_RATE_GET_ERROR:
            return {
                ...state,
                error: action.error,
                loading: false,
            }
        case actionTypes.VAT_RATE_GET_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                vatRates: action.vatRates
            }
        default:
            return state;
    }
}