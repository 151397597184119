import * as actionTypes from "./actionTypes";

export const cashFlowGetAll = () => {
	return {
		type: actionTypes.CASH_FLOW_GET_ALL
	}
}

export const cashFlowGetError = (thisError) => {
	return {
		type: actionTypes.CASH_FLOW_GET_ERROR,
		error: thisError
	}
}

export const cashFlowGetSuccess = (data) => {
	return {
		type: actionTypes.CASH_FLOW_GET_SUCCESS,
		cashFlow: data
	}
}

