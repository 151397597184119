export const VAT_RATE_GET_ALL = "VAT_RATE_GET_ALL";
export const VAT_RATE_GET_BY_ID = "VAT_RATE_GET_BY_ID";
export const VAT_RATE_GET_ERROR = "VAT_RATE_GET_ERROR";
export const VAT_RATE_GET_SUCCESS = "VAT_RATE_GET_SUCCESS";

export const VAT_RATE_CREATE = "VAT_RATE_CREATE";
export const VAT_RATE_CREATE_ERROR = "VAT_RATE_CREATE_ERROR";
export const VAT_RATE_CREATE_SUCCESS = "VAT_RATE_CREATE_SUCCESS";

export const VAT_RATE_DELETE = "VAT_RATE_DELETE";
export const VAT_RATE_DELETE_ERROR = "VAT_RATE_DELETE_ERROR";
export const VAT_RATE_DELETE_SUCCESS = "VAT_RATE_DELETE_SUCCESS";

