import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";

import * as actionsCurrency from '../../store/Currency/actions';
import * as actionsSupplierInvoice from '../../store/SupplierInvoice/actions';
import * as actionsSupplier from '../../store/Supplier/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as browseFormControls from '../../helpers/browseFormControls';
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as columnsSupplierInvoice from '../../definitions/columns/supplierInvoice';
import * as config from '../../config';
import * as editFormControls from "../../helpers/editFormControls";
import * as inputSelectUtils from "../../helpers/inputSelectUtils";
import * as tableUtils from '../../helpers/tableUtils';

class SupplierInvoiceEdit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: "",
			supplierId: "",
			invoiceNo: "",
			invoiceNoAlt: "",
			externalId: "",
			externalDocumentType: "",
			externalDocumentNo: "",
			invoiceDate: "",
			dueDate: "",
			bookEntryDate: "",
			invoiceAmount: 0,
			paidAmountOutsideCRM: 0,
			vatAmount: 0,
			withholdAmount: 0,
			currencyId: "",
			discountPercent1: 0,
			discountPercent2: 0,
			discountPercent3: 0,
			discountDueDate1: "",
			discountDueDate2: "",
			discountDueDate3: "",
			customExchangeRate: 0,
			reference: "",
			blocked: "",
			dimension1: "",
			dimension2: "",
			dimension3: "",
			category: "",
			type: "",

			vatRate: 0,
			invoiceAmountWithoutVAT: 0,

			showColumnSelectionForm: false,

			supplierInvoices: [],
			errors: {},
			showPaidAmountOutsideCRM: false,
			useCashDiscounts: false,
			useCustomExchangeRate: false,
			useWithholdAmount: false,
			showERPRelatedFields: false
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	setVatRateAndInvoiceAmount = (invoiceAmountWithoutVAT, vatAmount) => {
		invoiceAmountWithoutVAT = parseFloat(invoiceAmountWithoutVAT);
		vatAmount = parseFloat(vatAmount);
		this.setState({
			vatRate: (invoiceAmountWithoutVAT == 0 ? 0 : Math.round(vatAmount / invoiceAmountWithoutVAT * 10000) / 100),
			invoiceAmount: Math.round((invoiceAmountWithoutVAT + vatAmount) * 100) / 100
		});
	}

	setVatAmountAndInvoiceAmount = (invoiceAmountWithoutVAT, vatRate) => {
		invoiceAmountWithoutVAT = parseFloat(invoiceAmountWithoutVAT);
		vatRate = parseFloat(vatRate);
		const vatAmount = Math.round(invoiceAmountWithoutVAT * vatRate) / 100;
		this.setState({
			vatAmount: vatAmount,
			invoiceAmount: Math.round((invoiceAmountWithoutVAT + vatAmount) * 100) / 100
		});
	}

	setVatRateAndVatAmount = (invoiceAmountWithoutVAT, invoiceAmount) => {
		invoiceAmountWithoutVAT = parseFloat(invoiceAmountWithoutVAT);
		invoiceAmount = parseFloat(invoiceAmount);
		const vatAmount = Math.round((invoiceAmount - invoiceAmountWithoutVAT) * 100) / 100;
		this.setState({
			vatRate: Math.round(vatAmount / invoiceAmountWithoutVAT * 10000) / 100,
			vatAmount: vatAmount
		});
	}

	onChange(e) {
		const thisObjectName = e.target.name;
		let thisValue;
		if (e.target.type === "checkbox") {
			thisValue = e.target.checked;
		} else {
			thisValue = e.target.value;
		}
		if (thisObjectName === "invoiceAmountWithoutVAT") {
			this.setVatRateAndInvoiceAmount(thisValue, this.state.vatAmount);
		} else if (thisObjectName === "vatRate") {
			this.setVatAmountAndInvoiceAmount(this.state.invoiceAmountWithoutVAT, thisValue);
		} else if (thisObjectName === "vatAmount") {
			this.setVatRateAndInvoiceAmount(this.state.invoiceAmountWithoutVAT, thisValue);
		} else if (thisObjectName === "invoiceAmount") {
			this.setVatRateAndVatAmount(this.state.invoiceAmountWithoutVAT, thisValue);
		} else if (thisObjectName === "showPaidAmountOutsideCRM") {
			this.setState({
				paidAmountOutsideCRM: (thisValue ? this.state.invoiceAmount : 0)
			})
		}
		this.setState({
			[thisObjectName]: thisValue
		});
	}

	onSubmit(e) {
		e.preventDefault();		// prevent the form from refreshing
		let newOrUpdatedSupplierInvoice = {
			supplierId: this.state.supplierId,
			invoiceNo: this.state.invoiceNo,
			invoiceNoAlt: this.state.invoiceNoAlt,
			externalId: this.state.externalId,
			externalDocumentType: this.state.externalDocumentType,
			externalDocumentNo: this.state.externalDocumentNo,
			invoiceDate: this.state.invoiceDate,
			dueDate: this.state.dueDate,
			bookEntryDate: this.state.bookEntryDate,
			invoiceAmount: this.state.invoiceAmount,
			paidAmountOutsideCRM: this.state.paidAmountOutsideCRM,
			vatAmount: this.state.vatAmount,
			withholdAmount: this.state.withholdAmount,
			currencyId: this.state.currencyId,
			discountPercent1: this.state.discountPercent1,
			discountPercent2: this.state.discountPercent2,
			discountPercent3: this.state.discountPercent3,
			discountDueDate1: this.state.discountDueDate1,
			discountDueDate2: this.state.discountDueDate2,
			discountDueDate3: this.state.discountDueDate3,
			customExchangeRate: this.state.customExchangeRate,
			reference: this.state.reference,
			blocked: this.state.blocked,
			dimension1: this.state.dimension1,
			dimension2: this.state.dimension2,
			dimension3: this.state.dimension3,
			category: this.state.category,
			type: this.state.type,
		}
		if (this.state.id) {
			newOrUpdatedSupplierInvoice = {
				id: this.state.id,
				...newOrUpdatedSupplierInvoice
			}
		}
		this.props.onCreateSupplierInvoice(newOrUpdatedSupplierInvoice, this.props.history);
	}

	onDeleteSupplierInvoice = () => {
		if (window.confirm("Are you sure you want to delete this supplier invoice?")) {
			this.props.onDeleteSupplierInvoice(this.state.id, this.props.history);
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		if (id) {
			this.props.onGetSupplierInvoiceById(id);
		}
		this.props.onGetCurrencies();
		this.props.onGetSuppliers();

		const defaultColumnSetup = "showPaidAmountOutsideCRM";
		const pathnameWithoutId = this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf("/"));
		this.setState({
			columns: tableUtils.getSavedColumnSetup(columnsSupplierInvoice, pathnameWithoutId, defaultColumnSetup)
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {

		if (prevProps.error !== this.props.error) {
			if (this.props.error) {
				window.scrollBy(0, -document.body.scrollHeight);
				this.setState({
					errors: this.props.error
				});
			} else {
				this.setState({
					errors: ""
				})
			}
		}

		if (prevProps.currencies !== this.props.currencies) {
			if (!this.state.currencyId && this.props.currencies[0]) {
				this.setState({
					currencyId: this.props.currencies[0].id
				})
			}
		}

		if (prevProps.suppliers !== this.props.suppliers) {
			if (!this.state.supplierId && this.props.suppliers[0]) {
				this.setState({
					supplierId: this.props.suppliers[0].id
				})
			}
		}

		if (prevProps.supplierInvoices !== this.props.supplierInvoices) {
			if (this.props.supplierInvoices && this.props.supplierInvoices[0]) {

				const thisSupplierInvoice = this.props.supplierInvoices[0];

				this.setState({
					id: thisSupplierInvoice.id,
					supplierId: thisSupplierInvoice.supplierId,
					invoiceNo: thisSupplierInvoice.invoiceNo,
					invoiceNoAlt: thisSupplierInvoice.invoiceNoAlt,
					externalId: thisSupplierInvoice.externalId,
					externalDocumentType: thisSupplierInvoice.externalDocumentType,
					externalDocumentNo: thisSupplierInvoice.externalDocumentNo,
					invoiceDate: thisSupplierInvoice.invoiceDate,
					dueDate: thisSupplierInvoice.dueDate,
					bookEntryDate: thisSupplierInvoice.bookEntryDate,
					invoiceAmount: thisSupplierInvoice.invoiceAmount,
					paidAmountOutsideCRM: thisSupplierInvoice.paidAmountOutsideCRM,
					vatAmount: thisSupplierInvoice.vatAmount,
					withholdAmount: thisSupplierInvoice.withholdAmount,
					currencyId: thisSupplierInvoice.currencyId,
					discountPercent1: thisSupplierInvoice.discountPercent1,
					discountPercent2: thisSupplierInvoice.discountPercent2,
					discountPercent3: thisSupplierInvoice.discountPercent3,
					discountDueDate1: thisSupplierInvoice.discountDueDate1,
					discountDueDate2: thisSupplierInvoice.discountDueDate2,
					discountDueDate3: thisSupplierInvoice.discountDueDate3,
					customExchangeRate: thisSupplierInvoice.customExchangeRate,
					reference: thisSupplierInvoice.reference,
					blocked: thisSupplierInvoice.blocked,
					dimension1: thisSupplierInvoice.dimension1,
					dimension2: thisSupplierInvoice.dimension2,
					dimension3: thisSupplierInvoice.dimension3,
					category: thisSupplierInvoice.category,
					type: thisSupplierInvoice.type,

					showPaidAmountOutsideCRM: thisSupplierInvoice.paidAmountOutsideCRM,
					useWithholdAmount: thisSupplierInvoice.withholdAmount,

					vatRate: (thisSupplierInvoice.invoiceAmount - thisSupplierInvoice.vatAmount == 0 ? 0 : Math.round(thisSupplierInvoice.vatAmount / (thisSupplierInvoice.invoiceAmount - thisSupplierInvoice.vatAmount) * 10000) / 100),
					invoiceAmountWithoutVAT: thisSupplierInvoice.invoiceAmount - thisSupplierInvoice.vatAmount
				});
			} else {
				this.setState({
					id: "",
					supplierId: "",
					invoiceNo: "",
					invoiceNoAlt: "",
					externalId: "",
					externalDocumentType: "",
					externalDocumentNo: "",
					invoiceDate: "",
					dueDate: "",
					bookEntryDate: "",
					invoiceAmount: "",
					paidAmountOutsideCRM: "",
					vatAmount: "",
					withholdAmount: "",
					currencyId: "",
					discountPercent1: "",
					discountPercent2: "",
					discountPercent3: "",
					discountDueDate1: "",
					discountDueDate2: "",
					discountDueDate3: "",
					customExchangeRate: "",
					reference: "",
					blocked: "",
					dimension1: "",
					dimension2: "",
					dimension3: "",
					category: "",
					type: "",

					vatRate: 0,
					invoiceAmountWithoutVAT: 0
				});
			}

			this.setState({
				useCashDiscounts: this.state.discountPercent1 || this.state.discountPercent2 || this.state.discountPercent3,
				useCustomExchangeRate: this.state.customExchangeRate,
				useWithholdAmount: this.state.withholdAmount
			})
		}
	}

	launchColumnSelectionForm = () => {
		this.setState({
			showColumnSelectionForm: true
		});
	}

	applySelectedColumns = (selectedColumns) => {
		const pathnameWithoutId = this.props.location.pathname.substring(0, this.props.location.pathname.lastIndexOf("/"));
		tableUtils.setSavedColumnSetup(selectedColumns, pathnameWithoutId);
		this.setState({
			columns: tableUtils.applySavedColumnSetup(columnsSupplierInvoice, selectedColumns)
		});
	}

	render() {
		const pageTitle = (this.state.id || this.props.loading ? "Edit" : "Create") + " supplier invoice | " + config.AppName;
		const breadcrumbsTitle = "Supplier invoices";
		const breadcrumbsItem = (this.state.id || this.props.loading ? "Edit" : "New") + " supplier invoice";

		const currencyOptions = inputSelectUtils.generateOptionsFromData(this.props.currencies, currencyRow => (currencyRow.code + (currencyRow.name ? " (" + currencyRow.name + ")" : "")));
		const supplierOptions = inputSelectUtils.generateOptionsFromData(this.props.suppliers, supplierRow => supplierRow.name);

		const cashDiscountSubform = (
			<React.Fragment>
				{editFormControls.numberControl("discountPercent1", "Cash discount #1, %", this.onChange, this.state.discountPercent1, 0.01)}
				{editFormControls.dateControl("discountDueDate1", "Cash discount #1 due date", this.onChange, this.state.discountDueDate1)}
				{editFormControls.numberControl("discountPercent2", "Cash discount #2, %", this.onChange, this.state.discountPercent2, 0.01)}
				{editFormControls.dateControl("discountDueDate2", "Cash discount #2 due date", this.onChange, this.state.discountDueDate2)}
				{editFormControls.numberControl("discountPercent3", "Cash discount #3, %", this.onChange, this.state.discountPercent3, 0.01)}
				{editFormControls.dateControl("discountDueDate3", "Cash discount #3 due date", this.onChange, this.state.discountDueDate3)}
			</React.Fragment>
		)

		const erpRelatedFieldSubform = (
			<React.Fragment>
				{editFormControls.textControl("dimension1", "Dimension #1", this.onChange, this.state.dimension1)}
				{editFormControls.textControl("dimension2", "Dimension #2", this.onChange, this.state.dimension2)}
				{editFormControls.textControl("dimension3", "Dimension #3", this.onChange, this.state.dimension3)}
				{editFormControls.textControl("externalId", "External ID", this.onChange, this.state.externalId)}
				{editFormControls.textControl("externalDocumentType", "External document type", this.onChange, this.state.externalDocumentType)}
				{editFormControls.textControl("externalDocumentNo", "External document No.", this.onChange, this.state.externalDocumentNo)}
				{editFormControls.dateControl("bookEntryDate", "Book entry date", this.onChange, this.state.bookEntryDate)}
			</React.Fragment>
		)

		const ColumnSelectionFormWithProps =
			<ColumnSelectionForm
				availableColumns={[
					columnsSupplierInvoice.invoiceNoAlt,
					columnsSupplierInvoice.paidAmountOutsideCRM,
					columnsSupplierInvoice.useWithholdingTax,
					columnsSupplierInvoice.useCashDiscounts,
					columnsSupplierInvoice.showERPRelatedFields,
					columnsSupplierInvoice.reference,
					columnsSupplierInvoice.blocked
				]}
				selectedColumns={this.state.columns}
				applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
				onClose={() => this.setState({ showColumnSelectionForm: false })}
			/>

		const editForm = (
			<Row>
				{browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
				<Col lg="12">
					<Card>
						<CardBody>
							<form
								className="outer-repeater"
								onSubmit={this.onSubmit}
							>
								<div data-repeater-list="outer-group" className="outer">
									<div data-repeater-item className="outer">

										{editFormControls.hiddenValueControl("id", this.onChange, this.state.id)}
										{editFormControls.selectControl("supplierId", "Supplier", this.onChange, this.state.supplierId, supplierOptions)}

										<FormGroup className="mb-4" row>
											<Label
												htmlFor="invoiceNo"
												className={editFormControls.labelClass}
											>
												Invoice No.
											</Label>
											<Col lg={editFormControls.valueColSize / 2}>
												{editFormControls.textControlWithoutLabel("invoiceNo", this.onChange, this.state.invoiceNo, "Enter invoice No.")}
											</Col>
											<Col lg={editFormControls.valueColSize / 2}>
												{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.invoiceNoAlt) && editFormControls.textControlWithoutLabel("invoiceNoAlt", this.onChange, this.state.invoiceNoAlt, "Enter alternative No.")}
											</Col>
										</FormGroup>

										{editFormControls.dateControl("invoiceDate", "Invoice date", this.onChange, this.state.invoiceDate)}
										{editFormControls.dateControl("dueDate", "Due date", this.onChange, this.state.dueDate)}
										{editFormControls.selectControl("currencyId", "Currency", this.onChange, this.state.currencyId, currencyOptions)}

										{editFormControls.numberControl("invoiceAmountWithoutVAT", "Total amount without VAT", this.onChange, this.state.invoiceAmountWithoutVAT, 0.01)}
										{editFormControls.numberControl("vatRate", "VAT rate, %", this.onChange, this.state.vatRate, 0.01, "Enter VAT rate (percentage)")}
										{editFormControls.numberControl("vatAmount", "VAT amount", this.onChange, this.state.vatAmount, 0.01, "Enter VAT amount")}
										{editFormControls.numberControl("invoiceAmount", "Total amount with VAT", this.onChange, this.state.invoiceAmount, 0.01)}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.paidAmountOutsideCRM) && editFormControls.checkboxControl("showPaidAmountOutsideCRM", "Invoice already paid outside CashManager", this.onChange, this.state.showPaidAmountOutsideCRM, "Check if the invoice has already been partially or fully paid outside CashManager (e.g. by credit card, directly in the internet bank, etc), and enter the payment amount.")}
										{this.state.showPaidAmountOutsideCRM ? editFormControls.numberControl("paidAmountOutsideCRM", "Amount paid outside CashManager", this.onChange, this.state.paidAmountOutsideCRM, 0.01) : null}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.useWithholdingTax) && editFormControls.checkboxControl("useWithholdAmount", "Use withholding tax", this.onChange, this.state.useWithholdAmount)}
										{this.state.useWithholdAmount ? editFormControls.numberControl("withholdAmount", "Withholding tax amount", this.onChange, this.state.withholdAmount, 0.01) : null}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.useCashDiscounts) && editFormControls.checkboxControl("useCashDiscounts", "Use cash discounts", this.onChange, this.state.useCashDiscounts)}
										{this.state.useCashDiscounts ? cashDiscountSubform : null}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.useCustomExchangeRate) && editFormControls.checkboxControl("useCustomExchangeRate", "Use custom exchange rate", this.onChange, this.state.useCustomExchangeRate)}
										{this.state.useCustomExchangeRate ? editFormControls.numberControl("customExchangeRate", "Custom exchange rate", this.onChange, this.state.customExchangeRate, 0.0000000000001) : null}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.showERPRelatedFields) && editFormControls.checkboxControl("showERPRelatedFields", "Show ERP-related fields", this.onChange, this.state.showERPRelatedFields)}
										{this.state.showERPRelatedFields ? erpRelatedFieldSubform : null}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.reference) && editFormControls.textControl("reference", "Reference", this.onChange, this.state.reference)}
										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.blocked) && editFormControls.checkboxControl("blocked", "Blocked", this.onChange, this.state.blocked)}

										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.category) && editFormControls.textControl("category", "Category", this.onChange, this.state.category)}
										{this.state.columns && this.state.columns.includes(columnsSupplierInvoice.type) && editFormControls.textControl("type", "Type", this.onChange, this.state.type)}

									</div>
								</div>
								<Row className="justify-content-end">
									<Col lg="10">
										{editFormControls.saveButton(this.props.saving, this.state.id)}
										{" "}
										{editFormControls.deleteButton(this.props.deleting, this.onDeleteSupplierInvoice, this.state.id)}
									</Col>
								</Row>
							</form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		);

		const loading = this.props.loading || this.props.loadingCurrencies || this.props.loadingCurrencies;

		return (
			<React.Fragment>
				{this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						{editFormControls.errorAlert(this.props.error)}

						{editFormControls.formLoadingSpinner(loading)}

						{!loading ? editForm : null}

					</Container>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ currency, supplier, supplierInvoice, vatRate }) => ({
	currencies: currency.currencies,
	deleting: supplierInvoice.deleting,
	error: supplierInvoice.error,
	loading: supplierInvoice.loading,
	loadingCurrencies: currency.loading,
	loadingSuppliers: supplier.loading,
	saving: supplierInvoice.saving,
	suppliers: supplier.suppliers,
	supplierInvoices: supplierInvoice.supplierInvoices,
	vatRates: vatRate.vatRates
})

const mapDispatchToProps = dispatch => ({
	onGetCurrencies: () => dispatch(actionsCurrency.currencyGetAll()),
	onGetSuppliers: () => dispatch(actionsSupplier.supplierGetAll()),
	onGetSupplierInvoiceById: (id) => dispatch(actionsSupplierInvoice.supplierInvoiceGetById(id)),
	onCreateSupplierInvoice: (supplierInvoice, history) => dispatch(actionsSupplierInvoice.supplierInvoiceCreate(supplierInvoice, history)),
	onDeleteSupplierInvoice: (id, history) => dispatch(actionsSupplierInvoice.supplierInvoiceDelete(id, history))
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupplierInvoiceEdit);
