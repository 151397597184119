import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as rest from "../../helpers/restHelper";
import { getEntriesFromResponseData } from 'helpers/sagaAndReducerHelper';

function* getCRMBankImplementationSaga() {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CRM_BANK_IMPLEMENTATION_GET_ALL
		);
		yield put(actions.crmBankImplementationGetSuccess(getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.crmBankImplementationGetError(e));
	}
}

function* crmBankImplementationSaga() {
	yield (takeLatest(actionTypes.CRM_BANK_IMPLEMENTATION_GET_ALL, getCRMBankImplementationSaga));
}

export default crmBankImplementationSaga;