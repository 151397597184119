import * as actionTypes from "./actionTypes";

export const userAccountResendActivation = (data) => {
	return {
		type: actionTypes.USER_ACCOUNT_RESEND_ACTIVATION,
		data: data
	}
}

export const userAccountResendActivationError = (error) => {
	return {
		type: actionTypes.USER_ACCOUNT_RESEND_ACTIVATION_ERROR,
		error: error
	}
}

export const userAccountResendActivationSuccess = (data) => {
	return {
		type: actionTypes.USER_ACCOUNT_RESEND_ACTIVATION_SUCCESS,
		data: data
	}
}

export const userAccountUpdate = (updatedUserAccount) => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE,
		updatedUserAccount: updatedUserAccount
	}
}

export const userAccountUpdateError = (error) => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE_ERROR,
		error: error
	}
}

export const userAccountUpdateSuccess = (payload) => {
	return {
		type: actionTypes.USER_ACCOUNT_UPDATE_SUCCESS,
		payload: payload
	}
}

export const loginUser = (user, history) => {
	return {
		type: actionTypes.USER_LOGIN,
		payload: { user, history },
	}
}

export const loginSuccess = (data) => {
	return {
		type: actionTypes.USER_LOGIN_SUCCESS,
		payload: data,
	}
}

export const loginFail = error => {
	return {
		type: actionTypes.USER_LOGIN_FAIL,
		payload: error,
	}
}

export const loginCheck = (history) => {
	return {
		type: actionTypes.USER_LOGIN_CHECK,
		payload: { history }
	}
}

export const loginCheckFail = () => {
	return {
		type: actionTypes.USER_LOGIN_CHECK_FAIL
	}
}

export const logoutUser = history => {
	return {
		type: actionTypes.USER_LOGOUT,
		payload: { history },
	}
}

export const logoutUserError = error => {
	return {
		type: actionTypes.USER_LOGOUT_ERROR,
		error: { error }
	}
}

export const logoutUserSuccess = () => {
	return {
		type: actionTypes.USER_LOGOUT_SUCCESS,
		payload: {},
	}
}

export const userRegister = (data, history) => {
	return {
		type: actionTypes.USER_REGISTER,
		payload: { data, history }
	}
}

export const userRegisterError = (error) => {
	return {
		type: USER_REGISTER_ERROR,
		error: error
	}
}