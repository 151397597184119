import * as actionTypes from "./actionTypes";

const initialState = {
	copying: false,
	copyingLines: false,
	copySuccess: false,
	customerInvoices: null,
	customerInvoiceLines: null,
	deleting: false,
	deletingLines: false,
	error: null,
	errorLines: null,
	loading: false,
	loadingLines: false,
	movingLines: false,
	printing: false,
	saving: false,
	sendingInvoices: false,
	sendingReminders: false
}

export default function customerInvoiceReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CUSTOMER_INVOICE_COPY:
			return {
				...state,
				copying: true,
				copySuccess: false,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_COPY_ERROR:
			return {
				...state,
				copying: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_COPY_SUCCESS:
			return {
				...state,
				copying: false,
				copySuccess: true,
				error: null,
				customerInvoices: [action.customerInvoice]
			}
		case actionTypes.CUSTOMER_INVOICE_CREATE:
			return {
				...state,
				saving: true,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				error: null,
				customerInvoices: [action.customerInvoice]
			}
		case actionTypes.CUSTOMER_INVOICE_CREATE_ERROR:
			return {
				...state,
				saving: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_DELETE_LIST:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_GET_ALL:
		case actionTypes.CUSTOMER_INVOICE_GET_BY_ID:
		case actionTypes.CUSTOMER_INVOICE_GET_UNALLOCATED:
		case actionTypes.CUSTOMER_INVOICE_GET_OVERDUE:
			return {
				...state,
				copySuccess: false,
				error: null,
				loading: true
			}
		case actionTypes.CUSTOMER_INVOICE_GET_SUCCESS:
			return {
				...state,
				loading: false,
				customerInvoices: action.customerInvoices
			}
		case actionTypes.CUSTOMER_INVOICE_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_PRINT_LIST_TO_PDF:
		case actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF:
			return {
				...state,
				printing: true
			}
		case actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF_ERROR:
			return {
				...state,
				printing: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_PRINT_TO_PDF_SUCCESS:
			return {
				...state,
				printing: false
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_LIST:
			return {
				...state,
				sendingInvoices: true,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_ERROR:
			return {
				...state,
				sendingInvoices: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_SUCCESS:
			return {
				...state,
				sendingInvoices: false,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_LIST:
			return {
				...state,
				sendingReminders: true,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_ERROR:
			return {
				...state,
				sendingReminders: false,
				error: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_SEND_REMINDERS_SUCCESS:
			return {
				...state,
				sendingReminders: false,
				error: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_GET_ALL:
			return {
				...state,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_GET_BY_ID:
			return {
				...state,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_GET_IN_PROCESS:
			return {
				...state,
				loadingLines: true
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_GET_SUCCESS:
			return {
				...state,
				loadingLines: false,
				customerInvoiceLines: action.customerInvoiceLines
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_GET_ERROR:
			return {
				...state,
				loadingLines: false,
				errorLines: action.errorLines
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_CREATE:
			return {
				...state,
				saving: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_CREATE_SUCCESS:
			return {
				...state,
				saving: false,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_CREATE_ERROR:
			return {
				...state,
				saving: false,
				errorLines: action.errorLines
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_MOVE_UP:
		case actionTypes.CUSTOMER_INVOICE_LINE_MOVE_DOWN:
			return {
				...state,
				movingLines: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_COPY:
			return {
				...state,
				copyingLines: true,
				movingLines: true,
				errorLines: null
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_MOVE_SUCCESS:
			return {
				...state,
				copyingLines: false,
				movingLines: false,
				errorLines: null,
				customerInvoiceLines: action.customerInvoiceLines
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_MOVE_ERROR:
			return {
				...state,
				copyingLines: false,
				movingLines: false,
				errorLines: action.error
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_DELETE_LIST:
			return {
				...state,
				deletingLines: true
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_DELETE_ERROR:
			return {
				...state,
				deletingLines: false
			}
		case actionTypes.CUSTOMER_INVOICE_LINE_DELETE_SUCCESS:
			return {
				...state,
				deletingLines: false,
				customerInvoiceLines: action.customerInvoiceLines
			}
		default:
			return state;
	}
}