import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";

import * as actionsPaymentBulk from '../../store/PaymentBulk/actions';
import * as actionsPaymentFile from '../../store/PaymentFile/actions';
import * as actionsSmartId from '../../store/SmartId/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from "../../helpers/browseFormControls";
import * as browseFormTools from "../../helpers/browseFormTools";
import * as columnsPayment from "../../definitions/columns/payment";
import * as columnsPaymentBulk from "../../definitions/columns/paymentBulk";
import * as columnsPaymentFile from "../../definitions/columns/paymentFile";
import * as config from '../../config';
import * as editFormControls from "../../helpers/editFormControls";
import * as endpointsBackendFilters from '../../definitions/endpoints/endpoints-backend-filters';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as hwcryptoHelper from '../../helpers/hwcryptoHelper';
import * as selectRowUtils from '../../helpers/selectRowUtils';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class PaymentFileBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			filter: null,
			paymentBulksAreExpandable: false,
			paymentTotal: null,
			showDeleteButton: false,
			showRefreshStatusButton: false,
			showSignButton: false,
			selectedRows: [],
			title: ""
		}
	}

	setExpandable() {
		let paymentBulksAreExpandable = false;
		for (let i in this.props.paymentFiles) {

			const thisPaymentFile = this.props.paymentFiles[i];
			for (let j in thisPaymentFile.paymentBulks) {

				const thisPaymentBulk = thisPaymentFile.paymentBulks[j];
				if (thisPaymentBulk.isExpandable) {
					paymentBulksAreExpandable = true;
					break;
				}
			}

			if (paymentBulksAreExpandable) {
				break;
			}
		}
		this.setState({
			paymentBulksAreExpandable: paymentBulksAreExpandable
		});
	}

	componentDidMount() {
		let showDeleteButton, showRefreshStatusButton, showSignButton, filter, title;
		if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_UNSIGNED) {
			showDeleteButton = true;
			showRefreshStatusButton = false;
			showSignButton = true;
			filter = endpointsBackendFilters.PAYMENT_FILE_TO_SIGN_IN_CRM;
			title = "Payments to sign";

			// } else if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_UNSIGNED) {
			// 	showDeleteButton = false;
			// 	showRefreshStatusButton = false;
			// 	showSignButton = false;
			// 	filter = endpointsBackendFilters.PAYMENT_FILE_TO_SIGN_IN_BANK;
			// 	title = "Payments to sign in bank";

			// } else if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_SENT_TO_BANK_SIGNED) {
			// 	showDeleteButton = false;
			// 	showRefreshStatusButton = true;
			// 	showSignButton = false;
			// 	filter = endpointsBackendFilters.PAYMENT_FILE_SIGNED_IN_CRM_AND_SENT_TO_BANK;
			// 	title = "Payments signed and sent to bank";

			// } else if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_EXPORTED_TO_BANK_AS_FILE) {
			// 	showDeleteButton = true;
			// 	showRefreshStatusButton = false;
			// 	showSignButton = false;
			// 	filter = endpointsBackendFilters.PAYMENT_FILE_SENT_AS_FILE_TO_BANK;
			// 	title = "Payments exported to bank as file";

		} else if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_PROCESSED) {
			showDeleteButton = true;
			showRefreshStatusButton = true;
			showSignButton = false;
			filter = endpointsBackendFilters.PAYMENT_FILE_PROCESSED;
			title = "Processed payments";

		} else if (this.props.location.pathname === endpointsFrontend.PAYMENT_BROWSE_ERRONEOUS) {
			showDeleteButton = true;
			showRefreshStatusButton = false;
			showSignButton = false;
			filter = endpointsBackendFilters.PAYMENT_FILE_FAILED;
			title = "Failed payments";
		}
		this.props.onGetPaymentFiles(filter);
		this.setState({
			showDeleteButton: showDeleteButton,
			showSignButton: showSignButton,
			showRefreshStatusButton: showRefreshStatusButton,
			filter: filter,
			title: title
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.total != this.props.total) {
			this.setState({
				paymentTotal: this.props.total
			});
		}

		if (prevProps.error != this.props.error) {
			this.setState({
				error: this.props.error
			})
		}

		if ((prevProps.paymentFiles != this.props.paymentFiles) && this.props.paymentFiles && this.props.paymentFiles.length) {
			this.setExpandable();
		}

		if (prevProps.messageToUser === this.props.messageToUser && this.props.messageToUser && this.props.messageToUser.length) {
			this.props.onResetPaymentBulkMessage();
		}
	}

	onResetSignPaymentFiles = () => {
		if (window.confirm("Are you sure you want to reset all signatures of the selected payment files?")) {
			this.props.onResetSignPaymentFiles(this.state.selectedRows, this.props.history);
		}
	}

	containsPastDates = () => {
		const selectedRowArray = this.props.paymentFiles.filter(row => this.state.selectedRows.includes(row.id));
		for (let i in selectedRowArray) {
			const thisPaymentBulks = selectedRowArray[i].paymentBulks;
			for (let j in thisPaymentBulks) {
				const paymentDateParts = thisPaymentBulks[j].paymentDate.split("-");
				const paymentDateAsObject = new Date(paymentDateParts[0], paymentDateParts[1], paymentDateParts[2], 0, 0, 0, 0);
				const currentDateAsObject = new Date();
				currentDateAsObject.setHours(0, 0, 0, 0);
				if (paymentDateAsObject < currentDateAsObject) {
					return true;
				}
			}
		}
		return false;
	}


	onSignPaymentFilesViaIDCard = () => {
		if (this.containsPastDates()) {
			this.setState({
				error: { message: "The selected files contain payments with dates in the past." }
			});
		} else {
			hwcryptoHelper.signPayments(
				this.state.selectedRows,
				this.props.onGetCertificate,
				this.props.onSignPaymentFilesViaIDCard,
				this.props.history,
				(error) => {
					this.setState({
						error: error
					});
				}
			);
		}
	}

	onSignPaymentFilesViaSmartId = () => {
		if (this.containsPastDates()) {
			this.setState({
				error: { message: "The selected files contain payments with dates in the past." }
			});
		} else {
			this.props.onSignPaymentFilesViaSmartId({
				paymentFileIdList: this.state.selectedRows,
				signingCertificate: this.props.smartIdSignersCertificate,
				testMode: false,
				documentNumber: this.props.smartIdDocumentNumber
			});
		}
	}

	onResetFailedBulks = () => {
		let hasPartiallyRejectedInBank = false;
		for (let i in this.props.paymentFiles) {
			const thisRow = this.props.paymentFiles[i];
			if (this.state.selectedRows.includes(thisRow.id)) {
				if (thisRow.paymentStatus === "PARTIALLY_REJECTED_IN_BANK") {
					hasPartiallyRejectedInBank = true;
				}
			}
		}
		let confirmationText;
		if (hasPartiallyRejectedInBank) {
			confirmationText = "Failed payments will be returned to Prepared Payments.\nConfirmed payments will not be affected.";
		} else {
			confirmationText = "Failed payments will be returned to Prepared Payments";
		}
		if (window.confirm(confirmationText)) {
			this.props.onResetFailedBulks(this.state.selectedRows, this.props.history);
		}
	}

	onDeletePaymentFiles = () => {
		if (window.confirm("Payments will be returned to Prepared Payments")) {
			this.props.onDeletePaymentFiles(this.state.selectedRows, this.props.history);
		}
	}

	render() {

		const pageTitle = this.state.title + " | " + config.AppName;;
		const breadcrumbsTitle = "Payments";
		const breadcrumbsItem = this.state.title;

		const columns = [
			columnsPaymentFile.createdDatetime,
			columnsPaymentFile.bankAccountNo,
			columnsPaymentFile.totalAmount,
			columnsPaymentFile.currencyCode,
			columnsPaymentFile.paymentBulkCount,
			columnsPaymentFile.filename,
			columnsPaymentFile.signingStatusSummary,
			columnsPaymentFile.paymentStatus
		];

		if (this.state.title === "Failed payments") {
			columns.push(
				columnsPaymentFile.bankStatusMessage
			);
		}

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				// this.props.history.push(endpointsFrontend.PAYMENT_EDIT.replace(":id", row.id));
			}
		};

		const selectRows = (selectedRows) => {
			this.setState({
				selectedRows: selectedRows
			});
			this.props.onGetPaymentTotal(selectedRows);
		}

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				selectRows(selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect));
			},

			onSelectAll: (isSelect, rows, e) => {
				selectRows(selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect));
			}
		};

		const expandPaymentBulkRow = {
			renderer: (row) => {
				const columns = browseFormTools.removeFiltersFromColumns([
					columnsPayment.bankAccountNo,
					columnsPayment.supplierName,
					columnsPayment.totalAmountToPay,
					columnsPayment.currencyCode,
					columnsPayment.paymentDate,
					columnsPayment.supplierBankAccountNo,
					columnsPayment.paymentDetails
				]);

				return (
					<table className="table">
						<tbody style={{ borderColor: "#FFFFFF" }}>
							<tr>
								<td style={{ border: "0px transparent" }}></td>
								<td style={{ border: "0px transparent" }}>
									<div
										style={{
											position: "relative"
										}}
									>
										<BootstrapTable
											keyField='id'
											data={row.payments}
											columns={columns}
											condensed
										/>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
		}

		const expandPaymentFileRow = {
			renderer: (row, rowIndex) => {
				const columns = browseFormTools.removeFiltersFromColumns([
					columnsPaymentBulk.bankAccountNo,
					columnsPaymentBulk.supplierName,
					columnsPaymentBulk.totalAmount,
					columnsPaymentBulk.currencyCode,
					columnsPaymentBulk.paymentDate,
					columnsPaymentBulk.supplierBankAccountNo,
					columnsPaymentBulk.paymentDetails,
					columnsPaymentBulk.paymentStatus
				]);

				if (this.state.title === "Failed payments") {
					columns.push(browseFormTools.removeFiltersFromColumns([
						columnsPaymentBulk.bankStatusMessage
					])[0]);
				}

				let paymentBulkTable;
				if (this.state.paymentBulksAreExpandable) {
					paymentBulkTable =
						<BootstrapTable
							keyField='id'
							data={row.paymentBulks}
							columns={columns}
							expandRow={expandPaymentBulkRow}
							condensed
						/>
				} else {
					paymentBulkTable =
						<BootstrapTable
							keyField='id'
							data={row.paymentBulks}
							columns={columns}
							condensed
						/>
				}

				return (
					<table className="table">
						<tbody style={{ borderColor: "#FFFFFF" }}>
							<tr>
								<td style={{ border: "0px transparent" }}></td>
								<td style={{ border: "0px transparent" }}>
									<div
										style={{
											position: "relative"
										}}
									>
										{paymentBulkTable}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				);
			},
			showExpandColumn: true,
			//expandByColumnOnly: true,
			expandColumnRenderer: ({ expanded }) => browseFormControls.expandColumnRenderer(expanded),
			expandHeaderColumnRenderer: ({ isAnyExpands }) => browseFormControls.expandHeaderColumnRenderer(isAnyExpands)
		}

		let paymentFileTable;
		if (this.state.showDeleteButton || this.state.showSignButton) {
			paymentFileTable =
				<div className="mt-3">
					<BootstrapTable
						keyField='id'
						data={this.props.paymentFiles}
						columns={columns}
						pagination={paginationFactory()}
						rowEvents={rowEvents}
						filter={filterFactory()}
						selectRow={selectRow}
						expandRow={expandPaymentFileRow}
						defaultSorted={[{
							dataField: 'createdDatetime',
							order: 'desc'
						}]}
					/>
				</div>
		} else {
			paymentFileTable =
				<div className="mt-3">
					<BootstrapTable
						keyField='id'
						data={this.props.paymentFiles}
						columns={columns}
						pagination={paginationFactory()}
						rowEvents={rowEvents}
						filter={filterFactory()}
						expandRow={expandPaymentFileRow}
						defaultSorted={[{
							dataField: 'createdDatetime',
							order: 'desc'
						}]}
					/>
				</div>
		}

		const signViaIDCardButton =
			<Button
				color="primary"
				disabled={this.state.selectedRows.length === 0 || this.props.signingWithIDCard}
				onClick={this.onSignPaymentFilesViaIDCard}
			>
				Sign with ID card
				{" "}
				{this.props.signingWithIDCard ? editFormControls.buttonSpinner() : null}
			</Button>

		const signViaSmartIDButton =
			<Button
				color="primary"
				disabled={this.state.selectedRows.length === 0 || this.props.signingWithSmartId}
				onClick={this.onSignPaymentFilesViaSmartId}
			>
				Sign with Smart-ID
				{" "}
				{this.props.signingWithSmartId ? editFormControls.buttonSpinner() : null}
			</Button>

		const signResetButton =
			<Button
				color="danger"
				disabled={this.state.selectedRows.length === 0}
				onClick={this.onResetSignPaymentFiles}
			>
				Reset signatures
				{" "}
				{this.props.resetting ? editFormControls.buttonSpinner() : null}
			</Button>

		const totalLabel =
			<Label
				className="col-form-label col-lg-2"
			>
				{this.state.paymentTotal ? this.state.paymentTotal.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null}
				{" "}
				{this.state.paymentTotal ? this.state.paymentTotal.currencyCode : null}
			</Label>

		const refreshStatusButton =
			<Button
				color="primary"
				onClick={() => this.props.onRefreshPaymentStata(this.state.filter)}
				disabled={this.props.receiving}
			>
				Refresh status
				{" "}
				{this.props.receiving ? editFormControls.buttonSpinner() : null}
			</Button>

		const smartIdControlCodeControls =
			<React.Fragment>
				<Label>
					Control code:
				</Label>
				<br />
				{this.props.smartIdControlCodeLoading ? editFormControls.bigSpinner() : (<h1>{this.props.smartIdControlCode}</h1>)}
			</React.Fragment>

		return (

			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.state.error)}
										{editFormControls.errorAlert(this.props.errorSmartId)}
										{editFormControls.warningAlertWithLink(this.props.messageToUser)}

										{this.props.notCreatedPayments && this.props.notCreatedPayments.length ? (
											<Alert color="warning">{this.props.notCreatedPayments.length} payment(s) could not be created because their total amount is zero or negative</Alert>
										) : null}

										{this.state.selectedRows && this.state.selectedRows.length > 1 ? (
											<Alert color="warning">{this.state.selectedRows.length} payment files are selected. To sign them, you will need to enter the PIN code(s) {this.state.selectedRows.length} times.</Alert>
										) : null}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.paymentFiles ?
											paymentFileTable
											: null}

										<br />

										{this.state.showSignButton ? totalLabel : null}

										{" "}

										{this.state.showSignButton && !this.props.smartIdLoggedIn ? signViaIDCardButton : null}

										{" "}

										{this.state.showSignButton && this.props.smartIdLoggedIn ? signViaSmartIDButton : null}

										{" "}

										{this.state.showSignButton ? signResetButton : null}

										{" "}

										{this.state.showRefreshStatusButton ? refreshStatusButton : null}

										{" "}

										{editFormControls.deleteButton(this.props.resettingBulks, this.onResetFailedBulks, this.state.showDeleteButton, this.state.selectedRows.length === 0, "Reset failed payments")}

										{" "}

										{editFormControls.deleteButton(this.props.deleting, this.onDeletePaymentFiles, this.state.showDeleteButton, this.state.selectedRows.length === 0)}

										<br />

										{(this.props.smartIdControlCode || this.props.smartIdControlCodeLoading) && !this.props.errorSmartId && smartIdControlCodeControls}

									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);

	}
}

const mapStateToProps = ({ payment, paymentBulk, paymentFile, smartId, userLogin }) => ({
	deleting: paymentFile.deleting,
	error: paymentFile.error,
	errorSmartId: smartId.error,
	loading: paymentFile.loading,
	messageToUser: paymentBulk.messageToUser,
	notCreatedPayments: payment.notCreatedPayments,
	paymentFiles: paymentFile.paymentFiles,
	receiving: paymentFile.receiving,
	resettingBulks: paymentFile.resettingBulks,
	saving: paymentFile.saving,
	signingWithIDCard: paymentFile.signing,
	signingWithSmartId: smartId.signing,
	smartIdControlCode: smartId.controlCode,
	smartIdControlCodeLoading: smartId.controlCodeLoading,
	smartIdDocumentNumber: smartId.documentNumber,
	smartIdLoggedIn: smartId.loggedIn,
	smartIdSignersCertificate: smartId.signersCertificate,
	total: paymentFile.total,
	username: userLogin.username
})

const mapDispatchToProps = dispatch => ({
	onDeletePaymentFiles: (paymentsToDelete, history) => dispatch(actionsPaymentFile.paymentFileDeleteList(paymentsToDelete, history)),
	onGetCertificate: () => dispatch(actionsPaymentFile.paymentFileSignGetCertificate()),
	onGetPaymentFiles: (filter) => dispatch(actionsPaymentFile.paymentFileGet(filter)),
	onGetPaymentTotal: (selectedPaymentFiles, history) => dispatch(actionsPaymentFile.paymentFileGetTotal(selectedPaymentFiles, history)),
	onResetPaymentBulkMessage: () => dispatch(actionsPaymentBulk.paymentBulkMessageToUserReset()),
	onRefreshPaymentStata: (filter) => dispatch(actionsPaymentFile.paymentFileRefreshStatus(filter)),
	onResetFailedBulks: (paymentsToReset, history) => dispatch(actionsPaymentFile.paymentFileResetFailedBulkList(paymentsToReset, history)),
	onResetSignPaymentFiles: (paymentFilesToReset, history) => dispatch(actionsPaymentFile.paymentFileSignReset(paymentFilesToReset, history)),
	onSignPaymentFilesViaIDCard: (paymentFilesToSign, signingCertificate, signingCertificateObject, history) => dispatch(actionsPaymentFile.paymentFileSignInit(paymentFilesToSign, signingCertificate, signingCertificateObject, history)),
	onSignPaymentFilesViaSmartId: (data) => dispatch(actionsSmartId.smartIdSignPaymentFileInit(data))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PaymentFileBrowse));
