export const UnderlinedUppercaseToHumanReadable = (inputString) => {
    if (!inputString) {
        return inputString;
    }

    let result = inputString.substring(0, 1).toUpperCase();
    for (let i = 1; i < inputString.length; ++i) {
        const thisChar = inputString.substring(i, i + 1);
        if (thisChar === "_") {
            result += " ";
        } else if (thisChar >= "A" && thisChar <= "Z") {
            result += thisChar.toLowerCase();
        } else {
            result += thisChar;
        }
    }
    return result;
}

export const GetPlatformSpecificCRLF = () => {
    const userAgentData = (navigator.userAgent ? navigator.userAgent : navigator.platform ? navigator.platform : "").toLowerCase();
    if (userAgentData.indexOf("windows") > 0) {
        return 0x0D0A;
        //return "\r\n";
    } else if (userAgentData.indexOf("mac") > 0) {
        return "\r";
    } else {
        return "\n";
    }
}