import { numberFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const customerQuoteId = {
	dataField: 'customerQuoteId',
	text: 'Customer quote ID',
	filter: textFilter(),
	sort: true
};
export const position = {
	dataField: 'position',
	text: 'Position'
};
export const unitId = {
	dataField: 'unitId',
	text: 'Unit ID',
	filter: textFilter(),
	sort: true
};
export const quantity = {
	dataField: 'quantity',
	text: 'Quantity',
	headerStyle: { textAlign: "right" },
	align: "right",
};
export const price = {
	dataField: 'price',
	text: 'Price',
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell)
};
export const vatRate = {
	dataField: 'vatRate',
	text: 'VAT rate, %',
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell, row) => formatUtils.formatAmount(cell)
};
export const discount = {
	dataField: 'discount',
	text: 'Discount, %',
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell, row) => formatUtils.formatAmount(cell)
};
export const unitNameLang0 = {
	dataField: 'unitNameLang0',
	text: 'Unit'
};
export const unitNameLang1 = {
	dataField: 'unitNameLang1',
	text: 'Unit'
};
export const description2 = {
	dataField: 'description2',
	text: 'Description 2',
	filter: textFilter(),
	sort: true
};
export const productId = {
	dataField: 'productId',
	text: 'Product ID',
	filter: textFilter(),
	sort: true
};
export const productNameLang0 = {
	dataField: 'productNameLang0',
	text: 'Product'
};
export const productNameLang1 = {
	dataField: 'productNameLang1',
	text: 'Product name lang 1',
	filter: textFilter(),
	sort: true
};
export const totalAmountWithoutVAT = {
	dataField: 'totalAmountWithoutVAT',
	text: 'Amount excl. VAT',
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell)
};
export const totalAmountWithVAT = {
	dataField: 'totalAmountWithVAT',
	text: 'Total amount with VAT',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const sequenceNo = {
	dataField: 'sequenceNo',
	text: 'Sequence No.',
	filter: textFilter(),
	sort: true
};
