import * as actionTypes from "./actionTypes";

export const unitCreate = (unit, history) => {
	return {
		type: actionTypes.UNIT_CREATE,
		unit: unit, 
		history: history
	}
}

export const unitCreateError = (thisError) => {
	return {
		type: actionTypes.UNIT_CREATE_ERROR,
		error: thisError
	}
}

export const unitCreateSuccess = () => {
	return {
		type: actionTypes.UNIT_CREATE_SUCCESS
	}
}

export const unitDelete = (id, history) => {
	return {
		type: actionTypes.UNIT_DELETE,
		id: id,
		history: history
	}
}

export const unitDeleteError = (thisError) => {
	return {
		type: actionTypes.UNIT_DELETE_ERROR,
		error: thisError
	}
}

export const unitDeleteSuccess = () => {
	return {
		type: actionTypes.UNIT_DELETE_SUCCESS
	}
}

export const unitGetAll = () => {
	return {
		type: actionTypes.UNIT_GET_ALL
	}
}

export const unitGetById = (id) => {
	return {
		type: actionTypes.UNIT_GET_BY_ID,
		id: id
	}
}

export const unitGetError = (thisError) => {
	return {
		type: actionTypes.UNIT_GET_ERROR,
		error: thisError
	}
}

export const unitGetSuccess = (data) => {
	return {
		type: actionTypes.UNIT_GET_SUCCESS,
		units: data
	}
}

