export const COMPANY_CREATE = "COMPANY_CREATE";
export const COMPANY_CREATE_ERROR = "COMPANY_CREATE_ERROR";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";

export const COMPANY_DELETE = "COMPANY_DELETE";
export const COMPANY_DELETE_ERROR = "COMPANY_DELETE_ERROR";
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";

export const COMPANY_GET_ALL = "COMPANY_GET_ALL";
export const COMPANY_GET_ALL_FOR_TENANT = "COMPANY_GET_ALL_FOR_TENANT";
export const COMPANY_GET_ALL_FOR_TENANT_SUCCESS = "COMPANY_GET_ALL_FOR_TENANT_SUCCESS";
export const COMPANY_GET_ALL_SUCCESS = "COMPANY_GET_ALL_SUCCESS";
export const COMPANY_GET_BY_ID = "COMPANY_GET_BY_ID";
export const COMPANY_GET_LAST = "COMPANY_GET_LAST";
export const COMPANY_GET_ERROR = "COMPANY_GET_ERROR";
export const COMPANY_GET_SUCCESS = "COMPANY_GET_SUCCESS";

export const COMPANY_NONE_AVAILABLE = "COMPANY_NONE_AVAILABLE";

export const COMPANY_SET_SELECTED = "COMPANY_SET_SELECTED";