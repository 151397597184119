import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from "./actions";
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as hwcryptoHelper from '../../helpers/hwcryptoHelper';
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

// function* deleteCM20PaymentFileSaga({ id, history }) {
// 	try {
// 		yield call(
// 			rest.del,
// 			endpointsBackend.CM20PAYMENT_FILE_GET_BY_ID.replace("{cm20PaymentFileId}", id)
// 		);
// 		yield put(actions.cm20PaymentFileDeleteSuccess());
// 		history.push(endpointsFrontend.CM20PAYMENT_BROWSE_PREPARED);
// 	} catch (e) {
// 		yield put(actions.cm20PaymentFileDeleteError(e));
// 	}
// }

// function* deleteCM20PaymentFileListSaga({ cm20PaymentFilesToDelete, history }) {
// 	try {
// 		yield call(
// 			rest.del,
// 			endpointsBackend.CM20PAYMENT_FILE_CREATE_OR_UPDATE,
// 			{ idList: cm20PaymentFilesToDelete }
// 		);
// 		yield put(actions.cm20PaymentFileDeleteSuccess());
// 		yield call(getAllCM20PaymentFilesSaga, { filter: "processed" });
// 		//history.push(endpointsFrontend.CM20PAYMENT_FILE_BROWSE_PREPARED);
// 	} catch (e) {
// 		yield put(actions.cm20PaymentFileDeleteError(e));
// 	}
// }

function* getAllCM20PaymentFilesSaga({ filter }) {
	try {
		const response = yield call(
			rest.get,
			endpointsBackend.CM20PAYMENT_FILE_GET_FILTERED.replace("{filter}", filter)
		);
		yield put(actions.cm20PaymentFileGetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.cm20PaymentFileGetError(e));
	}
}

// function* getCM20PaymentFileTotalSaga({ selectedCM20PaymentFiles }) {
// 	try {
// 		const response = yield call(
// 			rest.post,
// 			endpointsBackend.CM20PAYMENT_FILE_GET_TOTAL_BY_ID_LIST,
// 			{ idList: selectedCM20PaymentFiles }
// 		);

// 		yield put(actions.cm20PaymentFileGetTotalSuccess(response.data));
// 	} catch (e) {
// 		yield put(actions.cm20PaymentFileGetTotalError(e));
// 	}
// }

function* signCM20PaymentFilesSaga({ cm20PaymentFilesToSign, signingCertificate, signingCertificateObject, history }) {
	try {
		let responseFinalize;

		for (let i in cm20PaymentFilesToSign) {
			const responseInitRaw = yield call(
				rest.post,
				endpointsBackend.CM20PAYMENT_FILE_SIGN_INIT,
				{ 
					paymentFileId: cm20PaymentFilesToSign[i],
					signingCertificate: signingCertificate,
					testMode: false
				}
			);

			const signedData = yield call(
				hwcryptoHelper.signData,
				responseInitRaw.data,
				signingCertificateObject,
				false
			);

			responseFinalize = yield call(
				rest.post,
				endpointsBackend.CM20PAYMENT_FILE_SIGN_FINALIZE,
				{
					paymentFileId: cm20PaymentFilesToSign[i],
					signingCertificate: signingCertificate,
					signedDataHexString: signedData[0].signedDataHexString
				}
			);
		}

		yield put(actions.cm20PaymentFileSignSuccess(sagaHelper.getEntriesFromResponseData(responseFinalize.data)));

		//history.push(endpointsFrontend.CM20PAYMENT_FILE_SIGN_SUCCESS);
	} catch (e) {
		yield put(actions.cm20PaymentFileSignError(e));
	}
}

function* refreshPaymentFileStatus({ filter }) {
	try {
		yield call(
			rest.get,
			endpointsBackend.BANK_STATEMENT_RECEIVE_ALL
		);
		yield put(actions.cm20PaymentFileRefreshStatusSuccess());
		yield call(getAllCM20PaymentFilesSaga, { filter });
	} catch (e) {
		yield put(actions.cm20PaymentFileRefreshStatusError(e));
	}
}

function* resetSignCM20PaymentFilesSaga({ cm20PaymentFilesToReset, history }) {
	try {
		const response = yield call(
			rest.post,
			endpointsBackend.CM20PAYMENT_FILE_SIGN_RESET,
			{
				idList: cm20PaymentFilesToReset
			}
		);
		yield put(actions.cm20PaymentFileSignResetSuccess(sagaHelper.getEntriesFromResponseData(response.data)));
	} catch (e) {
		yield put(actions.cm20PaymentFileSignError(e));
	}
}

function* CM20PaymentFileSaga() {
	// yield (takeLatest(actionTypes.CM20PAYMENT_FILE_DELETE, deleteCM20PaymentFileSaga));
	// yield (takeLatest(actionTypes.CM20PAYMENT_FILE_DELETE_LIST, deleteCM20PaymentFileListSaga));
	yield (takeLatest(actionTypes.CM20PAYMENT_FILE_GET, getAllCM20PaymentFilesSaga));
	// yield (takeLatest(actionTypes.CM20PAYMENT_FILE_GET_TOTAL, getCM20PaymentFileTotalSaga));
	yield (takeLatest(actionTypes.CM20PAYMENT_FILE_REFRESH_STATUS, refreshPaymentFileStatus))
	yield (takeLatest(actionTypes.CM20PAYMENT_FILE_SIGN_INIT, signCM20PaymentFilesSaga));
	yield (takeLatest(actionTypes.CM20PAYMENT_FILE_SIGN_RESET, resetSignCM20PaymentFilesSaga));
}

export default CM20PaymentFileSaga;