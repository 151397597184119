import {
	USER_ACTIVATE,
	USER_ACTIVATE_ERROR,
	USER_ACTIVATE_SUCCESS
} from "./actionTypes"

export const userActivate = token => {
	return {
		type: USER_ACTIVATE,
		payload: token
	}
}

export const userActivateSuccess = message => {
	return {
		type: USER_ACTIVATE_SUCCESS,
		payload: message
	}
}

export const userActivateError = message => {
	return {
		type: USER_ACTIVATE_ERROR,
		payload: message
	}
}