import * as actionTypes from "./actionTypes";

export const customerCreate = (customer, history) => {
	return {
		type: actionTypes.CUSTOMER_CREATE,
		customer: customer, 
		history: history
	}
}

export const customerCreateError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_CREATE_ERROR,
		error: thisError
	}
}

export const customerCreateSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_CREATE_SUCCESS
	}
}

export const customerDelete = (id, history) => {
	return {
		type: actionTypes.CUSTOMER_DELETE,
		id: id,
		history: history
	}
}

export const customerDeleteError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_DELETE_ERROR,
		error: thisError
	}
}

export const customerDeleteSuccess = () => {
	return {
		type: actionTypes.CUSTOMER_DELETE_SUCCESS
	}
}

export const customerGetAll = () => {
	return {
		type: actionTypes.CUSTOMER_GET_ALL
	}
}

export const customerGetById = (id) => {
	return {
		type: actionTypes.CUSTOMER_GET_BY_ID,
		id: id
	}
}

export const customerGetError = (thisError) => {
	return {
		type: actionTypes.CUSTOMER_GET_ERROR,
		error: thisError
	}
}

export const customerGetSuccess = (data) => {
	return {
		type: actionTypes.CUSTOMER_GET_SUCCESS,
		customers: data
	}
}

