import * as actionTypes from "./actionTypes";
import * as sagaAndReducerHelper from "../../helpers/sagaAndReducerHelper";

const initialState = {
	deleting: false,
	error: null,
	loading: false,
	saving: false,
	suppliers: null,
}

export default function supplierReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SUPPLIER_GET_ALL:
			return {
				...state,
				error: null
			}
		case actionTypes.SUPPLIER_GET_BY_ID:
			return {
				...state,
				error: null
			}
		case actionTypes.SUPPLIER_GET_IN_PROCESS:
			return {
				...state,
				loading: true
			}
		case actionTypes.SUPPLIER_GET_SUCCESS:
			return {
				...state,
				loading: false,
				suppliers: action.suppliers
			}
		case actionTypes.SUPPLIER_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_CREATE:
		case actionTypes.SUPPLIER_CREATE_WITH_BANK_ACCOUNTS:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.SUPPLIER_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.SUPPLIER_CREATE_SUCCESS:
			return {
				...state,
				error: null,
				saving: false,
				suppliers: sagaAndReducerHelper.updateEntries(state.suppliers, action.data)
			}
		case actionTypes.SUPPLIER_DELETE:
			return {
				...state,
				deleting: true,
				error: null
			}
		case actionTypes.SUPPLIER_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error
			}
		case actionTypes.SUPPLIER_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				suppliers: action.data
			}
		default:
			return state;
	}
}