import {
	USER_ACTIVATE,
	USER_ACTIVATE_SUCCESS,
	USER_ACTIVATE_ERROR,
} from "./actionTypes";

const initialState = {
	error: null,
	successMessage: null,
	token: null
}

const userActivate = (state = initialState, action) => {
	switch (action.type) {
		case USER_ACTIVATE:
			state = {
				...state,
				token: action.payload,
				error: null
			};
			break;
		case USER_ACTIVATE_ERROR:
			state = {
				...state,
				error: action.payload,
				successMessage: null
			}
			break;
		case USER_ACTIVATE_SUCCESS:
			state = {
				...state,
				error: null,
				successMessage: action.payload
			}
			break;
		default:
			break;
	}
	return state;
}

export default userActivate;