import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/
import paginationFactory from 'react-bootstrap-table2-paginator';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";

import * as actions from '../../store/CustomerInvoice/actions';
import Breadcrumbs from "components/Common/Breadcrumb";
import * as browseFormControls from '../../helpers/browseFormControls';
import * as columnsCustomerInvoice from '../../definitions/columns/customerInvoice';
import ColumnSelectionForm from "components/Pages/ColumnSelectionForm";
import * as config from '../../config';
import * as editFormControls from '../../helpers/editFormControls';
import * as endpointsFrontend from '../../definitions/endpoints/endpoints-frontend';
import * as formatUtils from '../../helpers/formatUtils';
import * as selectRowUtils from '../../helpers/selectRowUtils';
import * as tableUtils from '../../helpers/tableUtils';
import * as xlsxHelper from '../../helpers/xlsxHelper';

import "assets/scss/datatables.scss";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';


class CustomerInvoiceBrowse extends Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			selectedRows: [],
			reportDropdownOpen: false,
			showColumnSelectionForm: false,
			columns: []
		}
	}

	componentDidMount() {
		let defaultColumnSetup;

		if (this.props.location.pathname === endpointsFrontend.CUSTOMER_INVOICE_BROWSE_OVERDUE) {
			this.props.onGetOverdueCustomerInvoices();
			defaultColumnSetup = "customerName,invoiceNo,dueDate,daysOverdue,amountWithVAT,unallocatedAmount,currencyCode,lastSendingDate,lastReminderSendingDate";
		} else if (this.props.location.pathname === endpointsFrontend.CUSTOMER_INVOICE_BROWSE_UNPAID) {
			this.props.onGetUnallocatedCustomerInvoices();
			defaultColumnSetup = "customerName,invoiceNo,invoiceDate,dueDate,amountWithoutVAT,amountWithVAT,unallocatedAmount,lastSendingDate,currencyCode";
		} else {
			this.props.onGetCustomerInvoices();
			defaultColumnSetup = "customerName,invoiceNo,invoiceDate,amountWithVAT,currencyCode";
		}

		this.setState({
			columns: tableUtils.getSavedColumnSetup(columnsCustomerInvoice, this.props.location.pathname, defaultColumnSetup)
		});

	}

	handleChange = date => {
		this.setState({
			startDate: date,
		});
	}

	onSendInvoices = () => {
		let hasAlreadySentInvoices = false;
		const selectedInvoices = this.props.customerInvoices.filter(customerInvoice => this.state.selectedRows.includes(customerInvoice.id));
		for (let key in selectedInvoices) {
			if (selectedInvoices[key].lastSendingDate) {
				hasAlreadySentInvoices = true;
			}
		}
		if (!hasAlreadySentInvoices || window.confirm("Some of selected invoices have already been sent. Are you sure you want to send them again?")) {
			this.props.onSendInvoices(this.state.selectedRows);
		}
	}

	onSendReminders = () => {
		let hasTodayAlreadySentReminders = false;
		const selectedInvoices = this.props.customerInvoices.filter(customerInvoice => this.state.selectedRows.includes(customerInvoice.id));
		const todayDateAsString = formatUtils.formatJSDate(new Date());
		for (let key in selectedInvoices) {
			if (selectedInvoices[key].lastReminderSendingDate === todayDateAsString) {
				hasTodayAlreadySentReminders = true;
			}
		}
		if (!hasTodayAlreadySentReminders || window.confirm("Some of selected invoices have already been sent reminders today. Are you sure you want to send reminders again?")) {
			this.props.onSendReminders(this.state.selectedRows);
		}
	}

	onPrintInvoices = () => {
		this.props.onPrintInvoices(this.state.selectedRows);
	}

	onDeleteInvoices = () => {
		if (window.confirm("Are you sure you want to delete the selected invoices?")) {
			this.props.onDeleteInvoices(this.state.selectedRows, this.props.history);
		}
	}

	launchColumnSelectionForm = () => {
		this.setState({
			showColumnSelectionForm: true
		});
	}

	applySelectedColumns = (selectedColumns) => {
		tableUtils.setSavedColumnSetup(selectedColumns, this.props.location.pathname);
		this.setState({
			columns: tableUtils.applySavedColumnSetup(columnsCustomerInvoice, selectedColumns)
		});
	}

	onAllDataReport = () => {
		xlsxHelper.exportToXLSX([{
			records: this.props.customerInvoices,
			columns: this.state.columns,
			sheetName: "All invoices"
		}], 
		formatUtils.companyNameAsFilenamePrefix(this.props.selectedCompany) + "customer-invoices-all.xlsx");
	}

	onCurrentMonthReport = () => {
		xlsxHelper.exportToXLSX([{
			records: this.props.customerInvoices.filter(invoice => invoice.invoiceDate.startsWith((new Date()).toISOString().substring(0, 8))), // Assuming that invoiceDate always comes in JSON as yyyy-MM-dd
			columns: this.state.columns,
			sheetName: "Current month invoices"
		}], 
		formatUtils.companyNameAsFilenamePrefix(this.props.selectedCompany) + "customer-invoices-current-month.xlsx");
	}

	onLastMonthReport = () => {
		const lastMonthDate = new Date();
		lastMonthDate.setMonth((new Date()).getMonth() - 1);

		xlsxHelper.exportToXLSX([{
			records: this.props.customerInvoices.filter(invoice => invoice.invoiceDate.startsWith(lastMonthDate.toISOString().substring(0, 8))), // Assuming that invoiceDate always comes in JSON as yyyy-MM-dd
			columns: this.state.columns,
			sheetName: "Last month invoices"
		}], 
		formatUtils.companyNameAsFilenamePrefix(this.props.selectedCompany) + "customer-invoices-last-month.xlsx");
	}

	render() {

		const pageTitle = "Customer Invoices | " + config.AppName;
		const breadcrumbsTitle = "Customer invoice";
		const breadcrumbsItem = "Browse customer invoices";

		const rowEvents = {
			onClick: (e, row, rowIndex) => {
				this.props.history.push(endpointsFrontend.CUSTOMER_INVOICE_EDIT.replace(":id", row.id));
			}
		};

		const selectRow = {
			mode: 'checkbox',

			onSelect: (row, isSelect, rowIndex, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelect(this.state.selectedRows, row, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
				// this.props.onGetPaymentTotal(selectedRows);
			},

			onSelectAll: (isSelect, rows, e) => {
				const selectedRows = selectRowUtils.getSelectedRowOnSelectAll(this.state.selectedRows, rows, isSelect);
				this.setState({
					selectedRows: selectedRows
				});
				// this.props.onGetPaymentTotal(selectedRows);
			}
		};

		const ColumnSelectionFormWithProps =
			<ColumnSelectionForm
				availableColumns={columnsCustomerInvoice}
				selectedColumns={this.state.columns}
				applySelectedColumns={(selectedColumns) => this.applySelectedColumns(selectedColumns)}
				onClose={() => this.setState({ showColumnSelectionForm: false })}
			/>

		const table =
			<div className="mt-3">
				{browseFormControls.columnSelectionButton(this.launchColumnSelectionForm)}
				<BootstrapTable
					keyField='id'
					data={this.props.customerInvoices}
					columns={this.state.columns}
					pagination={paginationFactory()}
					rowEvents={rowEvents}
					rowStyle={{ cursor: "pointer" }}
					filter={filterFactory()}
					selectRow={selectRow}
					defaultSorted={[{
						dataField: 'invoiceNo',
						order: 'desc'
					}]}
				/>
			</div>

		const sendInvoicesButton = (
			<Button
				color="primary"
				onClick={this.onSendInvoices}
				disabled={this.props.sendingInvoices || this.state.selectedRows.length === 0}
			>
				Send invoices
				{" "}
				{this.props.sendingInvoices ? editFormControls.buttonSpinner() : null}
			</Button>
		);

		const sendRemindersButton = (
			<Button
				color="secondary"
				onClick={this.onSendReminders}
				disabled={this.props.sendingReminders || this.state.selectedRows.length === 0}
			>
				Send reminders
				{" "}
				{this.props.sendingReminders ? editFormControls.buttonSpinner() : null}
			</Button>
		);

		const printButton = (
			<Button
				color="secondary"
				onClick={this.onPrintInvoices}
				disabled={this.props.printing || this.state.selectedRows.length === 0}
			>
				Print to PDF
				{" "}
				{this.props.printing ? editFormControls.buttonSpinner() : null}
			</Button>
		);

		const deleteButton = editFormControls.deleteButton(this.props.deleting, this.onDeleteInvoices, true);

		const reportDropdown =
			<Dropdown
				isOpen={this.state.reportDropdownOpen}
				toggle={() => this.setState({
					reportDropdownOpen: !this.state.reportDropdownOpen
				})}
			>
				<DropdownToggle
					color="secondary"
					disabled={!this.props.customerInvoices || this.props.customerInvoices.length === 0}
					caret
				>
					Report...
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem onClick={this.onCurrentMonthReport}>Current month invoices (XLSX)</DropdownItem>
					<DropdownItem onClick={this.onLastMonthReport}>Last month invoices (XLSX)</DropdownItem>
					<DropdownItem onClick={this.onAllDataReport}>All invoices (XLSX)</DropdownItem>
				</DropdownMenu>
			</Dropdown>

		let buttons;
		if (this.props.location.pathname === endpointsFrontend.CUSTOMER_INVOICE_BROWSE_OVERDUE) {
			buttons = (<>
				{sendInvoicesButton}
				&nbsp;
				{sendRemindersButton}
				&nbsp;
				{printButton}
				&nbsp;
				{deleteButton}
			</>)
		} else if (this.props.location.pathname === endpointsFrontend.CUSTOMER_INVOICE_BROWSE_UNPAID) {
			buttons = (<>
				{sendInvoicesButton}
				&nbsp;
				{printButton}
				&nbsp;
				{deleteButton}
			</>)
		} else {
			buttons = (<>
				{printButton}
				&nbsp;
				{reportDropdown}
				&nbsp;
				{deleteButton}
			</>)
		}


		return (

			<React.Fragment>
				{this.state.showColumnSelectionForm && ColumnSelectionFormWithProps}

				<div className="page-content">
					<MetaTags>
						<title>{pageTitle}</title>
					</MetaTags>
					<Container fluid>
						<Breadcrumbs title={breadcrumbsTitle} breadcrumbItem={breadcrumbsItem} />

						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										{editFormControls.errorAlert(this.props.error)}

										{editFormControls.formLoadingSpinner(this.props.loading)}

										{!this.props.loading && this.props.customerInvoices && this.state.columns.length ?
											table
											: null}

										<div style={{ display: 'flex' }}>
											{buttons}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment >
		);

	}
}

const mapStateToProps = ({ company, customerInvoice }) => ({
	customerInvoices: customerInvoice.customerInvoices,
	printing: customerInvoice.printing,
	loading: customerInvoice.loading,
	selectedCompany: company.selectedCompany,
	sendingInvoices: customerInvoice.sendingInvoices,
	sendingReminders: customerInvoice.sendingReminders,
	error: customerInvoice.error
})

const mapDispatchToProps = dispatch => ({
	onGetCustomerInvoices: () => dispatch(actions.customerInvoiceGetAll()),
	onGetOverdueCustomerInvoices: () => dispatch(actions.customerInvoiceGetOverdue()),
	onGetUnallocatedCustomerInvoices: () => dispatch(actions.customerInvoiceGetUnallocated()),
	onPrintInvoices: (selectedRows) => dispatch(actions.customerInvoicePrintList(selectedRows)),
	onDeleteInvoices: (selectedRows) => dispatch(actions.customerInvoiceDeleteList(selectedRows)),
	onSendInvoices: (selectedRows) => dispatch(actions.customerInvoiceSendList(selectedRows)),
	onSendReminders: (selectedRows) => dispatch(actions.customerInvoiceSendRemindersList(selectedRows))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CustomerInvoiceBrowse));
