import * as actionTypes from "./actionTypes";

const initialState = {
	attemptedLoginUsername: "",
	checkingLogin: false,
	error: "",
	errorRegistering: "",
	loading: false,
	loggedIn: false,
	saveSuccess: false,
	saving: false,
	resendingActivation: false,
	smartIdDocumentNumber: "",
	tenantExternalId: "",
	username: "",
	userData: null
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.USER_ACCOUNT_RESEND_ACTIVATION:
			return {
				...state,
				error: null,
				resendingActivation: true
			}
		case actionTypes.USER_ACCOUNT_RESEND_ACTIVATION_ERROR:
			return {
				...state,
				error: action.error,
				resendingActivation: false
			}
		case actionTypes.USER_ACCOUNT_RESEND_ACTIVATION_SUCCESS:
			return {
				...state,
				resendingActivation: false
			}
		case actionTypes.USER_ACCOUNT_UPDATE:
			return {
				...state,
				error: false,
				saveSuccess: false,
				saving: true
			}
		case actionTypes.USER_ACCOUNT_UPDATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false
			}
		case actionTypes.USER_ACCOUNT_UPDATE_SUCCESS:
			return {
				...state,
				saveSuccess: true,
				userData: {
					userId: action.payload.userId,
					username: action.payload.username,
					isActivated: action.payload.isActivated,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					smartIdIsEnabled: action.payload.smartIdIsEnabled,
					smartIdIdentityType: action.payload.smartIdIdentityType,
					smartIdCountryCode: action.payload.smartIdCountryCode,
					smartIdIdentityNumber: action.payload.smartIdIdentityNumber,
					userRoles: action.payload.userRoles
				},
				error: false,
				saving: false
			}
		case actionTypes.USER_LOGIN:
			state = {
				...state,
				attemptedLoginUsername: action.payload.user.email.trim(),
				error: "",
				loading: true
			};
			break;
		case actionTypes.USER_LOGIN_CHECK:
			state = {
				...state,
				checkingLogin: true
			}
			break;
		case actionTypes.USER_LOGIN_CHECK_FAIL:
			state = {
				...state,
				checkingLogin: false
			}
			break;
		case actionTypes.USER_LOGIN_SUCCESS:
			state = {
				...state,
				checkingLogin: false,
				error: "",
				loading: false,
				loggedIn: true,
				smartIdDocumentNumber: action.payload.smartIdDocumentNumber,
				tenantExternalId: action.payload.tenantExternalId,
				userData: {
					userId: action.payload.userId,
					username: action.payload.username,
					isActivated: action.payload.isActivated,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					smartIdIsEnabled: action.payload.smartIdIsEnabled,
					smartIdIdentityType: action.payload.smartIdIdentityType,
					smartIdCountryCode: action.payload.smartIdCountryCode,
					smartIdIdentityNumber: action.payload.smartIdIdentityNumber,
					userRoles: action.payload.userRoles
				},
				username: action.payload.username
			};
			break;
		case actionTypes.USER_LOGIN_FAIL:
			state = {
				...state,
				checkingLogin: false,
				loading: false,
				loggedIn: false,
				error: action.payload
			};
			break;
		case actionTypes.USER_LOGOUT:
			state = {
				...state,
				error: "",
				loading: false,
				loggedIn: false,
				username: "",
				smartIdDocumentNumber: "",
				tenantExternalId: "",
				userData: null
			};
			break;
		case actionTypes.USER_LOGOUT_ERROR:
			state = {
				...state,
				error: action.error
			}
		case actionTypes.USER_LOGOUT_SUCCESS:
			state = {
				...state,
				loading: false,
				loggedIn: false,
				error: "",
				username: "",
				smartIdDocumentNumber: "",
				tenantExternalId: "",
				userData: null
			}
			break;
		case actionTypes.USER_REGISTER:
			state = {
				...state,
				errorRegistering: ""
			}
			break;
		case actionTypes.USER_REGISTER_ERROR:
			state = {
				...state,
				errorRegistering: action.error
			}
			break;
		default:
			break;
	}
	return state;
}

export default login;
