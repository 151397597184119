export const SupplierBankAccountStatusConstants = {
    OK: "OK",
    NEW: "NEW",
    UPDATED: "UPDATED",
    NOT_FOUND: "NOT_FOUND"
};

// 2024.10.17: "code" values are not used, do not correspond to any backend values and can be safely changed anytime
export const SupplierBankAccountStatus = [
    { code: 1, id: SupplierBankAccountStatusConstants.OK, description: "OK" },
    { code: 2, id: SupplierBankAccountStatusConstants.NEW, description: "New" },
    { code: 3, id: SupplierBankAccountStatusConstants.UPDATED, description: "Updated" },
    { code: 4, id: SupplierBankAccountStatusConstants.NOT_FOUND, description: "Not found" },
];

export const formatSupplierBankAccountStatusColor = (status) => {
    switch (status) {
        case SupplierBankAccountStatusConstants.NOT_FOUND:
            return "danger";
        case SupplierBankAccountStatusConstants.UPDATED:
            return "warning";
        case SupplierBankAccountStatusConstants.OK:
            return "success";
        case SupplierBankAccountStatusConstants.NEW:
            return "primary";
        default:
            return "secondary";
    }
}