import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

class MiniWidget extends Component {

	render() {

		const titlePlain = (title) => <h5 className="font-size-14 mb-0">{title}</h5>

		const titleAsLink = (title, link) =>
			<Link to={link}>
				{titlePlain(title)}
			</Link>

		const badgePlain = (color, value) =>
			<span
				className={"badge badge-soft-" + color + " font-size-12"}
				style={{ marginBottom: "0.3rem", marginTop: "0.05rem" }}
			>
				{value}
			</span>

		const badgeAsLink = (color, value, link) =>
			<Link to={link}>
				{badgePlain(color, value)}
			</Link>

		const report = this.props.report;

		if (report) {
			const reportValues = report.value2
				? <React.Fragment><h5>{report.value}</h5><h5>{report.value2}</h5></React.Fragment>
				: <React.Fragment><h4>{report.value}</h4></React.Fragment>

			return (
				<React.Fragment>
					{report ? (
						<Card>
							<CardBody>
								<div className="d-flex align-items-center mb-3">
									<div className="avatar-xs me-3">
										<span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
											<i className={report.icon} />
										</span>
									</div>
									{report.link ? titleAsLink(report.title, report.link) : titlePlain(report.title)}
								</div>
								<div className="text-muted mt-4">
									{report.link
										? (<Link to={report.link}>{reportValues}</Link>)
										: reportValues}
									{report.badges && report.badges.map((badge, key) => badge.value &&
										<div className="d-flex" key={key}>
											{badge.link ? badgeAsLink(badge.color, badge.value, badge.link) : badgePlain(badge.color, badge.value)}
											{" "}
											<span className="ms-2 text-truncate">{badge.description}</span>
										</div>
									)}
								</div>
							</CardBody>
						</Card>
					) : null}
				</React.Fragment>
			);

		} else {
			return null;
		}
	}

}

export default MiniWidget;
