import * as actionTypes from "./actionTypes";
import * as cronHelper from "../../helpers/cronHelper";

const initialState = {
	deleting: false,
	error: null,
	executedTaskName: "",
	executing: false,
	loaded: false,
	loading: false,
	moving: false,
	saving: false,
	scheduledTasks: null
}

const updateScheduledTasks = (state, updatedScheduledTask) => {
	const scheduledTasks = [...state.scheduledTasks];
	let updated = false;
	for (let i = 0; i < scheduledTasks.length; ++i) {
		if (scheduledTasks[i].id === updatedScheduledTask.id) {
			scheduledTasks[i] = updatedScheduledTask;
			updated = true;
			break;
		}
	}
	if (!updated) {
		scheduledTasks.push(updatedScheduledTask);
	}
	return scheduledTasks;
}

const processScheduledTasks = (tasks) => {
	const processedTasks = [];
	for (let i in tasks) {
		let scheduleType;
		let nextExecutionTime;
		try {
			const processedCronExpression = cronHelper.parseCronExpression(tasks[i].cronExpression);;
			scheduleType = processedCronExpression.scheduleType;
			nextExecutionTime = processedCronExpression.nextExecutionTime;
		} catch (error) {
			scheduleType = '(invalid)';
			nextExecutionTime = '(invalid)';
		}
		processedTasks.push({
			...tasks[i],
			scheduleType,
			nextExecutionTime
		});
	}
	return processedTasks;
}

export default function scheduledTaskReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SCHEDULED_TASK_CREATE:
			return {
				...state,
				error: null,
				saving: true
			}
		case actionTypes.SCHEDULED_TASK_CREATE_ERROR:
			return {
				...state,
				error: action.error,
				saving: false,
			}
		case actionTypes.SCHEDULED_TASK_CREATE_SUCCESS:
			const updatedScheduledTasks = updateScheduledTasks(state, action.scheduledTask);
			return {
				...state,
				error: null,
				saving: false,
				scheduledTasks: processScheduledTasks(updatedScheduledTasks)
			}
		case actionTypes.SCHEDULED_TASK_DELETE:
			return {
				...state,
				deleting: true,
				error: null,
			}
		case actionTypes.SCHEDULED_TASK_DELETE_ERROR:
			return {
				...state,
				deleting: false,
				error: action.error,
			}
		case actionTypes.SCHEDULED_TASK_DELETE_SUCCESS:
			return {
				...state,
				deleting: false,
				error: null,
				scheduledTasks: processScheduledTasks(action.scheduledTasks)
			}
		case actionTypes.SCHEDULED_TASK_EXECUTE:
			return {
				...state,
				executedTaskName: action.executedTaskName,
				executing: true,
				error: null,
			}
		case actionTypes.SCHEDULED_TASK_EXECUTE_ERROR:
			return {
				...state,
				executedTaskName: "",
				executing: false,
				error: action.error,
			}
		case actionTypes.SCHEDULED_TASK_EXECUTE_SUCCESS:
			return {
				...state,
				executedTaskName: "",
				executing: false,
				error: null,
			}
		case actionTypes.SCHEDULED_TASK_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.SCHEDULED_TASK_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.SCHEDULED_TASK_GET_ERROR:
			return {
				...state,
				error: action.error,
				loading: false,
			}
		case actionTypes.SCHEDULED_TASK_GET_SUCCESS:
			return {
				...state,
				scheduledTasks: processScheduledTasks(action.scheduledTasks),
				loaded: true,
				loading: false,
			}
		default:
			return state;
	}
}