export const COUNTRY_GET_ALL = "COUNTRY_GET_ALL";
export const COUNTRY_GET_BY_ID = "COUNTRY_GET_BY_ID";
export const COUNTRY_GET_IN_PROCESS = "COUNTRY_GET_IN_PROCESS";
export const COUNTRY_GET_ERROR = "COUNTRY_GET_ERROR";
export const COUNTRY_GET_SUCCESS = "COUNTRY_GET_SUCCESS";

export const COUNTRY_CREATE = "COUNTRY_CREATE";
export const COUNTRY_CREATE_ERROR = "COUNTRY_CREATE_ERROR";
export const COUNTRY_CREATE_SUCCESS = "COUNTRY_CREATE_SUCCESS";

export const COUNTRY_DELETE = "COUNTRY_DELETE";

