import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';		// docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/

import * as formatUtils from 'helpers/formatUtils';

export const customerId = {
	dataField: 'customerId',
	text: 'Customer ID',
	filter: textFilter(),
	sort: true
};
export const customerName = {
	dataField: 'customerName',
	text: 'Customer name',
	filter: textFilter(),
	sort: true
};
export const invoiceNo = {
	dataField: 'invoiceNo',
	text: 'Invoice No.',
	filter: textFilter(),
	sort: true
};
export const invoiceNoAlt = {
	dataField: 'invoiceNoAlt',
	text: 'Alt. invoice No.',
	filter: textFilter(),
	sort: true
};
export const invoiceDate = {
	dataField: 'invoiceDate',
	text: 'Invoice date',
	filter: textFilter(),
	sort: true
};
export const dueDate = {
	dataField: 'dueDate',
	text: 'Due date',
	filter: textFilter(),
	sort: true
};
export const canceled = {
	dataField: 'canceled',
	text: 'Canceled',
	filter: selectFilter({
		options: {
			"true": "Canceled",
			"false": "Not canceled"
		}
	}),
	formatter: (cell) => {return (cell && cell == true ? (<span className={"badge badge-soft-danger font-size-12"} style={{ marginBottom: "0.3rem" }}>Canceled</span>) : "")},
	sort: true
};
export const amountWithoutVAT = {
	dataField: 'amountWithoutVAT',
	text: 'Amount without VAT',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const amountWithVAT = {
	dataField: 'amountWithVAT',
	text: 'Amount with VAT',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const vatAmount = {
	dataField: 'vatAmount',
	text: 'VAT amount',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const vatRate = {
	dataField: 'vatRate',
	text: 'VAT rate',
	filter: textFilter(),
	sort: true
};
export const currencyId = {
	dataField: 'currencyId',
	text: 'Currency ID',
	filter: textFilter(),
	sort: true
};
export const currencyCode = {
	dataField: 'currencyCode',
	text: 'Currency',
	filter: textFilter(),
	sort: true
};
export const id = {
	dataField: 'id',
	text: 'ID',
	filter: textFilter(),
	sort: true
};
export const externalId = {
	dataField: 'externalId',
	text: 'External ID',
	filter: textFilter(),
	sort: true
};
export const discountPercent1 = {
	dataField: 'discountPercent1',
	text: 'Discount percent 1',
	filter: textFilter(),
	sort: true
};
export const discountPercent2 = {
	dataField: 'discountPercent2',
	text: 'Discount percent 2',
	filter: textFilter(),
	sort: true
};
export const discountPercent3 = {
	dataField: 'discountPercent3',
	text: 'Discount percent 3',
	filter: textFilter(),
	sort: true
};
export const discountDueDate1 = {
	dataField: 'discountDueDate1',
	text: 'Discount due date 1',
	filter: textFilter(),
	sort: true
};
export const discountDueDate2 = {
	dataField: 'discountDueDate2',
	text: 'Discount due date 2',
	filter: textFilter(),
	sort: true
};
export const discountDueDate3 = {
	dataField: 'discountDueDate3',
	text: 'Discount due date 3',
	filter: textFilter(),
	sort: true
};
export const unallocatedAmount = {
	dataField: 'unallocatedAmount',
	text: 'Unallocated',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const unallocatedAmountLocalCurrency = {
	dataField: 'unallocatedAmountLocalCurrency',
	text: 'Unallocated (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const amountLocalCurrency = {
	dataField: 'amountLocalCurrency',
	text: 'Amount (local currency)',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	formatter: (cell) => formatUtils.formatAmount(cell),
	sort: true
};
export const customerRegNo = {
	dataField: 'customerRegNo',
	text: 'Customer reg. No.',
	filter: textFilter(),
	sort: true
};
export const lastSendingDate = {
	dataField: 'lastSendingDate',
	text: 'Sent on',
	filter: textFilter(),
	sort: true
};
export const lastReminderSendingDate = {
	dataField: 'lastReminderSendingDate',
	text: 'Last reminder sent on',
	filter: textFilter(),
	sort: true
};
export const daysOverdue = {
	dataField: 'daysOverdue',
	text: 'Days overdue',
	filter: textFilter(),
	headerStyle: { textAlign: "right" },
	align: "right",
	sort: true
};
export const category = {
	dataField: 'category',
	text: 'Category',
	filter: textFilter(),
	sort: true
};
export const type = {
	dataField: 'type',
	text: 'Type',
	filter: textFilter(),
	sort: true
};
export const dimension1 = {
	dataField: 'dimension1',
	text: 'Dimension 1',
	filter: textFilter(),
	sort: true
};
export const dimension2 = {
	dataField: 'dimension2',
	text: 'Dimension 2',
	filter: textFilter(),
	sort: true
};
export const dimension3 = {
	dataField: 'dimension3',
	text: 'Dimension 3',
	filter: textFilter(),
	sort: true
};
