import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as actionsCM20PaymentFile from '../CM20PaymentFile/actions';
import * as actionsPaymentFile from '../PaymentFile/actions';
import * as actionTypes from './actionTypes';
import * as endpointsBackend from '../../definitions/endpoints/endpoints-backend';
import * as endpointsFrontend from "../../definitions/endpoints/endpoints-frontend";
import * as loginHelper from "../../helpers/loginHelper";
import * as rest from "../../helpers/restHelper";
import * as sagaHelper from "../../helpers/sagaAndReducerHelper";

function* smartIdLoginSaga({ data, history, saveUserData }) {
    try {
        const responseInit = yield call(
            rest.post,
            endpointsBackend.USER_ACCOUNT_LOGIN_VIA_SMART_ID_INIT,
            data
        );
        yield put(actions.smartIdLoginInitSuccess(responseInit.data));
        // The verification code has now been received from backend

        const responseFinalize = yield call(
            rest.post,
            endpointsBackend.USER_ACCOUNT_LOGIN_VIA_SMART_ID_FINALIZE,
            {
                username: data.username,
                hashInBase64: responseInit.data.hashInBase64,
                hashType: responseInit.data.hashType,
                identityType: data.identityType,
                countryCode: data.countryCode,
                identityNumber: data.identityNumber
            }
        );
        yield put(actions.smartIdLoginFinalizeSuccess(responseFinalize.data));

        loginHelper.saveLastUser(data.username, responseFinalize.data.tenantExternalId);
        if (saveUserData) {
            loginHelper.saveUserSmartIdData(data.username, data.countryCode, data.identityNumber);
            loginHelper.saveLoginType(loginHelper.LOGIN_TYPE_SMART_ID);
        } else {
            loginHelper.clearUserSmartIdData(data.username);
        }

        loginHelper.saveSmartIdSessionData({
            countryCode: responseFinalize.data.smartIdCountryCode,
            documentNumber: responseFinalize.data.smartIdDocumentNumber,
            identityNumber: responseFinalize.data.smartIdIdentityNumber,
            identityType: responseFinalize.data.smartIdIdentityType,
            loggedIn: responseFinalize.data.smartIdDocumentNumber.length > 0,
            signersCertificate: responseFinalize.data.smartIdSignersCertificate
        });

        history.push(endpointsFrontend.START_PAGE_REDIRECT);
    } catch (e) {
        yield put(actions.smartIdLoginError(e));
    }
}

function* smartIdLoginRefreshSaga() {
    const smartIdSessionData = loginHelper.getSmartIdSessionData();
    if (smartIdSessionData != null) {
        yield put(actions.smartIdLoginRefreshSuccess(smartIdSessionData));
    }
}

function* smartIdSignBasePaymentFileSaga(data, urlInit, urlFinalize, actionInitSuccess) {
    let responseFinalize;

    for (let i in data.paymentFileIdList) {

        const responseInit = yield call(
            rest.post,
            urlInit,
            {
                paymentFileId: data.paymentFileIdList[i],
                signingCertificate: data.signingCertificate,
                testMode: data.testMode
            }
        );
        yield put(actionInitSuccess(responseInit.data));
        // The verification code has now been received from backend

        responseFinalize = yield call(
            rest.post,
            urlFinalize,
            {
                documentNumber: data.documentNumber,
                signingCertificate: data.signingCertificate,
                paymentFileId: data.paymentFileIdList[i],
                hashInSHA256: responseInit.data.signableByteHashInSHA256
            }
        );
    }

    return responseFinalize;
}

function* smartIdSignCM20PaymentFileSaga({ data }) {
    try {
        const responseFinalize = yield call(
            smartIdSignBasePaymentFileSaga,
            data,
            endpointsBackend.CM20PAYMENT_FILE_SIGN_VIA_SMART_ID_INIT,
            endpointsBackend.CM20PAYMENT_FILE_SIGN_VIA_SMART_ID_FINALIZE,
            actions.smartIdSignCM20PaymentFileInitSuccess
        );
        yield put(actions.smartIdSignCM20PaymentFileFinalizeSuccess());
        yield put(actionsCM20PaymentFile.cm20PaymentFileSignSuccess(sagaHelper.getEntriesFromResponseData(responseFinalize.data)));
    } catch (e) {
        yield put(actions.smartIdSignPaymentFileError(e));
    }
}

function* smartIdSignPaymentFileSaga({ data }) {
    try {
        const responseFinalize = yield call(
            smartIdSignBasePaymentFileSaga,
            data,
            endpointsBackend.PAYMENT_FILE_SIGN_VIA_SMART_ID_INIT,
            endpointsBackend.PAYMENT_FILE_SIGN_VIA_SMART_ID_FINALIZE,
            actions.smartIdSignPaymentFileInitSuccess
        );
        yield put(actions.smartIdSignPaymentFileFinalizeSuccess());
        yield put(actionsPaymentFile.paymentFileSignSuccess(sagaHelper.getEntriesFromResponseData(responseFinalize.data)));
    } catch (e) {
        yield put(actions.smartIdSignPaymentFileError(e));
    }
}

function* smartIdSaga() {
    yield (takeLatest(actionTypes.SMART_ID_LOGIN_INIT, smartIdLoginSaga));
    yield (takeLatest(actionTypes.SMART_ID_LOGIN_REFRESH, smartIdLoginRefreshSaga));
    yield (takeLatest(actionTypes.SMART_ID_SIGN_CM20_PAYMENT_FILE_INIT, smartIdSignCM20PaymentFileSaga));
    yield (takeLatest(actionTypes.SMART_ID_SIGN_PAYMENT_FILE_INIT, smartIdSignPaymentFileSaga));
}

export default smartIdSaga;