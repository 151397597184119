import * as actionTypes from "./actionTypes";

const initialState = {
	products: null,
	loading: false,
	error: null
}

export default function productReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.PRODUCT_GET_ALL:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.PRODUCT_GET_BY_ID:
			return {
				...state,
				error: null,
				loading: true
			}
		case actionTypes.PRODUCT_GET_SUCCESS:
			return {
				...state,
				loading: false,
				products: action.products
			}
		case actionTypes.PRODUCT_GET_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		case actionTypes.PRODUCT_CREATE:
			return {
				...state,
				error: null
			}
		case actionTypes.PRODUCT_CREATE_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
}